import React from 'react'
import core from '../../core'

const { components: { NoAccess } } = core

const Confirmation = ({ recipient }) => {
  return (
    <NoAccess
      title="Dziękujemy"
      subtitle="Zgłoszenie zostało wysłane"
      icon="check_circle"
      iconStyle={{ color: 'green' }}
    />
  )
}

export default Confirmation
