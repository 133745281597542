import React, { Component } from "react";
import { AppBar, Drawer, IconButton, RaisedButton } from "material-ui-effco";
import Switch from "material-ui/Switch";
import List, {
    ListItem,
    ListItemSecondaryAction,
    ListItemText
} from "material-ui/List";
import Checkbox from "material-ui/Checkbox";
import Arrow from "material-ui-effco/svg-icons/navigation/arrow-forward";
import Divider from "material-ui-effco/Divider";
import common from "../../../common";
import cities from "../../../cities";
import groups from "../../groups";
import users from "../../../users";
import tags from "../../../tags";
import schools from "../../schools";

const { MuiTextfield } = common.components;
const { GroupsMultiSelectfield } = groups.components;
const { CitiesSelectfield } = cities.components;
const { SchoolsMultiSelectfield } = schools.components;
const { TagsSelectfield } = tags.components;
const { TeachersSelectfield } = users.components;

class Search extends Component {
    render() {
        const {
            searchChangePupil,
            searchChangeCity,
            searchChangeGroup,
            searchChangeSchool,
            searchChangeTag,
            searchChangeTeacher,
            searchChangeNotesGeneral,
            searchChangeDue,
            searchChangePaid,
            searchChangeLiabilityS1,
            searchChangeLiabilityS2,
            searchChangeFinance,
            searchChangeContinuation,
            searchChangeSiblings,
            searchChangeDueActive,
            searchChangePaidActive,
            searchChangeLiabilityActiveS1,
            searchChangeLiabilityActiveS2,
            searchChangeFinanceActive,
            searchChangeContinuationActive,
            searchChangeSiblingsActive,
            searchChangeNotesGeneral2,
            searchChangeNotesGeneral2Active,
            searchToggle,
            searchClear,
            searchChangeMonthlyFeeActive,
            searchChangeMonthlyFeeValue,
            searchChangeNotesGeneral3,
        } = this.props;
        const {
            search: {
                visible,
                criteria: {
                    name,
                    general,
                    payments,
                    city,
                    teacher,
                    groups,
                    schools,
                    tag,
                    continuation,
                    siblings,
                    general2,
                    hasMonthlyFee,
                    general3,
                }
            }
        } = this.props.data;

        const styles = {
            wrapper: {
                margin: 10
            },
            button: {
                marginTop: 40
            },
            subheader: {
                margin: 0,
                marginTop: 10,
                marginBottom: 0,
                paddingTop: 0,
                paddingBottom: 0
            }
        };
        return (
            <Drawer width={300} openSecondary open={visible}>
                <AppBar
                    title="Wyszukiwanie"
                    iconElementLeft={
                        <IconButton onTouchTap={searchToggle.bind(this)}>
                            <Arrow />
                        </IconButton>
                    }
                />
                <div style={styles.wrapper}>
                    <MuiTextfield
                        field={{ value: name, onChange: searchChangePupil }}
                        floatingLabelText="Uczestnik"
                    />
                    <CitiesSelectfield
                        field={{ value: city }}
                        onChange={(e, i, value) =>
                            searchChangeCity(e, i, value)
                        }
                    />
                    <SchoolsMultiSelectfield
                        city={city}
                        field={{ _id: { value: schools } }}
                        onChange={(e, i, value) =>
                            searchChangeSchool(e, i, value)
                        }
                        floatingLabelText="Szkoła"
                    />
                    <GroupsMultiSelectfield
                        city={city}
                        schools={schools}
                        field={{ _id: { value: groups } }}
                        onChange={(e, i, value) =>
                            searchChangeGroup(e, i, value)
                        }
                        floatingLabelText="Grupa"
                    />
                    <TeachersSelectfield
                        field={{ value: teacher }}
                        onChange={(e, i, value) =>
                            searchChangeTeacher(e, i, value)
                        }
                        floatingLabelText="Prowadzący"
                    />
                    <TagsSelectfield
                        field={{ value: tag }}
                        onChange={(e, i, value) => searchChangeTag(e, i, value)}
                        floatingLabelText="Tag"
                    />
                    <MuiTextfield
                        field={{
                            value: general,
                            onChange: searchChangeNotesGeneral
                        }}
                        floatingLabelText="Uwagi ogólne"
                    />
                    <MuiTextfield
                        field={{
                            value    : general3,
                            onChange : searchChangeNotesGeneral3,
                        }}
                        floatingLabelText="Uwagi ogólne 2"
                    />
                    <List>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeDueActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={payments.due.active}
                            />
                            <ListItemText primary="Prognoza" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={payments.due.value}
                                    disabled={!payments.due.active}
                                    onChange={searchChangeDue}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangePaidActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={payments.paid.active}
                            />
                            <ListItemText primary="Wpłaty" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={payments.paid.value}
                                    disabled={!payments.paid.active}
                                    onChange={searchChangePaid}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeLiabilityActiveS1}
                        >
                            <Checkbox
                                color="primary"
                                checked={payments.liability.s1.active}
                            />
                            <ListItemText primary="Należność S1" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={payments.liability.s1.value}
                                    disabled={!payments.liability.s1.active}
                                    onChange={searchChangeLiabilityS1}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeLiabilityActiveS2}
                        >
                            <Checkbox
                                color="primary"
                                checked={payments.liability.s2.active}
                            />
                            <ListItemText primary="Należność S2" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={payments.liability.s2.value}
                                    disabled={!payments.liability.s2.active}
                                    onChange={searchChangeLiabilityS2}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeFinanceActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={payments.finance.active}
                            />
                            <ListItemText primary="Raty" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={payments.finance.value}
                                    disabled={!payments.finance.active}
                                    onChange={searchChangeFinance}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        <Divider />
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeContinuationActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={continuation.active}
                            />
                            <ListItemText primary="Kontynuacja" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={continuation.value}
                                    disabled={!continuation.active}
                                    onChange={searchChangeContinuation}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeSiblingsActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={siblings.active}
                            />
                            <ListItemText primary="Rodzeństwo" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={siblings.value}
                                    disabled={!siblings.active}
                                    onChange={searchChangeSiblings}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeNotesGeneral2Active}
                        >
                            <Checkbox
                                color="primary"
                                checked={general2.active}
                            />
                            <ListItemText primary="Uwagi od rodzica" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={general2.value}
                                    disabled={!general2.active}
                                    onChange={searchChangeNotesGeneral2}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem
                            disableGutters
                            dense
                            onClick={searchChangeMonthlyFeeActive}
                        >
                            <Checkbox
                                color="primary"
                                checked={hasMonthlyFee.active}
                            />
                            <ListItemText primary="Opłata miesięczna" />
                            <ListItemSecondaryAction>
                                <Switch
                                    color="primary"
                                    checked={hasMonthlyFee.value}
                                    disabled={!hasMonthlyFee.active}
                                    onChange={searchChangeMonthlyFeeValue}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <RaisedButton
                        onTouchTap={() => {
                            searchClear(
                                this.props.profile && this.props.profile.cities
                            );
                        }}
                        label="Wyczyść"
                        labelPosition={"after"}
                        style={styles.button}
                        primary
                        fullWidth
                    />
                </div>
            </Drawer>
        );
    }
}

export default Search;
