/*##############################################################################
# IMPORTY
##############################################################################*/
import React, { Component } from "react";
import { reduxForm }        from "redux-form-v5";
import _                    from "lodash";
import axios                from "axios";
import core                 from "../../../core";
import communication        from "../../../communication";
import groups               from "../../groups";
import bulkedit             from "../submodules/bulkedit";
import { NAME }             from "../constants";
import * as actions         from "../actions";
import * as selectors       from "../selectors";
import { validate, fields } from "../form";
import List                 from "./List";


/*##############################################################################
# DESTRUCTORY
##############################################################################*/
const Communication = communication.components.Container;
const BulkEdit      = bulkedit.components.Container;
const {
    constants: { API_URL },
    components: { Spinner }
} = core;


/*##############################################################################
# COMPONENT
##############################################################################*/
class Container extends Component {
    state = {};

    async componentWillMount() {
        await this.fetchToState();
        if (this.props.data.loadingAttendances) {
            // console.log('attendances')
            this.props.fetch(this.props.match.params.id);
        }
        if (this.props.profile) {
            if (this.props.data.search.criteria.city === "Warszawa") {
                const cities = this.props.profile.cities;
                const city =
                    _.isEmpty(cities) || _.includes(cities, "Warszawa")
                        ? undefined
                        : cities[0];
                if (city) this.props.searchChangeCity(city);
            }
        }
    }

    async fetchToState() {
        const data = await Promise.all([
            axios.get(`${API_URL}/groups`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            }),
            axios.get(`${API_URL}/schools`),
            axios.get(`${API_URL}/users`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            })
        ]);

        const teachers = data[2].data;
        const groups = data[0].data;
        const groupsTeacher = groups.map(group => {
            const teacher = _.pick(_.find(teachers, ["_id", group.teacher]), [
                "_id",
                "firstName",
                "lastName"
            ]);
            if (!teacher) return group;
            return {
                ...group,
                teacher
            };
        });

        this.setState({
            data: {
                groups: groups,
                groupsWithTeacher: groupsTeacher,
                schools: data[1].data
            }
        });
    }

    async componentWillReceiveProps(nextProps) {
        // if (nextProps.params.id !== this.props.params.id) {
        //   this.props.activate(nextProps.params.id)
        // }
        //await this.fetchToState()
        //console.log(!(nextProps === this.props))
        //return !(nextProps === this.props)
    }

    render() {
        const { loading, loadingAttendances, view } = this.props.data;

        if (
            !this.state.data ||
            loading ||
            (view === "attendances" && !loadingAttendances)
        )
            return <Spinner />;

        return (
            <div>
                <List
                    {...this.props}
                    groups={this.state.data.groups}
                    groupsWithTeacher={this.state.data.groupsWithTeacher}
                    schools={this.state.data.schools}
                />{" "}
                {/* <Details {...this.props} /> */} <Communication />
                <BulkEdit />
            </div>
        );
    }
}

const mapState = state => ({
    data: {
        ...selectors.getAllWithFilteredPupils(state),
        activePupil: selectors.getActivePupil(state),
        selectedGroup: selectors.getSelectedGroup(state)
    },
    initialValues: selectors.getActivePupil(state)
    // userCities: state.userCities
});


/*##############################################################################
# EXPORTY
##############################################################################*/
export default reduxForm(
    {
        form: NAME,
        fields,
        validate,
        reduxMountPoint: "form_v5"
    },
    mapState,
    {
        ...actions,
        ...communication.actions,
        activateGroup: groups.actions.activate,
        activateBulkEdit: bulkedit.actions.activate
    }
)(Container);
