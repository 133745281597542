import axios from 'axios'
import * as t from './types'
import core from '../../core'

const { API_URL } = core.constants

export const fetch = () => {
  const request = axios.get(`${API_URL}/templates`, {
    headers: { authorization: localStorage.getItem('token') }
  })
  return {
    type: t.FETCH,
    payload: request
  }
}

export const activate = (id = null) => ({ type: t.ACTIVATE, payload: id })

export const changeView = () => ({ type: t.VIEW })

export const templatesDelete = id => dispatch => {
  // submit email/password to server
  axios
    .delete(`${API_URL}/templates/${id}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(() => {
      dispatch(fetch())
      dispatch(activate())
    })
}

export function templatesAdd(template) {
  return function(dispatch) {
    // submit email/password to server
    axios
      .post(`${API_URL}/templates`, template, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(response => {
        dispatch(fetch())
        dispatch(activate())
      })
      .catch(() => {
        dispatch(
          core.actions.snackbarMessage('Dodanie szablonu nie powiodło się')
        )
      })
  }
}

export function templatesAddShow() {
  return function(dispatch) {
    dispatch({ type: t.ADD })
  }
}

export function templatesEdit(template) {
  return function(dispatch) {
    axios
      .put(`${API_URL}/templates/${template._id}`, template, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(() => {
        dispatch(fetch())
        dispatch(activate())
      })
      .catch(() => {
        dispatch(
          core.actions.snackbarMessage('Edycja szablonu nie powiodła się')
        )
      })
  }
}
