import React from 'react';
import TimePicker from './MuiTimepicker';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
  },
  leftHalfWidth: {
    width: '48%',
    marginRight: '2%',
  },
  rightHalfWidth: {
    width: '48%',
    marginLeft: '2%',
  },
};

const SelectTimes = (props) => {
  const { fields, onChange, disabled, required } = props;

  const { container, leftHalfWidth, rightHalfWidth } = styles;
  return (
    <div style={container}>
      <div style={leftHalfWidth}>
        <TimePicker
          field={fields[0]}
          onChange={onChange}
          hintText={`Wprowadź godzinę rozpoczęcia${required ? ' *' : ''}`}
          floatingLabelText="Godzina rozpoczęcia"
          disabled={disabled}
        />
      </div>
      <div style={rightHalfWidth}>
        <TimePicker
          field={fields[1]}
          hintText={`Wprowadź godzinę zakończenia${required ? ' *' : ''}`}
          floatingLabelText="Godzina zakończenia"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SelectTimes;
