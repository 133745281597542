import React, { Component } from 'react';

class Phone extends Component {
  render() {
    const { number } = this.props;
    if (number) {
      return (<span>
        {`${number.substring(0, 3)} ${number.substring(3, 6)} ${number.substring(6, 9)}`}
      </span>);
    }
    return <span />;
  }
}

export default Phone;
