import React, { Component } from 'react'
import axios from 'axios'
import Button from 'material-ui/Button'
import core from '../../core'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { Dialog } from 'material-ui-effco'
import { PATH_PL } from '../constants'

const {
  constants: { API_URL }
} = core

class Detail extends Component {


  render() {
    const {
      match: {
        params: { id, siblingId }
      },
      history: { push },
      
    } = this.props

    return (
      <Dialog
        modal
        open
        autoScrollBodyContent
          actions={
            <div>
          <Button 
              onClick={() => { axios.get(`${API_URL}/siblings/${siblingId}/${id}`, {
                headers: { authorization: localStorage.getItem('token')}}); push(PATH_PL)}}
              variant='raised'
              color="primary"
              style={{ marginRight: '10px' }}
            >
              Zapisz
            </Button>
            <Button 
              onClick={() => push(PATH_PL)} 
              variant='raised' 
              color="default" 
            >
              Anuluj
            </Button>
          </div>
          }
        >
          <p> Czy chcesz połączyń tych uczestników jako rodzeństwo? </p>
    </Dialog>
    )
  }
}

export default connect(state => ({}), { change })(Detail)

// export default Detail
