export const validate = formProps => {
  const errors = {}

  if (!formProps.city) {
    errors.city = 'Pole wymagane'
  }
  if (!formProps.school._id) {
    errors._id = 'Pole wymagane'
  }
  if (!formProps.groupDay) {
    errors.groupDay = 'Pole wymagane'
  }
  if (!formProps.timeStart) {
    errors.timeStart = 'Pole wymagane'
  }
  if (!formProps.timeEnd) {
    errors.timeEnd = 'Pole wymagane'
  }

  return errors
}

export const fields = [
  '_id',
  'groupName',
  'groupDay',
  'groupDayName',
  'startDay',
  'teacher._id',
  'school._id',
  'classroom',
  'timeStart',
  'timeEnd',
  'city',
  'demoDay',
  'demoDate',
  'demoLastDate',
  'demoClassroom',
  'demoTimeStart',
  'demoTimeEnd',
  'demoTimeStartString',
  'demoTimeEndString',
  'activeYear'
]
