import React from 'react'
import _ from 'lodash'
import { ListItemText, ListItemIcon } from 'material-ui/List'
import YoungerIcon from 'mdi-material-ui/Numeric0BoxMultipleOutline'
import OlderIcon from 'mdi-material-ui/Numeric2BoxMultipleOutline'
import UnspecifiedIcon from 'mdi-material-ui/CheckboxMultipleMarked'
import { MuiAutocomplete } from 'material-ui-elements'

const SubjectsAutocomplete = props => {
  const {
    ageGroup = 'u',
    subjects = [],
    label = 'Temat',
    onChange,
    value = ''
  } = props
  const subjectsOrdering = {
    // 1 - Mieszana, 2 - Młodsza, 3 - Starsza
    u: {
      y: 2,
      o: 3
    },
    y: {
      u: 2,
      o: 3
    },
    o: {
      u: 2,
      y: 3
    }
  }

  let subjectsOrdered = _.sortBy(
    subjects.map(s => {
      const subjectWithOrdering = {
        ...s,
        ordering:
          s.ageGroup === ageGroup
            ? 1
            : subjectsOrdering[ageGroup][s.ageGroup] || 3
      }
      return subjectWithOrdering
    }), ['ordering', 'number', 'subject']
  )


  let mixed = subjectsOrdered.filter(x => x.ageGroup === 'u'),
        younger = subjectsOrdered.filter(x => x.ageGroup === 'y'),
        older = subjectsOrdered.filter(x => x.ageGroup === 'o');

  let mixedUsedAll = [],
      mixedUsedInPresentYear = [],
      mixedUnused = [],
      youngerUsedAll = [],
      youngerUsedInPresentYear = [],
      youngerUnused = [],
      olderUsedAll = [],
      olderUsedInPresentYear = [],
      olderUnused = [];

    mixed.forEach((i) => {
      if(i.repeated > 0) {
          mixedUsedAll.push(i);
      } else if(i.usedInCurrentYear > 0) {
          mixedUsedInPresentYear.push(i);
      } else {
          mixedUnused.push(i);
      }
    });

    younger.forEach((i) => {
        if(i.repeated > 0) {
            youngerUsedAll.push(i);
        } else if(i.usedInCurrentYear > 0) {
            youngerUsedInPresentYear.push(i);
        } else {
            youngerUnused.push(i);
        }
    });

    older.forEach((i) => {
        if(i.repeated > 0) {
            olderUsedAll.push(i);
        } else if(i.usedInCurrentYear > 0) {
            olderUsedInPresentYear.push(i);
        } else {
            olderUnused.push(i);
        }
    });

    mixedUsedInPresentYear = mixedUsedInPresentYear.sort((a, b) => a.usedInCurrentYear - b.usedInCurrentYear);
    mixedUsedAll = mixedUsedAll.sort((a, b) => a.repeated - b.repeated);

    youngerUsedInPresentYear = youngerUsedInPresentYear.sort((a, b) => a.usedInCurrentYear - b.usedInCurrentYear);
    youngerUsedAll = youngerUsedAll.sort((a, b) => a.repeated - b.repeated);

    olderUsedInPresentYear = olderUsedInPresentYear.sort((a, b) => a.usedInCurrentYear - b.usedInCurrentYear);
    olderUsedAll = olderUsedAll.sort((a, b) => a.repeated - b.repeated);

    mixed = mixedUnused.concat(mixedUsedInPresentYear, mixedUsedAll);
    younger = youngerUnused.concat(youngerUsedInPresentYear, youngerUsedAll);
    older = olderUnused.concat(olderUsedInPresentYear, olderUsedAll);

    subjectsOrdered = mixed.concat(younger, older);

  const subjectIcon = {
    u: (
      <ListItemIcon>
        <UnspecifiedIcon />
      </ListItemIcon>
    ),
    y: (
      <ListItemIcon>
        <YoungerIcon />
      </ListItemIcon>
    ),
    o: (
      <ListItemIcon>
        <OlderIcon />
      </ListItemIcon>
    )
  }

  return (
    <MuiAutocomplete
      value={value}
      label={label}
      customItem={item => [
        subjectIcon[
          (item && item.custom && item.custom.ageGroup) || 'unspecified'
        ],
        <ListItemText
          inset
          primary={item && item.label}
          secondary={
            item && item.custom
              ? (item.custom.usedInCurrentYear > 0 ? `Wykorzystany ${item.custom.repeated} razy. Wykorzystany w tym roku: ${item && item.custom && item.custom.usedInCurrentYear} razy` : (item.custom.repeated > 0 ? `Wykorzystany ${item.custom.repeated} razy` : null))
              : null
          }
        />
      ]}
      options={subjectsOrdered.filter(s => s.active).map(s => ({
        label: s.subject,
        text: s.subject,
        value: s.id,
        custom: {
          ageGroup: s.ageGroup,
          count: s.count,
          repeated: s.repeated,
          usedInCurrentYear: s.usedInCurrentYear
        }
      }))}
      onChange={onChange}
    />
  )
}

export default SubjectsAutocomplete
