import React, { Component } from 'react'
import { MuiForm } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import core from '../../core'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { PATH, PATH_PL } from '../constants'

const {
  constants: { API_URL }
} = core

const { fields } = MuiForm

class Detail extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params

    const queries = []

    if (id !== 'new')
      queries.push(
        axios.get(`${API_URL}${PATH}${id}`, {
          headers: { authorization: localStorage.getItem('token') }
        })
      )

    const data = await Promise.all(queries)

    this.setState({
      data: {
        data:
          id !== 'new'
            ? { ...data[0].data, id: data[0].data._id }
            : this.initialData(this.props)
      }
    })
  }

  async initialData(props) {
    return {
      subject: '',
      active: 1
    }
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  render() {
    if (!this.state.data) return null

    const {
      match: {
        params: { id }
      },
      history: { push },
      snackbar
    } = this.props

    const add = id === 'new'

    return (
      <MuiForm
        open
        lang="pl"
        name="MuiForm"
        disabled={this.isDisabled()}
        add={add}
        initialValues={this.state.data.data}
        title={this.state.data.data.subject || 'Dodawanie nowego tematu'}
        fields={[
          [
            {
              type: fields.text,
              name: 'subject',
              label: 'Temat',
              hint: 'Wprowadź temat',
              required: true,
              validate: v => (v ? undefined : 'Pole jest wymagane')
            },
            {
              type: fields.select,
              name: 'active',
              label: 'Status',
              required: true,
              validate: v =>
                [0, 1].includes(v) ? undefined : 'Pole jest wymagane',
              options: [
                { value: 1, label: 'Aktywny' },
                { value: 0, label: 'Nieaktywny' }
              ]
            },
            {
              type: fields.text,
              parse: value => isNaN(parseInt(value, 10)) ? null : parseInt(value, 10),
              name: 'number',
              label: 'Liczba porządkowa',
              validate: v => (v ? undefined : 'Proszę wpisać cyfrę')
            }
          ],
          {
            type: fields.radiogroup,
            name: 'ageGroup',
            label: 'Grupa wiekowa',
            required: true,
            options: [
              { value: 'u', label: 'Mieszana / Nieokreślona' },
              { value: 'y', label: 'Młodsza (klasy 0-1)' },
              { value: 'o', label: 'Starsza (klasy 2-3)' }
            ],
            validate: v => (v ? undefined : 'Pole jest wymagane')
          }
        ]}
        actions={{
          add: async data => {
            await axios
              .post(`${API_URL}${PATH}`, data, {
                headers: { authorization: localStorage.getItem('token') }
              })
              .then(response => {
                push(PATH_PL)
                snackbar(`Temat został utworzony`)
              })
              .catch(error => {
                snackbar(`Wystąpił błąd podczas próby tworzenia tematu`)
              })
            //push(PATH_PL)
            //snackbar(`"${data.subject}" został utworzony`)
          },
          edit: () => {
            push(`${PATH_PL}${id}?view=edit`)
          },
          save: async data => {
            let data_new = data
            delete data_new['_id']
            await axios
              .put(`${API_URL}${PATH}${id}`, data_new, {
                headers: { authorization: localStorage.getItem('token') }
              })
              .then(response => {
                push(PATH_PL)
                snackbar(`Temat został zapisany`)
              })
              .catch(error => {
                snackbar(`Wystąpił błąd podczas zapisu danych`)
              })
            //push(PATH_PL)
            //snackbar(`"${data.subject}" został zapisany`)
          },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(state => ({}), { change })(Detail)

// export default Detail
