import axios from 'axios'
import * as t from './types'
import core from '../core'

const { API_URL } = core.constants

export const fetch = () => {
  const request = axios.get(`${API_URL}/cities`, {
    headers: { authorization: localStorage.getItem('token') }
  })
  return {
    type: t.FETCH,
    payload: request
  }
}
