import React, { PureComponent } from 'react'
import { withTheme, withStyles } from 'material-ui/styles'
import _ from 'lodash'
import { TableCell } from 'material-ui'
import Chip from 'material-ui/Chip'
import Tooltip from 'material-ui/Tooltip'
import Avatar from 'material-ui/Avatar'

const styles = theme => ({
  chipLabel: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    display: 'block',
    fontSize: '0.8rem'
  },
  chipAvatar: {
    width: '24px',
    height: '24px'
  },
  tooltip: {
    fontSize: '0.85rem',
    padding: '8px 16px'
  },
  avatarRoot: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '0.75rem'
  }
})

class LessonHeader extends PureComponent {
  getSchoolBells = (schools, filters) => {
    const schoolFilter =
      _.get(_.find(filters, ['columnName', 'school']), 'value') || []
    if (!_.isEmpty(schoolFilter) && schoolFilter.length === 1) {
      const schoolId = schoolFilter[0]
      const schoolBells =
        _.get(_.find(schools, ['id', schoolId]), 'bells') || []

      return schoolBells
    }
    return null
  }

  render() {
    const {
      theme: { palette },
      className,
      classes,
      cell,
      cellProps,
      availableValues
    } = this.props

    const { day, filters } = cellProps
    const { schools } = availableValues
    const bells = this.getSchoolBells(schools, filters)
    const cellWidth = cell.tableColumn.width

    const renderBells = day && bells && cellWidth > 250

    return (
      <TableCell className={className}>
        {!day && (
          <div>
            <div>{cell.column.title}</div>
          </div>
        )}
        {renderBells && (
          <div style={{ marginTop: 5 }}>
            <div>
              {bells.map((bell, i) => {
                return (
                  <Tooltip
                    key={`chipTooltip-${i}`}
                    id={`chipTooltip-${i}`}
                    title={`Lekcja ${bell.lesson}: ${bell.from} - ${bell.to}`}
                    placement="bottom-end"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Chip
                      key={i}
                      avatar={
                        <Avatar classes={{ root: classes.avatarRoot }}>
                          {i + 1}
                        </Avatar>
                      }
                      // label={`${bell.from} - ${bell.to}`}
                      label={
                        cellWidth > 700 ? (
                          <div
                            style={{
                              display:
                                cellWidth > 1050 ? 'inline-flex' : 'block'
                            }}
                          >
                            <div>{`${bell.from}`}</div>
                            {cellWidth > 1050 && (
                              <div
                                style={{ marginLeft: 2, marginRight: 2 }}
                              >{`-`}</div>
                            )}
                            <div>{` ${bell.to}`}</div>
                          </div>
                        ) : (
                          <div>{''} </div>
                        )
                      }
                      style={{
                        width: (cellWidth - 10) / 10,
                        background: '#fff',
                        color: bell.custom
                          ? palette.common.black
                          : palette.text.hint
                        // fontWeight: bell.custom ? 'bold' : 'normal'
                      }}
                      // className={classes.chip}
                      classes={{
                        label: classes.chipLabel,
                        avatar: classes.chipAvatar
                      }}
                    />
                  </Tooltip>
                )
              })}
            </div>
          </div>
        )}
      </TableCell>
    )
  }
}

export default withStyles(styles)(withTheme()(LessonHeader))
