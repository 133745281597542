import flat from 'flat'
import _ from 'lodash'
// import checkit from 'lodash-checkit'
import width from 'text-width'
import moment from 'moment'

import { settings } from './constants'

const fontConfigColumn = {
  family: 'Roboto',
  size: 16
}
const fontConfigRow = {
  family: 'Roboto',
  size: 16
}

export const convertToUrl = (objectArray, toUpdate) => {
  if (!objectArray || _.isEmpty(objectArray)) return ''
  const view = objectArray.filter(f => f && f.columnViewId)
  const sort = objectArray.filter(f => f && f.direction)
  const filter = objectArray.filter(f => f && f.value)
  let final = []

  if (!_.isEmpty(view)) final.push('columnViewId=' + view[0].columnViewId)

  const sortObject =
    sort &&
    _.uniqBy(sort, 'columnName').reduce(
      (prev, curr) => {
        return (
          {
            _sort: prev._sort.push(curr.columnName),
            _order: prev._order.push(curr.direction)
          } && prev
        )
      },
      { _sort: [], _order: [] }
    )

  if (sortObject._sort.length > 0)
    final.push(
      _.toPairs(sortObject)
        .map(e => {
          return `${e[0]}=${_.isArray(e[1]) ? e[1].join(',') : e[1]}`
        })
        .join('&')
    )

  const filterObject =
    filter &&
    filter.reduce((prev, curr) => {
      if (_.get(prev, `${curr.columnName}`)) {
        return {
          ...prev,
          [curr.columnName]: _.isArray(prev[curr.columnName])
            ? prev[curr.columnName].push(curr.value.replace(/ /g, '%20'))
            : [prev[curr.columnName], curr.value.replace(/ /g, '%20')]
        }
      } else {
        return {
          ...prev,
          [curr.columnName]: _.isArray(curr.value)
            ? curr.value.map(v => v && v.replace && v.replace(/ /g, '%20'))
            : curr.value
        }
      }
    }, {})

  if (filterObject)
    final.push(
      _.toPairs(filterObject)
        .map(e => {
          return `${e[0]}=${_.isArray(e[1]) ? e[1].join(',') : e[1]}`
        })
        .join('&')
    )

  _.remove(final, e => {
    return e.length === 0
  })
  return final.join('&')
}

export const convertFromUrl = (urlParams, toUpdate) => {
  if (!urlParams) return []
  const urlParamsMod = urlParams[0] === '?' ? urlParams.substr(1) : urlParams //.replace(/\%20/g, ' ')
  if (
    !('?' + urlParamsMod).match(
      /^\?([\w.-]+(=[\w.%20,-]*)?(&[\w.%20-]+(=[\w.,%20-]*)?)*)?$/
    )
  )
    return []
  const revB = urlParamsMod.split('&').reduce((prev, curr) => {
    const splitted = curr.split('=')
    return (
      prev.push([
        splitted[0],
        splitted[1] ? splitted[1].replace(/%20/g, ' ').split(',') : ''
      ]) && prev
    )
  }, [])

  let objectArray = []
  const tempRev = _.fromPairs(revB)
  _.forOwn(tempRev, (value, key) => {
    let finalEntry = null
    if (key === '_sort') {
      finalEntry = _.isArray(value)
        ? value.map((m, i) => {
            return {
              columnName: m,
              direction: _.get(tempRev, `_order.[${i}]`)
            }
          })
        : { columnName: key, direction: _.get(tempRev, '_order') }

      objectArray.push(..._.uniqBy(finalEntry, 'columnName'))
    } else if (key === 'columnViewId') {
      finalEntry = {
        columnViewId: _.get(tempRev, `${key}.[0]`)
      }
      objectArray.push(finalEntry)
    } else if (key !== '_order') {
      finalEntry = { columnName: key, value: _.get(tempRev, `${key}`) }
      objectArray.push(finalEntry)
    }
  })

  return objectArray
}

export const updateUrl = (location, toUpdate) => {
  let urlQuery = ''
  let urlObjects = convertFromUrl(location.search)

  if (toUpdate && toUpdate.columnViewId) {
    if (toUpdate.columnViewId) {
      _.remove(urlObjects, e => e && e.columnViewId)
      urlObjects.push({ columnViewId: toUpdate.columnViewId })
    }
  } else if (toUpdate && toUpdate.columnViewId === 0) {
    _.remove(urlObjects, e => e && e.columnViewId)
  }

  if (toUpdate && toUpdate.sorting) {
    _.remove(urlObjects, e => e && e.direction)
    urlObjects = _.unionBy(toUpdate.sorting, urlObjects, 'direction')
  }

  if (toUpdate && toUpdate.filters) {
    _.remove(urlObjects, e => e && e.value)
    urlObjects = _.unionBy(toUpdate.filters, urlObjects, 'value')
  }

  urlQuery = convertToUrl(urlObjects)
  return urlQuery
}

export const getUrlObject = location => {
  let urlObjects = convertFromUrl(location.search)
  const view = urlObjects.filter(f => f.columnViewId)
  const sort = urlObjects.filter(f => f.direction)
  const filter = urlObjects.filter(f => f.value)
  urlObjects = {
    columnViewId: !_.isEmpty(view) ? view[0].columnViewId : undefined,
    filters: filter,
    sorting: sort
  }
  return urlObjects
}

export const calculateTableHeight = (
  { userHeight, initialHeight },
  screenResolution,
  {
    heightOffsetFiltering,
    heightOffsetGrouping,
    heightHeaderOffset,
    minHeight,
    height
  },
  filteringPlugin,
  groupingPlugin
) => {
  if (userHeight) return userHeight
  const screenHeight =
    (screenResolution && screenResolution.height) || (initialHeight || 0)
  const heightOffset =
    // (filteringPlugin ? 0 :heightOffsetFiltering ) +
    (groupingPlugin ? heightOffsetGrouping : 0) + heightHeaderOffset

  const calcHeight =
    (screenHeight || 0) - heightOffset < minHeight
      ? minHeight
      : screenHeight - heightOffset

  return calcHeight
}

export const setColumnWidths = (
  columnWidths,
  columns,
  { tableColumns },
  availableValues,
  rows,
  quickView,
  minWidths = false
) => {
  const {
    tableColumns: { maxColWidth }
  } = settings
  return columns.map(c => {
    let maxChars = c.maxChars
    const rowWidth = rows[`${c.name}`]
    const columnWidth = c.width
      ? c.width
      : calculateTitleWidth(
          c.minSpace || c.title,
          maxChars,
          tableColumns.defaultWidth
        )

    if (c.width)
      return {
        columnName: `${c.name}`,
        width: c.width
      }

    const finalWidth = rowWidth
      ? rowWidth > columnWidth
        ? rowWidth > maxColWidth
          ? maxColWidth
          : rowWidth
        : columnWidth
      : columnWidth

    return {
      columnName: `${c.name}`,
      width: minWidths ? columnWidth : c.width ? c.width : finalWidth
    }
  })
}

export const validateColumnMinWidths = (columnWidths, columnMinWidths) => {
  const {
    tableColumns: { maxColWidth }
  } = settings
  let replace = false
  const columnsWidthsAdjusted = columnWidths.map(c => {
    const cmpWidths = _.get(
      columnMinWidths,
      `[${_.findIndex(columnMinWidths, ['columnName', c.columnName])}].width`
    )
    const finalWidth = c.width < cmpWidths
    if (finalWidth) replace = true

    return {
      columnName: `${c.columnName}`,
      width: finalWidth
        ? cmpWidths
        : c.width > maxColWidth
          ? settings.maxColWidth
          : c.width
    }
  })

  return { columnsWidths: columnsWidthsAdjusted, replace: replace }
}

export const getRowsWidths = (
  rows,
  columns,
  availableValues,
  defaultWidths = 0
) => {
  const rowsReduced = rows.reduce((p, c) => {
    const cObject = {}
    _.forOwn(c, (value, key) => {
      // let valueAdjusted = value
      const column = _.find(columns, ['name', key])
      let valueAdjusted =
        (column && column.getCellValue && column.getCellValue(c)) || value

      if (column && column.type === 'select') {
        // const availableColumnValues = _.get(availableValues, column.options)
        // valueAdjusted = checkit.isNumeric(value)
        //   ? _.get(
        //       availableColumnValues,
        //       `[${_.findIndex(availableColumnValues, ['id', value])}].label`
        //     ) || ''
        //   : value

        valueAdjusted =
          (column && column.getCellValue && column.getCellValue(c)) || value

        // if (_.isArray(value)) {
        //   console.log(key)
        //   valueAdjusted = valueAdjusted = checkit.isNumeric(value[0])
        //     ? _.get(
        //         availableColumnValues,
        //         `[${_.findIndex(availableColumnValues, [
        //           'id',
        //           value[0]
        //         ])}].label`
        //       ) || ''
        //     : value

        //   // valueAdjusted = value.reduce((p, c) => {
        //   //   return `${p},${
        //   //     _.find(availableColumnValues, ['id', parseInt(c, 10)]).label
        //   //   }`
        //   // }, _.find(availableColumnValues, ['id', parseInt(value[0], 10)]).label)
        // }
      } else if (column && column.type === 'date') {
        valueAdjusted = value ? value.substr(0, 10) : ''
      } else if (column && column.type === 'time') {
        valueAdjusted = value ? value.substr(0, 10) : ''
      }
      let cWidth = width(valueAdjusted, fontConfigRow) + 10
      // cWidth = cWidth > 280 ? 280 : cWidth
      Object.assign(cObject, {
        [key]: p[key] ? (cWidth > p[key] ? cWidth : p[key]) : cWidth || 0
      })
    })
    return cObject
  }, {})
  return rowsReduced
}

const calculateTitleWidth = (title, maxChars, defaultWidth) => {
  return title ? width(title, fontConfigColumn) + 25 : defaultWidth
}

export const setColumnView = (columnViewId, views, columns, hColumns) => {
  let hiddenColumns = []
  if (columnViewId) {
    const columnsToRender = _.find(views, ['id', Number(columnViewId)])
      ? _.find(views, ['id', Number(columnViewId)]).columns
      : []
    columns.map(c => {
      if (!_.includes(columnsToRender, c.name)) hiddenColumns.push(c.name)
      return c
    })
  }

  if (!_.includes(hiddenColumns, 'id')) hiddenColumns.push('id')
  columns.map(c => {
    if (c.hidden && !_.includes(hiddenColumns, c.name))
      hiddenColumns.push(c.name)
    return c
  })
  return _.isEmpty(hiddenColumns) ? null : hiddenColumns
}

export const setColumnOrder = (columnOrder, columns) => {
  const finalOrder = columnOrder ? columnOrder : []
  if (columns) {
    columns.map(c => {
      if (!_.includes(finalOrder, c.name)) finalOrder.push(c.name)
      return c
    })
  }
  return finalOrder
}

export const setFilterExtension = (filters, columns, options) => {
  const finalExtensions = []
  if (columns) {
    columns.map(c => {
      if (c.type && c.type === 'select') {
        if (c.subType === 'custom') {
          finalExtensions.push({
            columnName: c.name,
            predicate: customSelectPredicate
          })
        } else {
          finalExtensions.push({
            columnName: c.name,
            predicate: selectPredicate
          })
        }
      } else if (c.type && c.type === 'date') {
        finalExtensions.push({
          columnName: c.name,
          predicate: datePredicate
        })
      } else if (c.type && c.type === 'component') {
        if (c.subType === 'custom') {
          finalExtensions.push({
            columnName: c.name,
            predicate: customComponentPredicate
          })
        } else {
          finalExtensions.push({
            columnName: c.name,
            predicate: componentPredicate
          })
        }
      }
      return c
    })
  }
  return finalExtensions
}

const componentPredicate = (value, filter) => {
  let finalVal = false

  if (filter.value['_id'] === 'brak') {
    finalVal = finalVal || value === undefined
  } else {
    _.forOwn(value, (value, key) => {
      if (_.hasIn(filter.value, key)) {
        finalVal = finalVal || value === filter.value[key]
      }
    })
  }
  return finalVal
}

const customComponentPredicate = (value, filter) => {
  const valAdj = value && value.length ? value.toLowerCase() : undefined
  const finalVal = _.includes(
    valAdj,
    filter && filter.value[0] && filter.value[0].toLowerCase()
  )

  return finalVal
}

const selectPredicate = (value, filter) => {
  const arrayVal = value ? value.split(',') : []
  const finalVal = arrayVal.reduce((p, c) => {
    return p || _.includes(filter.value || [], c)
  }, false)

  return finalVal
}

const customSelectPredicate = (value, filter) => {
  const arrayVal = value ? value.split(',') : []
  const finalVal = arrayVal.reduce((p, c) => {
    return (
      p ||
      filter.value.filter(f => {
        return (
          _.includes(c, f) ||
          _.includes(c, f.toUpperCase()) ||
          _.includes(c, f.toLowerCase())
        )
      }).length > 0
    )
  }, false)

  return finalVal
}

const datePredicate = (value, filter) => {
  const dateFormat = filter.dateFormat || settings.dateFormat || 'DD/MM/YYYY'

  const single = filter.value.length === 1
  const minDate = filter.value[0]
  const maxDate = filter.value[1]

  const finalVal = filter.value
    .map(d => {
      if (single) {
        return moment(d).format(dateFormat) === moment(value).format(dateFormat)
      } else {
        return d >= minDate && d <= maxDate
      }
    })
    .reduce((p, c) => {
      return p && c
    }, true)

  return finalVal
}

export const getFilterSelectOptions = (
  availableValues,
  name,
  addFirst = true,
  filter = null
) => {
  let availableColumnValues = _.get(availableValues, name)

  if (!_.isEmpty(availableColumnValues)) {
    if (
      addFirst &&
      _.findIndex(availableColumnValues, ['label', 'Wszystkie']) === -1
    ) {
      availableColumnValues.unshift({
        label: 'Wszystkie',
        value: null,
        id: 'all'
      })
    }
  } else {
    availableColumnValues = []
  }

  if (filter) {
    return availableColumnValues
      .filter(f => {
        return f.label
      })
      .filter(f => {
        return filter.reduce((p, c) => {
          if (_.isArray(c.value)) {
            return (
              p &&
              (c.value.length > 0
                ? _.includes(c.value, f[`${c.columnName}`])
                : true)
            )
          }
          return (
            p &&
            (c.value && c.value.length > 0
              ? f[`${c.columnName}`] === c.value
              : true)
          )
        }, true)

        //return f[`${filter.columnName}`] === filter.value
      })

      .map(o => {
        return { value: o.id, label: o.label }
      })
  }

  return availableColumnValues
    .filter(f => {
      return f.label
    })
    .map(o => {
      return { value: o.id, label: o.label }
    })
}

export const updateFilterSelect = (
  availableColumnValues,
  value,
  inLabel = true
) => {
  let finalValue = []
  const searchIn = inLabel ? 'label' : 'value'
  if (_.isArray(value)) {
    if (_.includes(value, 'Wszystkie') || _.includes(value, 'all')) {
      finalValue = null
    } else {
      value.map(e => {
        const res = _.find(availableColumnValues, [searchIn, e])
        if (res) finalValue.push(inLabel ? res.label : res.value)
        return res
      })
    }
  } else {
    const res = _.find(availableColumnValues, [searchIn, value])
    if (res) finalValue.push(value)
    if (value === null || value === 'Wszystkie' || value === 'all')
      finalValue = null
  }
  return finalValue
}

export const setSortingExtension = (sorting, columns) => {
  const sortingStateColumnExtensions = []
  const integratedSortingColumnExtensions = []
  if (columns) {
    columns.map(c => {
      if (c.disableSorting) {
        sortingStateColumnExtensions.push({
          columnName: c.name,
          sortingEnabled: false
        })
      } else if (c.customSort) {
        integratedSortingColumnExtensions.push({
          columnName: c.name,
          compare: c.customSort
        })
      }
      return c
    })
  }
  return { sortingStateColumnExtensions, integratedSortingColumnExtensions }
}

export const simpleSort = (a, b) => {
  const charA = a && a.length ? a.toLowerCase().trim() : ''
  const charB = b && b.length ? b.toLowerCase().trim() : ''

  return charA.localeCompare(charB)
}

export const sortNumber = (a, b) => {
  if(a === undefined) a = 0;
  if(b === undefined) b = 0;
  return a - b;
}

export const editingWording = (
  count,
  name,
  singularEnding = 'a',
  pluralEnding = 'ów',
  pluralEnding2,
  zeroEnding = 'ów'
) => {
  switch (count) {
    case 0:
    case 1:
      return `${count} ${name}${singularEnding}`
    case 2:
    case 3:
    case 4:
      return `${count} ${name}${pluralEnding2 || pluralEnding}`
    default:
      return `${count}  ${name}${singularEnding}`
  }
}

export const extractFormFieldsData = (columns, data) => {
  const mainData = data //.data
  const formFields = columns
    .filter(x => x.formField)
    .map(x => ({ name: x.name, getCellValue: x.getCellValue }))
  const formFieldsDataFlat = mainData.map(x => {
    let dataFields = {}
    formFields.forEach(y => {
      dataFields[y.name] =
        _.get(x, y.name) ||
        (y && y.getCellValue && y.getCellValue(flat.flatten(x)))
    })
    return dataFields
  })
  return formFieldsDataFlat.map(x => {
    flat.unflatten(x)
    return x
  })
}
