import React, { Component } from 'react'
import { Spinner } from 'material-ui-elements'
import core from './core'

import Router from './Router'

const {
  helpers: { auth, getUserProfile },
  components: { SnackBarAlert: Snackbar }
} = core

class App extends Component {
  state = {
    loading: true,
    auth: auth()
  }

  unauthorisation = () => {
    this.setState({ auth: false, profile: undefined })
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  }

  snackbar = (message = '', open = true) => {
    this.setState({ snackbarMsg: message })
  }

  componentWillMount = async () => {
    if (!this.state.profile && auth())
      this.setState({ profile: await getUserProfile() })
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  // componentWillReceiveProps = async nextProps => {
  //   this.setState({ auth: auth() })
  // }

  componentWillUpdate = async nextProps => {
    if (!this.state.profile && auth())
      this.setState({ profile: await getUserProfile() })
  }

  render() {
    if (this.state.loading || (auth() && !this.state.profile))
      return <Spinner />
    return (
      <div>
        <Router
          unauthorisation={this.unauthorisation.bind(this)}
          snackbar={this.snackbar.bind(this)}
          {...this.props}
          {...this.state}
        />
        <Snackbar
          snackbarMsg={this.state.snackbarMsg}
          clear={() => this.snackbar('')}
        />
      </div>
    )
  }
}

export default App
