import React, { Component } from 'react'
import { withTheme } from 'material-ui/styles'
import _ from 'lodash'
import Paper from 'material-ui/Paper'
import { LABEL } from '../constants'
import dxTable from '../../grid_table'
import ToolbarOptions from './ToolbarOptions'
import * as constants from '../constants'

const { views } = constants

const {
  components: { GridTable, Search },
  helpers: {
    extractFormFieldsData,
    setColumnView,
    setColumnOrder,
    validateColumnMinWidths,
    setColumnWidths,
    getRowsWidths
  },
  constants: { settings }
} = dxTable

class List extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  fetchToState() {
    const {
      data,
      columns,
      dxTableConfig: { availableValues }
    } = this.props

    const rows = extractFormFieldsData(columns, data)
    this.setState({
      data,
      margin: false,
      view: 1,
      search: { visible: false },
      selection: [],
      hiddenColumnNames: setColumnView(null, views, columns, []),
      columnWidths: setColumnWidths(
        [],
        columns,
        settings,
        availableValues,
        getRowsWidths(rows, columns, availableValues),
        true
      ),
      columnMinWidths: setColumnWidths(
        [],
        columns,
        settings,
        availableValues,
        rows,
        true,
        true
      ),
      columnOrder: setColumnOrder([], columns),
      initialColumnOrder: setColumnOrder([], columns),
      filters: [],
      columns,
      rows: rows,
      sorting: [],
      views,
      lockColumnWidths: false,
      defaultSorting: [],
      advancedFilters: [],
      hideFilterRow: true,
      availableValues
    })

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refresh) {
      this.fetchToState()
    }
  }

  searchToggle = state => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        visible: !this.state.search.visible
      },
      margin: !this.state.search.visible
    })
  }

  changeSelection = selection => {
    this.setState({ ...this.state, selection })
  }

  changeColumnWidths = columnWidths => {
    const validate = validateColumnMinWidths(
      columnWidths,
      this.state.columnMinWidths
    )
    this.setState({
      columnWidths: validate.replace ? validate.columnsWidths : columnWidths,
      lockColumnWidths: true
    })
  }

  changeFilters = (filters, addToUrl = true) => {
    this.setState({ ...this.state, filters })
  }

  hiddenColumnNamesChange = hiddenColumnNames => {
    this.setState({ hiddenColumnNames })
  }

  changeColumnOrder = order => {
    const { initialColumnOrder } = this.props
    this.setState({ columnOrder: order || initialColumnOrder })
  }

  changeMargin = margin => {
    this.setState({
      margin: margin !== undefined ? margin : !this.state.margin
    })
  }

  render() {
    const { data, toolbarTopProps, propFilters, teacherView } = this.props
    const {
      selection,
      views,
      margin,
      columns,
      hideFilterRow,
      columnOrder,
      rows,
      search,
      filters,
      sorting,
      hiddenColumnNames,
      columnMinWidths,
      lockColumnWidths,
      columnWidths,
      availableValues
    } = this.state

    return (
      <div className={'group activities-list'}>
        <Paper
          style={{
            marginRight: margin ? 310 : 0
          }}
        >
          <GridTable
            lang="pl"
            title={teacherView ? 'Tematy' : LABEL}
            name={'tab'}
            dateFormat={'DD-MM-YYYY'} //DATE_FORMAT}
            topComponent={
              !teacherView && (
                <ToolbarOptions
                  // updateViewURL={this.updateViewURL}
                  searchToggle={this.searchToggle}
                  // getSelection={this.getSelection.bind(this)}
                  changeFilters={this.changeFilters}
                  isFilters={!_.isEmpty(filters)}
                  isSelection={!_.isEmpty(selection)}
                  {...toolbarTopProps}
                />
              )
            }
            changeSelection={this.changeSelection}
            selection={selection}
            filters={propFilters || filters}
            defaultFilters={filters}
            hideFilterRow={hideFilterRow}
            multiple={true}
            hiddenColumnNamesChange={this.hiddenColumnNamesChange}
            hiddenColumnNames={hiddenColumnNames}
            columnOrder={columnOrder}
            changeColumnOrder={this.changeColumnOrder}
            changeColumnWidths={this.changeColumnWidths}
            showToolbar={true}
            sortingPlugin
            filteringPlugin
            selectionCheckboxAlways
            verticalScrollingPlugin
            pagingPlugin
            columnResizingPlugin
            changeMargin={this.changeMargin}
            disableRowClick={true}
            input={{
              data: data,
              columns: columns,
              views: views,
              rows: rows,
              sorting: sorting || [{ columnName: 'city', direction: 'desc' }],
              defaultSorting: [{ columnName: 'city', direction: 'desc' }],
              grouping: [],
              availableValues: availableValues || [],
              pageSizes: [10, 20, 50, 0],
              initialHeight: 1100,
              columnWidths,
              lockColumnWidths,
              columnMinWidths
            }}
            {...this.props}
          />
        </Paper>
        {!teacherView && (
          <Search
            search={search}
            searchToggle={this.searchToggle}
            columns={columns.filter(
              f =>
                f.filterField &&
                !_.includes(hiddenColumnNames, f.name) &&
                !f.hidden
            )}
            filters={filters}
            dateFormat={'DD-MM-YYYY'}
            changeFilters={this.changeFilters}
            availableValues={availableValues}
            {...this.props}
          />
        )}
      </div>
    )
  }
}

export default withTheme()(List)
