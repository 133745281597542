import React, { Component } from 'react'
import {
  Paper,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody,
  TableRowColumn,
  IconButton
} from 'material-ui-effco'
import { Fab } from 'material-ui-elements'
import EmailIcon from 'material-ui-effco/svg-icons/communication/email'
import PhoneIcon from 'material-ui-effco/svg-icons/communication/phone'
import FilterIcon from 'material-ui-effco/svg-icons/content/filter-list'

const styles = {
  leftBox: {
    display: 'inline-block',
    width: '49%',
    marginRight: '0.5%'
  },
  leftBoxFull: {
    display: 'inline-block',
    width: '99%'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    title: {
      marginLeft: 24,
      color: '#000 87%'
    }
  },
  templateTypeRow: {
    width: 30
  },
  btn: {
    marginTop: 3,
    float: 'right'
  }
}

class TemplatesList extends Component {
  // Render Table
  renderTable(sourceData) {
    return (
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>Nazwa</TableHeaderColumn>
            <TableHeaderColumn style={styles.templateTypeRow} />
          </TableRow>
        </TableHeader>
        <TableBody showRowHover displayRowCheckbox={false}>
          {sourceData.map((row, index) => this.renderTableRow(row, index))}
        </TableBody>
      </Table>
    )
  }

  // Render Table Row
  renderTableRow(tableRow, index) {
    const { activate } = this.props
    return (
      <TableRow
        onTouchTap={() => activate(tableRow._id)}
        key={index}
        style={{ cursor: 'pointer' }}
      >
        <TableRowColumn>{tableRow.name}</TableRowColumn>
        <TableRowColumn style={styles.templateTypeRow}>
          {this.renderAdminIcon(tableRow.notification)}
          {this.renderIcon(tableRow.type)}
        </TableRowColumn>
      </TableRow>
    )
  }

  // Render Channel icon
  renderIcon(type) {
    switch (type) {
      case 'mail':
        return <EmailIcon />
      default:
        return <PhoneIcon />
    }
  }

  // Render Admin icon
  renderAdminIcon(notification) {
    // if (notification) return <TemplateAdminIcon />
  }

  render() {
    const { data: { templates }, activeTemplate, view } = this.props.data
    const { templatesAddShow, changeView } = this.props

    const activeStyle = activeTemplate.type
      ? styles.leftBox
      : styles.leftBoxFull

    return (
      <div style={activeStyle}>
        <Paper>
          <Toolbar style={styles.toolbar}>
            <ToolbarGroup firstChild>
              <ToolbarTitle style={styles.toolbar.title} text="Szablony" />
            </ToolbarGroup>
            <ToolbarGroup>
              <IconButton
                tooltip={`Pokaż szablony ${
                  view === 'default' ? 'monitów' : 'komunikacji'
                }`}
                tooltipPosition="bottom-left"
                touch
                style={styles.btn}
                onTouchTap={() => changeView()}
              >
                <FilterIcon />
              </IconButton>
            </ToolbarGroup>
          </Toolbar>
          {this.renderTable(templates)}
        </Paper>
        <Fab onClick={() => templatesAddShow()} />
      </div>
    )
  }
}

export default TemplatesList
