import React from 'react'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import core from '../core'
import StatusIcon from './components/StatusIcon'

const { fields } = MuiDxGrid

const {
  constants: {
    oldTheme: {
      palette: { primary1Color, accent3Color }
    }
  }
} = core

export const NAME = 'templates'
export const LABEL = 'Szablony'
export const PATH = `/${NAME}/`
export const PATH_PL = `/administracja/szablony/`

const typeOption = [
  { value: 'all', label: 'Wszystkie' },
  {
    value: 'mail',
    label: 'Mail',
    icon: 'mail',
    color: 'black'
  },
  {
    value: 'sms',
    label: 'SMS',
    icon: 'sms',
    color: 'black'
  }
]

const templateType = [
  { value: 'all', label: 'Wszystkie' },
  {
    value: 'comm',
    label: 'Komunikacja',
    icon: 'mail_outline', //'alternate_email', //'mail_outline',
    color: primary1Color
  },
  {
    value: 'alert',
    label: 'Administracyjne',
    icon: 'notifications_none',
    color: primary1Color
  }
]

const shareType = [
  { value: 'all', label: 'Wszystkie' },
  {
    value: 'private', //'lock',
    label: 'Prywatny', //'Do odczytu',
    icon: 'perm_identity', //'lock',
    color: 'black'
  },
  {
    value: 'public', //'unlock',
    label: 'Publiczny', //'Edytowalny',
    icon: 'language', //'lock_open',
    color: 'black'
  }
]

export const columns = [
  // {
  //   type: fields.statusIcon,
  //   name: 'shareType',
  //   title: 'Zakres',
  //   align: 'center',
  //   width: 90,
  //   style: { marginLeft: 30 },
  //   filteringEnabled: true,
  //   customFilter: {
  //     type: fields.select
  //   },
  //   options: shareType
  // },
  {
    type: fields.component,
    name: 'shareType',
    title: 'Zakres',
    align: 'center',
    width: 90,
    style: { marginLeft: 30 },
    filteringEnabled: true,
    customFilter: {
      type: fields.select
    },
    // customSort: customStatusSort,
    options: shareType,
    component: (v, rId, r, c, cp, dataRow) => {
      return (
        <StatusIcon
          tooltip={r.ownerName}
          style={{ marginLeft: 30 }}
          value={v}
          options={shareType}
        />
      )
    }
  },

  {
    type: fields.statusIcon,
    name: 'templateType',
    title: 'Rodzaj',
    align: 'center',
    width: 80,
    style: { color: primary1Color, marginLeft: 15 },
    filteringEnabled: true,
    customFilter: {
      // type: fields.multiselect
      type: fields.select
    },
    options: templateType,
    order: 6
  },
  {
    type: fields.text,
    name: 'ownerName', //'ownerId',
    title: 'Wlaściciel',
    hidden: true,
    width: 120
    // filteringEnabled: true
  },
  {
    type: fields.text,
    name: 'name',
    title: 'Nazwa',
    // width: 120
    filteringEnabled: true,
    order: -4
  },
  {
    type: fields.text,
    name: 'notification',
    title: 'Kategoria',
    wordWrap: true,
    hidden: true,
    filteringEnabled: false
  },
  {
    type: fields.statusIcon,
    name: 'type',
    title: 'Typ',
    align: 'center',
    width: 60,
    style: { color: accent3Color, marginLeft: 10 },
    filteringEnabled: true,
    customFilter: {
      type: fields.select
    },
    options: typeOption
  }
]
