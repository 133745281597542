import React, { Component } from 'react'
import { Paper, IconButton } from 'material-ui-effco'
import InfoIcon from 'material-ui-effco/svg-icons/action/info'
import PhoneIcon from 'material-ui-effco/svg-icons/communication/phone'
import NoteIcon from 'material-ui-effco/svg-icons/av/note'
import EditIcon from 'material-ui-effco/svg-icons/image/edit'
import UndoIcon from 'material-ui-effco/svg-icons/content/undo'
import SaveIcon from 'material-ui-effco/svg-icons/content/save'
import Password from './Password'
import common from '../../../common'
import { oldTheme } from '../../constants'

const { MuiTextfield } = common.components

class Profile extends Component {
  render() {
    const { palette } = oldTheme
    const {
      //palette,
      handleSubmit,
      profileEdit,
      profileEditToggle,
      fields: { firstName, lastName, email, phone, role, notes },
      // core: { profile: { disabled } } } = this.props;
      data: { disabled }
    } = this.props

    const styles = {
      headerContainer: {
        width: '100%',
        display: 'flex'
      },
      header: {
        width: '50%',
        marginLeft: 20,
        padding: 10,
        color: palette.accent3Color
      },
      headerIcons: {
        display: 'inline-block',
        width: '50%',
        marginRight: 20,
        textAlign: 'right'
      },
      icon: {
        height: 20,
        marginBottom: -3,
        marginRight: 5
      },
      paper: {
        padding: 10
      },
      headerText: {
        fontSize: 20,
        margin: 15
      },
      divTable: {
        display: 'table',
        width: 'auto'
      },
      divRow: {
        display: 'table-row',
        width: 'auto',
        clear: 'both'
      },
      divCellCaption: {
        display: 'table-column',
        float: 'left',
        width: 200,
        fontSize: 14,
        marginLeft: 60,
        marginTop: 15,
        marginBottom: 25,
        paddingTop: 12,
        fontWeight: 800
      },
      divCellField: {
        display: 'table-column',
        float: 'left',
        width: 300,
        fontSize: 14,
        marginTop: 10,
        marginBottom: 15
      }
    }

    return (
      <div>
        <div>
          <div style={styles.headerContainer}>
            <div style={styles.header}>
              {`${firstName.value} ${lastName.value}`.toUpperCase()}
              <br />
              <small>{role.value}</small>{' '}
            </div>
            <div style={styles.headerIcons}>
              {disabled ? (
                <IconButton
                  onTouchTap={() => profileEditToggle()}
                  tooltip="Edytuj"
                  tooltipPosition="bottom-left"
                  touch
                >
                  <EditIcon color={palette.accent3Color} />
                </IconButton>
              ) : (
                <div>
                  <IconButton
                    onTouchTap={() => profileEditToggle()}
                    tooltip="Porzuć zmiany"
                    tooltipPosition="bottom-left"
                    touch
                  >
                    <UndoIcon color={palette.accent3Color} />
                  </IconButton>
                  <IconButton
                    onTouchTap={handleSubmit(profileEdit)}
                    tooltip="Zapisz"
                    tooltipPosition="bottom-left"
                    touch
                  >
                    <SaveIcon color={palette.accent3Color} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <Paper style={styles.paper}>
          <div style={styles.headerText}>
            <InfoIcon style={styles.icon} />Informacje podstawowe
          </div>
          <div style={styles.divTable}>
            <div style={styles.divRow}>
              <div style={styles.divCellCaption}>Imię</div>
              <div style={styles.divCellField}>
                <MuiTextfield
                  field={firstName}
                  hintText="Wprowadź imię *"
                  disabled={disabled}
                />
              </div>
            </div>
            <div style={styles.divRow}>
              <div style={styles.divCellCaption}>Nazwisko</div>
              <div style={styles.divCellField}>
                <MuiTextfield
                  field={lastName}
                  hintText="Wprowadź nazwisko *"
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div style={styles.headerText}>
            <PhoneIcon style={styles.icon} />Dane kontaktowe
          </div>
          <div style={styles.divTable}>
            <div style={styles.divRow}>
              <div style={styles.divCellCaption}>Email</div>
              <div style={styles.divCellField}>
                <MuiTextfield
                  field={email}
                  hintText="Wprowadź email *"
                  disabled={disabled}
                />
              </div>
            </div>
            <div style={styles.divRow}>
              <div style={styles.divCellCaption}>Telefon</div>
              <div style={styles.divCellField}>
                <MuiTextfield
                  field={phone}
                  hintText="Wprowadź nr telefonu *"
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div style={styles.headerText}>
            <NoteIcon style={styles.icon} />Notatki
          </div>
          <div style={styles.divTable}>
            <div style={styles.divRow}>
              <div style={styles.divCellCaption}>Notatka</div>
              <div style={styles.divCellField}>
                <MuiTextfield field={notes} disabled={disabled} rows={1} />
              </div>
            </div>
          </div>
          {disabled ? <div /> : <Password {...this.props} />}
        </Paper>
      </div>
    )
  }
}

export default Profile
