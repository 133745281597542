import moment from 'moment'
import _ from 'lodash'

moment.locale('pl')

export const getMonday = date => {
  var day = date.getDay() || 7
  if (day !== 1) date.setHours(-24 * (day - 1))
  return date
}

export const returnDateRange = dateFrom => {
  const dateTo = moment(dateFrom).add(4, 'days')
  const dateFromShort = `${moment(dateFrom).format('D')} ${
    moment(dateFrom).format('M') !== dateTo.format('M')
      ? ' ' + moment(dateFrom).format('MMM') + ' '
      : ''
  } ${
    moment(dateFrom).format('Y') !== dateTo.format('Y')
      ? ' ' + moment(dateFrom).format('YYYY') + ' '
      : ''
  }`
  return `${dateFromShort} - ${dateTo.format('ll')}`
}

export const getWeekData = (groups = [], activeFrom) => {
  let classes = []
  // For each group
  groups.forEach(g => {
    // For each class of the group
    g.classes.forEach(c => {
      // if class falls within the week to be displayed
      if (
        moment(c.date).isBetween(
          moment(activeFrom),
          moment(activeFrom).add(5, 'days'),
          'days',
          '[]'
        )
      ) {
        // find index of the date of the class
        let classesIndex = _.findIndex(classes, {
          date: new Date(c.date).setHours(0, 0, 0, 0)
        })
        // it index of the date of the class exists, add it to the day
        if (classesIndex > -1) {
          classes[classesIndex].classes.push({
            ...g,
            classId: c._id,
            subject: c.subject || undefined
          })
          // otherwise, create an entry for the day and push first class to it
        } else {
          classes.push({
            date: new Date(c.date).setHours(0, 0, 0, 0),
            classes: [{ ...g, subject: c.subject || undefined, classId: c._id }]
          })
        }
      }
    })
  })

  classes.forEach((c, i, arr) => {
    arr[i].classes = _.sortBy(c.classes, 'timeStartString')
  })

  return _.sortBy(classes, 'date')
}

export const getPupilAttendance = (pupil, group) => {
  return {
    attended:
      _.find(pupil.attendance || [], { classId: group.classId }) &&
      _.find(pupil.attendance || [], { classId: group.classId }).attended,
    classId: group.classId
  }
}

export const updateAttendanceInState = (state, attendanceDetails) => {
  // find the group of the pupil
  const groupIndex = _.findIndex(state.classes, {
    _id: attendanceDetails.selectedGroup
  })
  // find the pupil
  const pupilIndex = _.findIndex(state.classes[groupIndex].pupils, {
    _id: attendanceDetails.pupilId
  })
  // find the attendance of the pupil
  const attendanceIndex = _.findIndex(
    state.classes[groupIndex].pupils[pupilIndex].attendance,
    {
      classId: attendanceDetails.classId
    }
  )
  let newAttendance = state.classes[groupIndex].pupils[pupilIndex].attendance
  newAttendance[attendanceIndex] = {
    ...newAttendance[attendanceIndex],
    attended: newAttendanceStatus(attendanceDetails.attended)
  }
  // console.log(attendanceDetails)

  // console.log(newAttendance[attendanceIndex])

  let newPupils = state.classes[groupIndex].pupils
  newPupils[pupilIndex] = {
    ...newPupils[pupilIndex],
    attendance: newAttendance
  }
  // console.log(newPupils[pupilIndex])
  let newClasses = state.classes
  newClasses[groupIndex] = { ...newClasses[groupIndex], pupils: newPupils }
  return { ...state, classes: newClasses }
}

const newAttendanceStatus = attStatus => {
  switch (attStatus) {
    case true:
      return false
    case null:
      return true
    default:
      return null
  }
}
