import React from 'react'
import common from '../../../common'

const { MuiTextfield } = common.components

const DetailsMisc = props => {
  const {
    data: { active: { disabled } },
    fields: { custom: { account1, account2, account3, account4 } }
  } = props

  return (
    <div>
      <MuiTextfield
        field={account1}
        floatingLabelText="Konto 1"
        hintText="Wprowadź dane konta"
        disabled={disabled}
      />
      <MuiTextfield
        field={account2}
        floatingLabelText="Konto 2"
        hintText="Wprowadź dane konta"
        disabled={disabled}
      />
      <MuiTextfield
        field={account3}
        floatingLabelText="Konto 3"
        hintText="Wprowadź dane konta"
        disabled={disabled}
      />
      <MuiTextfield
        field={account4}
        floatingLabelText="Konto 4"
        hintText="Wprowadź dane konta"
        disabled={disabled}
      />
    </div>
  )
}

export default DetailsMisc
