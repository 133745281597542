import React from 'react'
import Chip from 'material-ui/Chip'
import { oldTheme } from '../../../core/constants'

const SelectedGroup = ({ selectedGroup }) => {
  if (!selectedGroup) return null
  return (
    <div>
      {`Grupa ${selectedGroup.groupName}${
        selectedGroup.classroom
          ? `, zajęcia w sali ${selectedGroup.classroom}`
          : ''
      }`}
      {selectedGroup.groupNote && (
        <Chip
          style={{
            background: oldTheme.palette.primary1Color,
            color: oldTheme.palette.alternateTextColor,
            marginLeft: '5px'
          }}
          label={selectedGroup.groupNote}
        />
      )}
    </div>
  )
}

export default SelectedGroup
