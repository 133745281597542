import React from 'react'
import { Route, Switch } from 'react-router-dom'

import List from './List'
import ClassesDialog from './ClassesDialog'
import GroupDialog from './GroupDialog'

import { PATH_PL } from '../constants'

const InnerRouter = props => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${PATH_PL}:day(pn|wt|sr|cz|pt)?/:id?`}
          render={routeProps => <List {...props} {...routeProps} />}
        />
        {/* <Route
          exact
          path={`${PATH_PL}:day(pn|wt|sr|cz|pt)`}
          render={routeProps => <List {...props} {...routeProps} />}
        />
        <Route
          path={`${PATH_PL}`}
          render={routeProps => <List {...props} {...routeProps} />}
        /> */}
      </Switch>
      <Route
        exact
        path={`${PATH_PL}:day(pn|wt|sr|cz|pt)?/:id(^nowagrupa|edycjagrupowa|\\w{24})`}
        render={routeProps => <ClassesDialog {...props} {...routeProps} />}
      />
      <Route
        exact
        path={`${PATH_PL}:day(pn|wt|sr|cz|pt)/:id(nowagrupa)`}
        render={routeProps => <GroupDialog {...props} {...routeProps} />}
      />
    </div>
  )
}

export default InnerRouter
