export const NAME = 'groups/update/classes'
export const LABEL = 'Tematy wg prowadzących'
export const PATH = `/${NAME}/`
export const PATH_PL = `/administracja/grupy/zajecia`

export const views = [
  {
    id: 1,
    viewName: 'QuickView',
    columns: ['groupName', 'teacher']
  }
]
