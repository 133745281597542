import React from 'react'
import { withTheme } from 'material-ui/styles'
import _ from 'lodash'
import moment from 'moment'

const ClassIndicator = props => {
  const {
    theme: { palette },
    cellProps: {
      history: { push },
      path,
      snackbar,
      selection,
      changeSelection,
      filters,
      setGroupDetails
    },
    point,
    toRender,
    pieceSize,
    day
  } = props

  const setBgColor = value => {
    let finalVal = null
    const val = value
    if (val < 0) {
      finalVal = 'green'
    } else if (val > 0.25 && val <= 0.75) {
      finalVal = palette.primary[200] //'pink'
    } else if (val > 0.75 && val < 1.75) {
      finalVal = palette.primary[500] //'red'
    } else if (val >= 1.75) {
      finalVal = palette.primary[800] //'brown'
    } else {
      finalVal = null
    }
    return finalVal
  }

  return (
    <div>
      {toRender.map((d, i) => {
        const clickedArea = Math.round(point.x / pieceSize)

        const startTime = moment()
          .startOf('day')
          .add(8, 'hour')
          .add(day ? d.start * 5 : d.start * 5, 'minute')
        const endTime = moment(startTime).add(
          day ? d.length * 5 : d.length * 5,
          'minute'
        )
        const detailedTime = moment()
          .startOf('day')
          .add(8, 'hour')
          .add(clickedArea * 5, 'minute')

        return (
          <span
            key={i}
            title={
              day
                ? detailedTime.format('HH:mm')
                : `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`
            }
          >
            <div
              key={i}
              style={{
                background: setBgColor(d.value),
                minHeight: 20,
                display: 'inline-block',
                width: Number(d.length) * pieceSize,
                borderRadius: 10
              }}
              onClick={
                day
                  ? () => {
                      let areaValue = null
                      let set = false
                      toRender.reduce((p, c, i, arr) => {
                        if (p + c.length >= clickedArea && !set) {
                          areaValue = toRender[i].value
                          set = true
                        }
                        return p + c.length
                      }, 0)
                      if (!_.isEmpty(selection)) {
                        if (areaValue === 0) {
                        } else if (areaValue > 0) {
                          snackbar('Wybrana godzina jest niedostepna')
                        } else {
                          const groupDetails = {
                            day,
                            clickedArea,
                            selection,
                            changeSelection,
                            city: _.get(
                              _.find(filters, ['columnName', 'city']),
                              'value[0]'
                            ),
                            district: _.get(
                              _.find(filters, ['columnName', 'district']),
                              'value[0]'
                            ),
                            school: _.get(
                              _.find(filters, ['columnName', 'school']),
                              'value[0]'
                            )
                          }
                          setGroupDetails(groupDetails)
                          push(`${path}${day}/nowagrupa?view=edit`)
                        }
                      } else {
                        snackbar('Zaznacz osoby do dodania')
                      }
                    }
                  : null
              }
            />
          </span>
        )
      })}
    </div>
  )
}

export default withTheme()(ClassIndicator)
