import React, { Component } from 'react'
import _ from 'lodash'
import { MenuItem } from 'material-ui-effco'
import core from '../../core/'
import common from '../../common'

const {
  helpers: { api }
} = core
const { MuiSelectfield } = common.components

class TeacherSelectField extends Component {
  state = {}
  async fetchToState() {
    const data = await api.get([{ teachers: 'users?role=Prowadzący' }])
    data.teachers = data.teachers.filter(teacher => teacher.active)
    data.teachers = _.orderBy(data.teachers, ['firstName', 'lastName'])
    this.setState({ data })
  }

  async componentWillMount() {
    await this.fetchToState()
  }

  render() {
    if (!this.state.data) return null
    const { teachers } = this.state.data
    const {
      onChange,
      field,
      hintText,
      disabled,
      floatingLabelText
    } = this.props

    const teachersOptions = teachers.map(teacher => (
      <MenuItem
        key={teacher._id}
        value={teacher._id}
        primaryText={`${teacher.firstName} ${teacher.lastName}`}
      />
    ))
    teachersOptions.unshift(
      <MenuItem key={null} value={null} primaryText={'Brak'} />
    )

    return (
      <MuiSelectfield
        field={field}
        onChange={onChange}
        floatingLabelText={floatingLabelText}
        disabled={disabled}
        hintText={hintText}
        children={teachersOptions}
      />
    )
  }
}

export default TeacherSelectField
