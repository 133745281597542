import React, { Component } from 'react'
import AppBar               from 'material-ui/AppBar'
import Toolbar              from 'material-ui/Toolbar'
import Avatar               from 'material-ui/Avatar'
import Chip                 from 'material-ui/Chip'
import Typography           from 'material-ui/Typography'
import NavLink              from './NavLink'
import Logo                 from '../assets/logo.png'
import { oldTheme }         from '../constants'
import Auth                 from './Auth'
import Menu, { MenuItem }   from 'material-ui/Menu'
import Profile              from 'material-ui-effco/svg-icons/social/person'
import Logout               from 'material-ui-effco/svg-icons/action/lock'
import axios                from 'axios'
import {API_URL, APP_ENV, APP_VERSION} from '../../core/constants'


class Header extends Component {
  state = { open: false, activeYear:2017 }

  async componentWillMount() {
      await this.fetchToState()
  }
  
  async fetchToState() {
      const settings = await axios.get(`${API_URL}/settings`, {
          headers: { authorization: localStorage.getItem('token') }
      })

      const activeYear = settings.data.filter(x=>x.name == "activeYear")[0].value

      this.setState({
          activeYear: activeYear,
          open: this.state.open,
      })
  }


  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  render() {
    const {
      palette: { canvasColor, primary1Color }
    } = oldTheme
    const {
      profile,
      history: { push }
    } = this.props

    const userInitials =
      profile && profile.firstName
        ? profile.firstName.substr(0, 1).toUpperCase() +
          profile.lastName.substr(0, 1).toUpperCase()
        : ''
    const appYear =
      profile && profile.settings && profile.settings.activeYear
        ? profile.settings.activeYear
        : this.state.activeYear


    const superAdmin = profile && profile.role === 'Super Admin'

    return (
      <AppBar
        style={{
          backgroundColor: canvasColor
        }}
      >
        <Toolbar>
          <div style={{ display: 'flex', flex: 1, marginTop: 4 }}>
            <img src={Logo} height="40" alt="logo" />
            <div style={{ marginTop: 8, marginLeft: 24 }}>
              <Typography variant="title" color="secondary">
                {APP_ENV === 'PROD'
                  ? ''
                  : `${APP_VERSION} ${ APP_ENV.toUpperCase() }`}
              </Typography>
            </div>
          </div>
          <NavLink to="/" icon="dashboard" caption="Panel" {...this.props} />
          <Auth roles={['Prowadzący']} {...this.props}>
            <NavLink
              to="/zajecia/"
              icon="event"
              caption="Zajecia"
              {...this.props}
            />
            <NavLink
              to="/tematy/"
              icon="subject"
              caption="Tematy"
              {...this.props}
            />
            <NavLink
              to="/pokazowe/"
              icon="today"
              caption="Pokazowe"
              {...this.props}
            />
            <NavLink
              to="/sprawozdania"
              icon="event_note"
              caption="Sprawozdania"
              {...this.props}
            />
          </Auth>
          <Auth {...this.props}>
            <NavLink
              to="/uczestnicy/"
              icon="person"
              caption="Uczestnicy"
              {...this.props}
            />
            <NavLink
              to="/grupy/"
              icon="group"
              caption="Grupy"
              {...this.props}
            />
            <NavLink
              to="/szkoly/"
              icon="school"
              caption="Szkoły"
              {...this.props}
            />
            <NavLink
              to={`/administracja/${superAdmin ? 'ustawienia/' : ''}`}
              icon="settings"
              caption="Administracja"
              {...this.props}
            />
          </Auth>
          <div style={{ marginRight: 12 }} />
          <Chip
            style={{
              background: oldTheme.palette.primary1Color,
              color: oldTheme.palette.alternateTextColor
            }}
            label={`${appYear} / ${appYear * 1 + 1}`}
          />
          <Avatar
            onClick={this.handleClick}
            style={{
              marginLeft: 24,
              background: oldTheme.palette.primary1Color,
              cursor: 'pointer'
            }}
          >
            {userInitials}
          </Avatar>
          <Menu
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            onChange={this.handleChangeSingle}
            onClose={this.handleRequestClose}
          >
            <MenuItem onTouchTap={() => push('/profil')}>
              <Profile style={{ color: primary1Color, marginRight: 12 }} />
              Profil
            </MenuItem>
            <MenuItem onTouchTap={() => push('/logout')}>
              <Logout style={{ color: primary1Color, marginRight: 12 }} />
              Wyloguj
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    )
  }
}

export default Header
