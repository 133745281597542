import { NAME } from './constants'

// Pupil
export const FETCH = `${NAME}/FETCH`
export const CLEAR_FETCH = `${NAME}/CLEAR_FETCH`
export const FETCH_ATTENDANCES = `${NAME}/FETCH_ATTENDANCES`
export const REFRESH = `${NAME}/REFRESH`
export const ACTIVATE = `${NAME}/ACTIVATE`
export const EDIT_TOGGLE = `${NAME}/EDIT_TOGGLE`
export const ADD = `${NAME}/ADD`
export const CANCEL = `${NAME}/CANCEL`
export const DELETE = `${NAME}/DELETE`
export const VIEW = `${NAME}/VIEW`

// Multiselect & show more
export const PUPILSLIST_TOGGLE_MULTISELECT = `${NAME}/TOGGLE_MULTISELECT`
export const PUPIL_SELECTED = `${NAME}/PUPIL_SELECTED`
export const PUPIL_SELECTED_ALL = `${NAME}/PUPIL_SELECTED_ALL`
export const PUPILSLIST_SHOW_MORE = `${NAME}/PUPILSLIST_SHOW_MORE`

// Search
export const SEARCH_TOGGLE = `${NAME}/SEARCH_TOGGLE`
export const SEARCH_CHANGE_CITY = `${NAME}/SEARCH_CHANGE_CITY`
export const SEARCH_CHANGE_GROUP = `${NAME}/SEARCH_CHANGE_GROUP`
export const SEARCH_CHANGE_SCHOOL = `${NAME}/SEARCH_CHANGE_SCHOOL`
export const SEARCH_CHANGE_PUPIL = `${NAME}/SEARCH_CHANGE_PUPIL`
export const SEARCH_CHANGE_TAG = `${NAME}/SEARCH_CHANGE_TAG`
export const SEARCH_CHANGE_TEACHER = `${NAME}/SEARCH_CHANGE_TEACHER`
export const SEARCH_CHANGE_NOTES_GEN = `${NAME}/SEARCH_CHANGE_NOTES_GEN`
export const SEARCH_CHANGE_DUE_ACTIVE = `${NAME}/SEARCH_CHANGE_DUE_ACTIVE`
export const SEARCH_CHANGE_PAID_ACTIVE = `${NAME}/SEARCH_CHANGE_PAID_ACTIVE`
export const SEARCH_CHANGE_LIABILITY_S1_ACTIVE = `${NAME}/SEARCH_CHANGE_LIABILITY_S1_ACTIVE`
export const SEARCH_CHANGE_LIABILITY_S2_ACTIVE = `${NAME}/SEARCH_CHANGE_LIABILITY_S2_ACTIVE`
export const SEARCH_CHANGE_FINANCE_ACTIVE = `${NAME}/SEARCH_CHANGE_FINANCE_ACTIVE`
export const SEARCH_CHANGE_CONTINUATION_ACTIVE = `${NAME}/SEARCH_CHANGE_CONTINUATION_ACTIVE`
export const SEARCH_CHANGE_SIBLINGS_ACTIVE = `${NAME}/SEARCH_CHANGE_SIBLINGS_ACTIVE`

export const SEARCH_CHANGE_MONTHLY_FEE_ACTIVE = `${NAME}/SEARCH_CHANGE_MONTHLY_FEE_ACTIVE`
export const SEARCH_CHANGE_MONTHLY_FEE_VALUE  = `${NAME}/SEARCH_CHANGE_MONTHLY_FEE_VALUE`

export const SEARCH_CHANGE_NOTES_GENERAL3 = `${NAME}/SEARCH_CHANGE_NOTES_GENERAL3`

export const SEARCH_CHANGE_DUE = `${NAME}/SEARCH_CHANGE_DUE`
export const SEARCH_CHANGE_PAID = `${NAME}/SEARCH_CHANGE_PAID`
export const SEARCH_CHANGE_LIABILITY_S1 = `${NAME}/SEARCH_CHANGE_LIABILITY_S1`
export const SEARCH_CHANGE_LIABILITY_S2 = `${NAME}/SEARCH_CHANGE_LIABILITY_S2`
export const SEARCH_CHANGE_FINANCE = `${NAME}/SEARCH_CHANGE_FINANCE`
export const SEARCH_CHANGE_CONTINUATION = `${NAME}/SEARCH_CHANGE_CONTINUATION`
export const SEARCH_CHANGE_SIBLINGS = `${NAME}/SEARCH_CHANGE_SIBLINGS`
export const SEARCH_CHANGE_NOTES_GENERAL2 = `${NAME}/SEARCH_CHANGE_NOTES_GENERAL2`
export const SEARCH_CHANGE_NOTES_GENERAL2_ACTIVE = `${NAME}/SEARCH_CHANGE_NOTES_GENERAL2_ACTIVE`
export const SEARCH_UPDATE = `${NAME}/SEARCH_UPDATE`
export const SEARCH_CLEAR = `${NAME}/SEARCH_CLEAR`
