import * as tl from './types';
import core from '../core';

const t = { ...tl, ...core.types };

const INITIAL_STATE = {
  visible: false,
  type: null,
  contentOption: 1,
  sendingMessage: false,
  sentMessages: 0,
  messagesToSend: [],
  normalize: true,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case t.TOGGLE:
      return { ...INITIAL_STATE, visible: !state.visible, type: action.payload.type };

    case t.SEND_DISABLED:
      return { ...state, sendDisabled: action.payload };

    case t.TEXT_CHANGE:
      return { ...state, message: action.payload.message, subject: action.payload.subject };

    case t.OPTION_CHANGE:
      return { ...state, contentOption: action.payload, selectedTemplate: { _id: null } };

    case t.TEMPLATE_CHANGE:
      return { ...state, selectedTemplate: { ...state.selectedTemplate, _id: action.payload } };

    case t.SEND_MESSAGE:
      return { ...state, sentMessages: state.sentMessages + 1 };
    case t.SENDING_MODE:
      return { ...state, sendingMessage: !state.sendingMessage };
    case t.NORMALIZE:
      return { ...state, normalize: !state.normalize };
    case t.CLEAR_COMMUNICATION:
      return state;


    case t.UNAUTH:
      return INITIAL_STATE;

    default:
      return state;
  }
}
