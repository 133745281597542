import axios from 'axios'
import _ from 'lodash'
import { push } from 'react-router-redux'
// import { reset, destroy, initialize } from 'redux-form-v5'
import { reset } from 'redux-form-v5'
import * as t from './types'
import { NAME, PATH_PL } from './constants'
import core from '../../core/'
// import { fields } from './form'

const {
  constants: { API_URL },
  actions: { snackbarMessage }
} = core

export const activate = (id = null) => dispatch => {
  // console.log('activate')
  // dispatch({ type: t.ACTIVATE, payload: id })
  dispatch(push(`${PATH_PL}${id || ''}`))
  // if (!id) dispatch(destroy(NAME))
}

export const fetch = (id = null) => dispatch => {
  axios
    .get(`${API_URL}/${NAME}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(pupils => {
      dispatch({
        type: t.FETCH,
        payload: pupils.data
      })
      //dispatch(activate(id))
    })
}

export const clearFetch = (id = null) => dispatch => {
  dispatch({
    type: t.CLEAR_FETCH
  })
}

export const refreshPupil = id => (dispatch, getState) => {
  axios
    .get(
      `${API_URL}/${NAME}/${id}${
        getState()[NAME].loadingAttendances ? '' : '/attendances'
      }`,
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(({ data }) => {
      const pupils = getState()[NAME].data[NAME]
      const pupilIds = pupils.map(p => p._id)
      pupils[pupilIds.indexOf(data._id)] = data
      dispatch({
        type: t.REFRESH,
        payload: pupils
      })
    })
}

export const changeView = (type = 'default') => (dispatch, getState) => {
  // if (getState()[NAME].loadingAttendances) {
  //   axios
  //     .get(`${API_URL}/${NAME}/attendances`, {
  //       headers: { authorization: localStorage.getItem('token') }
  //     })
  //     .then(pupils =>
  //       dispatch({ type: t.FETCH_ATTENDANCES, payload: pupils.data })
  //     )
  // axios
  //   .get(`${API_URL}/${NAME}/attendances`, {
  //     headers: { authorization: localStorage.getItem('token') }
  //   })
  //   .then(pupils =>
  //     dispatch({ type: t.FETCH_ATTENDANCES, payload: pupils.data })
  //   )
  // }
  dispatch({ type: t.VIEW, payload: type })
}

export const editToggle = () => ({ type: t.EDIT_TOGGLE })

export const editUndo = () => dispatch => {
  dispatch(reset(NAME))
  dispatch(editToggle())
}

export const editDelete = id => dispatch => {
  axios
    .delete(`${API_URL}/pupils/${id}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(activate())
      dispatch(fetch())
      dispatch(snackbarMessage(res.data.message))
    })
}

export function editSave(pupil, view = 'default') {
  const pupilAdjusted = pupil
  pupilAdjusted.group = pupil.group._id
  return dispatch => {
    axios
      .put(`${API_URL}/pupils/${pupil._id}`, pupilAdjusted, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(res => {
        dispatch(refreshPupil(pupil._id))
        dispatch(snackbarMessage(res.data.message))
      })
      .then(() => {
        dispatch(activate())
      })
      .catch(() => {
        dispatch(snackbarMessage('Edycja uczestnika nie powiodła się'))
      })
  }
}

export function editAdd(pupil) {
  const pupilAdjusted = pupil
  pupilAdjusted.group = pupil.group._id
  return dispatch => {
    // submit email/password to server
    axios
      .post(`${API_URL}/pupils`, pupilAdjusted, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(res => {
        dispatch(fetch())
        dispatch(snackbarMessage(res.data.message))
      })
      .then(() => {
        dispatch(activate())
      })
      .catch(() => {
        dispatch(snackbarMessage('Dodanie uczestnika nie powiodło się'))
      })
  }
}

export const editAddShow = (id = null) => dispatch => {
  // console.log('activate')
  // dispatch({ type: t.ACTIVATE, payload: id })
  dispatch(push(`${PATH_PL}new/?view=edit`))
  // if (!id) dispatch(destroy(NAME))
}

// export function editAddShow() {
//   return dispatch => {
//     // dispatch({ type: t.ACTIVATE, payload: 'add' })
//     // dispatch({ type: t.ADD })
//     dispatch(push(`${PATH_PL}new/?view=edit`))
//     // dispatch(
//     //   initialize(NAME, { contact: {}, payment: { due: {} }, notes: {} }, fields)
//     // )
//   }
// }

export const uploadPupils = event => dispatch => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = e => {
      axios
        .post(
          `${API_URL}/pupils/bulk`,
          { data: e.target.result },
          { headers: { authorization: localStorage.getItem('token') } }
        )
        .then(() => {
          dispatch(fetch())
        })
        .catch(() => {
          dispatch(snackbarMessage('Dodanie użytkowników nie powiodło się'))
        })
    }
    reader.readAsText(file, 'cp1250')
  }
}

export const searchShowMore = () => ({ type: t.PUPILSLIST_SHOW_MORE })

// Detail

// export function togglePupilDetails(selectedPupil) {
//   return {
//     type: t.PUPILSDETAILS_TOGGLE,
//     payload: selectedPupil,
//   };
// }

export const toggleMultiSelect = () => ({
  type: t.PUPILSLIST_TOGGLE_MULTISELECT
})

export const togglePupilSelect = (id, selectedRows, pupilIds) => dispatch => {
  const selectedIndex = selectedRows.indexOf(id)
  let newSelected = []

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selectedRows, id)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selectedRows.slice(1))
  } else if (selectedIndex === selectedRows.length - 1) {
    newSelected = newSelected.concat(selectedRows.slice(0, -1))
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    )
  }

  const allSelected = newSelected.length === pupilIds.length

  dispatch({
    type: t.PUPIL_SELECTED,
    payload: { selectedRows: newSelected, selectAllChecked: allSelected }
  })
}

export const pupilSelectedToggle = (selectedRows, pupilIds) => dispatch => {
  let newSelected = _.difference(pupilIds, selectedRows)
  const allSelected = newSelected.length === pupilIds.length

  dispatch({
    type: t.PUPIL_SELECTED,
    payload: { selectedRows: newSelected, selectAllChecked: allSelected }
  })
}

export const togglePupilSelectAll = (checked, ids) => {
  const newSelected = !checked ? ids : []

  return {
    type: t.PUPIL_SELECTED_ALL,
    payload: newSelected
  }
}

export function searchToggle() {
  return dispatch => {
    dispatch({ type: t.SEARCH_TOGGLE })
  }
}

export function searchChangeCity(event, key, value) {
  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_CITY, payload: value })
    dispatch({ type: t.SEARCH_CHANGE_SCHOOL })
    dispatch({ type: t.SEARCH_CHANGE_GROUP })
  }
}

export function searchChangeGroup(event, key, value) {
  return (dispatch, getState) => {
    const selectedGroups = getState()[NAME].search.criteria.groups
    const selection = _.difference(value, selectedGroups)[0]
    if (selection === 'none') {
      value = ['none']
    } else if (value.indexOf('none') > -1) {
      value.splice(value.indexOf('none'), 1)
    }
    if (selection === 'any') {
      value = ['any']
    } else if (value.indexOf('any') > -1) {
      value.splice(value.indexOf('any'), 1)
    }
    dispatch({ type: t.SEARCH_CHANGE_GROUP, payload: value })
  }
}

export function searchSetGroup(value) {
  return (dispatch, getState) => {
    dispatch({ type: t.SEARCH_CHANGE_GROUP, payload: [value] })
  }
}

export function searchChangeSchool(event, key, value) {
  return (dispatch, getState) => {
    const selectedSchools = getState()[NAME].search.criteria.schools
    const selection = _.difference(value, selectedSchools)[0]
    if (selection === 'none') {
      value = ['none']
    } else if (value.indexOf('none') > -1) {
      value.splice(value.indexOf('none'), 1)
    }
    if (selection === 'any') {
      value = ['any']
    } else if (value.indexOf('any') > -1) {
      value.splice(value.indexOf('any'), 1)
    }
    dispatch({ type: t.SEARCH_CHANGE_SCHOOL, payload: value })
    dispatch({ type: t.SEARCH_CHANGE_GROUP })
  }
}

export function searchChangePupil(event) {
  const pupil = event.target.value ? event.target.value : ''

  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_PUPIL, payload: pupil })
  }
}

export function searchChangeTag(event, key, value) {
  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_TAG, payload: value })
  }
}

export function searchChangeTeacher(event, key, value) {
  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_TEACHER, payload: value })
  }
}

export function searchChangeNotesGeneral(event) {
  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_NOTES_GEN, payload: event.target.value })
  }
}

export const searchChangeDue = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_DUE })
}
export const searchChangeDueActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_DUE_ACTIVE })
}
export const searchChangePaid = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_PAID })
}
export const searchChangePaidActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_PAID_ACTIVE })
}
export const searchChangeLiabilityS1 = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_LIABILITY_S1 })
}
export const searchChangeLiabilityActiveS1 = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_LIABILITY_S1_ACTIVE })
}
export const searchChangeLiabilityS2 = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_LIABILITY_S2 })
}
export const searchChangeLiabilityActiveS2 = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_LIABILITY_S2_ACTIVE })
}
export const searchChangeFinance = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_FINANCE })
}
export const searchChangeFinanceActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_FINANCE_ACTIVE })
}
export const searchChangeContinuation = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_CONTINUATION })
}
export const searchChangeContinuationActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_CONTINUATION_ACTIVE })
}
export const searchChangeSiblings = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_SIBLINGS })
}
export const searchChangeSiblingsActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_SIBLINGS_ACTIVE })
}
 

export const searchChangeMonthlyFeeActive = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_MONTHLY_FEE_ACTIVE })
}

export const searchChangeMonthlyFeeValue = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_MONTHLY_FEE_VALUE })
}


export const searchChangeNotesGeneral2 = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_NOTES_GENERAL2 })
}
export const searchChangeNotesGeneral2Active = () => dispatch => {
  dispatch({ type: t.SEARCH_CHANGE_NOTES_GENERAL2_ACTIVE })
}

export function searchChangeNotesGeneral3(event) {
  return dispatch => {
    dispatch({ type: t.SEARCH_CHANGE_NOTES_GENERAL3, payload: event.target.value })
  }
}

export function searchClear(cities) {
  const city = _.isEmpty(cities) ? undefined : cities[0]
  return dispatch => {
    dispatch({ type: t.SEARCH_CLEAR, payload: city })
    // if (cities && _.size(cities) > 0) dispatch({ type: t.SEARCH_CLEAR })
  }
}
