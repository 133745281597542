import React from 'react';
import { TextField } from 'material-ui-effco';

export default
  ({ field, value, disabled, style, hintText, floatingLabelText, rows, type, underlineShow, underlineDisabledStyle }) =>
    <div>
      <TextField
        {...field}
        value={field ? field.value : value}
        hintText={hintText}
        floatingLabelText={floatingLabelText}
        disabled={disabled}
        fullWidth
        multiLine={!!rows}
        rows={rows}
        errorText={field ? field.touched && field.error && field.error : null}
        underlineShow={underlineShow}
        underlineDisabledStyle={underlineDisabledStyle || { borderColor: '#ccc' }}
        hintStyle={{ color: '#bbb' }}
        floatingLabelFixed
        type={type}
      />
    </div>;
