import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'
import core from '../core'
import * as actions from './actions'
import { getAll, getActivePupil } from './selectors'
import { NAME } from './constants'
import ClassesMaster from './components/master'

const { components: { Spinner }, helpers: { api } } = core

class ClassesContainer extends Component {
  state = {}
  async componentWillMount() {
    this.props.classesFetch()
    this.setState({ subjects: await api.get('subjects') })
  }

  render() {
    const { loading } = this.props.data
    return loading ? (
      <Spinner />
    ) : (
      <ClassesMaster {...this.props} {...this.state} />
    )
  }
}

const mapState = state => ({
  data: getAll(state),
  authenticated: core.selectors.isAuthenticated(state),
  initialValues: getActivePupil(state)
})

export default reduxForm(
  {
    form: `${NAME}/comments`,
    fields: ['notes.teacher'],
    reduxMountPoint: 'form_v5'
  },
  mapState,
  actions
)(ClassesContainer)
