import React from 'react'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import { IconButton } from 'material-ui-effco'
import DownloadIcon from 'material-ui-effco/svg-icons/file/file-download'

const Export = props => {
  // const pupilsAdjusted = pupils.map((p) => { p.name; });
  const {
    data: {
      data: { pupils }
    }
  } = props
  // const groups = props.groups || []
  const schools = props.schools || []
  const groupsWithTeacher = props.groupsWithTeacher || []

  const pupilsAdjusted = []
  pupils.map(p => {
    const pupilGroup = _.find(groupsWithTeacher, ['_id', p.group]) || {}
    const pupilSchool = _.find(schools, ['_id', p.school]) || {}
    const dueS1 = (p.payment && p.payment.due && p.payment.due.s1) * 1 || 0
    const dueS2 = (p.payment && p.payment.due && p.payment.due.s2) * 1 || 0
    const paidSum =
      p.payment && p.payment.paid
        ? p.payment.paid
            .map(y => y.amount * 1)
            .reduce((prev, curr) => prev * 1 + curr * 1, 0)
        : 0
    pupilsAdjusted.push([
      p.city,
      p.firstName,
      p.lastName,
      p.group ? pupilGroup.groupName : '',
      pupilGroup &&
      pupilGroup.teacher &&
      pupilGroup.teacher.firstName &&
      pupilGroup.teacher.lastName
        ? `${pupilGroup.teacher.firstName} ${pupilGroup.teacher.lastName}`
        : '',
      p.tag || '',
      p.school ? pupilSchool.number : '',
      p.className,
      +dueS1,
      +dueS2,
      +paidSum,
      +dueS1 + +dueS2 - +paidSum,
      p.contact.email,
      p.contact.phone,
      p.siblings ? 'T' : 'N',
      p.continuation ? 'T' : 'N',
      p.payment && p.payment.finance ? 'T' : 'N',
      p.promotion ? 'T' : 'N',
      (p.notes && p.notes.general) || '',
      (p.notes && p.notes.management) || ''
    ])
    return pupilsAdjusted
  })
  pupilsAdjusted.unshift([
    'Miasto',
    'Imię',
    'Nazwisko',
    'Grupa',
    'Prowadzący',
    'Tag',
    'Szkoła',
    'Klasa',
    'Prognoza S1',
    'Prognoza S2',
    'Wpłaty',
    'Należność',
    'Email',
    'Telefon',
    'Rodzeństwo',
    'Kontynuacja',
    'Raty',
    'Promocja',
    'Uwagi ogólne',
    'Uwagi ActNPlay'
  ])

  return (
    <CSVLink data={pupilsAdjusted} filename={'Uczestnicy.csv'} separator=";">
      <IconButton
        tooltip="Eksport danych"
        tooltipPosition="bottom-left"
        disabled={false}
        style={{ marginTop: 3, float: 'right' }}
        containerElement="label"
      >
        <DownloadIcon />
      </IconButton>
    </CSVLink>
  )
}

export default Export
