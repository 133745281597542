import _ from 'lodash'
import levenshtein from 'js-levenshtein'

export const customStatusSort = (a, b) => {
  const order = ['precision', 'normal', 'low', 0]
  const indexA = _.findIndex(order, f => f === a)
  const indexB = _.findIndex(order, f => f === b)
  if (indexA === indexB) return 0
  return indexA > indexB ? 1 : -1
}

export const levenshteinDistanceToPercentage = (s, t) => {
  return (1 - levenshtein(s, t) / Math.max(s.length, t.length)) * 100
}
