import _ from 'lodash'
import checkit from 'lodash-checkit'
import axios from 'axios'
import flat from 'flat'
import qs from 'query-string'
import xlsx from 'xlsx'
import FileSaver from 'file-saver'
import numeral from 'numeral'
import { API_URL } from '../constants'
export { default as api } from './apiHelper'

export const ccy = (number, format = '0,0.00', zero = '', symbol = '€') =>
  number !== 0 ? `${symbol}${numeral(Number(number)).format(format)}` : zero

export const selectedWording = count => {
  switch (count) {
    case 1:
      return `${count} zaznaczony`

    case 2:
    case 3:
    case 4:
      return `${count} zaznaczone`

    default:
      return `${count} zaznaczonych`
  }
}

export const editingWording = (count, name, singularEnding = 'a') => {
  switch (count) {
    case 1:
      return `${count} ${name}${singularEnding}`

    default:
      return `${count}  ${name}ów`
  }
}

export const isEmail = stringToTest =>
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    stringToTest
  )

export const isPhone = stringToTest => /^\d{9}$/.test(stringToTest)

export const isClass = stringToTest => /^\d\D{0,1}$/.test(stringToTest)

export const remapArray = (array, fieldMappings) =>
  array.map(({ id, ...rest }) =>
    _.mapKeys(flat(rest), (value, key) => fieldMappings[key])
  )

export const unremapArray = (array, fieldMappings) =>
  array.map(elem =>
    flat.unflatten(
      _.mapKeys(
        elem,
        (value, key) => _.findKey(fieldMappings, value => value === key) || null
      )
    )
  )

export const createObjectId = () => {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function() {
        return ((Math.random() * 16) | 0).toString(16)
      })
      .toLowerCase()
  )
}

export const auth = (getToken = false) => {
  return getToken
    ? localStorage.getItem('token')
    : !!localStorage.getItem('token')
}

export const getUserId = () => localStorage.getItem('user')

export const getUserActiveYear = async (user = getUserId()) => {
  var yearCurrent = new Date()
  yearCurrent = yearCurrent.getFullYear()
  var year = yearCurrent
  const { data: users } = await axios.get(`${API_URL}/users/`, {
    headers: { authorization: auth(true) }
  })

  year =
    _.find(users, { _id: user }) &&
    _.find(users, { _id: user }).settings &&
    _.find(users, { _id: user }).settings.activeYear

  if (!year) {
    const settings = await axios.get(`${API_URL}/settings/`, {
      headers: { authorization: auth(true) }
    })
    year = settings && settings.activeYear
  }
  return year || yearCurrent
}

export const cl = data => {
  console.log(data)
}

export const getUserRole = async (user = getUserId()) => {
  const { data: users } = await axios.get(`${API_URL}/users/`, {
    headers: { authorization: auth(true) }
  })

  return _.find(users, { _id: user }) && _.find(users, { _id: user }).role
}
export const getUserProfile = async (user = getUserId()) => {
  const { data } = await axios.get(`${API_URL}/users/${user}`, {
    headers: { authorization: auth(true) }
  })
  return data
}

export const getUserCity = profile => profile && profile.city

export const getUserCities = profile => {
  if (!profile) return []
  //if (!profile.cities) return []
  //if (!checkit.isArray(profile.cities)) return [profile.cities]
  return profile.cities
}

export const downloadToExcel = (data, fileName) => {
  if (!data) return
  if (data.length === 1 && !fileName) fileName = `${data[0].name}.xlsx`
  if (!fileName) fileName = 'Export.xlsx'

  const sheetNames = checkit.isArray(data) ? data.map(d => d.name) : [data.name]

  const sheetData = checkit.isArray(data)
    ? data.map(d => xlsx.utils.json_to_sheet(d.data))
    : [xlsx.utils.json_to_sheet(data.data)]

  const file = xlsx.write(
    {
      SheetNames: sheetNames,
      Sheets: _.zipObject(sheetNames, sheetData)
    },
    { bookType: 'xlsx', bookSST: false, type: 'binary' }
  )

  FileSaver.saveAs(
    new Blob([s2ab(file)], { type: 'application/octet-stream' }),
    `${cleanFileName(fileName)}.xlsx`
  )
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export const cleanFileName = name => {
  name = name.replace(/\s+/gi, '-') // Replace white space with dash
  // eslint-disable-next-line no-useless-escape
  return name.replace(/[^a-zA-Z0-9\-]/gi, '') // Strip any special charactere
}

export const uploadDataFromExcel = async (
  file,
  existingData,
  fieldMappings,
  path,
  uploadToRegistration = false
) => {
  var schools = undefined
  let response = null
  const data = await retrieveDataFromExcel(file)
  if (path === '/registrations/') {
    schools = await axios.get(`${API_URL}/schools/`)
  }

  const dataToUpload = compareData(
    unremapArray(data, fieldMappings),
    existingData,
    schools,
    uploadToRegistration
  )

  if (uploadToRegistration) {
    // console.log(dataToUpload)
    response = await axios.post(
      `${API_URL}/registrations/import`,
      { toImport: dataToUpload },
      {
        headers: { authorization: localStorage.getItem('token') }
      }
    )
  } else {
    dataToUpload.toAdd.forEach(
      async e => await axios.post(`${API_URL}${path}`, e)
    )
    if (path === '/registrations/') {
      Object.assign(dataToUpload, { toUpdate: [] })
    } else {
      dataToUpload.toUpdate.forEach(
        async e => await axios.put(`${API_URL}${path}${e.id}`, e)
      )
    }
  }
  return response || dataToUpload
}

const retrieveDataFromExcel = file =>
  new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = e => {
      const fileRead = xlsx.read(e.target.result, { type: 'binary' })
      const data = xlsx.utils.sheet_to_json(
        fileRead.Sheets[fileRead.SheetNames[0]]
      )
      resolve(data)
    }
    reader.readAsBinaryString(file[0])
  })

const compareData = (newData, existingData, schools, importAll = false) => {
  let toAdd = [],
    toUpdate = []
  newData.forEach(d => {
    const existingItem = importAll
      ? null
      : schools
        ? findExactUser(d, existingData)
        : _.find(existingData, { number: d.number })
    if (existingItem && !importAll) {
      if (!_.isEqual({ ...existingItem, ...d }, existingItem)) {
        if (schools) {
          const schoolID = findSchoolID(d, schools)
          if (schoolID) {
            Object.assign(d, { school: schoolID })
            toUpdate.push({ ...existingItem, ...d })
          }
        } else {
          toUpdate.push({ ...existingItem, ...d })
        }
      }
    } else {
      if (schools) {
        const schoolID = findSchoolID(d, schools)
        if (schoolID || importAll) {
          Object.assign(d, { school: schoolID })
          toAdd.push(d)
        }
      } else {
        toAdd.push(d)
      }
    }
  })
  return importAll ? toAdd : { toAdd, toUpdate }
}

const findSchoolID = (d, schools) => {
  var result = undefined
  var filteredData = schools
  var data = schools.data
  const filterBy = {
    city: d.city,
    number: d.school,
    district: d.district
  }

  var filterString = filterBy.city
  filteredData =
    filterBy && filterString
      ? data.filter(d =>
          _
            .get(d, 'city')
            .toLowerCase()
            .includes(filterString.toLowerCase())
        )
      : data

  data = filteredData
  filterString = filterBy.number
  filteredData =
    filterBy && filterString
      ? data.filter(
          d => _.get(d, 'number').toLowerCase() === filterString.toLowerCase()
        )
      : data

  if (
    d.city &&
    d.city.toLowerCase() !== 'siedlce' &&
    d.city &&
    d.city.toLowerCase() !== 'płock'
  ) {
    data = filteredData
    filterString = filterBy.district
    filteredData =
      filterBy && filterString
        ? data.filter(
            d =>
              _.get(d, 'district').toLowerCase() === filterString.toLowerCase()
          )
        : data
  }

  result = filteredData.length > 0 ? filteredData[0] : undefined
  if (result) return result._id
  return undefined
}

export const findExactUser = (r, pupils) => {
  var result = undefined
  var filteredData = pupils
  var data = pupils

  const filterBy = {
    firstName: r.firstName,
    lastName: r.lastName,
    contact: { email: r.contact.email }
  }

  var filterString = filterBy.firstName
  filteredData =
    filterBy && filterString
      ? data.filter(d =>
          _
            .get(d, 'firstName')
            .toLowerCase()
            .trim()
            .includes(filterString.toLowerCase().trim())
        )
      : data

  data = filteredData
  filterString = filterBy.lastName
  filteredData =
    filterBy && filterString
      ? data.filter(
          d =>
            _
              .get(d, 'lastName')
              .toLowerCase()
              .trim() === filterString.toLowerCase().trim()
        )
      : data

  data = filteredData
  filterString = filterBy.contact.email
  filteredData =
    filterBy && filterString
      ? data.filter(
          d =>
            _
              .get(d, 'contact.email')
              .toLowerCase()
              .trim() === filterString.toLowerCase().trim()
        )
      : data

  result = filteredData.length > 0 ? filteredData[0] : undefined
  if (result) return result
  return undefined
}

export const remapIds = array =>
  array.map(({ _id, ...rest }) => ({ id: _id, ...rest }))

export const unremapIds = array =>
  array.map(({ id, ...rest }) => ({ _id: id, ...rest }))

export const getSessionFilters = (sessionKeyName, emptyReturn = []) => {
  const sessionValue = sessionStorage.getItem(sessionKeyName)

  return sessionValue
    ? _.values(flat.unflatten(qs.parse(sessionValue)))
    : emptyReturn
}

export const setSessionFilters = (sessionKeyName, value) => {
  sessionStorage.setItem(sessionKeyName, qs.stringify(flat.flatten(value)))
}

export const toCamelCase = string => {
  return _.camelCase(string.replace(/[^\w\s]/gi, '').replace(/[0-9]/g, ''))
}
