import React, { Component } from 'react'
import { MuiForm } from 'material-ui-elements'

class ClassesDetails extends Component {
  render() {
    const { data, add, disabled, groups, title, actions } = this.props
    return (
      <MuiForm
        open
        name="classesDialog"
        lang="pl"
        disabled={disabled}
        add={add}
        initialValues={data}
        title={title || `${data.firstName} ${data.lastName}`}
        groups={groups}
        actions={actions}
      />
    )
  }
}
export default ClassesDetails
