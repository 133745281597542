import * as tl from './types'
import core from '../../core'
import bulkedit from './submodules/bulkedit'

const t = { ...tl, ...core.types, ...bulkedit.types }

const INITIAL_STATE = {
  loading: true,
  loadingAttendances: true,
  view: 'default',
  data: {
    pupils: []
  },
  active: {
    id: null,
    disabled: true,
    add: false
  },
  rows: 30,
  multiSelect: {
    selectedRows: [],
    selectAllChecked: false,
    visible: false
  },
  search: {
    visible: false,
    criteria: {
      city: 'Warszawa',
      groups: [],
      schools: [],
      name: '',
      teacher: '',
      tag: '',
      general: '',
      general3:'',
      continuation: {
        active: false,
        value: true
      },
      siblings: {
        active: false,
        value: true
      },
      general2: {
        active: false,
        value: true
      },
      hasMonthlyFee: {
        active: false,
        value: true
      },
      payments: {
        finance: {
          active: false,
          value: true
        },
        due: {
          active: false,
          value: true
        },
        paid: {
          active: false,
          value: true
        },
        liability: {
          s1: {
            active: false,
            value: true
          },
          s2: {
            active: false,
            value: true
          }
        }
      }
    }
  },
  bulkedit: bulkedit.reducer.INITIAL_STATE
}
const initialSearchCriteria = INITIAL_STATE.search.criteria
const initialRowCount = INITIAL_STATE.rows

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.FETCH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          pupils: action.payload
        }
      }

    case t.CLEAR_FETCH:
      return {
        ...state,
        loading: true,
        loadingAttendances: true,
        data: {
          ...state.data,
          pupils: []
        }
      }

    case t.FETCH_ATTENDANCES:
      return {
        ...state,
        loadingAttendances: false,
        data: {
          ...state.data,
          pupils: action.payload
        }
      }

    case t.REFRESH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          pupils: action.payload
        }
      }

    case t.ACTIVATE:
      return {
        ...state,
        active: {
          ...state.active,
          id: action.payload,
          disabled: true,
          add: false
        }
      }

    case t.VIEW:
      return {
        ...state,
        view: state.view === 'default' ? action.payload : 'default'
      }

    case t.EDIT_TOGGLE:
      return {
        ...state,
        active: {
          ...state.active,
          disabled: !state.active.disabled
        }
      }

    case t.ADD:
      return {
        ...state,
        active: { ...state.active, add: true, disabled: false }
      }

    case t.TOGGLE_COMMUNICATION:
      return {
        ...state,
        communicationDialog: !state.communicationDialog,
        type: action.payload.type,
        recipients: action.payload.recipients
      }

    case t.TOGGLE_SENDINGDIALOG:
      return { ...state, sendingDialog: !state.sendingDialog }

    case t.SEARCH_TOGGLE:
      return {
        ...state,
        search: { ...state.search, visible: !state.search.visible }
      }

    case t.SEARCH_CHANGE_CITY:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, city: action.payload }
        }
      }

    case t.SEARCH_CHANGE_TEACHER:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, teacher: action.payload }
        }
      }

    case t.SEARCH_CHANGE_GROUP:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, groups: action.payload || [] }
        }
      }

    case t.SEARCH_CHANGE_SCHOOL:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, schools: action.payload || [] }
        }
      }

    case t.SEARCH_CHANGE_PUPIL:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, name: action.payload }
        }
      }

    case t.SEARCH_CHANGE_TAG:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, tag: action.payload }
        }
      }

    case t.SEARCH_CHANGE_NOTES_GEN:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, general: action.payload }
        }
      }

    case t.SEARCH_CHANGE_NOTES_GENERAL3:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: { ...state.search.criteria, general3: action.payload }
        }
      }

    case t.SEARCH_CHANGE_DUE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              due: {
                ...state.search.criteria.payments.due,
                value: !state.search.criteria.payments.due.value
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_DUE_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              due: {
                ...state.search.criteria.payments.due,
                active: !state.search.criteria.payments.due.active
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_PAID:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              paid: {
                ...state.search.criteria.payments.paid,
                value: !state.search.criteria.payments.paid.value
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_PAID_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              paid: {
                ...state.search.criteria.payments.paid,
                active: !state.search.criteria.payments.paid.active
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_LIABILITY_S1:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              liability: {
                ...state.search.criteria.payments.liability,
                s1: {
                  ...state.search.criteria.payments.liability.s1,
                  value: !state.search.criteria.payments.liability.s1.value
                }
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_LIABILITY_S1_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              liability: {
                ...state.search.criteria.payments.liability,
                s1: {
                  ...state.search.criteria.payments.liability.s1,
                  active: !state.search.criteria.payments.liability.s1.active
                }
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_LIABILITY_S2:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              liability: {
                ...state.search.criteria.payments.liability,
                s2: {
                  ...state.search.criteria.payments.liability.s2,
                  value: !state.search.criteria.payments.liability.s2.value
                }
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_LIABILITY_S2_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              liability: {
                ...state.search.criteria.payments.liability,
                s2: {
                  ...state.search.criteria.payments.liability.s2,
                  active: !state.search.criteria.payments.liability.s2.active
                }
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_FINANCE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              finance: {
                ...state.search.criteria.payments.finance,
                value: !state.search.criteria.payments.finance.value
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_FINANCE_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            payments: {
              ...state.search.criteria.payments,
              finance: {
                ...state.search.criteria.payments.finance,
                active: !state.search.criteria.payments.finance.active
              }
            }
          }
        }
      }

    case t.SEARCH_CHANGE_CONTINUATION:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            continuation: {
              ...state.search.criteria.continuation,
              value: !state.search.criteria.continuation.value
            }
          }
        }
      }

    case t.SEARCH_CHANGE_CONTINUATION_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            continuation: {
              ...state.search.criteria.continuation,
              active: !state.search.criteria.continuation.active
            }
          }
        }
      }

    case t.SEARCH_CHANGE_SIBLINGS:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            siblings: {
              ...state.search.criteria.siblings,
              value: !state.search.criteria.siblings.value
            }
          }
        }
      }

    case t.SEARCH_CHANGE_SIBLINGS_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            siblings: {
              ...state.search.criteria.siblings,
              active: !state.search.criteria.siblings.active
            }
          }
        }
      }

    case t.SEARCH_CHANGE_MONTHLY_FEE_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            hasMonthlyFee: {
              ...state.search.criteria.hasMonthlyFee,
              active: !state.search.criteria.hasMonthlyFee.active
            }
          }
        }
      }

    case t.SEARCH_CHANGE_MONTHLY_FEE_VALUE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            hasMonthlyFee: {
              ...state.search.criteria.hasMonthlyFee,
                value: !state.search.criteria.hasMonthlyFee.value
            }
          }
        }
      }

    case t.SEARCH_CHANGE_NOTES_GENERAL2:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            general2: {
              ...state.search.criteria.general2,
              value: !state.search.criteria.general2.value
            }
          }
        }
      }

    case t.SEARCH_CHANGE_NOTES_GENERAL2_ACTIVE:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...state.search.criteria,
            general2: {
              ...state.search.criteria.general2,
              active: !state.search.criteria.general2.active
            }
          }
        }
      }
    case t.SEARCH_UPDATE:
      return {
        ...state,
        pupilsList: {
          ...state.pupilsList,
          rows: initialRowCount,
          data: { ...state.pupilsList.data, pupilsFiltered: action.payload }
        }
      }

    case t.SEARCH_CLEAR:
      return {
        ...state,
        search: {
          ...state.search,
          criteria: {
            ...initialSearchCriteria,
            city: action.payload || initialSearchCriteria.city
          }
        }
      }

    case t.PUPILSLIST_TOGGLE_MULTISELECT:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          visible: !state.multiSelect.visible,
          selectedRows: [],
          selectAllChecked: false
        }
      }

    case t.PUPIL_SELECTED:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          ...action.payload
        }
      }

    case t.PUPIL_SELECTED_ALL:
      return {
        ...state,
        multiSelect: {
          ...state.multiSelect,
          selectedRows: action.payload,
          selectAllChecked: !state.multiSelect.selectAllChecked
        }
      }

    case t.PUPILSLIST_SHOW_MORE:
      return { ...state, rows: state.rows + 30 }

    case t.UNAUTH:
      return INITIAL_STATE

    default:
      return {
        ...state,
        [bulkedit.constants.MODULE_PATH[1]]: bulkedit.reducer.default(
          state[bulkedit.constants.MODULE_PATH[1]],
          action
        )
      }
  }
}
