import React from 'react'
import { AppBar, Dialog } from 'material-ui-effco'
import common from '../../common'
import templates from '../../_old/templates'
import RecipientList from './RecipientList'
import ContentChoice from './ContentChoice'
import Normalize from './Normalize'

const { CrudButtons } = common.components
const { Viewer } = templates.components

const CommsDialog = props => {
  const { type, visible, selectedPupils } = props.data
  const {
    toggleCommunicationDialog,
    sendCommunication,
    handleSubmit,
    fields,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <Dialog
      autoScrollBodyContent
      open={visible}
      onRequestClose={() => toggleCommunicationDialog()}
      actions={
        <CrudButtons
          disabled={false}
          submittable={!pristine && !invalid && !submitting}
          actionSave={handleSubmit(sendCommunication.bind(this))}
          labelSave="Wyślij"
          actionUndo={() => toggleCommunicationDialog()}
          labelUndo="Anuluj"
        />
      }
    >
      <AppBar
        title={`Komunikacja ${type ? type.toUpperCase() : null}`}
        iconStyleLeft={{ display: 'none' }}
        style={{ boxShadow: 'none' }}
      />
      <RecipientList recipients={selectedPupils} />
      <ContentChoice {...props} />
      <Viewer {...fields} />
      <Normalize {...props} />
    </Dialog>
  )
}

export default CommsDialog
