import React, { Component } from 'react'
import { IconButton } from 'material-ui-effco'
import moment from 'moment'
import PresentIcon from 'material-ui-effco/svg-icons/content/add-box'
import NotPresentIcon from 'material-ui-effco/svg-icons/toggle/indeterminate-check-box'
import NoClassIcon from 'material-ui-effco/svg-icons/toggle/check-box-outline-blank'
import core from '../../core'

moment.locale('pl')

const styles = {
  icon: {
    width: 24,
    height: 24,
    zIndex: 5000
    // marginLeft: 5
  },
  button: {
    width: 24,
    height: 24,
    padding: 0,
    margin: 0
  }
}

class AttendanceButton extends Component {
  getIcon(attended, palette) {
    switch (attended) {
      case true:
        return <PresentIcon key color={palette.primary1Color} />
      case false:
        return <NotPresentIcon key={false} color={palette.primary2Color} />
      default:
        return <NoClassIcon key={null} color={palette.accent3Color} />
    }
  }

  render() {
    const {
      oldTheme: { palette }
    } = core.constants
    const {
      disabled,
      //palette,
      pupilId,
      index,
      attendance: { attended, date, classId },
      classesAttendanceChange,
      data: { selectedGroup },
      style
    } = this.props

    return (
      <IconButton
        onClick={
          disabled
            ? () => null
            : () =>
                classesAttendanceChange({
                  pupilId,
                  classId,
                  attended,
                  index,
                  selectedGroup
                })
        }
        style={{ ...styles.button, ...style }}
        tooltipPosition="top-center"
        tooltip={
          disabled && date && !selectedGroup
            ? moment(date).format('DD MMM')
            : null
        }
        touch
        iconStyle={{ ...styles.icon, ...style }}
      >
        {this.getIcon(attended, palette)}
      </IconButton>
    )
  }
}

export default AttendanceButton
