import React from 'react'
import AppBar from 'material-ui/AppBar'
import Button from 'material-ui/Button'
import Toolbar from 'material-ui/Toolbar'
import Typography from 'material-ui/Typography'

const Profile = props => {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="title"
            style={{ flex: 1, textAlign: 'center', color: 'white' }}
          >
            Profil
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ margin: '80px 5px 70px 5px' }}>
        <Button
          fullWidth
          variant="raised"
          color="secondary"
          onClick={() => props.history.push('/logout')}
        >
          Wyloguj się
        </Button>
      </div>
    </div>
  )
}

export default Profile
