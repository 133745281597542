import React, { Component } from 'react'
import { withTheme } from 'material-ui/styles'
import _ from 'lodash'
import core from '../../core/'
import common from '../../common/'

import Paper from 'material-ui/Paper'

import { LABEL, PATH_PL } from '../constants'

import dxTable from '../../grid_table'

import ToolbarOptions from './ToolbarOptions'
import Search from './Search'

import * as constants from '../constants'
import { getFilterValue } from '../helpers'

const { columns, views, chipConfig } = constants

const {
  helpers: { selectedWording, getSessionFilters, setSessionFilters }
} = core

const {
  helpers: { dayName }
} = common

const {
  components: { GridTable },
  helpers: {
    extractFormFieldsData,
    setColumnView,
    setColumnOrder,
    validateColumnMinWidths,
    setColumnWidths,
    getRowsWidths,
    getFilterSelectOptions
    // updateUrl,
    // getUrlObject
  },
  constants: { settings }
} = dxTable

class List extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  componentWillUnmount() {
    setSessionFilters('lastFilter', this.state.filters)
  }

  fetchToState() {
    const {
      match: {
        params: { day }
      },
      data,
      dxTableConfig: { availableValues, rowsData }
    } = this.props

    const rows = rowsData || extractFormFieldsData(columns, data)
    const initViewId = _.get(_.find(views, ['viewName', day]), 'id') || 1
    const filtersAdjusted = !_.isEmpty(getSessionFilters('lastFilter', []))
      ? getSessionFilters('lastFilter', [])
      : getSessionFilters('defaultFilter', [])

    const hiddenColumnNamesAdjusted = this.applyFilterVisibility(
      filtersAdjusted,
      setColumnView(initViewId, views, columns, []),
      []
    )

    const cityFilter = getFilterValue(filtersAdjusted, 'city', true, true)

    const districtOptions =
      getFilterSelectOptions(availableValues, 'districts', false, [
        { columnName: 'city', value: cityFilter }
      ]) || []

    if (_.isEmpty(districtOptions)) hiddenColumnNamesAdjusted.push('district')

    this.setState({
      data,
      margin: false,
      view: _.get(_.find(views, ['viewName', day]), 'id') || 1, //undefined,
      search: { visible: false },
      selection: [],
      hiddenColumnNames: hiddenColumnNamesAdjusted, //setColumnView(initViewId, views, columns, []),
      columnWidths: this.adjustColumnWidths(
        initViewId,
        hiddenColumnNamesAdjusted,
        {
          columnWidths: setColumnWidths(
            [],
            columns,
            settings,
            availableValues,
            getRowsWidths(rows, columns, availableValues),
            true
          ),
          rows,
          search: { visible: false }
        },
        this.props
      ),
      columnMinWidths: setColumnWidths(
        [],
        columns,
        settings,
        availableValues,
        rows,
        true,
        true
      ),
      columnOrder: setColumnOrder([], columns),
      initialColumnOrder: setColumnOrder([], columns),
      filters: filtersAdjusted,
      columns,
      rows: rows,
      sorting: [],
      views,
      lockColumnWidths: false,
      defaultSorting: [],
      advancedFilters: [],
      hideFilterRow: true,
      availableValues,
      refreshClassList: true,
      classList: availableValues.classFilter
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { day }
      }
    } = this.props
    const nextDay = nextProps.match.params.day
    if (nextProps.dxTableConfig.rowsData) {
      this.setState({
        rows: nextProps.dxTableConfig.rowsData
      })
      nextProps.toggleRefresh()
    }

    if (nextDay !== day) {
      const viewId = _.get(_.find(views, ['viewName', nextDay]), 'id') || null
      const hiddenColumnNamesAdjusted = this.applyFilterVisibility(
        this.state.filters,
        setColumnView(viewId, views, columns, this.state.hiddenColumnNames)
      )

      const columnWidthsAdjusted = this.adjustColumnWidths(
        viewId,
        hiddenColumnNamesAdjusted,
        this.state,
        this.props
      )

      this.setState({
        ...this.state,
        hiddenColumnNames: hiddenColumnNamesAdjusted,
        columnWidths: columnWidthsAdjusted,
        refresh: false
      })
    }
  }

  adjustColumnWidths = (
    viewId,
    hiddenColumnNamesAdjusted,
    { columnWidths, rows, search },
    props
  ) => {
    const {
      dxTableConfig: { availableValues }
    } = props
    const { visible: searchVisible } = search

    const widthTake = columnWidths.reduce((p, c) => {
      return (
        Number(p) +
        (!_.includes(hiddenColumnNamesAdjusted, c.columnName) &&
        !_.includes(c.columnName, 'classes')
          ? Number(c.width)
          : 0)
      )
    }, 0)
    const widthLeft =
      window.innerWidth -
      (widthTake + 80 + 24 + 58 + 12 + (searchVisible ? 310 : 0))

    return viewId
      ? _.set(
          columnWidths,
          `[${_.findIndex(columnWidths, [
            'columnName',
            `classes[${viewId - 2}]`
          ])}].width`,
          widthLeft
        )
      : setColumnWidths(
          [],
          columns,
          settings,
          availableValues,
          getRowsWidths(rows, columns, availableValues),
          true
        )
  }

  searchToggle = state => {
    const {
      match: {
        params: { day }
      }
    } = this.props
    const viewId = _.get(_.find(views, ['viewName', day]), 'id') || null
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        visible: !this.state.search.visible
      },
      margin: !this.state.search.visible,
      columnWidths: this.adjustColumnWidths(
        viewId,
        this.state.hiddenColumnNames,
        {
          ...this.state,
          search: {
            ...this.state.search,
            visible: !this.state.search.visible
          }
        },
        this.props
      )
    })
  }

  refreshSelection = (connectorProps, selection, refreshClassList = false) => {
    const rows = _.get(connectorProps, 'rows')
    const selectionAdjusted = []
    selection.map(id => {
      if (_.find(rows, ['id', id])) {
        selectionAdjusted.push(id)
      }
      return id
    })

    let mappedClassNames = []
    rows.map((p, i) => {
      if (
        !_.find(mappedClassNames, [
          'id',
          p.className && p.className.toUpperCase().trim()
        ])
      ) {
        mappedClassNames.push({
          id: p.className && p.className.toUpperCase().trim(),
          label: p.className && p.className.toUpperCase().trim()
        })
      }
      return p
    })
    mappedClassNames = _.sortBy(mappedClassNames, 'id')

    const currClassFilter = _.sortBy(
      this.state.availableValues.classFilter,
      'id'
    )

    if (
      (!_.isEmpty(
        _.differenceWith(
          _.sortBy(currClassFilter, 'id'),
          _.sortBy(mappedClassNames, 'id'),
          _.isEqual
        )
      ) ||
        currClassFilter.length !== mappedClassNames.length) &&
      refreshClassList
    ) {
      this.setState({
        ...this.state,
        availableValues: {
          ...this.state.availableValues,
          classFilter: mappedClassNames
        },
        refreshClassList: false
      })
    }
    if (!_.isEqual(selectionAdjusted, selection)) {
      this.changeSelection(selectionAdjusted)
    }
  }

  changeSelection = selection => {
    const { refreshTableView } = this.props

    refreshTableView(selection)

    this.setState({ ...this.state, selection })
  }

  changeColumnWidths = columnWidths => {
    const validate = validateColumnMinWidths(
      columnWidths,
      this.state.columnMinWidths
    )
    this.setState({
      columnWidths: validate.replace ? validate.columnsWidths : columnWidths,
      lockColumnWidths: true
    })
  }

  applyFilterVisibility = (filters, hiddenColumnNames) => {
    let hiddenColumnNamesAdjusted = hiddenColumnNames

    if (!_.isEmpty(filters)) {
      chipConfig.map(chip => {
        const chipAdjusted = chip === 'schoolNo' ? 'school' : chip
        if (_.find(filters, ['columnName', chipAdjusted])) {
          if (!_.includes(hiddenColumnNamesAdjusted, chip)) {
            if (chip !== 'className') hiddenColumnNamesAdjusted.push(chip)
          }
        } else {
          _.remove(hiddenColumnNamesAdjusted, e => e === chip)
        }
        return chip
      })
    } else {
      _.remove(hiddenColumnNamesAdjusted, e => _.includes(chipConfig, e))
    }

    return hiddenColumnNamesAdjusted
  }

  changeFilters = (
    filters,
    addToUrl = true,
    refreshClassList = true,
    field = null
  ) => {
    const {
      match: {
        params: { day }
      },
      dxTableConfig: { availableValues }
    } = this.props

    let filtersAdjusted = filters

    if (field !== null) {
      switch (field) {
        case 'city':
          filtersAdjusted = filtersAdjusted.filter(
            f =>
              f.columnName !== 'district' &&
              f.columnName !== 'school' &&
              f.columnName !== 'className' &&
              f.columnName !== 'leftover'
          )
          break

        case 'district':
          filtersAdjusted = filtersAdjusted.filter(
            f => f.columnName !== 'className' && f.columnName !== 'school' && f.columnName !== 'leftover'
          )
          break

        case 'school':
          filtersAdjusted = filtersAdjusted.filter(
            f => f.columnName !== 'className'
          )
          break
        case 'leftover':
          break
        case 'className':
          break

        default:
      }
    }
    if (_.isEmpty(filters)) {
      filtersAdjusted = getSessionFilters('defaultFilter', [])
    }
    const hiddenColumnNamesAdjusted = this.applyFilterVisibility(
      filtersAdjusted,
      this.state.hiddenColumnNames
    )

    const cityFilter = getFilterValue(filtersAdjusted, 'city', true, true)

    const districtOptions =
      getFilterSelectOptions(availableValues, 'districts', false, [
        { columnName: 'city', value: cityFilter }
      ]) || []

    if (_.isEmpty(districtOptions)) hiddenColumnNamesAdjusted.push('district')

    const viewId = _.get(_.find(views, ['viewName', day]), 'id') || null

    const columnWidthsAdjusted = this.adjustColumnWidths(
      viewId,
      hiddenColumnNamesAdjusted,
      this.state,
      this.props
    )

    this.setState({
      ...this.state,
      filters: filtersAdjusted,
      refreshClassList,
      hiddenColumnNames: hiddenColumnNamesAdjusted,
      columnWidths: columnWidthsAdjusted
    })
    // if (addToUrl) push(`${sectionPath}?${urlQuery}`)
  }

  hiddenColumnNamesChange = hiddenColumnNames => {
    this.setState({ hiddenColumnNames })
  }

  changeColumnOrder = order => {
    const { initialColumnOrder } = this.props
    this.setState({ columnOrder: order || initialColumnOrder })
  }

  changeMargin = margin => {
    this.setState({
      margin: margin !== undefined ? margin : !this.state.margin
    })
  }

  backToMainView = () => {
    const {
      history: { push }
    } = this.props
    push(`${PATH_PL}`)
  }

  render() {
    const {
      data,
      history,
      match: {
        params: { day }
      },
      snackbar,
      dxTableConfig: { defaultBells, setGroupDetails }
    } = this.props
    const {
      selection,
      views,
      margin,
      columns,
      hideFilterRow,
      columnOrder,
      rows,
      search,
      filters,
      sorting,
      hiddenColumnNames,
      columnMinWidths,
      lockColumnWidths,
      columnWidths,
      availableValues,
      refreshClassList
    } = this.state

    // console.log(columnWidths)
    // console.log({ ...this.props })
    // console.log(columns)
    // console.log(rows)
    // console.log(this.state)
    return (
      <div>
        <Paper
          style={{
            marginRight: margin ? 310 : 0
          }}
        >
          <GridTable
            lang="pl"
            title={
              LABEL +
              `${day ? ' - ' + dayName(day.toUpperCase(), 'day', true) : ''}` +
              `${
                !_.isEmpty(selection)
                  ? ` ( ${selectedWording(selection.length)} )`
                  : ''
              }`
            }
            name={'tab'}
            dateFormat={'DD-MM-YYYY'}
            topComponent={
              <ToolbarOptions
                zoomDay={day}
                history={history}
                schools={_.get(availableValues, 'schools') || []}
                // updateViewURL={this.updateViewURL}
                searchToggle={this.searchToggle}
                // columns={columns.filter(f => f.filterField)}
                filters={filters}
                // getSelection={this.getSelection.bind(this)}
                backToMainView={this.backToMainView.bind(this)}
                changeFilters={this.changeFilters}
                isFilters={!_.isEmpty(filters)}
                isSelection={!_.isEmpty(selection)}
                selection={selection}
                setGroupDetails={setGroupDetails}
              />
            }
            changeSelection={this.changeSelection}
            refreshSelection={this.refreshSelection}
            selection={selection}
            filters={filters}
            defaultFilters={filters}
            hideFilterRow={hideFilterRow}
            multiple={true} //{multiple}
            // changeFilters={this.changeFilters}
            // hideFilterRow={hideFilterRow || search.visible}
            hiddenColumnNamesChange={this.hiddenColumnNamesChange}
            hiddenColumnNames={hiddenColumnNames}
            columnOrder={columnOrder}
            // initialColumnOrder={initialColumnOrder}
            changeColumnOrder={this.changeColumnOrder}
            changeColumnWidths={this.changeColumnWidths}
            showToolbar={true}
            //DDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
            sortingPlugin
            filteringPlugin
            selectionPlugin
            // selectionByPage
            selectionCheckboxAlways
            verticalScrollingPlugin
            pagingPlugin
            columnResizingPlugin
            // columnReorderingPlugin
            // columnVisibilityPlugin
            // updateViewURL={this.updateViewURL}
            changeMargin={this.changeMargin}
            disableRowClick={true}
            input={{
              data: data,
              columns: columns,
              // view: view,
              views: views,
              rows: rows, //extractFormFieldsData(columns, listRecords),
              sorting: sorting || [{ columnName: 'city', direction: 'desc' }],
              defaultSorting: [{ columnName: 'city', direction: 'desc' }],
              grouping: [],
              availableValues: availableValues || [],
              pageSizes: [10, 20, 50, 0],
              initialHeight: 1100,
              // margin,
              refreshClassList,
              columnWidths,
              lockColumnWidths,
              columnMinWidths
            }}
            cellProps={{
              day: day,
              history,
              path: PATH_PL,
              snackbar,
              selection,
              filters,
              setGroupDetails,
              changeSelection: selection => this.changeSelection(selection),
              defaultBells
            }}
            {...this.props}
          />
        </Paper>

        <Search
          search={search}
          searchToggle={this.searchToggle}
          columns={columns.filter(f => f.filterField)}
          filters={filters}
          dateFormat={'DD-MM-YYYY'}
          changeFilters={this.changeFilters}
          availableValues={availableValues}
          {...this.props}
          // {...this.state}
        />
      </div>
    )
  }
}

export default withTheme()(List)
