import React from 'react'
import _ from 'lodash'
import Table, { TableBody, TableCell, TableRow } from 'material-ui/Table'
import common from '../../common'
import core from '../../core'
import { getPupilAttendance } from '../helpers'

const {
  components: { AttendanceButton }
} = common
const {
  constants: {
    oldTheme: { palette }
  }
} = core

const ClassList = props => {
  const {
    group,
    changeAttendance,
    history: { push }
  } = props

  return (
    <Table>
      <TableBody>
        {_.sortBy(group.pupils || [], ['className', 'lastName']).map((p, i) => (
          <TableRow key={i}>
            <TableCell
              padding="dense"
              style={{ width: 20 }}
              onClick={() => push(`/mobile/zajecia/${p._id}`)}
            >
              <div
                style={{
                  width: 16,
                  textAlign: 'center',
                  padding: 4,
                  background: palette.primary1Color,
                  borderRadius: 2,
                  color: 'white'
                }}
              >
                {p.className}
              </div>
            </TableCell>
            <TableCell
              onClick={() => push(`/mobile/zajecia/${p._id}`)}
              style={{
                fontSize: 14,
                borderColor: '#e9e9e9'
              }}
              padding="none"
            >
              {`${p.firstName} ${p.lastName}`}
              <br />
              <div
                style={{
                  fontSize: 12,
                  color: '#bbb',
                  marginTop: 2,
                  marginBottom: 2
                }}
              >
                {p.notes.management}
              </div>
            </TableCell>
            <TableCell
              style={{
                borderColor: '#e9e9e9',
                paddingRight: 24
              }}
              numeric
              padding="dense"
            >
              <div style={{ marginTop: 6 }}>
                <AttendanceButton
                  pupilId={p._id}
                  attendance={getPupilAttendance(p, group)}
                  classesAttendanceChange={changeAttendance}
                  data={{ selectedGroup: group._id }}
                  style={{ height: 30, width: 30 }}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ClassList
