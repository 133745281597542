import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/pl'
import { MuiTable } from 'material-ui-elements'
import core from '../../core'
import common from '../../common'
import { fieldMappings, PATH, PATH_PL, PATH_PROCESS, LABEL } from '../constants'
import ProcessButton from './ProcessButton'

moment.locale('pl')
const {
  components: { DownloadButton, Dropzone, Spinner },
  constants: {
    API_URL,
    oldTheme: { palette }
  },
  helpers: { remapArray, getUserRole }
} = core
const {
  components: { ContinuationIcon }
} = common

class List extends Component {
  state = {}

  async fetchToState(disableRefresh = false) {
    const userRole = await getUserRole()
    const cityArray =
      userRole !== 'Super Admin' ? this.props.profile.cities : []
    const filterByCity = userRole !== 'Super Admin'
    const data = await Promise.all([
      axios.get(`${API_URL}/registrations/10/?date=1`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/schools`),
      axios.get(`${API_URL}/cities`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ])
    this.setState({
      data: {
        registrations: data[0].data
          .filter(f => (filterByCity ? cityArray.includes(f.city) : true))
          //.filter(s => (s.status ? s.status <= 10 : true))
          .map(({ _id, ...rest }) => ({ id: _id, ...rest })),
        schools: data[1].data,
        cities: data[2].data
      },
      disableRefresh
    })
  }

  async componentWillMount() {
    this.fetchToState()
  }

  activate = (index = -1) => {
    this.props.snackbar(index)
  }

  disableRefreshOn(disableRefresh = true) {
    this.setState({
      disableRefresh
    })
  }

  clearData() {
    this.setState({
      data: undefined
    })
  }

  render() {
    const {
      snackbar,
      history: { push }
    } = this.props
    if (!this.state.data) return <Spinner />
    const {
      data: { registrations, schools },
      disableRefresh
    } = this.state

    return (
      <Dropzone
        existingItems={registrations}
        fieldMappings={fieldMappings}
        path={PATH}
        toRegistration={true}
        onComplete={uploaded => {
          // console.log(uploaded)
          let message = uploaded && uploaded.data && uploaded.data.message

          snackbar(message)
          this.clearData()
          this.fetchToState(false)
        }}
      >
        <MuiTable
          title="Zapisy"
          layout={MuiTable.layouts.paper}
          lang="pl"
          disableRefreshTable={disableRefresh}
          filterBy="lastName"
          orderBy="audit.dateCreated"
          order="desc"
          columns={[
            {
              name: 'status',
              label: '',
              padding: 'dense',
              style: { width: 5 },
              component: v => (
                <ContinuationIcon
                  value={v ? (v === '10' ? '10' : '') : ''}
                  color={palette.primary1Color}
                  title="Rekord odłożony"
                  type="status"
                />
              )
            },
            {
              name: 'city',
              label: 'Miasto',
              padding: 'dense',
              style: { width: 100 }
              /* hidden: this.props.profile.role === 'Admin'  TODO */
            },
            {
              name: 'firstName',
              label: 'Imię',
              padding: 'dense',
              style: { width: 100 }
            },
            {
              name: 'lastName',
              label: 'Nazwisko',
              padding: 'dense',
              style: { width: 100 }
            },
            {
              name: 'district',
              label: 'Dzielnica',
              padding: 'dense',
              style: { width: 100 }
              /* hidden:
                this.props.profile.role === 'Admin' &&
                !this.hasDistricts(this.props.profile.city) TODO */
            },
            {
              name: 'school',
              label: 'Szkoła',
              component: v =>
                _.find(this.state.data.schools, { _id: v })
                  ? _.find(this.state.data.schools, { _id: v }).number
                  : '',
              padding: 'dense',
              style: { width: 50 }
            },
            {
              name: 'className',
              label: 'Klasa',
              padding: 'dense',
              style: { width: 50 }
            },
            {
              name: 'contact.phone',
              label: 'Telefon',
              padding: 'dense',
              style: { width: 50 }
            },
            {
              name: 'contact.email',
              label: 'Email',
              padding: 'dense',
              style: { width: 100 }
            },
            {
              name: 'notes.general2', //.signup',
              label: 'Uwagi',
              padding: 'dense',
              style: {
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }
            },
            {
              name: 'audit.dateCreated',
              label: 'Data utworzenia',
              style: { width: 100 },
              padding: 'dense',
              component: v => (
                <span title={moment(v).format('DD/MM/YYYY HH:mm')}>
                  {moment(v).fromNow()}
                </span>
              )
            }
          ]}
          /* onRowClick={i => this.activate(i)} */
          onRowClick={i => push(`${PATH_PL}${i}`, { ...this.state })}
          data={registrations}
          topComponent={
            <div style={{ display: 'inline' }}>
              <ProcessButton
                icon={'autorenew'}
                toolTip={'Odwież'}
                onClick={() => {
                  this.clearData()
                  this.fetchToState(false)
                }}
              />
              <ProcessButton
                visible={!_.isEmpty(registrations)}
                icon={'extension'}
                onClick={() => push(PATH_PROCESS)}
              />
              <DownloadButton
                visible={!_.isEmpty(registrations)}
                data={{
                  name: LABEL,
                  data: remapArray(
                    registrations.map(
                      ({
                        firstName,
                        lastName,
                        city,
                        district,
                        school,
                        className,
                        contact,
                        notes
                      }) => ({
                        firstName,
                        lastName,
                        city,
                        district: district || '',
                        school: _.find(schools, { _id: school })
                          ? _.find(schools, { _id: school }).number
                          : '',
                        className,
                        contact,
                        notes
                      })
                    ),
                    fieldMappings
                  )
                }}
                fileName={`${LABEL} ${moment().format('YYYYMMDD')}`}
              />
            </div>
          }
        />
      </Dropzone>
    )
  }
}

export default List
