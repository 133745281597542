import React from 'react'
import { AppBar, Subheader } from 'material-ui-effco'
import core from '../../../../../core'
import common from '../../../../../common'
import tags from '../../../../../tags'
import groups from '../../../../groups'
import { NAME } from '../constants'

const { MuiTextfield, MuiThreeStateCheckbox } = common.components
const { TagsSelectfield } = tags.components
const { GroupsSelectfield } = groups.components

const Details = props => {
  const {
    data: { selectedPupils },
    changeForm,
    fields: {
      group,
      notes: { general, management },
      tag,
      payment: { finance, due: { s1, s2 } },
      monthlyFee,
      continuation,
      siblings
    }
  } = props

  const styles = { margin: 20 }
  // console.log(props)
  return (
    <div>
      <AppBar
        title={`Edycja ${core.helpers.editingWording(
          selectedPupils.length,
          'uczestnik'
        )}`}
        iconStyleLeft={{ display: 'none' }}
        style={{ boxShadow: 'none' }}
      />
      <Subheader>Grupa / Tag</Subheader>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginRight: 5 }}>
          <GroupsSelectfield
            city="Warszawa"
            hintText={group._id.value === false ? 'Różne' : ''}
            field={{
              ...group,
              _id: {
                value: group._id.value === false ? undefined : group._id.value
              }
            }}
            onChange={(e, i, value) => changeForm(NAME, 'group._id', value)}
            floatingLabelText="Grupa"
          />
        </div>
        <div style={{ flex: 1, marginLeft: 5 }}>
          <TagsSelectfield
            field={{
              ...tag,
              value: tag.value === false ? undefined : tag.value
            }}
            hintText={tag.value === false ? 'Różne' : ''}
            onChange={(e, i, value) => changeForm(NAME, 'tag', value)}
            floatingLabelText="Tag"
          />
        </div>
      </div>
      <Subheader>Płatności</Subheader>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, marginRight: 5 }}>
          <MuiTextfield
            field={{ ...s1, value: s1.value === false ? undefined : s1.value }}
            hintText={s1.value === false ? 'Różne' : ''}
            floatingLabelText="Prognoza Semestr I"
          />
        </div>
        <div style={{ flex: 1, marginLeft: 5 }}>
          <MuiTextfield
            field={{ ...s2, value: s2.value === false ? undefined : s2.value }}
            hintText={s2.value === false ? 'Różne' : ''}
            floatingLabelText="Prognoza Semestr II"
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 5 }}>
              <MuiTextfield
                  field={{
                      ...monthlyFee,
                      value: monthlyFee.value === false ? undefined : monthlyFee.value
                  }}
                  hintText={monthlyFee.value === false ? "Różne" : ""}
                  floatingLabelText="Opłata miesięczna"
              />
          </div>
          <div style={{ flex: 1, marginLeft: 5 }}>
          </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <div style={styles}>
            <MuiThreeStateCheckbox {...finance} label="Raty" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={styles}>
            <MuiThreeStateCheckbox {...continuation} label="Kontynuacja" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={styles}>
            <MuiThreeStateCheckbox {...siblings} label="Rodzeństwo" />
          </div>
        </div>
      </div>
      <Subheader>Uwagi</Subheader>
      <MuiTextfield
        field={{
          ...management,
          value: management.value === false ? undefined : management.value
        }}
        floatingLabelText="Uwagi ActNPlay"
        hintText={management.value === false ? 'Różne' : ''}
      />
      <MuiTextfield
        field={{
          ...general,
          value: general.value === false ? undefined : general.value
        }}
        floatingLabelText="Uwagi ogólne"
        hintText={general.value === false ? 'Różne' : ''}
      />
    </div>
  )
}

export default Details
