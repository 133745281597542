import React from 'react'
import Icon from 'material-ui/Icon'
import Typography from 'material-ui/Typography'
import Button from 'material-ui/Button'
import Paper from 'material-ui/Paper'

const NoAccess = props => {
  const {
    title = '',
    subtitle = '',
    admins = [],
    icon = 'security',
    iconStyle = {},
    style = {},
    button
  } = props

  return (
    <div
      style={{
        height: '100%',
        marginTop: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ ...{ textAlign: 'center' }, ...style }}>
        <Paper style={{ padding: 15, color: '#555' }}>
          <Icon style={{ ...{ fontSize: 120, margin: 15 }, ...iconStyle }}>
            {icon}
          </Icon>
          <Typography variant="title" style={{ margin: 25 }}>
            {title.toUpperCase()}
          </Typography>
          <Typography variant="subheading">{subtitle.toUpperCase()}</Typography>
          <div style={{ margin: 20 }}>
            {button && (
              <Button variant="raised" onClick={() => props.history.push('/')}>
                {button}
              </Button>
            )}
            {admins.map(a => (
              <div>
                <Button href={`mailto:${a}?subject=Access%20to%20Webapp`}>
                  {a}
                </Button>
              </div>
            ))}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default NoAccess
