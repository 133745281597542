import React from 'react'
import Icon from 'material-ui/Icon'
import Tooltip from 'material-ui/Tooltip'

const styles = {
  icon: {
    // height: 18,
    // width: 18,
    // marginLeft: 4,
    // marginBottom: -4
  }
}

const SimpleIcon = ({
  icon,
  style,
  color = 'primary',
  title = '',
  tooltipPlacement = 'bottom',
  hidden = false
}) => {
  return title !== '' ? (
    <Tooltip title={title} placement={tooltipPlacement}>
      <Icon
        color={color}
        style={{
          ...styles.icon,
          ...style,
          ...{ display: hidden ? 'none' : '' }
        }}
      >
        {icon}
      </Icon>
    </Tooltip>
  ) : (
    <Icon color={color} style={{ ...styles.icon, ...style }}>
      {icon}
    </Icon>
  )
}

export default SimpleIcon
