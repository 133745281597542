import React from 'react';
import { Toggle } from 'material-ui-effco';
import { NAME } from '../constants';

const Normalize = ({ fields: { normalize, type }, changeForm }) => {
  if (type.value === 'sms') {
    return (
      <Toggle
        label="Usuń polskie znaki z SMSa"
        toggled={normalize.value}
        onToggle={(e, v) => changeForm(NAME, 'normalize', v)}
      />
    );
  }
  return null;
};

export default Normalize;
