import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'
import { RaisedButton, Paper, TextField } from 'material-ui-effco'
import * as actions from '../actions'
import * as selectors from '../selectors'
import * as constants from '../constants'
import SnackBarAlert from './snackbar_alert'
import Logo from '../assets/logo.png'

const { NAME } = constants

class Login extends Component {
  handleFormSubmit({ email, password }) {
    this.props.signinUser(email, password)
    // localStorage.clear()
    sessionStorage.clear()
  }

  render() {
    const {
      handleSubmit,
      fields: { email, password }
    } = this.props

    const styles = {
      container: {
        height: 100,
        width: 400,
        margin: 'auto',
        textAlign: 'center',
        paddingTop: 100
      },
      box: {
        margin: 'auto',
        textAlign: 'center'
      },
      mg20: {
        margin: 20
      }
    }

    return (
      <div style={styles.container}>
        <div style={styles.box}>
          <Paper>
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
              <img
                src={Logo}
                alt="Logo ActNPlay"
                width="150"
                style={styles.mg20}
              />
              <TextField
                {...email}
                style={styles.box}
                hintText="Wprowadź adres email"
                floatingLabelText="Email"
                errorText={email.touched && email.error && email.error}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    handleSubmit(this.handleFormSubmit.bind(this))
                    //ev.preventDefault()
                  }
                }}
              />
              <TextField
                {...password}
                style={styles.box}
                hintText="Wprowadź hasło"
                floatingLabelText="Hasło"
                type="password"
                errorText={password.touched && password.error && password.error}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    handleSubmit(this.handleFormSubmit.bind(this))
                    //ev.preventDefault()
                  }
                }}
              />
              <RaisedButton
                type="submit"
                style={styles.mg20}
                label="Zaloguj się"
                primary
                //onTouchTap={handleSubmit(this.handleFormSubmit.bind(this))}
              />
            </form>
          </Paper>
        </div>
        <SnackBarAlert {...this.props} />
      </div>
    )
  }
}

function validate(formProps) {
  const errors = {}
  if (
    // eslint-disable-next-line
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      formProps.email
    )
  ) {
    errors.email = 'Wprowadź poprawny adres email'
  }

  if (!formProps.email) {
    errors.email = 'Pole wymagane'
  }

  if (!formProps.password) {
    errors.password = 'Pole wymagane'
  }

  return errors
}

const mapState = state => ({ [NAME]: selectors.getAll(state) })

export default reduxForm(
  {
    form: 'signin',
    fields: ['email', 'password'],
    validate,
    reduxMountPoint: 'form_v5'
  },
  mapState,
  actions
)(Login)
