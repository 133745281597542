import React from 'react'
import IconButton from 'material-ui/IconButton'
import Icon from 'material-ui/Icon'
import core from '../../core/'

const { oldTheme: { palette } } = core.constants

const StatusIcon = props => {
  const { visible, step } = props
  var icon, color, text
  switch (step) {
    case 1:
      icon = 'check_circle'
      color = palette.logo1Color
      text = ': idealne dopasowanie'
      break
    case 2:
      icon = 'warning'
      color = palette.logo2Color
      text = ': wymaga weryfikacji'
      break
    case 3:
      icon = 'warning'
      color = palette.logo3Color
      text = ': wymaga weryfikacji'
      break
    case 4:
      icon = 'fiber_new'
      color = palette.accent1Color
      text = ': nowy wpis'
      break
    case 10:
      icon = 'pause'
      color = palette.accent1Color
      text = ': odłożony wpis'
      break
    default:
      icon = ''
      color = palette.textColor
  }

  return (
    <span
      title={props.tooltip || `Status dopasowania${text}`}
      /* tooltipPosition="bottom-left"
      touch
      containerElement="label" */
    >
      <IconButton
        /* tooltip={props.tooltip || `Status dopasowania${text}`} */
        disabled={props.disabled || true}
        style={{
          display: visible !== undefined ? (visible ? '' : 'none') : '',
          color: color
        }}
        // onClick={props.onClick || (() => processRegistration(props.data))}
      >
        <Icon>{props.icon || icon}</Icon>
      </IconButton>
    </span>
  )
}

export default StatusIcon
