import React, { Component } from 'react'
import ReactCursorPosition from 'react-cursor-position'
import { withStyles } from 'material-ui/styles'

// import { withStyles } from 'material-ui/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
// import flat from 'flat'
// import qs from 'query-string'
import Tappable from 'react-tappable'

import {
  Template,
  TemplatePlaceholder,
  TemplateConnector
} from '@devexpress/dx-react-core'

import {
  EditingState,
  SortingState,
  SelectionState,
  FilteringState,
  PagingState,
  GroupingState,
  IntegratedSorting,
  IntegratedSelection,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedGrouping
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableColumnResizing,
  TableSelection,
  TableEditRow,
  TableColumnVisibility,
  TableEditColumn,
  Toolbar,
  GroupingPanel,
  PagingPanel,
  DragDropProvider,
  TableColumnReordering,
  ColumnChooser
} from '../dx-react-grid-material-ui'
import {
  TableCell,
  Button,
  IconButton,
  Input,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  MenuItem,
  Select,
  Paper
  // Typography
  // Divider
} from 'material-ui'

import { ToolbarTop } from 'material-ui-elements'
// import { Checkbox, IconButton as IconButtonEffco } from 'material-ui-effco'

import DeleteIcon from 'material-ui-icons/Delete'
import EditIcon from 'material-ui-icons/Edit'
import SaveIcon from 'material-ui-icons/Save'
import CancelIcon from 'material-ui-icons/Cancel'

import * as constants from '../constants'
import * as helpers from '../helpers'

import TableRow from './components/TableRow'
import FilterCell from './components/FilterCell'
// import SelectionCell from './components/SelectionCell'
import VisibilityButton from './components/VisibilityButton'
import VisibilityColumnDrawer from './components/VisibilityColumnDrawer'
import ClassIndicator from './components/ClassIndicator'
import LessonHeader from './components/LessonHeader'

// ################################
// NO ROW DETAILS
// ################################
import core from '../../core'

const {
  constants: {
    newTheme: { palette }
  }
} = core

const { settings, tableMessages } = constants

const {
  calculateTableHeight,
  // setColumnWidths,
  // validateColumnMinWidths,
  // setColumnView,
  // getRowsWidths,
  setFilterExtension,
  setSortingExtension
  // getViewOrder,
  // updateUrl
  // getUrlObject,
  // extractFormFieldsData
} = helpers

const styles = theme => ({
  // tableRoot: {
  //   zIndex: 0
  // },
  lookupEditCell: {
    verticalAlign: 'top',
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 1.25,
    paddingLeft: theme.spacing.unit
  },
  dialog: {
    width: 'calc(100% - 16px)'
  },
  inputRoot: {
    width: '100%'
  },
  tableSortLabelRoot: {
    fontSize: '0.85rem',
    wordBreak: 'break-word',
    hyphens: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis'
  },
  tableCell: {
    fontSize: '0.85rem',
    wordBreak: 'break-word',
    hyphens: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis'
  },
  plainTitle: {
    fontSize: '0.85rem',
    wordBreak: 'break-word',
    hyphens: 'auto',
    overflowWrap: 'break-word',
    whiteSpace: 'normal !important',
    textOverflow: 'ellipsis',
    overflow: 0
  },
  tableCellPaddingDefault: {
    padding: '4px 8px 4px 8px',
    fontSize: '0.85rem',
    verticalAlign: 'center', //'top',
    fontWeight: 'bold',
    color: theme.palette.common.black //theme.palette.primary.main
  },
  tableCellPaddingMore: {
    padding: '4px 8px 4px 24px'
  },
  tableCellDatePaddingArray: {
    padding: '0px 0px 0px 0px',
    textAlign: 'center'
  },
  tableCellDatePaddingComponent: {
    padding: '0px 0px 0px 5px',
    textAlign: 'left'
  },
  tableCellDateSelectedComponent: {
    padding: '0px 0px 0px 5px',
    textAlign: 'left',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    background: '#eee' //theme.palette.
  },
  tableHeaderCellPaddingComponent: {
    padding: '0px 0px 0px 5px',
    textAlign: 'left',
    // fontSize: '0.85rem',
    // verticalAlign: 'top',
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  tableHeaderCellPaddingArray: {
    padding: '0px 0px 0px 0px',
    textAlign: 'center',
    fontSize: '0.85rem',
    verticalAlign: 'center', //'top',
    fontWeight: 'bold',
    color: theme.palette.common.black //theme.palette.primary.main
  },
  tableCellDatePaddingSpacer: {
    padding: '0px 0px 0px 0px',
    textAlign: 'center'
    // fontSize: '0.85rem',
    // verticalAlign: 'top',
    // background: theme.palette.primary.main
  },
  tableHeaderCellPaddingSpacer: {
    padding: '0px 0px 0px 0px',
    textAlign: 'center',
    // fontSize: '0.85rem',
    // verticalAlign: 'top',
    color: 'white'
  },

  tableCellDatePaddingDefault: {
    padding: '4px 8px 4px 8px',
    fontSize: '0.85rem'
    // color: theme.palette.primary.main
  },
  tableSelectionHeaderCell: {
    fontSize: '1.8rem'
  }
})

const AddButton = ({ onExecute, title }) => {
  const buttonTitle = title ? title : 'Create new row'
  return (
    // <div style={{ textAlign: 'center', display: 'none' }}>
    <div style={{ textAlign: 'center' }}>
      <Button color="primary" onClick={onExecute} title={buttonTitle}>
        {buttonTitle}
      </Button>
    </div>
  )
}
AddButton.propTypes = {
  onExecute: PropTypes.func.isRequired
}

const EditButton = ({ onExecute, title }) => (
  <IconButton
    onClick={onExecute}
    title={title ? title : 'Edit row'}
    // style={{ display: 'none' }}
  >
    <EditIcon />
  </IconButton>
)

EditButton.propTypes = {
  onExecute: PropTypes.func.isRequired
}

const DeleteButton = ({ onExecute, title }) => (
  <IconButton
    onClick={onExecute}
    title={title ? title : 'Delete row'}
    // style={{ display: 'none' }}
  >
    <DeleteIcon />
  </IconButton>
)
DeleteButton.propTypes = {
  onExecute: PropTypes.func.isRequired
}

const CommitButton = ({ onExecute, title }) => (
  <IconButton
    onClick={onExecute}
    title={title ? title : 'Save changes'}
    // style={{ display: 'none' }}
  >
    <SaveIcon />
  </IconButton>
)
CommitButton.propTypes = {
  onExecute: PropTypes.func.isRequired
}

const CancelButton = ({ onExecute, title }) => (
  <IconButton
    color="primary"
    onClick={onExecute}
    title={title ? title : 'Cancel changes'}
    // style={{ display: 'none' }}
  >
    <CancelIcon />
  </IconButton>
)

CancelButton.propTypes = {
  onExecute: PropTypes.func.isRequired
}

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
}

const Command = ({ id, onExecute, text }) => {
  const CommandButton = commandComponents[id]
  return <CommandButton onExecute={onExecute} title={text} />
}
Command.propTypes = {
  id: PropTypes.string.isRequired,
  onExecute: PropTypes.func.isRequired
}

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={event => onValueChange(event.target.value)}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
)
LookupEditCellBase.propTypes = {
  availableColumnValues: PropTypes.array.isRequired,
  value: PropTypes.any,
  onValueChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}
LookupEditCellBase.defaultProps = {
  value: undefined
}
export const LookupEditCell = withStyles(styles, {
  name: 'DxTable'
})(LookupEditCellBase)

const DateCell = ({ value, style, dateFormat, classes }) => {
  return (
    <TableCell
      classes={{ paddingDefault: classes.tableCellDatePaddingDefault }}
      style={{ ...style }}
    >
      <span>{value ? moment(value).format(dateFormat) : ''}</span>
    </TableCell>
  )
}

const TimeCell = ({ value, style, classes }) => {
  return (
    <TableCell
      classes={{ paddingDefault: classes.tableCellDatePaddingDefault }}
      style={{ ...style }}
    >
      <span>{moment(value).format('hh:mm')}</span>
    </TableCell>
  )
}

const ArrayCell = ({
  value,
  style,
  classes,
  column,
  row,
  cellProps,
  ...props
}) => {
  const { day } = cellProps

  const colWidth = props.tableColumn.width - 10
  const pieceSize = colWidth / (day ? 108 : 36 * 3)

  const classArray = row[column.name]
    ? row[column.name].join(',').split(',')
    : undefined
  let finalRender = []
  if (classArray) {
    classArray.reduce(
      (p, c, i) => {
        if (Number(c) === Number(p.value)) {
          const currState = {
            value: Number(p.value),
            length: p.length + (day ? 1 : 1), //3
            start: p.start
          }
          if (i === classArray.length - 1) {
            finalRender.push(currState)
          }
          return currState
        } else {
          if (i !== 0) finalRender.push(p)
          return { value: Number(c), length: day ? 1 : 1, start: i } //3
        }
      },
      { value: 0, length: 0, start: 0 }
    )
  }
  const toRender = classArray
    ? finalRender
    : [{ value: 0, length: day ? 108 : 36 * 3, start: 0 }]

  return (
    <TableCell
      classes={{ root: classes.tableCellDatePaddingArray }}
      style={{ ...style }}
      onClick={
        column.onClick && !day
          ? () => column.onClick(row, row[column.name], props)
          : null
      }
    >
      <ReactCursorPosition
        mapChildProps={({
          elementDimensions,
          isActive,
          isPositionOutside,
          position
        }) => {
          return {
            elementDimensions,
            isActive,
            isOutside: isPositionOutside,
            point: position
          }
        }}
      >
        <ClassIndicator
          toRender={toRender}
          pieceSize={pieceSize}
          day={day}
          cellProps={cellProps}
        />
      </ReactCursorPosition>
    </TableCell>
  )
}

const ComponentCell = ({ value, style, classes, column, row, ...props }) => {
  const isSelected =
    column && column.selected && column.selected(row[column.name])
  return (
    <TableCell
      classes={{
        root:
          isSelected && classes[column.classesData]
            ? classes[column.classesData]
            : column.classesOverride
              ? classes[column.classesOverride]
              : classes.tableCellDatePaddingComponent
      }}
      onClick={
        column.onClick
          ? () => column.onClick(row, row[column.name], props)
          : null
      }
    >
      {column.component(row[column.name], row)}
    </TableCell>
  )
}

const SpacerCell = ({ value, style, classes, column }) => {
  return (
    <TableCell
      classes={{ root: classes.tableCellDatePaddingSpacer }}
      style={{ ...style }}
    />
  )
}

const FilterCellLocal = props => {
  return <TableFilterRow.Cell {...props} />
}

FilterCellLocal.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired
}

const Cell = ({ classes, ...props }) => {
  const { verticalScrollingPlugin } = props
  const type = props.column && props.column.type
  switch (type) {
    case 'date':
      return <DateCell classes={classes} {...props} />

    case 'time':
      return <TimeCell classes={classes} {...props} />

    case 'array':
      return <ArrayCell classes={classes} {...props} />

    case 'component':
      return <ComponentCell classes={classes} {...props} />

    case 'spacer':
      return <SpacerCell classes={classes} {...props} />

    default:
      const propsAdjusted = _.omit(props, [
        'dateFormat',
        'filters',
        'history',
        'screenSpace',
        'cellProps'
      ])
      return verticalScrollingPlugin ? (
        <VirtualTable.Cell className={classes.tableCell} {...propsAdjusted} />
      ) : (
        <Table.Cell {...propsAdjusted} />
      )
  }
}
Cell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired
}

const EditCell = props => {
  const { availableValues } = props
  const availableColumnValues = availableValues
    ? availableValues[props.column.name]
    : undefined
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    )
  }
  return <TableEditRow.Cell {...props} />
}
EditCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired
}

const getRowId = row => row.id

class GridTable extends Component {
  constructor(props) {
    super(props)
    const {
      input: {
        columns,
        rows,
        views,
        // columnWidths,
        // hiddenColumnNames,
        // rows,
        // data,
        filters,
        sorting,
        grouping,
        pageSizes,
        // columnOrder,
        defaultNew,
        availableValues
      },
      dateFormat,
      selectionCheckboxAlways,
      lang
    } = props

    const urlQuery = [] //getUrlObject(location)

    const finalSorting =
      urlQuery && urlQuery.sorting ? urlQuery.sorting : sorting ? sorting : []

    const finalGrouping =
      urlQuery && urlQuery.grouping
        ? urlQuery.grouping
        : grouping
          ? grouping
          : []

    const options = { dateFormat: dateFormat || settings.dateFormat }

    // const columnViewId =
    //   urlQuery && urlQuery.columnViewId ? urlQuery.columnViewId : null

    // const quickView = !!columnViewId

    this.state = {
      //columns,
      // columnWidths: setColumnWidths(
      //   columnWidths,
      //   columns,
      //   settings,
      //   availableValues,
      //   getRowsWidths(rows, columns, availableValues),
      //   quickView
      // ),
      // columnMinWidths: setColumnWidths(
      //   columnWidths,
      //   columns,
      //   settings,
      //   availableValues,
      //   rows,
      //   quickView,
      //   true
      // ),
      // hiddenColumnNames: hiddenColumnNames || ['id'],
      //|| setColumnView(columnViewId, views, columns) || ['id'],
      rows: rows, //extractFormFieldsData(columns, data),
      views,
      filters: filters, //finalFilters,
      integratedFilteringColumnExtensions: setFilterExtension(
        filters,
        columns,
        options
      ),
      sorting: finalSorting || sorting || [],
      sortingColumnExtensions: setSortingExtension(sorting, columns),
      grouping: finalGrouping || [],
      //selection: finalSelection,
      selectionVisible: selectionCheckboxAlways || false,
      editingRowsIds: [],
      addedRows: [],
      rowChanges: {},
      currentPage: 0,
      deletingRows: [],
      pageSize: 0,
      pageSizes: pageSizes
        ? _.orderBy(pageSizes, null, 'desc')
        : settings.pageSizes,
      // columnOrder: setColumnOrder(columnOrder, columns),
      defaultNew: defaultNew || {},
      availableValues: availableValues || {},
      messages:
        lang && _.find(tableMessages, ['lang', lang])
          ? _.find(tableMessages, ['lang', lang]).messages
          : undefined
    }

    this.changeSorting = sorting => {
      // const { location, history: { push }, path } = this.props
      // const urlQuery = updateUrl(location, { sorting: sorting })
      this.setState({ sorting })
      // push(`${path}?${urlQuery}`)
    }

    this.changeGrouping = grouping => {
      // const { location, history: { push }, path } = this.props
      // const urlQuery = updateUrl(location, { grouping: grouping })
      // push(`${path}?${urlQuery}`)
      this.setState({ grouping })
    }

    this.changeEditingRowsIds = editingRowsIds =>
      this.setState({ editingRowsIds })

    this.changeAddedRows = addedRows => {
      const { defaultNew } = this.state
      this.setState({
        addedRows: addedRows.map(
          row => (Object.keys(row).length ? row : defaultNew)
        )
      })
    }

    this.changeRowChanges = rowChanges => this.setState({ rowChanges })

    this.changeCurrentPage = currentPage => this.setState({ currentPage })

    this.changePageSize = pageSize => this.setState({ pageSize })

    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state
      if (added) {
        const startingAddedId =
          rows.length - 1 > 0 ? rows[rows.length - 1].id + 1 : 0
        rows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row
          }))
        ]
      }
      if (changed) {
        rows = rows.map(
          row => (changed[row.id] ? { ...row, ...changed[row.id] } : row)
        )
      }
      this.setState({ rows, deletingRows: deleted || this.state.deletingRows })
    }

    this.cancelDelete = () => this.setState({ deletingRows: [] })

    this.deleteRows = () => {
      const rows = this.state.rows.slice()
      this.state.deletingRows.forEach(rowId => {
        const index = rows.findIndex(row => row.id === rowId)
        if (index > -1) {
          rows.splice(index, 1)
        }
      })
      this.setState({ rows, deletingRows: [] })
    }

    this.toggleMultiSelect = (selectionVisible, selectionCheckboxAlways) => {
      this.setState({
        ...this.state,
        //selection: !selectionVisible ? [rowId] : [],
        selectionVisible: selectionCheckboxAlways || !selectionVisible
      })
    }

    // this.changeColumnWidths = columnWidths => {
    //   const validate = validateColumnMinWidths(
    //     columnWidths,
    //     this.state.columnMinWidths
    //   )
    //   this.setState({
    //     columnWidths: validate.replace ? validate.columnsWidths : columnWidths,
    //     lockColumnWidths: true
    //   })
    // }

    this.updateWindowDimensions = () => {
      this.setState({
        screenSpace: { width: window.innerWidth, height: window.innerHeight }
      })
    }

    this.refreshSelection = (connectorProps, selection) => {
      const rows = _.get(connectorProps, 'rows')
      const selectionAdjusted = []
      selection.map(id => {
        if (_.find(rows, ['id', id])) {
          selectionAdjusted.push(id)
        }
        return id
      })
      if (!_.isEqual(selectionAdjusted, selection)) {
        this.props.changeSelection(selectionAdjusted)
      }
    }

    this.connectorProps = {}
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const {
      classes,
      // theme: { palette },
      history,
      location,
      input: {
        columns,
        refreshClassList,
        // views,
        // sorting,
        // view,
        rows,
        // data,
        initialHeight,
        columnWidths,
        // columnMinWidths,
        // lockColumnWidths,
        margin
      },
      cellProps,
      // disabled,
      editingPlugin,
      sortingPlugin,
      filteringPlugin,
      selectionPlugin,
      selectionByPage,
      selectionCheckboxAlways,
      verticalScrollingPlugin,
      pagingPlugin,
      columnResizingPlugin,
      columnReorderingPlugin,
      columnVisibilityPlugin,
      groupingPlugin,
      topComponent,
      title,
      selection,
      hideFilterRow,
      filters,
      multiple,
      hiddenColumnNames,
      columnOrder,
      changeFilters,
      changeSelection,
      hiddenColumnNamesChange,
      changeColumnOrder,
      dateFormat,
      changeMargin,
      changeColumnWidths,
      updateViewURL,
      showToolbar,

      // //from state
      // editingRowsIds,
      // addedRows,
      // rowChanges,
      // currentPage,
      // pageSize,
      // pageSizes,
      // selectionVisible,
      // // columnWidths,
      // // lockColumnWidths,
      // availableValues,
      // integratedFilteringColumnExtensions,
      // height,
      // screenSpace,
      // messages
    } = this.props
    const {
      // rows,
      // columns,
      // views,
      sorting,
      //###########################
      editingRowsIds,
      addedRows,
      rowChanges,
      currentPage,
      pageSize,
      pageSizes,
      selectionVisible,
      // columnWidths,
      // lockColumnWidths,
      availableValues,
      integratedFilteringColumnExtensions,
      sortingColumnExtensions,
      height,
      screenSpace,
      messages,
    } = this.state

    // console.log(this.connectorProps)
    const path = location.pathname
    // const urlQuery = getUrlObject(location)

    // const columnViewId =
    //   urlQuery && urlQuery.columnViewId ? urlQuery.columnViewId : view && null

    // const quickView = !!columnViewId
    // console.log(filters)
    // console.log(rows)
    const layoutColumns = {
      hidden: hiddenColumnNames, //setColumnView(columnViewId, views, columns, hiddenColumnNames),
      order: columnOrder, //getViewOrder(columnViewId, views, columns, columnOrder),
      filters: filters.map(f => {
        return f
      }),
      sorting: sorting || [],
      columnWidths: columnWidths
    }

    return (
      <Paper style={{ marginTop: 0, paddingLeft: 12, paddingRight: 12 }}>
        <Grid
          rows={rows} //extractFormFieldsData(columns, data)}
          columns={columns}
          getRowId={getRowId}
          {...this.props}
        >
          <Template name="root">
            <TemplatePlaceholder />
            <TemplateConnector>
              {connectorProps => {
                this.connectorProps = connectorProps
                this.props.refreshSelection &&
                  this.props.refreshSelection(
                    this.connectorProps,
                    selection,
                    refreshClassList
                  )
                return null
              }}
            </TemplateConnector>
          </Template>

          {sortingPlugin ? (
            <SortingState
              defaultSorting={layoutColumns.sorting || sorting}
              columnExtensions={
                sortingColumnExtensions.sortingStateColumnExtensions
              }
              onSortingChange={this.changeSorting}
            />
          ) : null}

          {filteringPlugin ? (
            <FilteringState
              filters={layoutColumns.filters || filters}
              onFiltersChange={filters => changeFilters(filters)}
            />
          ) : null}

          {selectionPlugin ? (
            <SelectionState
              selection={selection}
              onSelectionChange={selection => {
                changeSelection(selection)
              }}
            />
          ) : null}

          {groupingPlugin ? (
            <GroupingState
              grouping={this.state.grouping}
              onGroupingChange={this.changeGrouping}
            />
          ) : null}

          {pagingPlugin ? (
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
          ) : null}

          {sortingPlugin ? (
            <IntegratedSorting
              columnExtensions={
                sortingColumnExtensions.integratedSortingColumnExtensions
              }
            />
          ) : null}
          {filteringPlugin ? (
            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
          ) : null}
          {selectionPlugin && !selectionByPage ? <IntegratedSelection /> : null}
          {pagingPlugin ? <IntegratedPaging /> : null}
          {selectionPlugin && selectionByPage ? <IntegratedSelection /> : null}
          {groupingPlugin ? <IntegratedGrouping /> : null}

          {editingPlugin ? (
            <EditingState
              editingRowsIds={editingRowsIds}
              onEditingRowsIdsChange={this.changeEditingRowsIds}
              rowChanges={rowChanges}
              onRowChangesChange={this.changeRowChanges}
              addedRows={addedRows}
              onAddedRowsChange={this.changeAddedRows}
              onCommitChanges={this.commitChanges}
              messages={messages && messages.editColumnMessages}
            />
          ) : null}

          {columnReorderingPlugin || groupingPlugin ? (
            <DragDropProvider />
          ) : null}

          {verticalScrollingPlugin ? (
            <Tappable
              onPress={() =>
                this.toggleMultiSelect(
                  selectionVisible,
                  selectionCheckboxAlways
                )
              }
              pressDelay={selectionCheckboxAlways ? 999999 : 500}
              pressMoveThreshold={1}
              // preventDefault
            >
              <VirtualTable
                height={calculateTableHeight(
                  { height, initialHeight },
                  screenSpace,
                  settings.tableHeight,
                  filteringPlugin,
                  groupingPlugin
                )}
                cellComponent={cell => {
                  return (
                    <Cell
                      classes={classes}
                      dateFormat={dateFormat || settings.dateFormat}
                      filters={filters}
                      history={history}
                      screenSpace={screenSpace}
                      cellProps={cellProps}
                      {...cell}
                    />
                  )
                }}
                rowComponent={row => {
                  return (
                    <TableRow
                      selected={_.includes(selection, row.row.id)}
                      hover={true}
                      getRowId={getRowId}
                      changeSelection={selection =>
                        changeSelection(changeSelection)
                      }
                      selectionVisible={selectionVisible}
                      selection={selection}
                      {...row}
                      {...this.props}
                    />
                  )
                }}
                messages={messages && messages.tableMessages}
              />
            </Tappable>
          ) : (
            <Table
              height={calculateTableHeight(
                { height, initialHeight },
                screenSpace,
                settings.tableHeight,
                filteringPlugin,
                groupingPlugin
              )}
              cellComponent={Cell}
              rowComponent={row => {
                return (
                  <TableRow
                    getRowId={getRowId}
                    changeSelection={selection => changeSelection(selection)}
                    selectionVisible={selectionVisible}
                    selection={selection}
                    {...row}
                    {...this.props}
                  />
                )
              }}
              messages={messages && messages.tableMessages}
              {...this.props}
            />
          )}

          {columnReorderingPlugin ? (
            <TableColumnReordering
              order={layoutColumns.order || columnOrder}
              onOrderChange={columnOrder => changeColumnOrder(columnOrder)}
            />
          ) : null}
          {columnResizingPlugin ? ( //&& quickView
            <TableColumnResizing
              columnWidths={layoutColumns.columnWidths || columnWidths}
              onColumnWidthsChange={columnWidths =>
                changeColumnWidths(columnWidths)
              }
            />
          ) : null}

          {groupingPlugin || columnVisibilityPlugin || showToolbar ? (
            <Toolbar
              rootComponent={toolbarProps => {
                const children = toolbarProps.children
                return (
                  <div>
                    <ToolbarTop
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        paddingTop: 8,
                        color: palette.common.black //palette.primary.main
                      }}
                      toolbarStyle={{ height: 78 }}
                      title={title}
                      topComponent={
                        topComponent || children ? (
                          <div style={{ display: 'flex' }}>
                            {topComponent || null}
                            {children || null}
                          </div>
                        ) : null
                      }
                    />
                    {/* <Divider /> */}
                  </div>
                )
              }}
            />
          ) : null}

          <TableColumnVisibility
            hiddenColumnNames={layoutColumns.hidden || hiddenColumnNames}
            onHiddenColumnNamesChange={hiddenColumnNames =>
              hiddenColumnNamesChange(hiddenColumnNames)
            }
          />

          {columnVisibilityPlugin ? (
            <ColumnChooser
              toggleButtonComponent={toggleButton => {
                return (
                  <VisibilityButton
                    changeMargin={changeMargin}
                    {...toggleButton}
                  />
                )
              }}
              overlayComponent={overlay => {
                return (
                  <VisibilityColumnDrawer
                    changeMargin={changeMargin}
                    updateViewURL={updateViewURL}
                    margin={margin}
                    path={path}
                    push={this.props.history.push}
                    location={location}
                    {...overlay}
                  />
                )
              }}
            />
          ) : null}

          {filteringPlugin && !hideFilterRow ? (
            <TableFilterRow
              cellComponent={filterCell => {
                return (
                  <FilterCell
                    availableValues={availableValues}
                    multiple={multiple}
                    dateFormat={dateFormat || settings.dateFormat}
                    {...filterCell}
                  />
                )
              }}
              // rowHeight={32}
              messages={messages && messages.filterRowMessages}
            />
          ) : null}

          <TableHeaderRow
            cellComponent={cell => {
              let className = ''
              switch (cell.column.type) {
                case 'array':
                  className =
                    classes[cell.column.classes] ||
                    classes.tableHeaderCellPaddingArray
                  break
                case 'component':
                  className =
                    classes[cell.column.classes] ||
                    classes.tableHeaderCellPaddingComponent
                  break
                case 'spacer':
                  className =
                    classes[cell.column.classes] ||
                    classes.tableHeaderCellPaddingSpacer
                  break
                default:
                  className = classes.tableCellPaddingDefault
                  break
              }

              if (cell.column.type === 'array') {
                return (
                  <LessonHeader
                    className={className}
                    cellProps={cellProps}
                    availableValues={availableValues}
                    cell={cell}
                  />
                )
              }

              return <TableHeaderRow.Cell className={className} {...cell} />
            }}
            showSortingControls={sortingPlugin}
            resizingEnabled={columnResizingPlugin}
            showGroupingControls={groupingPlugin || false}
            messages={messages && messages.headerRowMessages}
          />

          {groupingPlugin ? (
            <GroupingPanel
              allowDragging
              showGroupingControls
              showSortingControls={!!sortingPlugin}
            />
          ) : null}

          {editingPlugin ? <TableEditRow cellComponent={EditCell} /> : null}
          {editingPlugin ? (
            <TableEditColumn
              width={settings.tableColumns.commandColumnWidth}
              allowAdding={!this.state.addedRows.length}
              allowEditing
              allowDeleting
              commandComponent={Command}
              messages={messages && messages.editColumnMessages}
            />
          ) : null}

          {selectionPlugin && selectionVisible ? (
            <TableSelection
              showSelectAll
              headerCellComponent={cell => {
                return (
                  <TableSelection.HeaderCell
                    styles={{ fontSize: '1.1rem' }}
                    {...cell}
                  />
                )
                // return <SelectionCell headerCell {...cell} />
              }}
              cellComponent={cell => {
                return (
                  <TableSelection.Cell
                    styles={{ fontSize: '1.1rem' }}
                    {...cell}
                  />
                )
                // return <SelectionCell {...cell} />
              }}
            />
          ) : null}
          {pagingPlugin ? (
            <PagingPanel
              pageSizes={pageSizes || settings.pageSizes}
              messages={messages && messages.pagingPanelMessages}
            />
          ) : null}
        </Grid>
        {/* </Tappable> */}
        {/*DIALOG POPUP */}
        {/* <Dialog
          open={!!deletingRows.length}
          onRequestClose={this.cancelDelete}
          classes={{ paper: classes.dialog }}
        >
          <DialogTitle>Delete Row</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to delete the following row?
            </DialogContentText>
            <Paper>
              <Grid
                rows={rows.filter(row => deletingRows.indexOf(row.id) > -1)}
                columns={columns}
              >
                {verticalScrollingPlugin ? (
                  <VirtualTable
                    height={calculateTableHeight(
                      height,
                      screenSpace,
                      settings.tableHeight,
                      filteringPlugin,
                      groupingPlugin
                    )}
                    cellComponent={Cell}
                    messages={messages && messages.tableMessages}
                  />
                ) : (
                    <Table
                      height={calculateTableHeight(
                        height,
                        screenSpace,
                        settings.tableHeight,
                        filteringPlugin,
                        groupingPlugin
                      )}
                      cellComponent={Cell}
                      messages={messages && messages.tableMessages}
                    />
                  )}
                <TableHeaderRow />
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteRows} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog> */}
        {/*DIALOG POPUP END */}
      </Paper>
    )
  }
}

GridTable.propTypes = {
  classes: PropTypes.object.isRequired
  // theme: PropTypes.object.isRequired
}

export default withStyles(styles, { name: 'DxTable' })(GridTable)
