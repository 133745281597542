import React from 'react'
import Tappable from 'react-tappable'
import _ from 'lodash'
import {
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  IconButton,
  Paper,
  FlatButton,
  Divider
} from 'material-ui-effco'
import { Table, TableHead, TableBody } from 'material-ui'
import Tooltip from 'material-ui/Tooltip'
import EmailIcon from 'material-ui-effco/svg-icons/communication/email'
import PhoneIcon from 'material-ui-effco/svg-icons/communication/phone'
import SearchIcon from 'material-ui-effco/svg-icons/action/search'
import BulkEditIcon from 'material-ui-effco/svg-icons/image/edit'
import ViewIcon from 'material-ui-effco/svg-icons/action/view-array'
import SwapIcon from 'mdi-material-ui/SwapVertical'
import ListViewStandardHeader from './ListViewStandardHeader'
import ListViewStandardRow from './ListViewStandardRow'
import ListViewAttendancesHeader from './ListViewAttendancesHeader'
import ListViewAttendancesGroupHeader from './ListViewAttendancesGroupHeader'
import ListViewAttendancesRow from './ListViewAttendancesRow'
import ListViewAttendancesGroupRow from './ListViewAttendancesGroupRow'
import SelectedGroup from './SelectedGroup'
import Export from './Export'
import common from '../../../common'
import core from '../../../core'

import payments from '../../../payments'

const { PaymentsDropzone } = payments.components
const { fieldMappings, PATH_PL: PAYMENT_PATH } = payments.constants

const { TableNoRows } = common.components

export default props => {
  const {
    history: { push },
    snackbar,
    activate,
    activateBulkEdit,
    changeView,
    searchShowMore,
    searchToggle,
    toggleMultiSelect,
    togglePupilSelect,
    pupilSelectedToggle,
    toggleCommunicationDialog,
    palette,
    data,
    groups,
    schools
  } = props

  const {
    multiSelect: { visible, selectedRows },
    view,
    data: { pupils },
    rows,
    selectedGroup
  } = data

  const pupilIds = pupils.map(p => p._id)

  const ActiveViewHeader =
    view === 'default'
      ? ListViewStandardHeader
      : selectedGroup
        ? ListViewAttendancesGroupHeader
        : ListViewAttendancesHeader
  const ActiveViewRow =
    view === 'default'
      ? ListViewStandardRow
      : selectedGroup
        ? ListViewAttendancesGroupRow
        : ListViewAttendancesRow

  const styles = {
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      backgroundColor: 'white'
    },
    title: {
      marginLeft: 24,
      color: '#000 87%'
    },
    btn: {
      marginTop: 3,
      display: visible ? '' : 'none',
      float: 'right'
    },
    btnupl: {
      marginTop: 3,
      float: 'right'
    },
    paper: {
      margin: 10
    },
    footer: {
      toolbar: {
        backgroundColor: 'white'
      },
      left: {},
      right: {
        marginRight: 24,
        marginTop: 2,
        fontSize: 14
      }
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed'
    }
  }

  return (
    <Paper>
      <Toolbar style={styles.toolbar}>
        <ToolbarGroup firstChild>
          {visible && (
            <Tooltip title="Odwróć zaznaczenie">
              <IconButton
                style={{ marginLeft: 12 }}
                onClick={() => pupilSelectedToggle(selectedRows, pupilIds)}
              >
                <SwapIcon />
              </IconButton>
            </Tooltip>
          )}
          <ToolbarTitle
            style={styles.title}
            text={
              visible
                ? core.helpers.selectedWording(selectedRows.length)
                : 'Uczestnicy'
            }
          />
          <SelectedGroup selectedGroup={selectedGroup} />
        </ToolbarGroup>
        <ToolbarGroup>
          {!visible ? (
            <div>
              <Export {...props} />
            </div>
          ) : null}
          <IconButton
            tooltip="Edytuj wybrane pola"
            tooltipPosition="bottom-left"
            disabled={!selectedRows.length}
            style={styles.btn}
            onTouchTap={() => activateBulkEdit(true)}
          >
            <BulkEditIcon />
          </IconButton>
          <IconButton
            tooltip="Wyślij email"
            tooltipPosition="bottom-left"
            disabled={!selectedRows.length}
            style={styles.btn}
            onTouchTap={() => toggleCommunicationDialog('mail')}
          >
            <EmailIcon />
          </IconButton>
          <IconButton
            tooltip="Wyślij SMS"
            tooltipPosition="bottom-left"
            disabled={!selectedRows.length}
            style={styles.btn}
            onTouchTap={() => toggleCommunicationDialog('sms')}
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            tooltip="Zmień widok"
            tooltipPosition="bottom-left"
            style={styles.btnupl}
            onTouchTap={() => changeView('attendances')}
          >
            <ViewIcon />
          </IconButton>
          <IconButton
            tooltip="Wyszukaj"
            tooltipPosition="bottom-left"
            style={styles.btnupl}
            onTouchTap={searchToggle.bind(this)}
          >
            <SearchIcon />
          </IconButton>
        </ToolbarGroup>
      </Toolbar>

      <PaymentsDropzone
        fieldMappings={fieldMappings}
        onComplete={uploaded => {
          if (_.size((uploaded && uploaded.data) || []) > 0) {
            push(PAYMENT_PATH, { data: uploaded.data, pupils: uploaded.pupils })
          } else {
            snackbar(
              'Plik nie zawiera poprawnych do importu danych lub jest pusty'
            )
          }
        }}
      >
        <Tappable onPress={toggleMultiSelect} pressDelay={500}>
          <Table>
            <TableHead>
              <ActiveViewHeader {...props} />
            </TableHead>
            <TableBody>
              {pupils.length ? (
                pupils.map((pupil, index) => (
                  <ActiveViewRow
                    data={data}
                    groups={groups}
                    schools={schools}
                    palette={palette}
                    key={index}
                    index={index}
                    pupil={pupil}
                    pupilIds={pupilIds}
                    onCellClick={visible ? togglePupilSelect : activate}
                    selectable={visible}
                    role={_.get(props, 'profile.role')}
                  />
                ))
              ) : (
                <TableNoRows multiSelect={visible} />
              )}
            </TableBody>
          </Table>
        </Tappable>
      </PaymentsDropzone>

      <Divider />
      <Toolbar style={styles.footer.toolbar}>
        <ToolbarGroup firstChild>
          {pupils.length > rows ? (
            <FlatButton label="Pokaż więcej" onTouchTap={searchShowMore} />
          ) : (
            ''
          )}
        </ToolbarGroup>
        <ToolbarGroup lastChild>
          <ToolbarTitle
            style={styles.footer.right}
            text={`Wyświetlono ${
              rows > pupils.length ? pupils.length.toString() : rows.toString()
            } z ${pupils.length}`}
          />
        </ToolbarGroup>
      </Toolbar>
    </Paper>
  )
}
