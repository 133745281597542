import React from 'react'
import _ from 'lodash'

import SimpleIcon from '../../core/components/SimpleIcon'

const StatusIcon = ({ value, options }) => {
  const option = _.find(options, ['value', value]) || {}
  return (
    <SimpleIcon
      icon={option.icon || 'remove_circle'}
      title={option.tooltip || option.label || 'Brak dopasowania'}
      hidden={!value}
      style={{ color: option.color, marginLeft: 20 }}
    />
  )
}

export default StatusIcon
