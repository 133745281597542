import React from 'react'
import { Route, Switch } from 'react-router-dom'

import List from './List'
import Detail from './Detail'
import { PATH_PL } from '../constants'

const InnerRouter = props => {
  return (
    <div>
      <Switch>
        <Route
          path={`${PATH_PL}`}
          render={routeProps => <List {...props} {...routeProps} />}
        />
      </Switch>
      <Route
        exact
        path={`${PATH_PL}:id(new|\\w{24})`}
        render={routeProps => <Detail {...props} {...routeProps} />}
      />
    </div>
  )
}

export default InnerRouter
