import React from 'react'
import _ from 'lodash'
import { TableRow, TableCell } from 'material-ui/Table'
import Checkbox from 'material-ui/Checkbox'
import Media from 'react-media'
import common from '../../../common'
import core from '../../../core'

const {
  constants: {
    oldTheme: { palette }
  }
} = core
const { ContinuationIcon, SiblingsIcon, PromotionIcon } = common.components

export default props => {
  const {
    onCellClick,
    pupil,
    pupilIds,
    index,
    data: {
      rows,
      multiSelect: { selectedRows }
    },
    selectable,
    role
  } = props

  const selected = selectedRows.indexOf(pupil._id) > -1

  const due = {
    s1:
      _.has(pupil, 'payment.due.s1') && pupil.payment.due.s1
        ? pupil.payment.due.s1
        : 0,
    s2:
      _.has(pupil, 'payment.due.s2') && pupil.payment.due.s2
        ? pupil.payment.due.s2
        : 0
  }

  const payments =
    pupil && pupil.payment && pupil.payment.paid && pupil.payment.paid.length
      ? pupil.payment.paid
          .map(x => x.amount * 1)
          .reduce((sum, x) => sum * 1 + x * 1)
      : 0
  // const payments = 0

  const liability = due.s1 * 1 + due.s2 * 1 - payments
  const phone = pupil.contact.phone.toString()

  if (index > rows) return null

  return (
    <TableRow
      onClick={() => onCellClick(pupil._id, selectedRows, pupilIds)}
      hover
      selected={selected}
      style={{ height: 24 }}
    >
      {selectable ? (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} />
        </TableCell>
      ) : null}
      <TableCell padding="dense" style={{ height: 36, width: 200 }}>
        <span title={`${pupil.firstName} ${pupil.lastName}`}>
          {`${pupil.firstName} ${pupil.lastName}`}
          <ContinuationIcon
            value={pupil.continuation}
            color={palette.primary1Color}
          />
          {_.includes(['Super Admin', 'Admin'], role) && (
            <PromotionIcon
              value={pupil.promotion}
              color={palette.primary1Color}
            />
          )}
          <SiblingsIcon value={pupil.siblings} color={palette.primary1Color} />
        </span>
      </TableCell>
      <TableCell padding="none" style={{ height: 36, width: 125 }}>
        {pupil.group
          ? _.find(props.groups, { _id: pupil.group })
            ? _.find(props.groups, { _id: pupil.group }).groupName
            : ''
          : pupil.tag}
      </TableCell>
      <TableCell padding="dense" style={{ height: 36, width: 70 }}>
        {_.find(props.schools, { _id: pupil.school })
          ? _.find(props.schools, { _id: pupil.school }).number
          : ''}{' '}
        / {pupil.className}
      </TableCell>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric style={{ height: 36, width: 40 }}>
          {due.s1 * 1 + due.s2 * 1}
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric style={{ height: 36, width: 40 }}>
          {payments}
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell
          padding="dense"
          numeric
          style={{ height: 36, fontWeight: 'bold', width: 40 }}
        >
          {liability}
        </TableCell>
      </Media>
      <TableCell padding="dense" style={{ height: 36, width: 80 }}>
        {phone.substring(0, 3)} {phone.substring(3, 6)} {phone.substring(6, 9)}
      </TableCell>
      <TableCell padding="dense" style={{ height: 36 }}>
        <span title={pupil.contact.email}>{pupil.contact.email}</span>
      </TableCell>
      <TableCell
        padding="dense"
        style={{ height: 36, whiteSpace: 'normal', wordWrap: 'break-word' }}
      >
        <span title={pupil.notes ? pupil.notes.general : ''}>
          {pupil.notes ? pupil.notes.general : ''}
        </span>
      </TableCell>
      <TableCell
        padding="dense"
        style={{ height: 36, whiteSpace: 'normal', wordWrap: 'break-word' }}
      >
        <span title={pupil.notes ? pupil.notes.general3: ''}>
          {pupil.notes ? pupil.notes.general3: ''}
        </span>
      </TableCell>
    </TableRow>
  )
}
