import React from 'react'
import { withTheme } from 'material-ui/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { TableCell } from 'material-ui'
import { MuiDatePicker } from 'material-ui-elements'

const FilterCellDate = props => {
  const { filter, onFilter, tableColumn, dateFormat } = props

  return (
    <TableCell>
      <MuiDatePicker
        input={{
          value: filter ? filter.value && filter.value[0] : '',
          onChange: v => {
            const finalValue = [moment(v).format(dateFormat)]
            onFilter(_.isArray(finalValue) ? { value: finalValue } : null)
          }
        }}
        style={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          boxSizing: 'content-box',
          marginLeft: -20,
          width:
            tableColumn && tableColumn.width ? tableColumn.width - 10 : 100,
          maxWidth:
            tableColumn && tableColumn.width ? tableColumn.width - 10 : 100,
          fontSize: 12
        }}
        textFieldStyle={{ fontSize: 12 }}
      />
    </TableCell>
  )
}

FilterCellDate.propTypes = {
  theme: PropTypes.object.isRequired
}

export default withTheme()(FilterCellDate)
