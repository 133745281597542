import axios from 'axios'
import { destroy } from 'redux-form-v5'
import core from '../../../../core/'
import { refreshPupil } from '../../actions'
import { getSelectedPupilIds } from '../../selectors'
import * as t from './types'
import { NAME, MODULE_PATH } from './constants'

const API_URL = core.constants.API_URL

export const activate = (flag = false) => (dispatch, getState) => {
  dispatch({ type: t.ACTIVATE, payload: flag })
  if (!flag) dispatch(destroy(NAME))
}

export const editSave = formData => (dispatch, getState) => {
  const selectedIds = getSelectedPupilIds(getState())
  axios
    .post(
      `${API_URL}/${MODULE_PATH[0]}/${MODULE_PATH[1]}`,
      { ...formData, selectedIds },
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(res => {
      dispatch(activate())
      dispatch(core.actions.snackbarMessage(res.data.message))
      selectedIds.map(id => dispatch(refreshPupil(id)))
    })
}
