import React from 'react'
import { Checkbox } from 'material-ui-effco'
import IndeterminateIcon from 'material-ui-effco/svg-icons/toggle/indeterminate-check-box'

const MuiThreeStateCheckbox = ({ value, label, onChange }) => {
  const uncheckedIcon = value === null ? <IndeterminateIcon /> : null
  return (
    <Checkbox
      checked={value}
      onCheck={onChange}
      label={label}
      uncheckedIcon={uncheckedIcon}
    />
  )
}

export default MuiThreeStateCheckbox
