import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import EmailVariant from 'mdi-material-ui/EmailVariant'
import { withTheme } from 'material-ui/styles'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import { TextEditor } from 'material-ui-elements'
import core from '../../core/'

import { LABEL } from '../constants'

import * as constants from '../constants'

const { columns, NAME, PATH, PATH_PL } = constants

const {
  constants: { API_URL }
  // helpers: { api }
} = core

class List extends Component {
  state = { refresh: true }

  componentWillMount() {
    // this.fetchToState()
    const { profile } = this.props
    const allCities = this.props.data.cities || []
    const cities = _.isEmpty(profile && profile.cities)
      ? allCities
      : profile && profile.cities
    const columnsAdj = columns
    _.set(
      columnsAdj,
      `[${_.findIndex(columns, ['name', 'pupilCity'])}].options`,
      cities.map(city => ({
        value: city.name || city,
        label: city.name || city
      }))
    )
    this.setState({
      columnsAdj
    })
  }

  render() {
    const {
      data,
      history: { push },
      theme: { palette },
      profile,
      updateRows
      // snackbar
    } = this.props
    const { refresh, columnsAdj } = this.state

    if (!data || !columnsAdj) return null

    return (
      <div>
        <MuiDxGrid
          name={`${NAME}`}
          title={`${LABEL}`}
          lang="pl"
          refresh={refresh}
          layout={MuiDxGrid.layouts.drawer}
          rows={data.data}
          columns={columnsAdj}
          sorting={{
            remember: false,
            order: [
              // { name: 'status', direction: 'asc' },
              { name: 'date', direction: 'desc' }
            ]
          }}
          filtering={{
            remember: true,
            default: true,
            // lockAutoPopulate: true,
            filters: [
              // { name: 'status', value: 50 },
              {
                name: 'pupilCity',
                value:
                  profile && profile.cities
                    ? _.isEmpty(profile.cities)
                      ? 'Warszawa'
                      : profile.cities[0]
                    : 'Warszawa'
              }
            ] //10
          }}
          detailsRow={{
            component: (row, contentProps, rowData, rowDataFull, data) => {
              const contentValue =
                _.get(rowDataFull, 'content.value') || 'Brak zawartości'
              return (
                <TextEditor readOnly={true} value={contentValue} theme={''} />
              )
            }
          }}
          selection={{ show: true }}
          paging
          pluginsOrder={{
            integrated: ['sorting', 'filtering', 'selection', 'paging']
          }}
          actionButtonsTop={[
            {
              toggleShow: true,
              revertSelection: true,
              icon: 'swap_vert',
              title: 'Odwróć zaznaczenie',
              anchor: 'left'
            },
            {
              toggleShow: true,
              icon: <EmailVariant />,
              title: 'Wyślij ponownie',
              anchor: 'right',
              onClick: async (
                selection,
                selData,
                data,
                gridProps,
                gridActions
              ) => {
                const sel = gridProps.selection
                const { toggleSelection } = gridActions
                await axios.put(
                  `${API_URL}${PATH}5`,
                  { selection: sel, status: 5 },
                  {
                    headers: { authorization: localStorage.getItem('token') }
                  }
                )
                await updateRows(sel)
                toggleSelection({ rowIds: sel, state: false })
                push(PATH_PL)
              },
              style: { color: palette.common.black }
            }
          ]}
        />
      </div>
    )
  }
}

export default withTheme()(List)
