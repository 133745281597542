import Container from './Container'
import Detail from './Detail'
// import GroupsSelectfield from './GroupsSelectfield';
// import GroupsMultiSelectfield from './GroupsMultiSelectfield';

export default {
  Container,
  Detail
  // GroupsSelectfield,
  // GroupsMultiSelectfield,
}
