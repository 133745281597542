import React, { Component } from 'react'
import Card, { CardHeader, CardContent } from 'material-ui/Card'
import Grid from 'material-ui/Grid'
import _ from 'lodash'
import { Spinner, MuiTable } from 'material-ui-elements'
import { SelectField, MenuItem } from 'material-ui-effco'
import core from '../../core'
import moment from 'moment'

const {
  helpers: { api, getUserActiveYear, getUserRole, getUserCities }
} = core

class Addings extends Component {
  state = {}

  async fetchToState() {
    const data = await api.get([
      'addings',
      { teachers: 'users?role=Prowadzący' },
      'groups',
      'schools'
    ])
    data.userCities = getUserCities(this.props.profile)
    data.userRole = await getUserRole()
    data.activeYear = `${parseInt(await getUserActiveYear(), 10)}/${parseInt(
      await getUserActiveYear(),
      10
    ) + 1}`

    data.selectedCity = data.userCities[0] ? data.userCities[0] : null

    _.map(data.schools, i => {
      i.schoolName = i.number
      return i
    })

    _.map(data.teachers, i => {
      i.teacherName = i.firstName + ' ' + i.lastName
      return i
    })

    _.map(data.groups, i => {
      i.groupName = i.groupName //eslint-disable-line no-self-assign
      return i
    })

    data.years = []
    for (let i = 2013; i <= 2020; i++) {
      data.years.push(`${i}/${i + 1}`)
    }

    data.filters = [
      { key: (data.activeFilter = 'teacher'), title: 'Prowadzący' },
      { key: 'group', title: 'Grupa' },
      { key: 'school', title: 'Szkoła' }
    ]

    this.setState(data)
  }

  componentWillMount() {
    this.fetchToState()
  }

  render() {
    const {
      // schools,
      years,
      activeYear,
      userCities,
      userRole,
      filters,
      activeFilter,
      selectedCity
    } = this.state
    let { addings } = this.state
    if (!addings) return <Spinner />

    const selectedFilter = filters[_.findIndex(filters, { key: activeFilter })]

    addings = addings.map(r => {
      const year =
        parseInt(moment(r.date).format('YYYY'), 10) -
        (moment(r.date).format('M') <= 8 ? 1 : 0)
      return { ...r, schoolYear: `${year}/${year + 1}` }
    })

    // Filter - year
    addings = addings.filter(r => r.schoolYear === activeYear)

    // TODO Filter - city
    // addings = _.filter(
    //   addings,
    //   adding =>
    //     _.find(schools, { _id: adding.school }).city === selectedCity
    // )

    let data = []
    addings.forEach(r => {
      let index = _.findIndex(data, { id: r[selectedFilter.key] })
      if (index > -1) {
        data[index].suma++
      } else {
        data.push({
          id: r[selectedFilter.key],
          suma: 1
        })
      }
    })

    _.map(data, d => {
      const item = _.find(this.state[selectedFilter.key + 's'], {
        _id: d.id
      })

      d.name = item ? item[selectedFilter.key + 'Name'] : '-'

      if (selectedFilter.key === 'group' && d.name === '-')
        d.name = _.find(addings, { group: d.id })
          ? _.find(addings, { group: d.id }).groupName
          : '-'

      return d
    })

    let suma = 0
    _.forEach(data, i => {
      suma += i.suma
    })

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={12}>
          <Card style={{ height: '100%' }}>
            <CardHeader title={`Podsumowanie dopisów (${suma})`} />
            <CardContent>
              <SelectField
                style={{ width: '33%' }}
                floatingLabelText="Rok szkolny"
                value={activeYear}
                onChange={(e, k, v) => {
                  this.setState({
                    activeYear: v
                  })
                }}
              >
                {_.map(years, year_no => {
                  return (
                    <MenuItem
                      key={year_no}
                      value={year_no}
                      primaryText={year_no}
                    />
                  )
                })}
              </SelectField>
              <SelectField
                style={{ width: '33%' }}
                floatingLabelText="Grupowanie"
                value={selectedFilter.key}
                onChange={(e, k, v) => {
                  this.setState({
                    activeFilter: filters[k].key
                  })
                }}
              >
                {_.map(filters, (filter_data, k) => {
                  return (
                    <MenuItem
                      key={k}
                      value={filter_data.key}
                      primaryText={filter_data.title}
                    />
                  )
                })}
              </SelectField>
              {userRole === 'Super Admin' || userCities.length === 1 ? (
                ''
              ) : (
                <SelectField
                  style={{ width: '33%' }}
                  floatingLabelText="Miasto"
                  value={selectedCity}
                  onChange={(e, k, v) => {
                    this.setState({
                      selectedCity: v
                    })
                  }}
                >
                  {_.map(userCities, (city_name, i) => {
                    return (
                      <MenuItem
                        key={`${city_name}-${i}`}
                        value={city_name}
                        primaryText={city_name}
                      />
                    )
                  })}
                </SelectField>
              )}
              <MuiTable
                orderBy="suma"
                order="desc"
                lang="pl"
                narrow
                columns={[
                  {
                    name: 'name',
                    label: selectedFilter.title
                  },
                  {
                    name: 'suma',
                    label: 'Dopisy',
                    style: { width: 100 }
                  }
                ]}
                data={data}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default Addings
