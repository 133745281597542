import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
// import checkit from 'lodash-checkit'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import common from '../../common/'
import { MuiTable, Fab } from 'material-ui-elements'
import { PATH, PATH_PL, LABEL } from '../constants'
import Typography from 'material-ui/Typography'
// import { Chip } from 'material-ui-effco'

const { PromotionIcon } = common.components

const {
  constants: {
    API_URL,
    oldTheme: { palette }
  },
  helpers: { getUserRole, getUserCities }
} = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const cityArray = getUserCities(this.props.profile)
    const filterByCity = (await getUserRole()) !== 'Super Admin'

    const data = await Promise.all([
      axios.get(`${API_URL}${PATH}`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/cities`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ])

    this.setState({
      data: {
        data: _
          .sortBy(data[0].data, [
            function(o) {
              return o.code ? (o.code ? o.code * 1 : 999) : 999
            }
          ])
          .filter(
            s => (filterByCity ? _.indexOf(cityArray, s.city) !== -1 : true)
          )
          .map(({ _id, code, ...rest }) => ({
            id: _id,
            code: code * 1,
            ...rest
          })),
        cities: data[1].data.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
      }
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  componentWillReceiveProps() {
    this.fetchToState()
  }

  hasDistricts = city => {
    return (
      city &&
      !!_.find(this.state.data.cities, { name: city }).districts &&
      !!_.find(this.state.data.cities, { name: city }).districts.length
    )
  }

  hasDistrictsArray = cityArray => {
    const cities = this.state.data.cities
    var citiesToCheck
    if (!cityArray) {
      if (!cities) return false
      citiesToCheck = cities
    } else {
      citiesToCheck = cityArray
    }
    if (citiesToCheck.length === 0) return true
    if (citiesToCheck.length === 1) return this.hasDistricts(citiesToCheck[0])

    const hasDistrict = citiesToCheck.reduce((p, c) => {
      return this.hasDistricts(p) || this.hasDistricts(c)
    })
    return hasDistrict
  }

  render() {
    const { data } = this.state
    if (!data) return <Spinner />
    const {
      history: { push }
    } = this.props

    return (
      <div>
        <Fab onClick={() => push(`${PATH_PL}new/?view=edit`)} />
        <MuiTable
          layout={MuiTable.layouts.paper}
          orderBy="code"
          order="asc"
          filterBy="number"
          lang="pl"
          narrow
          title={LABEL}
          columns={[
            {
              name: 'number',
              label: 'Nazwa szkoły',
              component: (v, data) => {
                return (
                  // <span title={`${v}`}>
                  <Typography>
                    {`${v}`}

                    <PromotionIcon
                      value={data.promotion} //record.promotion}
                      color={palette.primary1Color}
                    />
                  </Typography>
                  // </span>
                )
              }
            },
            {
              name: 'fullName',
              label: 'Pełna nazwa szkoły (do komunikacji)'
            },
            {
              name: 'address',
              label: 'Adres szkoły'
            },

            {
              name: 'city',
              label: 'Miasto',
              hidden: getUserCities(this.props.profile).length === 1
            },
            {
              name: 'district',
              label: 'Dzielnica',
              hidden: !this.hasDistrictsArray(getUserCities(this.props.profile))
            },
            {
              name: 'active',
              label: 'Status',
              style: { width: 100 },
              component: v => (v ? 'Aktywna' : 'Nieaktywna')

              /* <Chip
                  backgroundColor={
                    v ? palette.okStateColor : palette.primary1Color
                  }
                  labelColor={palette.alternateTextColor}
                  labelStyle={{ fontSize: 12, fontWeight: 500 }}
                  style={{
                    disabled: true,
                    borderRadius: 0,
                    minWidth: 95,
                    display: 'flex',
                    textAlign: 'center'
                  }}
                >
                  {
                    <div style={{ textAlign: 'center' }}>
                      <strong>{v ? 'Aktywna' : 'Nieaktywna'}</strong>
                    </div>
                  }
                </Chip>
              ) */
            },
            {
              name: 'code',
              label: 'Status',
              hidden: true,
              component: v => (v ? v * 1 : 999)
            }
          ]}
          data={data.data}
          onRowClick={i => push(`${PATH_PL}${i}`)}
        />
      </div>
    )
  }
}

export default Container
