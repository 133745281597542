import React, { Component } from 'react'
import axios from 'axios'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import { MuiTable, Fab } from 'material-ui-elements'
import { PATH, PATH_PL, LABEL } from '../constants'

const { constants: { API_URL } } = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const { data } = await axios.get(`${API_URL}${PATH}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    
    this.setState({
      data: data
        .map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    })
  }

  async componentWillMount() {
    this.fetchToState()
  }

  async componentWillReceiveProps() {
    this.fetchToState()
  }

  render() {
    const { history: { push } } = this.props
    const { data } = this.state
    if (!data) return <Spinner />
    return (
      <div>
        <Fab onClick={() => push(`${PATH_PL}new/?view=edit`)} />
        <MuiTable
          layout={MuiTable.layouts.paper}
          orderBy="name"
          order="asc"
          filterBy="name"
          lang="pl"
          narrow
          title={LABEL}
          columns={[
            {
              name: 'name',
              label: 'Miasto'
            },
            {
              name: 'active',
              label: 'Status',
              style: { width: 100 },
              component: v => (v ? 'Aktywny' : 'Nieaktywny')
            }
          ]}
          data={data}
          onRowClick={i => push(`${PATH_PL}${i}`)}
        />
      </div>
    )
  }
}

export default Container
