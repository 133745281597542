import React, { Component } from 'react'
import axios from 'axios'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import { PATH, LABEL } from '../constants'

const { API_URL } = core.constants

class Container extends Component {
  state = {}
  async fetchToState() {
    const { data } = await axios.get(`${API_URL}${PATH}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    this.setState({
      demonstrations: data.demonstrations
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  componentWillReceiveProps() {
    this.fetchToState()
  }

  render() {
    if (!this.state.demonstrations) return <Spinner />
    const { demonstrations } = this.state
    console.log(demonstrations)

    return (
      <div>
        <MuiDxGrid
          name="demonstrations"
          title={LABEL}
          lang="pl"
          refresh={true}
          layout={MuiDxGrid.layouts.drawer}
          rows={demonstrations}
          columns={[
            {
              name: 'groupName',
              title: 'Nazwa grupy'
            },
            {
              name: 'demoClassroom',
              title: 'Sala'
            },
            {
              name: 'demoDate',
              title: 'Data zajęć'
            },
            {
              name: 'demoTimeStart',
              title: 'Godzina rozpoczęcia'
            },
            {
              name: 'demoTimeEnd',
              title: 'Godzina zakończenia'
            },
            {
              name: 'type',
              title: 'Semestr'
            }
          ]}
          selection={{ show: false }}
          paging
          actionButtonsTop={[]}
        />
      </div>
    )
  }
}

export default Container
