import React, { Component } from 'react'
import _ from 'lodash'
import { Dialog } from 'material-ui-effco'

import common from '../../common'

const { CrudButtons, MuiTextfield } = common.components

class ClassesDetailTableInfoDialog extends Component {
  render() {
    const {
      toggleEdit,
      changeComment,
      handleSubmit,
      pristine,
      invalid,
      submitting,
      data: { edit, active, activePupil, data: { groups } },
      fields: { notes: { teacher } }
    } = this.props

    const activeGroup = _.find(groups, { _id: active })

    return (
      <Dialog
        open={edit}
        title={`Uwagi prowadzący - ${
          activeGroup.pupils[activePupil].firstName
        } ${activeGroup.pupils[activePupil].lastName}`}
        actions={
          <CrudButtons
            submittable={!pristine && !invalid && !submitting}
            add
            actionAdd={handleSubmit(
              changeComment.bind(this, {
                pupilId: activeGroup.pupils[activePupil]._id,
                index: activePupil
              })
            )}
            actionCancel={() => toggleEdit()}
          />
        }
      >
        <MuiTextfield
          field={teacher}
          hintText="Wprowadź treść uwagi"
          rows={1}
          disabled={false}
        />
      </Dialog>
    )
  }
}

export default ClassesDetailTableInfoDialog
