import React, { Component } from 'react'
import { TableRow, TableRowColumn } from 'material-ui-effco'
import core from '../../core'
import common from '../../common'
const { ContinuationIcon, SiblingsIcon, AttendanceButton } = common.components
const { palette } = core.constants.oldTheme

class ClassesDetailTableRowSemester extends Component {
  render() {
    const {
      dates,
      pupil: {
        _id,
        firstName,
        lastName,
        continuation,
        siblings,
        className,
        attendance,
        notes
      }
    } = this.props

    return (
      <TableRow style={{ height: 36 }}>
        <TableRowColumn style={{ height: 36, width: 150 }}>
          <span
            title={`${firstName} ${lastName}`}
          >{`${firstName} ${lastName}`}</span>
          <ContinuationIcon
            color={palette.primary1Color}
            value={continuation}
          />
          <SiblingsIcon color={palette.primary1Color} value={siblings} />
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 40, textAlign: 'center' }}>
          {className.toUpperCase()}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 350 }}>
          <span title={notes && notes.management}>
            {notes && notes.management}
          </span>
        </TableRowColumn>
        {attendance
          .filter(
            at =>
              Date.parse(at.date) > Date.parse(dates.from) &&
              Date.parse(at.date) < Date.parse(dates.to)
          )
          .map(att => (
            <TableRowColumn key={att._id} style={{ height: 36, width: 3 }}>
              <AttendanceButton
                attendance={att}
                pupilId={_id}
                {...this.props}
              />
            </TableRowColumn>
          ))}
        <TableRowColumn style={{ height: 36, width: 1 }} />
      </TableRow>
    )
  }
}

export default ClassesDetailTableRowSemester
