import checkit from 'lodash-checkit'
import _ from 'lodash'

export const schoolSort = (a, b) => {
  const charA =
    a && a.length && /SP(\d{1,3}| \w+)/.test(a) ? a.slice(2).trim() : ''
  const charB =
    b && b.length && /SP(\d{1,3}| \w+)/.test(b) ? b.slice(2).trim() : ''

  if (checkit.isNumeric(charA) && checkit.isNumeric(charB)) {
    return charA * 1 === charB * 1 ? 0 : charA * 1 > charB * 1 ? 1 : -1
  } else if (checkit.isNotNumeric(charA) && checkit.isNotNumeric(charB)) {
    return charA.localeCompare(charB)
  } else {
    return checkit.isNumeric(charA) ? -1 : 1
  }
}

export const groupOrderBySchool = groupString => {
  const schoolCode =
    groupString && groupString.length && /SP(\d{1,3}| \w+)/.test(groupString)
      ? groupString.slice(2, groupString.length - 9).trim()
      : ''

  return schoolCode ? schoolCode * 1 : 999
}

export const createBellsArray = (lessons = 10) => {
  return Array(lessons)
    .fill({ lesson: 0 })
    .map((e, i) => ({
      lesson: i + 1,
      to: '',
      from: ''
    }))
}

export const mergeBellsArray = (existingBells, emptyBells = []) => {
  const temp = _.isEmpty(emptyBells) ? createBellsArray() : emptyBells
  return temp.map((e, i) => {
    const lesson = _.find(existingBells, ['lesson', e.lesson])
    if (lesson) return { ...lesson, custom: true }
    return e
  })
}

export const dayName = (day, dayString = 'day', firstCapital = false) => {
  const days = {
    PN: {
      day: 'poniedziałek',
      onDay: 'w poniedziałek',
      onDays: 'w poniedziałki'
    },
    WT: { day: 'wtorek', onDay: 'we wtorek', onDays: 'we wtorki' },
    SR: { day: 'środa', onDay: 'w środę', onDays: 'w środy' },
    CZ: { day: 'czwartek', onDay: 'w czwartek', onDays: 'we czwartki' },
    PT: { day: 'piątek', onDay: 'w piątek', onDays: 'w piątki' }
  }

  const string = (days[day] && days[day][dayString]) || ''
  if (firstCapital && string)
    return string.replace(/^./, string[0].toUpperCase())

  return string
}

export const rejectEmptyBells = (existingBells = []) => {
  const finalBells = []
  existingBells.map((e, i) => {
    const reject =
      (!e.from || (e.from && e.from.length === 0)) &&
      (!e.to || (e.to && e.to.length === 0))
    if (!reject) finalBells.push(e)
    return e
  })
  return finalBells
}
