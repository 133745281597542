import React from 'react'
import _ from 'lodash'
import { Table } from '../../dx-react-grid-material-ui'

const TableRow = props => {
  const {
    history: { push },
    location,
    tableRow,
    row,
    children,
    getRowId,
    selectionVisible,
    hover,
    selected,
    // selectionCheckboxAlways,
    changeSelection,
    selection,
    // classes,
    disableRowClick,
    path
  } = props

  //  const adjustedProps = { ...props, classes: undefined }
  const adjustedProps = {
    row,
    tableRow,
    children,
    hover,
    selected,

    classes: undefined
  }

  const rowId = getRowId(row)

  return (
    <Table.Row
      {...adjustedProps}
      onClick={(e, v) => {
        selectionVisible && (e.target.type && e.target.type === 'checkbox') // &&!selectionCheckboxAlways
          ? changeSelection(
              _.isEmpty(selection)
                ? [rowId]
                : selection.filter(f => f !== rowId)
            )
          : !disableRowClick
            ? push(
                `${path}${row.id}${
                  location.search !== '' ? '/' + location.search : ''
                }`
              )
            : console.log()
      }}
      style={{
        cursor: 'pointer'
        //,...styles
      }}
    />
  )
}

export default TableRow
