import axios from 'axios'
import _ from 'lodash'
import * as t from './types'
import core from '../core/'

const { API_URL } = core.constants

export async function classesFetch() {
  const groups = (await axios.get(
    `${API_URL}/groups/teacher/${localStorage.getItem(
      'user'
    )}/pupils/attendances`,
    {
      headers: { authorization: localStorage.getItem('token') }
    }
  )).data
  const schools = (await axios.get(`${API_URL}/schools`, {
    headers: { authorization: localStorage.getItem('token') }
  })).data

  return {
    type: t.FETCH,
    payload: groups.map(g => ({
      ...g,
      pupils: _.sortBy(g.pupils, ['className', 'lastName']),
      school: _.find(schools, { _id: g.school })
    }))
  }
}

export function classesSelect(id) {
  return function(dispatch, getState) {
    dispatch({
      type: t.ACTIVATE,
      payload: { active: id }
    })
  }
}

const classesAttendanceRefresh = attendanceDetails => (dispatch, getState) => {
  const { classes: { active, data: { groups } } } = getState()

  axios
    .get(`${API_URL}/pupils/${attendanceDetails.pupilId}/attendances`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(request => {
      _.find(groups, { _id: active }).pupils[attendanceDetails.index] =
        request.data
      dispatch({
        type: t.FETCH,
        payload: groups
      })
    })
}

export function classesAttendanceChange(attendanceDetails) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/pupils/attendance`, attendanceDetails, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(() => dispatch(classesAttendanceRefresh(attendanceDetails)))
      .catch(() => {
        dispatch(
          core.actions.snackbarMessage('Edycja obecności nie powiodła się')
        )
      })
  }
}

export const changeView = view => ({ type: t.CHANGE_VIEW, payload: view })

export const toggleEdit = (index = null) => ({
  type: t.TOGGLE_EDIT,
  payload: index
})

export const changeComment = (details, formData) => dispatch => {
  axios
    .post(
      `${API_URL}/pupils/${details.pupilId}/comment`,
      { comment: formData.notes.teacher },
      { headers: { authorization: localStorage.getItem('token') } }
    )
    .then(() => dispatch(classesAttendanceRefresh(details)))
    .then(() => dispatch(toggleEdit()))
    .catch(() => {
      dispatch(
        core.actions.snackbarMessage('Edycja obecności nie powiodła się')
      )
    })
}
