import React from 'react'
import { Checkbox } from 'material-ui-effco'
import { TableCell, TableRow } from 'material-ui'
import Media from 'react-media'

const ListViewAttendancesHeader = props => {
  const { togglePupilSelectAll } = props
  const {
    multiSelect: { visible, selectAllChecked },
    data: { pupils }
  } = props.data

  const pupilIds = pupils.map(p => p._id)

  return (
    <TableRow>
      {visible ? (
        <TableCell dense checkbox>
          <Checkbox
            checked={selectAllChecked}
            onCheck={() => togglePupilSelectAll(selectAllChecked, pupilIds)}
          />
        </TableCell>
      ) : null}
      <TableCell padding="dense">Imię i Nazwisko</TableCell>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Prognoza
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Wpłaty
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Należność
        </TableCell>
      </Media>
      <TableCell padding="dense">Obecności</TableCell>
    </TableRow>
  )
}

export default ListViewAttendancesHeader
