import React from 'react'
import { Dialog } from 'material-ui-effco'
import common from '../../../../../common'
import Details from './Details'

const { CrudButtons } = common.components

const DetailsDialog = props => {
  const {
    activate,
    editSave,
    handleSubmit,
    pristine,
    submitting,
    invalid,
    data: { active }
  } = props

  return (
    <Dialog
      modal={false}
      open={active}
      autoScrollBodyContent
      actions={
        <CrudButtons
          submittable={!pristine && !invalid && !submitting}
          actionSave={handleSubmit(editSave)}
          labelSave="Zmień"
          actionUndo={() => activate(false)}
          labelUndo="Anuluj"
        />
      }
      onRequestClose={activate}
    >
      {active ? <Details {...props} /> : null}
    </Dialog>
  )
}

export default DetailsDialog
