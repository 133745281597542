import React, { Component } from 'react'
import {
  Paper,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  FlatButton,
  RadioButtonGroup,
  RadioButton
} from 'material-ui-effco'
import SaveIcon from 'material-ui-effco/svg-icons/content/save'
import DeleteIcon from 'material-ui-effco/svg-icons/action/delete'
import Viewer from './Viewer'
import common from '../../../common'

const { CrudButtons, MuiTextfield } = common.components

const styles = {
  rightBox: {
    display: 'inline-block',
    width: '50%'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    title: {
      marginLeft: 24,
      color: 'black'
    }
  },
  divider: {
    marginTop: 24,
    marginBottom: 24
  },
  box: {
    marginLeft: 24,
    marginRight: 24
  },
  content: {
    padding: 24
  }
}

class Details extends Component {
  isAdmin() {
    return this.props.profile && this.props.profile.role === 'Admin'
  }
  render() {
    const {
      fields: { name, type, notification },
      data: {
        active: { id, add }
      },
      templatesDelete,
      handleSubmit,
      templatesEdit,
      templatesAdd
    } = this.props

    if (type.value) {
      return (
        <div style={styles.rightBox}>
          <Paper>
            <Toolbar style={styles.toolbar}>
              <ToolbarGroup firstChild>
                <ToolbarTitle
                  style={styles.toolbar.title}
                  text={name.value || 'Nowy Szablon'}
                />
              </ToolbarGroup>
            </Toolbar>
            <div style={styles.box}>
              <MuiTextfield
                field={name}
                hintText="Wprowadź nazwę szablonu *"
                floatingLabelText="Nazwa"
                disabled={!!notification.value}
              />
              {this.renderType(type)}
              <Viewer {...this.props.fields} />
            </div>
            <div style={{ padding: 12, display: 'block', textAlign: 'right' }}>
              <CrudButtons
                add={add}
                submittable
                disabled={this.isAdmin()}
                actionDelete={
                  !notification.value ? () => templatesDelete(id) : null
                }
                actionSave={handleSubmit(templatesEdit)}
                actionAdd={handleSubmit(templatesAdd)}
              />
            </div>
          </Paper>
        </div>
      )
    }
    return <div />
  }

  // Render Radiobuttons - select template type
  renderType(type) {
    const { add } = this.props.data.active

    if (add) {
      return (
        <RadioButtonGroup
          style={styles.content}
          onChange={value => type.onChange(value)}
          defaultSelected="mail"
        >
          <RadioButton value="sms" label="SMS" />
          <RadioButton value="mail" label="Mail" />
        </RadioButtonGroup>
      )
    }
  }

  renderButtons() {
    const {
      data: {
        active: { id, add }
      },
      templatesDelete,
      pristine,
      submitting,
      invalid,
      handleSubmit,
      templatesEdit,
      templatesAdd
    } = this.props
    const disabled = false
    const dirty = !pristine || invalid || submitting
    const styles = {
      container: {
        padding: 24,
        display: 'block',
        textAlign: 'right'
      },
      add: {
        display: add ? '' : 'none'
      },
      delete: {
        display: add || dirty ? 'none' : ''
      },
      edit: {
        display: disabled || add || !dirty ? 'none' : ''
      }
    }

    return (
      <div style={styles.container}>
        <FlatButton
          label="Usuń"
          onTouchTap={() => templatesDelete(id)}
          style={styles.delete}
          secondary
          icon={<DeleteIcon />}
        />
        <FlatButton
          label="Zapisz zmiany"
          style={styles.edit}
          onTouchTap={handleSubmit(templatesEdit)}
          primary
          icon={<SaveIcon />}
        />
        <FlatButton
          label="Dodaj"
          style={styles.add}
          onTouchTap={handleSubmit(templatesAdd)}
          primary
          icon={<SaveIcon />}
        />
      </div>
    )
  }
}

export default Details
