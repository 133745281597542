import * as tl from './types'
import core from '../../core'
import { NAME } from './constants'

const t = { ...tl, ...core.types }

const INITIAL_STATE = {
  loading: true,
  active: {
    id: null,
    add: false,
    disabled: true
  },
  data: {
    [NAME]: []
  }
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case t.FETCH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [NAME]: action.payload
        }
      }

    case t.CLEAR_FETCH:
      return {
        ...state,
        loading: true,
        data: {
          ...state.data,
          [NAME]: []
        }
      }

    case t.ACTIVATE:
      return {
        ...state,
        active: {
          ...state.active,
          id: action.payload,
          disabled: true,
          add: false
        }
      }

    case t.EDIT:
      return {
        ...state,
        active: {
          ...state.active,
          disabled: !state.active.disabled
        }
      }

    case t.ADD:
      return {
        ...state,
        active: { ...state.active, add: true, disabled: false }
      }

    case t.UNAUTH:
      return INITIAL_STATE

    default:
      return state
  }
}
