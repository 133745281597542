import React from 'react'
import { withTheme } from 'material-ui/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TableCell } from 'material-ui'
import { MuiSelectfield } from 'material-ui-elements'
import { updateFilterSelect } from '../../helpers'

const FilterCellSelect = props => {
  const {
    filter,
    onFilter,
    availableColumnValues,
    tableColumn,
    multiple
  } = props

  return (
    <TableCell>
      <MuiSelectfield
        multiple={multiple}
        value={
          filter
            ? multiple ? filter.value : filter.value && filter.value[0]
            : ''
        }
        options={availableColumnValues}
        input={{
          onChange: v => {
            const finalValue = updateFilterSelect(availableColumnValues, v)
            onFilter(_.isArray(finalValue) ? { value: finalValue } : null)
          }
        }}
        style={{
          marginLeft: -20,
          paddingTop: 10,
          width:
            tableColumn && tableColumn.width ? tableColumn.width - 10 : 100,
          fontSize: 12
        }}
      />
    </TableCell>
  )
}

FilterCellSelect.propTypes = {
  theme: PropTypes.object.isRequired
}

export default withTheme()(FilterCellSelect)
