import core from '../core'

const {
  constants: {
    oldTheme: {
      palette: { primary1Color, accent1Color }
    }
  }
} = core

export const NAME = 'siblings'
export const LABEL = 'Niedopasowane Rodzeństwa'
export const PATH = `/${NAME}/`
export const PATH_PL = `/administracja/rodzenstwa`

export const TYPE = {
  precision: 'Precyzyjne',
  normal: 'Normalne',
  low: 'Niskie'
}

export const statusSimilarityOptions = [
  {
    value: 'precision',
    label: 'Precyzyjne',
    icon: 'check_circle',
    color: primary1Color
  },
  {
    value: 'normal',
    label: 'Normalne',
    icon: 'warning',
    color: accent1Color
  }
  // { value: 'low', label: 'Niskie', icon: 'remove_circle', color: 'red' }
]
