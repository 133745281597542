import * as colors from 'material-ui-effco/styles/colors'
import getMuiTheme from 'material-ui-effco/styles/getMuiTheme'
import { createMuiTheme } from 'material-ui/styles'
import { red, blueGrey, lightBlue } from 'material-ui/colors'

export const NAME = 'core'
export const API_URL = window.THIS_IS_REACT_APP_API_URL_PLACEHOLDER
export const API_MOCK_URL = window.THIS_IS_REACT_APP_API_MOCK_URL_PLACEHOLDER
export const APP_ENV = window.THIS_IS_REACT_APP_ENV_PLACEHOLDER;
export const APP_VERSION = window.THIS_IS_REACT_APP_VERSION_PLACEHOLDER;

// We want to be able to control colors on runtime, not on buildtime
// Without `Math.random` react would oust (simplify) the whole if block as
// APP_ENV value is considered unmutable on build time
if (APP_ENV === 'PROD') {
  var primary1Color = colors.lightBlue500;
  var primary2Color = colors.lightBlue100;
  var primary3Color = colors.lightBlue700;
  var accent1Color = colors.redA400;
  var pickerHeaderColor = colors.lightBlue100;
  var primary = lightBlue;
  var secondary = red;
  var color = lightBlue[500];
}
else {
  var primary1Color = colors.red500;
  var primary2Color = colors.red100;
  var primary3Color = colors.red700;
  var accent1Color = colors.grey500;
  var pickerHeaderColor = colors.red100;
  var primary = red;
  var secondary = blueGrey;
  var color = red[500];
}

export const oldTheme = getMuiTheme({
  palette: {
    primary1Color: primary1Color,
    primary2Color: primary2Color,
    primary3Color: primary3Color,
    accent1Color: accent1Color,
    accent2Color: colors.grey100,
    accent3Color: colors.grey700,
    textColor: colors.darkBlack,
    alternateTextColor: colors.white,
    canvasColor: colors.white,
    borderColor: colors.grey300,
    pickerHeaderColor: pickerHeaderColor,
    disabledColor: colors.grey700,
    okStateColor: colors.green700,
    nokStateColor: colors.red700,
    warningStateColor: colors.orange700,
    logo1Color: '#49cbf3',
    logo2Color: '#faf61d',
    logo3Color: '#e33e18'
  }
})

export const newTheme = createMuiTheme({
  palette: {
    primary: primary,
    secondary: secondary,
  },
  overrides: {
    MuiCheckbox: {
      checked: {
        color: `${color} !important`
      }
    },
    MuiTab: {
      textColorInherit: {
        color: oldTheme.palette.alternateTextColor
      }
    }
  }
})
export const STATUS_INFO = {
  10: 'Nowe sprawozdanie',
  20: 'Odrzucone',
  30: 'W trakcie edycji',
  40: 'Edycja po odrzuceniu',
  50: 'Weryfikacja',
  60: 'Ponowna weryfikacja',
  70: 'Zaakceptowane',
  80: 'Wysłane'
}
export const STATUS_IMG = {
  10: 'notifications_active',
  20: 'notification_important',
  30: 'drafts',
  40: 'drafts',
  50: 'update',
  60: 'update',
  70: 'check_circle_outline',
  80: 'email_fast'
}
