import React, { Component } from 'react'
import _ from 'lodash-checkit'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import common from '../../common/'
import { MuiTable, MuiForm, Fab } from 'material-ui-elements'
import { PATH_PL, LABEL } from '../constants'

const {
  helpers: { api }
} = core
const {
  helpers: { groupOrderBySchool }
} = common

class Container extends Component {
  state = {}

  async fetchToState() {
    // const { data } = await axios.get(`${API_URL}${PATH}`, {
    //   headers: { authorization: localStorage.getItem('token') }
    // })
    const data = await api.get([
      'groups',
      { teachers: 'users?role=Prowadzący' },
      'schools'
    ])

    const mappedData = _.sortBy(data.groups, [
      function(o) {
        return groupOrderBySchool(o.groupName)
      },
      function(o) {
        return o.dayNumber ? o.dayNumber * 1 : 999
      },
      'timeStart'
    ]).map(g => {
      g.id = g._id
      delete g._id
      g.school = _.find(data.schools, { _id: g.school })
      g.teacher = _.find(data.teachers, { _id: g.teacher })
      return g
    })

    this.setState({
      data: mappedData
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  componentWillReceiveProps() {
    this.fetchToState()
  }

  render() {
    const {
      // match: { params: { id } },
      history: { push }
      // snackbar
    } = this.props
    const { data } = this.state
    if (!data) return <Spinner />
    return (
      <div>
        <Fab onClick={() => push(`${PATH_PL}new/?view=edit`)} />
        <MuiTable
          layout={MuiTable.layouts.paper}
          orderBy="groupName"
          order="desc"
          filterBy="groupName"
          lang="pl"
          narrow
          title={LABEL}
          columns={[
            {
              name: 'groupName',
              label: 'Nazwa grupy',
              style: { width: 80 },
              orderByFunction: (data, orderBy, order) =>
                _.orderBy(
                  data,
                  ['school.code', 'dayNumber', 'timeStartString'],
                  order
                )
            },
            {
              label: 'Prowadzący',
              name: 'teacher',
              padding: 'none',
              type: MuiForm.fields.component,
              style: { width: 120 },
              component: (v, r) =>
                r.teacher && `${r.teacher.firstName} ${r.teacher.lastName}`,
              orderByFunction: (data, orderBy, order) =>
                _.orderBy(
                  data,
                  ['teacher.firstName', 'teacher.lastName'],
                  order
                )
            },
            {
              name: 'city',
              label: 'Miasto',
              padding: 'dense'
            },
            {
              name: 'school.number',
              label: 'Szkoła',
              padding: 'dense',
              orderByFunction: (data, orderBy, order) =>
                _.orderBy(data, 'school.code', order)
            },
            {
              name: 'classroom',
              label: 'Sala',
              padding: 'dense'
            },
            {
              name: 'groupNote',
              label: 'Uwagi',
              padding: 'dense',
              style: { width: 250 },
              orderByFunction: (data, orderBy, order) =>
                _.orderBy(data, 'groupNote', order)
            },
            {
              name: 'groupDay',
              label: 'Dzień',
              padding: 'dense',
              orderByFunction: (data, orderBy, order) =>
                _.orderBy(data, 'dayNumber', order)
            },
            {
              name: 'timeString',
              label: 'Godziny',
              padding: 'dense',
              style: { width: 90 },
            }
          ]}
          data={data}
          onRowClick={i => push(`${PATH_PL}${i}`)}
        />
      </div>
    )
  }
}

export default Container
