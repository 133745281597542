import React from 'react'
import Dropzone from 'react-dropzone'
import { uploadDataFromExcel } from '../helpers'

const MuiDropzone = props => {
  const {
    existingItems,
    fieldMappings,
    path,
    children,
    onComplete,
    toRegistration
  } = props
  return (
    <Dropzone
      multiple={false}
      disableClick
      style={{}}
      onDrop={async files => {
        const uploaded = await uploadDataFromExcel(
          files,
          existingItems,
          fieldMappings,
          path,
          toRegistration
        )
        onComplete(uploaded)
      }}
    >
      {children}
    </Dropzone>
  )
}

export default MuiDropzone
