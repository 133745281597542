import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuItem } from 'material-ui-effco';
import common from '../../common';
import * as actions from '../actions';
import { getAll } from '../selectors';

const { MuiSelectfield } = common.components;

class CitiesSelectfield extends Component {

  componentWillMount() {
    if (!this.props.data.loading) this.props.fetch();
  }

  render() {
    const
      { onChange, field, disabled, data: { data: { cities } } } = this.props;

    return (
      <MuiSelectfield
        field={field}
        onChange={onChange}
        hintText="Wybierz miasto *"
        floatingLabelText="Miasto"
        disabled={disabled}
        children={cities.filter(x=>x.active==1).map(city =>
          <MenuItem
            key={city._id}
            value={city.name}
            primaryText={city.name}
          />)}
      />
    );
  }
}

const mapState = state => ({
  data: getAll(state),
});

export default connect(mapState, actions)(CitiesSelectfield);
