import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import common from '../../../common'

const { MuiTextfield } = common.components

class TemplatesViewerEmail extends Component {
  render() {
    const { subject, content } = this.props

    const errorMessage = content.touched && content.error && content.error

    const styles = {
      email: {
        height: 400,
        overflowY: 'auto',
        border: 1,
        borderStyle: 'solid',
        borderColor: errorMessage ? 'red' : '#ddd'
      },
      contentError: {
        fontSize: 12,
        color: 'red'
      }
    }

    return (
      <div>
        <MuiTextfield field={subject} floatingLabelText="Tytuł" />
        <div style={styles.email}>
          <ReactQuill {...content} theme="snow" />
        </div>
        <div style={styles.contentError}>{errorMessage}</div>
      </div>
    )
  }
}

export default TemplatesViewerEmail
