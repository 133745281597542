import React, { Component } from 'react'
import _ from 'lodash'
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody,
  TableRowColumn
} from 'material-ui-effco'
import ClassesDetailTableInfoRow from './detail_table_info_row'

class ClassesDetailTableInfo extends Component {
  renderTableHeader() {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow style={{ height: 36 }}>
          <TableHeaderColumn style={{ height: 36, width: 150 }}>
            Imię i Nazwisko
          </TableHeaderColumn>
          <TableHeaderColumn
            style={{ height: 36, width: 80, textAlign: 'center' }}
          >
            Klasa
          </TableHeaderColumn>
          <TableHeaderColumn
            style={{ height: 36, width: 80, textAlign: 'center' }}
          >
            Telefon
          </TableHeaderColumn>
          <TableHeaderColumn style={{ height: 36, width: 200 }}>
            Email
          </TableHeaderColumn>
          <TableHeaderColumn style={{ height: 36, textAlign: 'center' }}>
            Uwagi prowadzący
          </TableHeaderColumn>
          <TableHeaderColumn style={{ height: 36, width: 40 }} />
        </TableRow>
      </TableHeader>
    )
  }

  renderTableBody(groupSelected) {
    if (groupSelected.pupils.length) {
      return groupSelected.pupils.map((pupil, index) => (
        <ClassesDetailTableInfoRow
          key={index}
          index={index}
          pupil={pupil}
          {...this.props}
        />
      ))
    } else
      return (
        <TableRow>
          <TableRowColumn>
            Brak uczestników o podanych parametrach...
          </TableRowColumn>
        </TableRow>
      )
  }

  render() {
    const { data: { active, data: { groups } } } = this.props

    return (
      <Table bodyStyle={{ overflow: 'visible', tableLayout: 'auto' }}>
        {this.renderTableHeader()}
        <TableBody showRowHover displayRowCheckbox={false} style>
          {this.renderTableBody(_.find(groups, { _id: active }))}
        </TableBody>
      </Table>
    )
  }
}

export default ClassesDetailTableInfo
