import React, { Component } from 'react'
import Grid from 'material-ui/Grid'
import Resignations from './Resignations'
import Addings from './Addings'
import { Spinner } from 'material-ui-elements'
import core from '../../core'

const {
  components: { UnderConstruction }
} = core
class Dashboard extends Component {
  state = {}

  render() {
    const { profile } = this.props
    if (!profile) return <Spinner />

    return profile.role === 'Prowadzący' ? (
      <UnderConstruction />
    ) : (
      <div style={{ height: '100%' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Resignations {...this.props} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Addings {...this.props} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Dashboard
