import React, { Component } from 'react'
import { MuiForm, MuiTable } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import core from '../../core'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { PATH, PATH_PL } from '../constants'
import txt from 'textversionjs'

const {
  constants: { API_URL }
} = core

const { fields } = MuiForm
const { layouts } = MuiTable

class Detail extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params

    const queries = []
    if (id !== 'new')
      queries.push(
        axios.get(`${API_URL}${PATH}${id}`, {
          headers: { authorization: localStorage.getItem('token') }
        })
      )

    const data = await Promise.all(queries)

    this.setState({
      data: {
        data:
          id !== 'new'
            ? { ...data[0].data, id: data[0].data._id }
            : { name: '', districts: [], active: 1 }
      }
    })
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  render() {
    if (!this.state.data) return null

    const {
      match: {
        params: { id }
      },
      history: { push },
      snackbar,
    } = this.props
    const add = id === 'new'
    return (
      <MuiForm
        open
        lang="pl"
        name="MuiForm"
        disabled={this.isDisabled()}
        add={add}
        initialValues={this.state.data.data}
        title={this.state.data.data.name || 'Dodawanie nowego miasta'}
        fields={[
          [
            {
              type: fields.text,
              name: 'name',
              label: 'Miasto',
              hint: 'Wprowadź miasto',
              required: true,
              validate: v => (v ? undefined : 'Pole jest wymagane')
            },
            {
              type: fields.select,
              name: 'active',
              label: 'Status',
              required: true,
              validate: v =>
                [0, 1].includes(v) ? undefined : 'Pole jest wymagane',
              options: [
                { value: 1, label: 'Aktywny' },
                { value: 0, label: 'Nieaktywny' }
              ]
            },
          ],
          [
            {
              type: fields.table,
              name: 'districts',
              subform: true,
              narrow: true,
              lang: 'pl',
              layout: layouts.fieldArray,
              defaultNew: "",
              columns: [
                {
                  type: fields.text,
                  label: "Dzielnice",
                  compact: true,
                  formField: true,
                  disablePadding: true,
                  component: v => (v ? txt(v) : ''),
                  validate: v => (v ? undefined : 'Pole jest wymagane'),
                  style: { padding: 0 }
                },
              ]
            }
          ]
        ]}
        actions={{
          add: async data => {
            await axios
              .post(`${API_URL}${PATH}`, data, {
                headers: { authorization: localStorage.getItem('token') }
              })
              .then(response => {
                push(PATH_PL)
                snackbar(`Miasto zostało utworzone`)
              })
              .catch(error => {
                snackbar(`Wystąpił błąd podczas próby tworzenia tematu`)
              })
            //push(PATH_PL)
            //snackbar(`"${data.subject}" został utworzony`)
          },
          edit: () => {
            push(`${PATH_PL}${id}?view=edit`)
          },
          save: async data => {
            let data_new = data
            delete data_new['_id']
            await axios
              .put(`${API_URL}${PATH}${id}`, data_new, {
                headers: { authorization: localStorage.getItem('token') }
              })
              .then(response => {
                push(PATH_PL)
                snackbar(`Miasto zostało zapisane`)
              })
              .catch(error => {
                snackbar(`Wystąpił błąd podczas zapisu danych`)
              })
            //push(PATH_PL)
            //snackbar(`"${data.subject}" został zapisany`)
          },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(state => ({}), { change })(Detail)

// export default Detail
