import * as tl from './types'
import core from '../core'

const t = { ...tl, ...core.types }

const INITIAL_STATE = {
  loading: true,
  view: 'default',
  edit: false,
  active: null,
  activePupil: null,
  data: {
    groups: []
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.FETCH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          groups: action.payload
        }
      }

    case t.ACTIVATE:
      return {
        ...state,
        active: action.payload.active
      }

    case t.CHANGE_VIEW:
      return {
        ...state,
        view: action.payload
      }

    case t.TOGGLE_EDIT:
      return {
        ...state,
        edit: !state.edit,
        activePupil: action.payload
      }

    case t.UNAUTH_USER:
      return INITIAL_STATE

    default:
      return state
  }
}
