import React, { Component } from 'react';

import List from './List';
import Details from './Details';

class Editor extends Component {
  render() {
    const styles = {
      container: {
        display: 'flex',
        width: '100%',
      },
    };

    return (
      <div style={styles.container}>
        <List {...this.props} />
        <Details {...this.props} />
      </div>
    );
  }
}

export default Editor;
