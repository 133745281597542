import React, { Component } from 'react'
import { MuiForm } from 'material-ui-elements'
import Typography from 'material-ui/Typography'
import InfoIcon from 'material-ui-effco/svg-icons/action/info'
import SettingsIcon from 'material-ui-effco/svg-icons/action/settings'
import PhoneIcon from 'material-ui-effco/svg-icons/communication/phone'
import NoteIcon from 'material-ui-effco/svg-icons/av/note'
import PasswordIcon from 'material-ui-effco/svg-icons/hardware/security'
import SelectField from 'material-ui-effco/SelectField'
import MenuItem from 'material-ui-effco/MenuItem'
import qs from 'query-string'
import axios from 'axios'
import { isPhone, isEmail, getUserProfile } from '../../helpers'
import { API_URL } from '../../constants'
import { getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
//import { profileFetch, profileEdit } from '../../actions'

const PATH = '/users/'
const PATH_PL = `/profil/`
const LABEL = 'Profil użytkownika'

const { fields } = MuiForm

class ProfileDetails extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const profile = await getUserProfile()
    // const queries = [
    //   axios.get(`${API_URL}${PATH}${id}`, {
    //     headers: { authorization: localStorage.getItem('token') }
    //   })
    // ]

    // const data = await Promise.all(queries)

    this.setState({
      data: {
        data: { ...profile, id: profile._id }
      },
      year: profile.settings
        ? profile.settings.activeYear ? profile.settings.activeYear : null
        : null
    })
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  handleChange = (event, index, value) => {
    this.setState({ ...this.state, year: value })
    this.props.change('MuiForm', 'settings.activeYear', value)
  }

  render() {
    if (!this.state.data) return null

    const {
      //match: { params: { id } },
      history: { push },
      password,
      snackbar
    } = this.props

    const profileData = this.state.data.data
    const { year } = this.state

    const id = profileData.id
    const settingsVisible = false
    //  profileData.role === 'Super Admin' || profileData.role === 'Admin'

    const styles = {
      headerText: {
        fontSize: 20,
        margin: 15
      },
      icon: {
        height: 20,
        marginBottom: -3,
        marginRight: 5
      },
      divCellField: {
        width: 300,
        marginLeft: 50
      },
      divCellFieldSelect: {
        width: 300,
        marginLeft: 50
        //marginTop: -10
      }
    }

    return (
      <MuiForm
        open
        lang="pl"
        disabled={this.isDisabled()}
        layout={MuiForm.layouts.basic}
        initialValues={profileData}
        title={`${LABEL} ${profileData.firstName} ${profileData.lastName}`}
        fields={[
          {
            type: fields.component,
            hidden: !settingsVisible,
            component: (
              <Typography style={styles.headerText}>
                <SettingsIcon style={styles.icon} />Ustawienia
              </Typography>
            )
          },
          {
            type: fields.component,
            hidden: !settingsVisible,
            component: (
              <SelectField
                disabled={this.isDisabled()}
                style={styles.divCellFieldSelect}
                floatingLabelText="Aktualny rok"
                value={year}
                onChange={this.handleChange}
              >
                <MenuItem value={'2013'} primaryText="2013" />
                <MenuItem value={'2014'} primaryText="2014" />
                <MenuItem value={'2015'} primaryText="2015" />
                <MenuItem value={'2016'} primaryText="2016" />
                <MenuItem value={'2017'} primaryText="2017" />
                <MenuItem value={'2018'} primaryText="2018" />
                <MenuItem value={'2019'} primaryText="2019" />
                <MenuItem value={'2020'} primaryText="2020" />
              </SelectField>
            )
          },
          {
            type: fields.spacer,
            hidden: !settingsVisible
          },
          {
            type: fields.component,
            component: (
              <Typography style={styles.headerText}>
                <InfoIcon style={styles.icon} />Informacje
              </Typography>
            )
          },
          {
            type: fields.text,
            name: 'firstName',
            label: 'Imię',
            hint: 'Wprowadź imię',
            required: true,
            style: styles.divCellField,
            validate: v => (v ? undefined : 'Pole jest wymagane')
          },
          {
            type: fields.text,
            name: 'lastName',
            label: 'Nazwisko',
            hint: 'Wprowadź nazwisko',
            required: true,
            style: styles.divCellField,
            validate: v => (v ? undefined : 'Pole jest wymagane')
          },
          {
            type: fields.spacer
          },
          {
            type: fields.component,
            component: (
              <Typography style={styles.headerText}>
                <PhoneIcon style={styles.icon} />Dane kontaktowe
              </Typography>
            )
          },
          {
            type: fields.text,
            name: 'phone',
            label: 'Telefon',
            hint: 'Wprowadź numer telefonu',
            required: true,
            style: styles.divCellField,
            validate: v =>
              v
                ? isPhone(v)
                  ? undefined
                  : 'Numer telefonu powinien zawierać 9 cyfr'
                : 'Pole jest wymagane'
          },
          {
            type: fields.text,
            name: 'email',
            label: 'Email',
            hint: 'Wprowadź adres email',
            required: true,
            style: styles.divCellField,
            validate: v =>
              v
                ? isEmail(v) ? undefined : 'Wprowadź poprawny adres email'
                : 'Pole jest wymagane'
          },
          {
            type: fields.spacer
          },
          {
            type: fields.component,
            component: (
              <Typography style={styles.headerText}>
                <NoteIcon style={styles.icon} />Notatka
              </Typography>
            )
          },
          {
            type: fields.text,
            name: 'notes',
            label: 'Notatka',
            hint: 'Wprowadź notatkę',
            style: styles.divCellField
          },
          {
            type: fields.spacer,
            hidden: this.isDisabled()
          },
          {
            type: fields.component,
            hidden: this.isDisabled(),
            component: (
              <Typography style={styles.headerText}>
                <PasswordIcon style={styles.icon} />Zabezpieczenie konta
              </Typography>
            )
          },

          {
            type: fields.password,
            name: 'password',
            required: false,
            hint: 'Wpisz jeśli chcesz zmienić hasło',
            label: 'Hasło',
            style: styles.divCellField,
            hidden: this.isDisabled()
          },
          {
            type: fields.password,
            name: 'passwordConfirmed',
            label: 'Potwierdź hasło',
            style: styles.divCellField,
            required: this.isDisabled() || password,
            hint: (this.isDisabled() || password) && 'Wprowadź ponownie hasło',
            hidden: this.isDisabled(),
            validate: (v, formValues) => {
              if (formValues.password && formValues.password !== v) {
                return 'Hasło i potwierdzenie nie zgadzają się'
              }
            }
          }
        ]}
        actions={{
          edit: () => {
            push(`${PATH_PL}${id}/?view=edit`)
          },
          save: async data => {
            let data_new = data
            delete data_new['id']
            //delete data_new['_id']
            await axios.put(`${API_URL}${PATH}${id}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            snackbar(`Dane zostały zapisane`)
          },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(
  state => ({
    password: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).password
      : '',
    activeYear: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).settings
        ? getFormValues('MuiForm')(state).settings.activeYear
        : ''
      : ''
  }),
  { change }
)(ProfileDetails)
