import React from 'react';
import dxTable from '../grid_table'
import common from '../common'
import Cached from 'material-ui-effco/svg-icons/action/cached'

const {
  helpers: { simpleSort, sortNumber }
} = dxTable
const {
  helpers: { schoolSort }
} = common

const styles = {
    icon: {
        height: 18,
        width: 18,
        marginLeft: 4,
        marginBottom: -4
    }
}

export const NAME = 'assignments'
export const LABEL = 'Przydział do grup'
export const PATH = '/groups/'
export const PATH_PL = `/administracja/zapisy/przydzial/`

export const views = [
  {
    id: 1,
    viewName: 'QuickView',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'leftover',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[0]',
      'classes[1]',
      'classes[2]',
      'classes[3]',
      'classes[4]'
    ]
  },
  {
    id: 2,
    viewName: 'pn',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[0]'
    ]
  },
  {
    id: 3,
    viewName: 'wt',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[1]'
    ]
  },
  {
    id: 4,
    viewName: 'sr',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[2]'
    ]
  },
  {
    id: 5,
    viewName: 'cz',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[3]'
    ]
  },
  {
    id: 6,
    viewName: 'pt',
    columns: [
      'id',
      'firstName',
      'lastName',
      'continuation',
      'city',
      'district',
      'schoolNo',
      'className',
      'notes.general2',
      'classes[4]'
    ]
  }
]

export const chipConfig = ['city', 'district', 'schoolNo', 'className']

const hourColumnWidth = 248 // 84 //85
// const spacerColumnWidth = 40 //10
const disableSwitchVisibilityHourSection = true
// const disableSwitchVisibilitySpacer = true

export const columns = [
  {
    name: 'id',
    title: 'ID',
    hidden: true,
    formField: true
  },
  {
    name: 'firstName',
    title: 'Imię',
    type: 'component',
    component: (v, r) => {
      return v || null
    },
    onClick: (row, v, props) => {
      const {
        history: { push },
        cellProps: { day, selection, setGroupDetails }
      } = props
      const id = row.id
      const groupDetails = {
        day,
        selection
      }
      setGroupDetails(groupDetails)
      push(`${PATH_PL}${day ? day + '/' : ''}${id}`)
    },
    customSort: simpleSort,
    formField: true
  },
  {
    name: 'lastName',
    title: 'Nazwisko',
    filterField: true,
    type: 'component',
    subType: 'custom',
    component: (v, r) => {
      return v || null
    },
    onClick: (row, v, props) => {
      const {
        history: { push },
        cellProps: { day, selection, setGroupDetails }
      } = props
      const id = row.id
      const groupDetails = {
        day,
        selection
      }
      setGroupDetails(groupDetails)
      push(`${PATH_PL}${day ? day + '/' : ''}${id}`)
    },
    customSort: simpleSort,
    formField: true
  },
  {
    name: 'continuation',
    title: ' ',
    minSpace: ' ',
    customSort: sortNumber,
    formField: true,
    type: 'component',
    filterField: false,
    component: (v, r) => {

      let icon;

      if(v === 1) {
          icon =  (<span title={'Kontynuacja'}>
            <Cached
                style={styles.icon}
                color={'red'}
            />
          </span>)
      }
      return icon
    },
  },
  {
    name: 'leftover',
    title: 'Pozostałości',
    type: 'selact',
    filterField: true,
    customSort: simpleSort,
    hidden: true,
    formField: true
  },
  {
    name: 'city',
    title: 'Miasto',
    type: 'select',
    multiple: false,
    options: 'cities',
    hidden: false,
    filterField: true,
    customSort: simpleSort,
    formField: true
  },
  {
    name: 'district',
    title: 'Dzielnica',
    type: 'select',
    multiple: false,
    options: 'districts',
    hidden: false,
    filterField: true,
    customSort: simpleSort,
    formField: true
  },
  {
    name: 'schoolID',
    title: 'Szkoła',
    type: 'component',
    width: 120,
    multiple: false,
    options: 'schools',
    hidden: true,
    formField: true
  },
  {
    name: 'schoolNo',
    title: 'Szkoła',
    type: 'component', //'select',
    component: (v, r) => {
      // console.log(v, r)
      return v || null
    },
    width: 120,
    multiple: false,
    options: 'schools',
    hidden: false,
    customSort: schoolSort,
    filterField: true,
    formField: true
  },
  {
    name: 'school',
    title: 'Szkoła',
    type: 'select',
    width: 120,
    multiple: false,
    options: 'schools',
    hidden: true, //false,
    // customSort: schoolSort,
    // filterField: true,
    formField: true
  },
  {
    name: 'className',
    title: 'Klasa',
    minSpace: 'Klasa ',
    type: 'select',
    subType: 'custom',
    multiple: true,
    width: 80,
    options: 'classFilter',
    customSort: simpleSort,
    filterField: true,
    formField: true
  },
  {
    name: 'notes.general2',
    title: 'Uwagi',
    minSpace: 'Uwagi ',
    customSort: simpleSort,
    width: 200,
    formField: true
  },
  //################ PONIEDZIALEK
  // {
  //   name: 'spacer0',
  //   title: '',
  //   type: 'spacer',
  //   disableSwitchVisibility: disableSwitchVisibilitySpacer,
  //   width: spacerColumnWidth
  //   // formField: true
  // },
  {
    name: 'classes[0]',
    title: 'Poniedziałek', // 'PN 8:00 10:00 12:00 14:00 16:00',
    type: 'array',
    getCellValue: row => row['classes[0]'],
    onClick: (row, v, props) => {
      const {
        history: { push }
      } = props
      push(`${PATH_PL}pn`)
    },
    minSpace: 800,
    width: hourColumnWidth,
    disableSwitchVisibility: disableSwitchVisibilityHourSection,
    disableSorting: true,
    formField: true
  },
  {
    name: 'classes[1]',
    title: 'Wtorek', //'WT 8:00 10:00 12:00 14:00 16:00',
    type: 'array',
    getCellValue: row => row['classes[1]'],
    onClick: (row, v, props) => {
      const {
        history: { push }
      } = props
      push(`${PATH_PL}wt`)
    },
    minSpace: '1',
    width: hourColumnWidth,
    disableSwitchVisibility: disableSwitchVisibilityHourSection,
    disableSorting: true,
    formField: true
  },
  {
    name: 'classes[2]',
    title: 'Środa', //'ŚR 8:00 10:00 12:00 14:00 16:00',
    type: 'array',
    getCellValue: row => row['classes[2]'],
    onClick: (row, v, props) => {
      const {
        history: { push }
      } = props
      push(`${PATH_PL}sr`)
    },
    minSpace: '1',
    width: hourColumnWidth,
    disableSwitchVisibility: disableSwitchVisibilityHourSection,
    disableSorting: true,
    formField: true
  },
  {
    name: 'classes[3]',
    title: 'Czwartek', //'CZ 8:00 10:00 12:00 14:00 16:00',
    type: 'array',
    getCellValue: row => row['classes[3]'],
    onClick: (row, v, props) => {
      const {
        history: { push }
      } = props
      push(`${PATH_PL}cz`)
    },
    minSpace: '1',
    width: hourColumnWidth,
    disableSwitchVisibility: disableSwitchVisibilityHourSection,
    disableSorting: true,
    formField: true
  },
  {
    name: 'classes[4]',
    title: 'Piątek', //'PT 8:00 10:00 12:00 14:00 16:00',
    type: 'array',
    getCellValue: row => row['classes[4]'],
    onClick: (row, v, props) => {
      const {
        history: { push }
      } = props
      push(`${PATH_PL}pt`)
    },
    minSpace: '1',
    width: hourColumnWidth,
    disableSwitchVisibility: disableSwitchVisibilityHourSection,
    disableSorting: true,
    formField: true
  }
]
