import _ from 'lodash'

export const updateSelectedGroups = (groupId, classId, selectedGroups = []) => {
  let newGroups = selectedGroups

  const group = _.find(newGroups, { group: groupId })
  const groupIndex = group && _.findIndex(newGroups, { group: groupId })
  const groupClasses = (group && group.classes) || []
  const classIndex = groupClasses.indexOf(classId)

  if (!group)
    // if groupId object does not exist, create it and add classId provided to
    // the classes array
    newGroups.push({ group: groupId, classes: [classId] })
  else if (!groupClasses.includes(classId)) {
    // if groupId exists but classId doesn't, add classId to the array
    if (_.size(newGroups[groupIndex].classes) < 1)
      newGroups[groupIndex].classes.push(classId)
  } else {
    // if groupId exists and classId exists, remove classId from the classes
    // array
    newGroups[groupIndex].classes.splice(classIndex, 1)
    // if classes array is empty, remove groupId object altogether
    if (!newGroups[groupIndex].classes.length) newGroups.splice(groupIndex, 1)
  }

  return newGroups
}

export const getMergedSubjectsWithCounts = (
  selectedGroups,
  subjectLists,
  subjects,
  groups = [],
  activeYear = new Date().getFullYear()
) => {
  const groupIds = selectedGroups.map(g => g.group)

  const mergedLists = subjectLists
    .filter(g => groupIds.includes(g.id))
    .reduce((ls, l) => {

      _.forEach(l.subjects, (subjectCount, subjectId) => {
        const subject = _.find(subjects, { _id: subjectId }) || {}
        const subjectIndex = _.findIndex(ls, { id: subjectId })
        if (subjectIndex !== -1) ls[subjectIndex].count += subjectCount || 0
        else {
            ls.push({ ...subject, count: subjectCount })
        }
      })
      return ls
    }, [])

  const usedTopics = getCurrentYearSubjects(groups, activeYear);

  const selectedGroupsIds = selectedGroups.map(x => x.group);

  const mergedSubjects = subjects.map(s => ({
    ...s,
    repeated: findNestedSubjects(mergedLists, s.number),
    usedInCurrentYear: usedTopics.filter(x => (x._id === s._id && selectedGroupsIds.includes(x.groupId))).length,
    ..._.find(mergedLists, { _id: s._id }),
    id: s._id
  }))

  return sortTopics(mergedSubjects)
}

const findNestedSubjects = (listOfSubjects, number) => {
    let filtered = listOfSubjects.filter(x => x.number === number);

    if(filtered.length > 0) {
      filtered = filtered.map(x => x.count).reduce((a,b) => a+b, 0);
    } else {
      filtered = 0;
    }
    return filtered;
};

const sortTopics = (topics) => {
    return topics.sort(function(a, b){
        return a.repeated - b.repeated
    });
};

export const getCurrentYearSubjects = (data, activeYear) => {
    let startDate = parseInt(activeYear) + '-09-01';
    let endDate = (parseInt(activeYear) + 1) + '-06-30';
    let topics = [];

    startDate = +new Date(startDate);
    endDate = +new Date(endDate);

    data.forEach(e => {
        Object.keys(e)
            .forEach(function eachKey(key) {
                let isGroup = key.indexOf('classes');
                if(isGroup !== -1) {
                    const createdAt = +new Date(e[key].date);
                    if(startDate <= createdAt && createdAt <= endDate) {
                        // console.log(e[key].subject);
                        if(e[key].subject !== null) {
                            let subjectData = {
                                _id: e[key].subject._id,
                                groupId: e.id
                            };
                            topics.push(subjectData);
                        }
                    }
                }
            });
    });

    return topics;
};
