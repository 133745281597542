import moment from 'moment'
import _ from 'lodash'

export const getSlots = (pupil, period = 12) => {
  const classes = pupil.classes ? pupil.classes.map(c => getDaySlots(c, 1)) : []
  const classesAS = pupil.classesAS
    ? pupil.classesAS.map(c => getDaySlots(c, 0.75))
    : []
  const pattern = Array(5).fill(Array(9).fill(Array(period).fill(0)))

  const classesSummed = pattern.map((day, dIndex) => {
    return day.map((hour, hIndex) => {
      return hour.map((minute, mIndex) => {
        const minAS =
          _.get(classesAS, `[${dIndex}].[${hIndex}].[${mIndex}]`) || 0
        const minS = _.get(classes, `[${dIndex}].[${hIndex}].[${mIndex}]`) || 0

        return minute + minAS + minS
      })
    })
  })

  return classesSummed
}

export const cmpSlots = (slots, period = 12) => {
  const pattern = Array(5).fill(Array(9).fill(Array(period).fill(0)))
  const classesAvailable = pattern.map((day, dIndex) => {
    return day.map((hour, hIndex) => {
      return hour.map((minute, mIndex) => {
        const result = slots.reduce((p, c) => {
          const element = _.get(c, `[${dIndex}][${hIndex}][${mIndex}]`) || 0
          return element + p
        }, 0)

        return result === 0 ? -0.5 : 0
      })
    })
  })

  return classesAvailable
}

export const rejectSmallTimeFrame = (slots, period = 12) => {
  let map = []
  let iStart = 0
  let iEnd = 0
  const flatSlots = slots.map((daySlot, dayIndex) => {
    const flattenDaySlots = _.flattenDeep(daySlot)
    if (_.every(flattenDaySlots, e => e === -0.5)) return flattenDaySlots
    map = Array(flattenDaySlots.length).fill(0)
    iStart = -1
    iEnd = 0
    flattenDaySlots.map((e, i) => {
      if (i === 0 && e === -0.5) {
        iStart = i
      }
      if (i > 0 && e === -0.5 && flattenDaySlots[i - 1] === 0) {
        iStart = i
      } else if (
        ((i > 0 && e === 0 && flattenDaySlots[i - 1] === -0.5) ||
          i === flattenDaySlots.length - 1) &&
        iStart > -1
      ) {
        iEnd = i

        if (i === flattenDaySlots.length - 1) iEnd = i + 1
        if (iEnd - iStart >= period) map.fill(-0.5, iStart, iEnd)
        iStart = -1
      }
      return e
    })
    return map
  })
  const finalClasses = flatSlots.map(a => {
    return _.chunk(a, period)
  })
  return finalClasses
}

export const applySlots = (classes, slotsPattern) => {
  if (_.isEmpty(slotsPattern) && !_.isEmpty(classes)) {
    return classes
  } else if (!_.isEmpty(slotsPattern) && _.isEmpty(classes)) {
    return slotsPattern
  }

  const classesAdjusted = classes.map((day, dIndex) => {
    return day.map((hour, hIndex) => {
      return hour.map((minute, mIndex) => {
        return (
          minute +
          (_.get(slotsPattern, `[${dIndex}].[${hIndex}].[${mIndex}]`) || 0)
        )
      })
    })
  })

  return classesAdjusted
}

const getDaySlots = (day, flag = 1, period = 12) => {
  if (!day || !day.from || !day.to) return hours.map(d => Array(period).fill(0)) // [false, false, false, false])
  return hours.map(h =>
    minutes.map(m => timeInRange(`${h}:${m}`, day.from, day.to, flag))
  )
}

const hours = ['08', '09', '10', '11', '12', '13', '14', '15', '16']
// const minutes = ['00', '15', '30', '45']
const minutes = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55'
]

export const timeInRange = (time, from, to, flag = 1) => {
  const format = 'hh:mm'
  return moment(time, format).isBetween(
    moment(from, format).subtract(1, 'seconds'),
    moment(to, format)
  )
    ? flag
    : 0
}

export const getSelectionHours = (classesArray, dayTable, type = 'classes') => {
  const initArray = dayTable.map((d, index) => {
    return {
      day: d,
      from: null,
      to: null
    }
  })

  return initArray.map((day, dIndex) => {
    const result = classesArray.reduce((pupilPrev, pupilCurr, pupilIndex) => {
      const to = _.get(pupilCurr, `${type}[${dIndex}].to`) || null
      const from = _.get(pupilCurr, `${type}[${dIndex}].from`) || null
      if (pupilIndex === 0)
        return {
          day: day.day,
          to,
          from
        }
      return {
        ...day,
        to: to === pupilPrev.to ? to : null,
        from: from === pupilPrev.from ? from : null
      }
    }, {})
    return result
  })
}

export const getSchoolsLabels = (schools, selectedIds, type = 0) => {
  return _.compact(
    (selectedIds || []).map(id => {
      if (type === 0) {
        return _.get(_.find(schools, ['id', id]), 'label')
      } else {
        return _.get(_.find(schools, ['value', id]), 'label')
      }
    })
  ).join(', ')
}

export const getSelectionNotes = (classesArray, type = 'general2') => {
  const combinedNotes = classesArray.reduce(
    (pupilPrev, pupilCurr, pupilIndex) => {
      const noteText = _.get(pupilCurr, `notes.${type}`) || undefined

      if (pupilIndex === 0) {
        return {
          noteText,
          isEqual: true
        }
      } else {
        return pupilPrev.noteText === noteText
          ? { noteText, isEqual: true }
          : { noteText: null, isEqual: false }
      }
    },
    { noteText: '', isEqual: false }
  )

  return combinedNotes.isEqual
    ? { [`${type}`]: combinedNotes.noteText }
    : { [`${type}`]: null }
}

export const getFilterValue = (
  filters,
  columnName,
  multiple = true,
  select = false
) => {
  const filter = _.find(filters, ['columnName', columnName])
  if (select) {
    return filter
      ? multiple
        ? filter.value
        : filter.value && filter.value[0]
      : ''
  }
  return filter ? filter.value && filter.value[0] : ''
}
