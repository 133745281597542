import React, { Component } from 'react'
// import _ from 'lodash'
import { withTheme } from 'material-ui/styles'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import { Fab } from 'material-ui-elements'

import { LABEL } from '../constants'

import * as constants from '../constants'

const { columns, NAME, PATH_PL } = constants

class List extends Component {
  state = { refresh: true }

  render() {
    const {
      data,
      history: { push },
      profile
      // theme: { palette }
      // snackbar
    } = this.props
    const { refresh } = this.state

    if (!data) return null

    return (
      <div>
        <Fab
          style={{ marginBottom: 20 }}
          onClick={() => push(`${PATH_PL}new/?view=edit`)}
        />
        <MuiDxGrid
          name={`${NAME}`}
          title={`${LABEL}`}
          lang="pl"
          refresh={refresh}
          layout={MuiDxGrid.layouts.drawer}
          rows={data.data}
          columns={columns}
          sorting={{
            remember: false,
            order: [
              {
                name: 'shareType',
                direction: profile && profile.role === 'Admin' ? 'asc' : 'desc'
              },
              { name: 'name', direction: 'asc' }
            ]
          }}
          filtering={{
            remember: true,
            default: true,
            lockAutoPopulate: true,
            filters: [
              { name: 'templateType', value: 'comm' }
              // {
              //   name: 'templateType',
              //   value:
              //     profile && profile.cities
              //       ? _.isEmpty(profile.cities)
              //         ? 'Warszawa'
              //         : profile.cities[0]
              //       : 'Warszawa'
              // }
            ] //10
          }}
          selection={{ show: false }}
          paging
          actions={{
            onRowClick: (rowId, rowData, gridProps) => {
              push(`${PATH_PL}${rowId}`)
            }
          }}
          actionButtonsTop={[]}
        />
      </div>
    )
  }
}

export default withTheme()(List)
