import React from 'react'
import uc from '../assets/uc.png'

const UnderConstruction = ({ text }) => {
  return (
    <div
      style={{
        display: 'flex',
        height: 500,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ margin: 'auto' }}>
        <img src={uc} height="300px" alt="Under Construction" />
        {text || ''}
      </div>
    </div>
  )
}

export default UnderConstruction
