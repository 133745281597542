import React from 'react'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import StatusIcon from './components/StatusIcon'
import { customStatusSort } from './helpers'
const { fields } = MuiDxGrid

export const NAME = 'payments'
export const LABEL = 'Płatności'
export const PATH = `/${NAME}/`
export const PATH_PL = `/platnosci/`

const statusOption = [
  { value: 'all', label: 'Wszystkie' },
  // {
  //   value: 0,
  //   label: 'Brak dopasowania',
  //   icon: '',
  //   color: 'green'
  // },
  {
    value: 10,
    label: 'Idealne dopasowanie',
    icon: 'check_circle',
    color: 'green'
  },
  { value: 20, label: 'Ręczne dopasowanie', icon: 'warning', color: 'yellow' },
  { value: 30, label: 'Pomiń płatność', icon: 'remove_circle', color: 'red' }
]

export const columns = [
  {
    type: fields.text,
    name: 'date',
    title: 'Data',
    width: 120
    // filteringEnabled: true
  },
  {
    // type: fields.password,
    name: 'sender',
    title: 'Nadawca',
    width: 300,
    wordWrap: true,
    filteringEnabled: true
  },
  {
    type: fields.number,
    name: 'amount',
    title: 'Kwota',
    align: 'center',
    width: 100,
    filteringEnabled: true
  },
  {
    type: fields.text,
    name: 'title',
    title: 'Tytuł',
    wordWrap: true,
    filteringEnabled: true
  },
  {
    type: fields.component,
    name: 'status',
    title: 'Status',
    align: 'center',
    width: 80,
    filteringEnabled: true,
    customFilter: {
      type: fields.select
    },
    customSort: customStatusSort,
    options: statusOption,
    component: value => <StatusIcon value={value} options={statusOption} />
  }
]

export const fieldMappings = {
  common: {
    sender: 'Nadawca',
    currency: 'Waluta'
  },
  1: {
    date: 'Data transakcji',
    title: 'Tytuł płatności (linia 1)',
    amount: 'Kwota'
  },
  2: {
    date: 'Data zlecenia',
    title: 'Tytuł',
    amount: 'Kwota w walucie rachunku'
  }
}
