import React, { Component } from 'react'
import _ from 'lodash'
import qs from 'query-string'
import axios from 'axios'
import moment from 'moment'
import flat from 'flat'
import Typography from 'material-ui/Typography'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import { PATH_PL, PATH } from '../constants'
import List from './List'
import { updateSelectedGroups, getMergedSubjectsWithCounts } from './helpers'

const {
  constants: { API_URL },
  helpers: { api, getUserRole, getUserActiveYear }
} = core

class Container extends Component {
  state = {}

  clearSelected() {
    this.setState({
      selectedClasses: {},
      selectedGroups: [],
      selectedSubjects: []
    })
  }

  clearRefresh() {
    this.setState({
      refresh: false
    })
  }

  // Filtrowanie: wszyscy lub wybrany prowadzący
  filter(data, teacher_id) {
    var filteredData = null
    if (teacher_id === 'all') filteredData = null
    else if (teacher_id === 'brak')
      filteredData = data.filter(g => {
        return !g.teacher
      })
    else
      filteredData = data.filter(g => {
        return g.teacher && teacher_id === g.teacher._id
      })

    return filteredData
  }

  async fetchToState(refresh = false) {
    const { teacherView } = this.props
    const userRole = await getUserRole()
    const activeYear = await getUserActiveYear()
    const cityArray =
      userRole !== 'Super Admin' ? this.props.profile.cities : [] //getUserCities(this.props.profile)
    const filterByCity = userRole !== 'Super Admin'

    const data = await api.get([
      teacherView
        ? { groups: `groups/teacher/${this.props.profile._id}/` }
        : 'groups',
      { teachers: 'users?role=Prowadzący' },
      { allSubjects: 'subjects' },
      {
        subjects: 'groups/subjects'
      },
      'schools'
    ])
    // Prowadzący
    const qs_parsed = qs.parse(this.props.location.search)
    var selectTeacher = qs_parsed.t ? qs_parsed.t : 'all'

    data.teachers = _.chain(data.teachers)
      .filter(t => {
        var allow = 0

        if (filterByCity)
          cityArray.forEach(c => {
            allow += t.cities.includes(c) ? 1 : 0
          })
        else allow = 1

        return allow && t.active
      })
      .map(t => {
        t.groups_count = 0
        return t
      })
      .value()

    // Grupy

    var teachersWithGroups = []
    var max_classes = 0
    data.groups = _.chain(data.groups)
      .map(g => {
        g.allowed = 1 // Grupy bez prowadzącego + z prowadzącym dostępnym dla zalogowanego
        // Max ilość zajęć (dla wszystkich)
        if (g.classes.length > max_classes) max_classes = g.classes.length

        g.id = g._id
        delete g._id

        // Dodajemy prowadzącego
        var hasTeacher = g.teacher
        g.teacher = _.find(data.teachers, { _id: g.teacher })
        if (hasTeacher && !g.teacher) g.allowed = 0

        if (g.teacher && teachersWithGroups.indexOf(g.teacher._id) === -1)
          teachersWithGroups.push(g.teacher._id)

        // Rzutujemy zajęcia na object
        _.sortBy(g.classes, ['date']).map((c, k) => {
          c.subject = c.subject
            ? _.find(data.allSubjects, { _id: c.subject })
            : null
          g['classes_' + k] = c
          return c
        })
        delete g.classes

        return g
      })
      .filter(g => {
        return g.allowed === 1
      })
      .value()
    // Wyłapujemy nauczycieli bez grup
    data.teachers = _.filter(data.teachers, t => {
      return teachersWithGroups.indexOf(t._id) !== -1
    })

    const availableValues = {
      teachers: data.teachers
    }

    // Add school detail
    data.groups = data.groups.map(g => ({
      ...g,
      school: _.find(data.schools, { _id: g.school }) || null
    }))

    data.activeYear = activeYear;

    const sortedGroups = _.sortBy(data.groups, ['dayNumber', 'timeStartString'])

    this.setState({
      max_classes: max_classes,
      data: sortedGroups,
      dxData: sortedGroups.map((d, i) => ({
        ...d,
        id: i + 1,
        group: { groupName: d.groupName, id: d.id }
      })),
      dxTableConfig: {
        availableValues
      },
      filteredData:
        selectTeacher !== 'all'
          ? this.filter(sortedGroups, selectTeacher)
          : null,
      teachers: data.teachers,
      subjects: data.subjects,
      allSubjects: data.allSubjects,
      selectTeacher: selectTeacher,
      selectedClasses: {},
      subjectId: null,
      refresh: refresh || false,
      selectedGroups: [],
      selectedSubjects: [],
      subjectRepeat: 0,
      activeYear: data.activeYear,
      groups: data.groups
    })
  }

  async assignButtonAction() {
    const { snackbar } = this.props
    const { selectedGroups, subjectId } = this.state

    let data = {
      subject: subjectId,
      groups: selectedGroups
    }

    await axios.post(`${API_URL}${PATH}`, data, {
      headers: { authorization: localStorage.getItem('token') }
    })

    // this.changeSubjectId()
    await this.fetchToState(true)
    snackbar(`Tematy zajęć zostały poprawnie przypisane`)
  }

  changeSubjectId(subject = '') {
    this.setState({
      subjectId: subject ? subject.value : null,
      selectedClasses: subject ? this.state.selectedClasses : [],
      selectedGroups: subject ? this.state.selectedGroups : []
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  render() {
    const { snackbar, teacherView } = this.props
    const {
      data,
      teachers,
      dxTableConfig,
      dxData,
      subjects,
      allSubjects,
      selectedClasses,
      selectedSubjects,
      subjectId,
      refresh,
      groups,
      activeYear
    } = this.state
    if (!data) return <Spinner />

    var dxColumns = [
      {
        name: 'id',
        title: 'rowKey',
        formField: true
      },
      {
        name: 'group',
        type: 'component',
        title: 'Nazwa grupy',
        filterField: true,
        classesOverride: 'tableCellPaddingMore',
        width: 150,
        component: (v, r) => {
          return v && `${v.groupName}`
        },
        disableSorting: true,
        formField: true
      },
      {
        title: 'Prowadzący',
        width: 200,
        hidden: teacherView,
        filterField: true,
        name: 'teacher',
        type: 'component',
        options: 'teachers',
        disableSorting: true,
        classes: 'tableCellPaddingDefault',
        component: (v, r) => {
          return v && `${v.firstName} ${v.lastName}`
        },
        formField: true
      }
    ]

    for (let i = 0; i < this.state.max_classes; i++) {
      dxColumns.push({
        title: 'Zajęcia ' + (i + 1),
        name: 'classes_' + i,
        width: 150,
        style: { width: 50, textAlign: 'left' },
        type: 'component',
        classesData: 'tableCellDateSelectedComponent',
        disableSorting: true,
        formField: true,
        selected: v => v && v._id in this.state.selectedClasses,
        component: (v, r) => {
          let duplicate = false
          if (subjectId && v) {
            const group_id = !!_.find(this.state.selectedGroups, [
              'group',
              _.get(r, 'group.id')
            ])
            if (group_id) {
              duplicate =
                _.size(
                  _.filter(_.values(flat.flatten(v)), f => f === subjectId)
                ) >= 1
            }
          }

          const date = v && v.date && moment(v.date).format('DD/MM')
          return (
            <div>
              <Typography variant="caption">
                {v && v.date ? `${date}` : '---'}{' '}
              </Typography>
              <div style={{ fontWeight: duplicate ? 'bold' : 'normal' }}>
                {v && v.subject ? `${v.subject.subject}` : ''}
              </div>
            </div>
          )
        },
        onClick: !teacherView
          ? (row, v) => {
              if (v && v._id) {

                const group_id = _.get(row, 'group.id')
                const classes_id = v._id
                const isGroupClasses = !!_.find(this.state.selectedGroups, [
                  'group',
                  group_id
                ])
                var ob = this.state.selectedClasses

                if (
                  isGroupClasses &&
                  !(classes_id in this.state.selectedClasses)
                )
                  snackbar(
                    'Nie można zaznaczyć więcej niż 1 zajęcia z danej grupy'
                  )

                if (classes_id in this.state.selectedClasses)
                  delete ob[classes_id]
                else if (!isGroupClasses) ob[classes_id] = group_id

                const selectedGroups = updateSelectedGroups(
                  group_id,
                  classes_id,
                  this.state.selectedGroups
                )

                this.setState({
                  selectedClasses: ob,
                  refresh: false,
                  selectedGroups,
                  selectedSubjects: getMergedSubjectsWithCounts(
                    selectedGroups,
                    subjects,
                    allSubjects,
                    groups,
                    activeYear
                  )
                })
              }
            }
          : null
      })

      if (i < this.state.max_classes - 1) {
        dxColumns.push({
          name: 'spacer' + i,
          title: '',
          type: 'spacer',
          disableSwitchVisibility: true,
          width: 12
        })
      }
    }

    const qs_parsed = qs.parse(this.props.location.search)
    const selectTeacher = qs_parsed.t ? qs_parsed.t : 'all'
    const propFilters =
      selectTeacher === 'all'
        ? []
        : [{ columnName: 'teacher', value: { _id: selectTeacher } }]

    return (
      <List
        data={dxData}
        columns={dxColumns}
        dxTableConfig={dxTableConfig}
        propFilters={propFilters}
        refresh={refresh}
        toolbarTopProps={{
          snackbar,
          clearSelected: this.clearSelected,
          history: this.props.history,
          handleAddSubject: this.handleAddSubject,
          path: PATH_PL,
          selectedClasses,
          selectedSubjects,
          subjectId,
          selectTeacher,
          changeSubjectId: this.changeSubjectId.bind(this),
          teachers,
          assignButtonAction: this.assignButtonAction.bind(this)
        }}
        {...this.props}
      />
    )
  }
}

export default Container
