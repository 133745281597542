import React from 'react';
import { FlatButton } from 'material-ui-effco';

export default ({ action, style, label, icon, disabled, secondary }) => {
  if (action) {
    return (
      <FlatButton
        label={label}
        style={style}
        icon={icon}
        primary={!secondary}
        secondary={!!secondary}
        onTouchTap={action}
        disabled={disabled}
      />
    );
  }

  return null;
};
