import React from 'react'
import _ from 'lodash'
import { TableRow, TableCell } from 'material-ui'
import { Checkbox } from 'material-ui-effco'
import Media from 'react-media'
import common from '../../../common'
import core from '../../../core'

const {
  ContinuationIcon,
  SiblingsIcon,
  AttendanceButton,
  PromotionIcon
} = common.components

const {
  oldTheme: { palette }
} = core.constants

export default props => {
  const {
    onCellClick,
    pupil,
    pupilIds,
    index,
    // palette,
    data: {
      rows,
      multiSelect: { selectedRows },
      selectedGroup
    },
    selectable,
    role
  } = props

  const selected = selectedRows.indexOf(pupil._id) > -1

  const due = {
    s1:
      _.has(pupil, 'payment.due.s1') && pupil.payment.due.s1
        ? pupil.payment.due.s1 * 1
        : 0,
    s2:
      _.has(pupil, 'payment.due.s2') && pupil.payment.due.s2
        ? pupil.payment.due.s2 * 1
        : 0
  }

  const payments =
    pupil && pupil.payment && pupil.payment.paid && pupil.payment.paid.length
      ? pupil.payment.paid
          .map(x => x.amount * 1)
          .reduce((sum, x) => sum * 1 + x * 1)
      : 0
  const liability = due.s1 * 1 + due.s2 * 1 - payments

  if (index > rows) return null
  return (
    <TableRow
      onClick={() => onCellClick(pupil._id, selectedRows, pupilIds)}
      hover
      selected={selected}
      style={{ height: 24 }}
    >
      {selectable ? (
        <TableCell padding="dense" checkbox>
          <Checkbox checked={selected} />
        </TableCell>
      ) : null}
      <TableCell padding="dense" style={{ height: 36 }}>
        <span title={`${pupil.firstName} ${pupil.lastName}`}>{`${
          pupil.firstName
        } ${pupil.lastName}`}</span>
        <ContinuationIcon
          value={pupil.continuation}
          color={palette.primary1Color}
        />
        {_.includes(['Super Admin', 'Admin'], role) && (
          <PromotionIcon
            value={pupil.promotion}
            color={palette.primary1Color}
          />
        )}
        <SiblingsIcon value={pupil.siblings} color={palette.primary1Color} />
      </TableCell>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric style={{ height: 36 }}>
          {due.s1 * 1 + due.s2 * 1}
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric style={{ height: 36 }}>
          {payments}
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell
          padding="dense"
          numeric
          style={{ height: 36, fontWeight: 'bold' }}
        >
          {liability}
        </TableCell>
      </Media>
      {selectedGroup.classes.map(cl => (
        <TableCell
          key={cl._id}
          padding="none"
          style={{
            margin: 0,
            padding: 0,
            marginLeft: 0,
            height: 36,
            overflow: 'visible'
          }}
        >
          <AttendanceButton
            disabled
            attendance={
              _.find(pupil.attendance || [], { classId: cl._id }) || {}
            }
            pupilId={pupil._id}
            {...props}
          />
        </TableCell>
      ))}
      <TableCell padding="dense" />
    </TableRow>
  )
}
