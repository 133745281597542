import React from 'react'
import PropTypes from 'prop-types'
import { withTheme, withStyles } from 'material-ui/styles'
import { IconButton } from 'material-ui-effco'
import ViewColumnIcon from 'mdi-material-ui/ViewColumn'

const styles = theme => ({})

const VisibilityButton = props => {
  const { onToggle, changeMargin, theme: { palette } } = props

  return (
    <IconButton
      tooltip="Column Visibility"
      tooltipPosition="bottom-left"
      touch
      onTouchTap={() => {
        changeMargin(true)
        onToggle()
      }}
      style={{
        marginTop: 3,
        width: 48,
        padding: '12px 12px 12px 12px'
      }}
      iconStyle={{ width: 24, height: 24, color: palette.primary.main }}
    >
      <ViewColumnIcon />
    </IconButton>
  )
}

VisibilityButton.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(withTheme()(VisibilityButton))
