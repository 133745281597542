import React from 'react'
import { withTheme } from 'material-ui/styles'
import PropTypes from 'prop-types'
import { TableCell } from 'material-ui'
import { MuiTextfield } from 'material-ui-elements'

const FilterCellText = props => {
  const { filter, onFilter, tableColumn } = props

  return (
    <TableCell>
      <MuiTextfield
        value={filter ? filter.value && filter.value[0] : undefined} //{filter ? filter.value : undefined}
        input={{
          onChange: (e, v) => {
            onFilter(v ? { value: [v] } : null)
          }
        }}
        style={{
          marginLeft: -20,
          width:
            tableColumn && tableColumn.width ? tableColumn.width - 10 : 100,
          fontSize: 12
        }}
      />
    </TableCell>
  )
}

FilterCellText.propTypes = {
  theme: PropTypes.object.isRequired
}

export default withTheme()(FilterCellText)
