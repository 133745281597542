/*##############################################################################
# IMPORTY
##############################################################################*/
import React, { Component }           from "react";
import { MuiForm, MuiTable }          from "material-ui-elements";
import { ListItemText, ListItemIcon } from "material-ui/List";
import YoungerIcon                    from "mdi-material-ui/Numeric0BoxMultipleOutline";
import OlderIcon                      from "mdi-material-ui/Numeric2BoxMultipleOutline";
import UnspecifiedIcon                from "mdi-material-ui/CheckboxMultipleMarked";
import moment                         from "moment";
import qs                             from "query-string";
import axios                          from "axios";
import _                              from "lodash";
import { connect }                    from "react-redux";
import { getFormValues, change }      from "redux-form";
import core                           from "../../core";
import { PATH, PATH_PL }              from "../constants";
import PupilsClasses                  from "./PupilsClasses";

/*##############################################################################
# DESTRUCTORY
##############################################################################*/
const {
    constants: { API_URL },
    helpers: { api, createObjectId }
} = core;
const { fields } = MuiForm;
const { layouts } = MuiTable;


/*##############################################################################
# COMPONENT
##############################################################################*/
class Detail extends Component {
    state = {};

    isDisabled = () => qs.parse(this.props.location.search).view !== "edit";

    isNew = () => this.props.match.params.id === "new";

    componentWillMount() {
        if (this.isNew()) {
            this.initialDataToState();
            return;
        }
        this.fetchToState();
    }

    async fetchToState() {
        const data = await api.get([
            "schools",
            "cities",
            {
                teachers: "users?role=Prowadzący"
            },
            {
                group: `${PATH}${this.props.match.params.id}`
            },
            "subjects",
            {
                subjectsWithCounts: `stats/subjects/all/${this.props.match.params.id}`
            },
            {
                defaultSubjects: "subjects?defaults=true"
            }
        ]);

        const hasSubjects = Array.isArray(data.subjectsWithCounts);

        data.group.classes = _.sortBy(data.group.classes, "date");
        data.subjects = hasSubjects
            ? data.subjects.map(s => {
                  const subjectDetail =
                      _.find(data.subjectsWithCounts, {
                          id: s._id
                      }) || {};
                  return {
                      ...s,
                      id: s._id,
                      ...subjectDetail
                  };
              })
            : data.subjects.map(s => ({
                  ...s,
                  id: s._id
              }));

        this.setState({
            data
        });
    }

    async initialDataToState() {
        let data = await api.get([
            "cities",
            "schools",
            {
                teachers: "users?role=Prowadzący"
            },
            "subjects",
            {
                defaultSubjects: "subjects?defaults=true"
            }
        ]);
        data.group = {
            number: "",
            fullName: "",
            district: "",
            timeStart: new Date(),
            timeEnd: new Date().setHours(new Date().getHours() + 1),
            ageGroup: "u"
        };
        this.setState({
            data
        });
    }

    render() {
        const {
            match: {
                params: { id }
            },
            history: { push },
            snackbar,
            change,
            formValues = {}
        } = this.props;
        if (!this.state.data) return null;
        const {
            group,
            schools,
            cities,
            teachers,
            subjects,
            defaultSubjects
        } = this.state.data;
        const disabled = this.isDisabled();

        const groupAgeGroup = formValues.ageGroup || "u";

        const defaultSubjectsForAgeGroup = defaultSubjects[groupAgeGroup] || [];

        const repeatingSubjects = subjects.filter(s => s.count).map(s => s._id);

        const canAssignDefaultSubjects = !_.intersection(
            defaultSubjectsForAgeGroup,
            repeatingSubjects
        ).length;

        const subjectsOrdering = {
            // 1 - Mieszana, 2 - Młodsza, 3 - Starsza
            u: {
                y: 2,
                o: 3
            },
            y: {
                u: 2,
                o: 3
            },
            o: {
                u: 2,
                y: 3
            }
        };

        const subjectsOrdered = _.sortBy(
            subjects.map(s => {
                const subjectWithOrdering = {
                    ...s,
                    ordering:
                        s.ageGroup === groupAgeGroup
                            ? 1
                            : subjectsOrdering[groupAgeGroup][s.ageGroup] || 3
                };
                return subjectWithOrdering;
            }),
            ["ordering", "count", "number", "subject"]
        );

        const subjectIcon = {
            u: (
                <ListItemIcon>
                    <UnspecifiedIcon />
                </ListItemIcon>
            ),
            y: (
                <ListItemIcon>
                    <YoungerIcon />
                </ListItemIcon>
            ),
            o: (
                <ListItemIcon>
                    <OlderIcon />
                </ListItemIcon>
            )
        };
        return (
            <MuiForm
                open
                lang="pl"
                disabled={this.isDisabled()}
                add={id === "new"}
                initialValues={group}
                title={id === "new" ? "Nowa Grupa" : `Grupa ${group.groupName}`}
                name="MuiForm"
                groups={[
                    {
                        label: "Podstawowe",
                        fields: [
                            [
                                {
                                    type: fields.select,
                                    name: "city",
                                    label: "Miasto",
                                    hint: "Wybierz miasto",
                                    required: true,
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane",
                                    options: cities.map(c => c.name),
                                    onChange: () => {
                                        change("MuiForm", "school", null);
                                        change("MuiForm", "teacher", null);
                                    }
                                },
                                {
                                    type: fields.select,
                                    name: "school",
                                    hint: "Wybierz szkołę",
                                    required: true,
                                    label: "Szkoła",
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane",
                                    options: _.sortBy(
                                        schools.filter(
                                            s =>
                                                s.city ===
                                                (formValues
                                                    ? formValues.city
                                                    : true)
                                        ),
                                        "code"
                                    ).map(s => ({
                                        value: s._id,
                                        label: `${s.number} (${
                                            s.district ? s.district + ", " : ""
                                        }${s.address})`
                                    }))
                                }
                            ],
                            [
                                {
                                    type: fields.select,
                                    name: "teacher",
                                    label: "Prowadzący",
                                    hint: "Wybierz prowadzącego",
                                    options: _.sortBy(
                                        teachers.filter(
                                            t =>
                                                t.cities.includes(
                                                    formValues
                                                        ? formValues.city
                                                        : true
                                                ) && t.active
                                        ),
                                        "lastName"
                                    ).map(t => ({
                                        value: t._id,
                                        label: `${t.firstName} ${t.lastName}`
                                    }))
                                },
                                {
                                    type: fields.text,
                                    name: "classroom",
                                    label: "Sala",
                                    hint: "Wprowadź numer/nazwę sali"
                                }
                            ],
                            [
                                {
                                    type: fields.select,
                                    required: true,
                                    name: "groupDay",
                                    label: "Dzień Tygodnia",
                                    hint: "Wybierz dzień tygodnia",
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane",
                                    options: [
                                        {
                                            value: "PN",
                                            label: "Poniedziałek"
                                        },
                                        {
                                            value: "WT",
                                            label: "Wtorek"
                                        },
                                        {
                                            value: "SR",
                                            label: "Środa"
                                        },
                                        {
                                            value: "CZ",
                                            label: "Czwartek"
                                        },
                                        {
                                            value: "PT",
                                            label: "Piątek"
                                        }
                                    ]
                                },
                                {
                                    type: fields.timepicker,
                                    name: "timeStart",
                                    required: true,
                                    cancelLabel: "Anuluj",
                                    hint: "Wybierz godzinę rozpoczęcia",
                                    label: "Godzina rozpoczęcia",
                                    onChange: (e, newTime) => {
                                        change(
                                            "MuiForm",
                                            "timeEnd",
                                            new Date(newTime).setHours(
                                                new Date(newTime).getHours() + 1
                                            )
                                        );
                                    },
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane"
                                },
                                {
                                    type: fields.timepicker,
                                    name: "timeEnd",
                                    required: true,
                                    cancelLabel: "Anuluj",
                                    label: "Godzina zakończenia",
                                    hint: "Wybierz godzinę zakończenia",
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane"
                                }
                            ],
                            {
                                type: fields.radiogroup,
                                name: "ageGroup",
                                label: "Grupa wiekowa",
                                required: true,
                                options: [
                                    {
                                        value: "u",
                                        label: "Mieszana / Nieokreślona"
                                    },
                                    {
                                        value: "y",
                                        label: "Młodsza (klasy 0-1)"
                                    },
                                    {
                                        value: "o",
                                        label: "Starsza (klasy 2-3)"
                                    }
                                ],
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane"
                            }
                        ]
                    },
                    {
                        label: "Zajęcia",
                        fields: !this.isNew() && [
                            {
                                type: fields.table,
                                name: "classes",
                                subform: true,
                                narrow: true,
                                lang: "pl",
                                data: formValues.classes,
                                layout: layouts.fieldArray,
                                disabled,
                                defaultNew: {
                                    _id: createObjectId(),
                                    date: new Date()
                                },
                                columns: [
                                    {
                                        type: fields.datepicker,
                                        name: "date",
                                        label: "Data / Godzina",
                                        hideLabel: true,
                                        formField: true,
                                        disabled,
                                        cancelLabel: "Anuluj",
                                        locale: "pl-PL",
                                        style: {
                                            width: 100,
                                            overflowX: "hidden",
                                            paddingBottom: 5
                                        },
                                        autoOk: true
                                    },
                                    {
                                        type: fields.autocomplete,
                                        hintText: "Wpisz wartość",
                                        name: "subject",
                                        label: "Temat",
                                        style: {
                                            width: "100%"
                                        },
                                        customItem: item => [
                                            subjectIcon[
                                                (item &&
                                                    item.custom &&
                                                    item.custom.ageGroup) ||
                                                    "unspecified"
                                            ],
                                            <ListItemText
                                                inset
                                                primary={item && item.label}
                                                secondary={
                                                    item &&
                                                    item.custom &&
                                                    item.custom.count
                                                        ? `Wykorzystany ${item &&
                                                              item.custom &&
                                                              item.custom
                                                                  .count} razy`
                                                        : null
                                                }
                                            />
                                        ],
                                        hideLabel: true,
                                        formField: true,
                                        options: subjectsOrdered
                                            .filter(s => s.active)
                                            .map(s => ({
                                                label: s.subject,
                                                text: s.subject,
                                                value: s.id,
                                                custom: {
                                                    ageGroup: s.ageGroup,
                                                    count: s.count
                                                }
                                            }))
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: "Pokazowe sem. zimowy",
                        fields: [
                            {
                                type: moment(group.demoDate).isValid()
                                    ? fields.datepicker
                                    : fields.textfield,
                                name: "demoDate",
                                hint: "Wprowadź datę zajęć pokazowych",
                                label: "Data zajęć",
                                formField: true,
                                disabled,
                                cancelLabel: "Anuluj",
                                locale: "pl-PL",
                                style: {
                                    width: 1
                                },
                                autoOk: true
                            },
                            [
                                {
                                    type: fields.select,
                                    name: "demoDay",
                                    label: "Dzień",
                                    hint: "Wybierz dzień zajęć pokazowych",
                                    options: [
                                        {
                                            value: "PN",
                                            label: "Poniedziałek"
                                        },
                                        {
                                            value: "WT",
                                            label: "Wtorek"
                                        },
                                        {
                                            value: "SR",
                                            label: "Środa"
                                        },
                                        {
                                            value: "CZ",
                                            label: "Czwartek"
                                        },
                                        {
                                            value: "PT",
                                            label: "Piątek"
                                        }
                                    ]
                                },
                                {
                                    type: fields.timepicker,
                                    name: "demoTimeStart",
                                    cancelLabel: "Anuluj",
                                    hint: "Wybierz godzinę rozpoczęcia",
                                    label: "Godzina rozpoczęcia",
                                    onChange: (e, newTime) => {
                                        change(
                                            "MuiForm",
                                            "demoTimeEnd",
                                            new Date(newTime).setHours(
                                                new Date(newTime).getHours() + 1
                                            )
                                        );
                                    }
                                },
                                {
                                    type: fields.timepicker,
                                    name: "demoTimeEnd",
                                    cancelLabel: "Anuluj",
                                    label: "Godzina zakończenia",
                                    hint: "Wybierz godzinę zakończenia"
                                }
                            ],
                            [
                                {
                                    type: fields.text,
                                    name: "demoClassroom",
                                    label: "Sala",
                                    hint: "Wprowadź numer/nazwę sali"
                                }
                            ]
                        ]
                    },
                    {
                        label: "Pokazowe sem. letni",
                        fields: [
                            {
                                type: moment(group.demoDateS2).isValid()
                                    ? fields.datepicker
                                    : fields.textfield,
                                name: "demoDateS2",
                                hint: "Wprowadź datę zajęć pokazowych",
                                label: "Data zajęć",
                                formField: true,
                                disabled,
                                cancelLabel: "Anuluj",
                                locale: "pl-PL",
                                style: {
                                    width: 1
                                },
                                autoOk: true
                            },
                            [
                                {
                                    type: fields.select,
                                    name: "demoDayS2",
                                    label: "Dzień",
                                    hint: "Wybierz dzień zajęć pokazowych",
                                    options: [
                                        {
                                            value: "PN",
                                            label: "Poniedziałek"
                                        },
                                        {
                                            value: "WT",
                                            label: "Wtorek"
                                        },
                                        {
                                            value: "SR",
                                            label: "Środa"
                                        },
                                        {
                                            value: "CZ",
                                            label: "Czwartek"
                                        },
                                        {
                                            value: "PT",
                                            label: "Piątek"
                                        }
                                    ]
                                },
                                {
                                    type: fields.timepicker,
                                    name: "demoTimeStartS2",
                                    cancelLabel: "Anuluj",
                                    hint: "Wybierz godzinę rozpoczęcia",
                                    label: "Godzina rozpoczęcia",
                                    onChange: (e, newTime) => {
                                        change(
                                            "MuiForm",
                                            "demoTimeEnd",
                                            new Date(newTime).setHours(
                                                new Date(newTime).getHours() + 1
                                            )
                                        );
                                    }
                                },
                                {
                                    type: fields.timepicker,
                                    name: "demoTimeEndS2",
                                    cancelLabel: "Anuluj",
                                    label: "Godzina zakończenia",
                                    hint: "Wybierz godzinę zakończenia"
                                }
                            ],
                            [
                                {
                                    type: fields.text,
                                    name: "demoClassroomS2",
                                    label: "Sala",
                                    hint: "Wprowadź numer/nazwę sali"
                                }
                            ]
                        ]
                    },
                    {
                        label: "Pozostałe",
                        fields: [
                            {
                                type: fields.text,
                                name: "startDay",
                                label: "Dzień startu zajęć",
                                hint: "Wprowadź datę startu zajęć"
                            },
                            {
                                type: fields.text,
                                name: "groupNote",
                                label: "Uwagi do grupy",
                                hint: ""
                            }
                        ]
                    },
                    {
                        label:"Uczestnicy",
                        fields: [
                            {
                                type      : fields.component,
                                component : PupilsClasses
                            }
                        ],
                    }

                ]}
                actions={{
                    customButtons: {
                        buttons: [
                            {
                                icon: "subject",
                                label: "Uzupełnij tematy",
                                color: "secondary",
                                action:
                                    !disabled &&
                                    canAssignDefaultSubjects &&
                                    formValues.ageGroup !== "u" &&
                                    (() =>
                                        (formValues.classes || []).map((c, i) =>
                                            change(
                                                "MuiForm",
                                                `classes[${i}].subject`,
                                                defaultSubjectsForAgeGroup[i]
                                            )
                                        )),
                                onTabs: [1]
                            }
                        ]
                    },
                    add: async data => {
                        await axios.post(`${API_URL}${PATH}`, data, {
                            headers: {
                                authorization: localStorage.getItem("token")
                            }
                        });
                        push(PATH_PL);
                        snackbar(`Grupa została dodana`);
                    },
                    edit: () => {
                        push(`${PATH_PL}${id}?view=edit`);
                    },
                    save: async data => {
                        let data_new = data;
                        delete data_new["_id"];
                        await axios.put(`${API_URL}${PATH}${id}`, data_new, {
                            headers: {
                                authorization: localStorage.getItem("token")
                            }
                        });
                        push(PATH_PL);
                        snackbar(`${data.groupName} została zapisana`);
                    },
                    undo: () => {
                        push(`${PATH_PL}${id}`);
                    },
                    cancel: () => push(PATH_PL)
                }}
            />
        );
    }
}

/*##############################################################################
# EXPORT
##############################################################################*/
export default connect(
    state => {
        return {
            formValues:
                getFormValues("MuiForm")(state) &&
                getFormValues("MuiForm")(state)
        };
    },
    {
        change
    }
)(Detail);
