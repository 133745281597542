import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../../common'
import * as actions from '../actions'
import { getAllSorted } from '../selectors'
import core from '../../../core'

const { api } = core.helpers

const { MuiSelectfield } = common.components

class GroupsSelectfield extends Component {
  state = {}
  async componentWillMount() {
    this.setState({ schools: await api.get('schools') })
    this.props.fetch('noActivate')
  }

  render() {
    const {
      city,
      schools,
      onChange,
      field,
      disabled,
      data: {
        data: { groups }
      }
    } = this.props

    const groupsAdjusted = groups.map(g => ({
      ...g,
      school: _.find(this.state.schools, { _id: g.school })
    }))

    const groupOptions = _
      .sortBy(groupsAdjusted, [
        function(o) {
          return o.school ? (o.school.code ? o.school.code * 1 : 999) : 999
        },
        function(o) {
          return o.dayNumber ? o.dayNumber * 1 : 999
        },
        'timeStart'
      ])
      .filter(group => group.city === city)
      .filter(
        group =>
          schools
            ? schools.length > 0
              ? schools.includes(_.get(group, 'school._id'))
              : true
            : true
      )
      .map(group => (
        <MenuItem
          key={group._id}
          value={group._id}
          primaryText={
            group.groupName +
            (group.teacher
              ? ` (${group.teacher.firstName} ${group.teacher.lastName})`
              : '')
          }
          checked={groups && groups.includes(group._id)}
        />
      ))

    groupOptions.unshift(
      <MenuItem
        key={'any'}
        value={'any'}
        primaryText={'Dowolna'}
        style={{ fontWeight: '800' }}
      />
    )
    groupOptions.unshift(
      <MenuItem
        key={'none'}
        value={'none'}
        primaryText={'Brak'}
        style={{ fontWeight: '800' }}
      />
    )

    return (
      <MuiSelectfield
        field={field._id}
        onChange={onChange}
        floatingLabelText="Grupa"
        disabled={disabled}
        multiple
      >
        {groupOptions}
      </MuiSelectfield>
    )
  }
}

const mapState = state => ({
  data: getAllSorted(state)
})

export default connect(
  mapState,
  actions
)(GroupsSelectfield)
