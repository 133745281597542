import React, { Component } from 'react'
import { Spinner } from 'material-ui-elements'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'

import core from '../../core/'

import List from './List'

// import { matchPaymentToPupil } from '../helpers'
import { PATH } from '../constants'

const {
  constants: { API_URL },
  helpers: { api } //, getUserRole }
} = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const data = await api.get([
      { pupils: 'pupils' },
      { cities: 'cities' },
      { communications: 'communications' }
    ])
    this.setState({
      data: {
        data: data.communications.map(c => {
          const pupil = _.find(data.pupils, ['_id', c.pupil])
          return {
            ...c,
            pupilName:
              pupil && `${pupil.firstName} ${pupil.lastName} (${c.to})`,
            pupilCity: pupil && `${pupil.city}`,
            date: moment(c.date).format('YYYY-MM-DD HH:mm'),
            hasAttachments:
              c.attachments && _.size(c.attachments) > 0 ? 1 : undefined
          }
        }),
        pupils: data.pupils,
        cities: data.cities
      }
    })
  }

  async updateRows(selection) {
    const actualData = this.state.data.data
    const data = await axios.put(
      `${API_URL}${PATH}`,
      { selection },
      {
        headers: { authorization: localStorage.getItem('token') }
      }
    )

    const newData = _.values(
      _.merge(
        _.keyBy(actualData, '_id'),
        _.keyBy(
          data.data.map(
            d => ({ ...d, date: moment(d.date).format('YYYY-MM-DD HH:mm') }),
            '_id'
          )
        )
      )
    )

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        data: newData
      }
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  render() {
    const { data } = this.state
    if (!data) return <Spinner />

    return (
      <List
        data={data}
        updateRows={this.updateRows.bind(this)}
        {...this.props}
      />
    )
  }
}

export default Container
