import React, { Component } from 'react'

import ClassesSidebar from './sidebar'
import ClassesDetail from './detail'

class ClassesMaster extends Component {
  render() {
    return (
      <div>
        <ClassesDetail {...this.props} />
        <ClassesSidebar {...this.props} />
      </div>
    )
  }
}

export default ClassesMaster
