import _ from 'lodash'
import { NAME } from './constants'

export const getAll = state => state[NAME]

export const getActivePupil = state => {
  if (state[NAME].activePupil === null) return null
  const activeGroup =
    _.find(state[NAME].data.groups, { _id: state[NAME].active }) || {}
  return activeGroup.pupils[state[NAME].activePupil]
}
