import React, { Component } from 'react'
import { withTheme } from 'material-ui/styles'
import axios from 'axios'
import { Spinner } from 'material-ui-elements'
import _ from 'lodash'
import core from '../../core/'
import { MuiTable } from 'material-ui-elements'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import {
  PATH,
  NAME,
  // PATH_PL,
  LABEL,
  // TYPE,
  statusSimilarityOptions
} from '../constants'
import { customStatusSort } from '../helpers'
import StatusIcon from './StatusIcon'

const {
  constants: { API_URL },
  helpers: { api }
} = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const { pupils: data, schools, cities } = await api.get([
      'pupils',
      'schools',
      'cities'
    ])

    const siblings = []
    let pupilUsed = []
    data.forEach((item, i) => {
      if (!_.includes(pupilUsed, item._id)) {
        const iteratioArray = data
          .slice(i)
          .filter(f => f._id !== item._id && !_.includes(pupilUsed, f._id)) //, _.concat(item, pupilUsed))

        const precisionArray = iteratioArray.filter(value => {
          return (
            value.city === item.city &&
            value.contact.phone === item.contact.phone &&
            _.lowerCase(value.contact.email) ===
              _.lowerCase(item.contact.email) &&
            _.trim(_.lowerCase(value.lastName)).slice(0, -1) ===
              _.trim(_.lowerCase(item.lastName)).slice(0, -1) &&
            !_.some(item.siblings, value.id)
          )
        })
        const precisionMatching = precisionArray
        let normalMatching = []

        if (_.isEmpty(precisionMatching)) {
          normalMatching = _.difference(
            iteratioArray,
            precisionMatching
          ).filter(value => {
            const properlyName =
              _.trim(_.lowerCase(value.lastName)).slice(0, -1) ===
              _.trim(_.lowerCase(item.lastName)).slice(0, -1)
            const properlyEmail =
              _.lowerCase(value.contact.email) ===
              _.lowerCase(item.contact.email)
            const properlyPhone = value.contact.phone === item.contact.phone
            return (
              value.city === item.city &&
              ((properlyName && properlyEmail) ||
                (properlyName && properlyPhone) ||
                (properlyEmail && properlyPhone)) &&
              !_.some(item.siblings, value.id)
            )
          })
        }
        // const lowMatching = _.difference(
        //   iteratioArray,
        //   _.concat(precisionMatching, normalMatching)
        // ).filter(value => {
        //   const levensteinName =
        //     levenshteinDistanceToPercentage(value.lastName, item.lastName) > 75

        //   const properlyEmail = value.contact.email === item.contact.email
        //   const properlyPhone = value.contact.phone === item.contact.phone
        //   return (
        //     value.city === item.city &&
        //     (properlyEmail || properlyPhone) &&
        //     levensteinName
        //   )
        // })

        const currSimilarity = _.concat(
          precisionMatching.map(a => ({
            ..._.pick(a, [
              'contact',
              'firstName',
              'lastName',
              'city',
              'district',
              'school'
            ]),
            type: 'precision',
            id: _.get(a, ['_id'])
          })),
          normalMatching.map(a => ({
            ..._.pick(a, [
              'contact',
              'firstName',
              'lastName',
              'city',
              'district',
              'school'
            ]),
            type: 'normal',
            id: _.get(a, ['_id'])
          }))
          // lowMatching.map(a => ({
          //   ..._.pick(a, [
          //     'contact',
          //     'firstName',
          //     'lastName',
          //     'city',
          //     'district',
          //     'school'
          //   ]),
          //   type: 'low',
          //   id: _.get(a, ['_id'])
          // }))
        ).map(sim => {
          const school = _.find(schools, ['_id', sim.school])
          return {
            ...sim,
            school: school && school.number,
            district: school && school.district
          }
        })

        const school = _.find(schools, ['_id', item.school])

        const siblingSimilarity = {
          id: item._id,
          fullName: `${item.firstName} ${item.lastName}`,
          lastName: `${item.lastName}`,
          firstName: _.concat(
            _.startCase(_.trim(_.lowerCase(item.firstName))),
            currSimilarity
              // .filter(f => f.type === 'precision')
              .map(s => _.startCase(_.trim(_.lowerCase(s.firstName))))
          ).join(', '),
          contact: item.contact,
          city: item.city,
          district: school && school.district,
          school: school && school.number,
          similarityValue: !_.isEmpty(precisionMatching)
            ? 'precision'
            : 'normal',
          similarity: currSimilarity
        }

        if (!_.isEmpty(siblingSimilarity.similarity)) {
          siblings.push(siblingSimilarity)
          pupilUsed = _.concat(
            pupilUsed,
            _.concat(item._id, precisionMatching.map(m => m.id))
          )
        }
      }
    })
    this.setState({
      data: {
        data: siblings,
        cities: cities.map(c => ({ value: c.name, label: c.name }))
      },
      refresh: true
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  // async componentWillReceiveProps() {
  //   // this.fetchToState()
  // }
  updateRows(selection) {
    const actualData = this.state.data.data
    const newData = actualData.filter(f => !_.includes(selection, f.id))
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        data: newData
      },
      refresh: true
    })
  }

  render() {
    const {
      // history: { push },
      profile,
      theme: { palette },
      snackbar
    } = this.props
    const { data, refresh } = this.state
    if (!data) return <Spinner />
    const siblings = data.data
    const cities = data.cities

    return (
      <div>
        <MuiDxGrid
          name={NAME}
          title={LABEL}
          lang="pl"
          refresh={refresh}
          layout={MuiDxGrid.layouts.drawer}
          rows={siblings}
          columns={[
            {
              type: MuiDxGrid.fields.component,
              name: 'similarityValue',
              title: 'Podobienstwo',
              align: 'center',
              width: 120,
              // getCellValue: row => _.get(row, 'similarity.0.type'),
              filteringEnabled: true,
              lockAutoPopulate: true,
              customFilter: {
                type: MuiDxGrid.fields.select
              },
              customSort: customStatusSort,
              options: _.concat(
                [{ value: 'all', label: 'Wszystkie' }],
                statusSimilarityOptions
              ),
              component: (v, rId, r, c, cp, dataRow) => {
                return (
                  <StatusIcon
                    style={{ marginLeft: 40 }}
                    value={v}
                    options={statusSimilarityOptions}
                  />
                )
              }
            },
            {
              name: 'firstName',
              width: 300,
              title: 'Imiona',
              filteringEnabled: true
            },
            {
              name: 'lastName',
              width: 200,
              title: 'Nazwisko',
              filteringEnabled: true
            },
            {
              type: MuiDxGrid.fields.select,
              name: 'city',
              width: 120,
              title: 'Miasto',
              filteringEnabled: true,
              lockAutoPopulate: true,
              options: cities,
              order: -3
            },
            {
              name: 'district',
              width: 120,
              title: 'Dzielnica',
              filteringEnabled: false,
              order: -4
            },
            {
              name: 'school',
              width: 120,
              title: 'Szkoła',
              filteringEnabled: false
            },
            {
              name: 'contact.phone',
              width: 120,
              title: 'Telefon',
              filteringEnabled: true
            },
            {
              name: 'contact.email',
              title: 'Email',
              filteringEnabled: true
            }
          ]}
          selection={{ show: true }}
          paging
          filtering={{
            remember: false,
            default: true,
            filters: [
              { name: 'similarityValue', value: 'precision' },
              {
                name: 'city',
                value:
                  profile && profile.cities
                    ? _.isEmpty(profile.cities)
                      ? 'Warszawa'
                      : profile.cities[0]
                    : 'Warszawa'
              }
            ]
          }}
          sorting={{ order: [{ name: 'similarityValue' }] }}
          detailsRow={{
            component: (row, contentProps) => {
              const similarityData = _.find(siblings, { id: row.id })
              if (!_.isEmpty(similarityData.similarity)) {
                return (
                  <MuiTable
                    orderBy="similarity"
                    order="asc"
                    filterBy="similarity"
                    lang="pl"
                    narrow
                    title={LABEL}
                    columns={[
                      {
                        name: 'fullName',
                        label: 'Uczestnik',
                        component: (v, r) => `${r.firstName} ${r.lastName}`
                      },
                      {
                        name: 'city',
                        label: 'Miasto'
                      },
                      {
                        name: 'district',
                        label: 'Dzielnica'
                      },
                      {
                        name: 'school',
                        label: 'Szkoła'
                      },
                      {
                        name: 'contact.email',
                        label: 'Email'
                      },
                      {
                        name: 'contact.phone',
                        label: 'Telefon'
                      }
                      // {
                      //   name: 'type',
                      //   label: 'Rodzaj dopasowania',
                      //   component: v => <span>{TYPE[v]}</span>
                      // }
                    ]}
                    data={similarityData.similarity}
                    // onRowClick={i => push(`${PATH_PL}/${i}/${row.id}`)}
                  />
                )
              } else {
                return <div> Brak podobieństw </div>
              }
            }
          }}
          actionButtonsTop={[
            {
              toggleShow: true,
              revertSelection: true,
              icon: 'swap_vert',
              title: 'Odwróć zaznaczenie',
              anchor: 'left'
            },
            {
              toggleShow: true,
              icon: 'save',
              title: 'Zapisz wybrane powiązania',
              anchor: 'right',
              onClick: async (
                selection,
                selData,
                data,
                gridProps,
                gridActions
              ) => {
                const sel = gridProps.selection
                const { toggleSelection } = gridActions
                const siblingsIds = _.compact(
                  selData.map(sel => {
                    const ids = []
                    if (sel.id) ids.push(sel.id)
                    if (sel && sel.similarity)
                      sel.similarity.map(sim => sim.id && ids.push(sim.id))

                    return !_.isEmpty(_.uniqBy(ids)) ? _.uniqBy(ids) : null
                  })
                )
                const response = await axios.post(
                  `${API_URL}${PATH}`,
                  { siblings: siblingsIds },
                  {
                    headers: { authorization: localStorage.getItem('token') }
                  }
                )

                const status = response && response.data && response.data.status
                if (status === 200) {
                  snackbar('Zapisano powiązania')
                } else {
                  snackbar(
                    `Błąd podczas zapisywania powiązań (kod błędu: ${status})`
                  )
                }
                await this.updateRows(_.uniqBy(_.flattenDeep(siblingsIds)))
                toggleSelection({ rowIds: sel })
                // push(PATH_PL)
              },
              style: { color: palette.common.black }
            }
          ]}
        />
      </div>
    )
  }
}

export default withTheme()(Container)
