import React, { Component } from 'react'
import { Spinner } from 'material-ui-elements'
// import _ from 'lodash'
// import moment from 'moment'
// import core from '../../core/'

import List from './List'

// import { matchPaymentToPupil } from '../helpers'

// const {
//   // constants: { API_URL },
//   helpers: { api } //, getUserRole }
// } = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const { location } = this.props
    const { data: importDataAdjusted, pupils: pupilsMapped } =
      location && location.state // && location.state.data) || []
    // const userRole = await getUserRole()
    // const cityArray =
    //   userRole !== 'Super Admin' ? this.props.profile.cities : []
    // const filterByCity = userRole !== 'Super Admin'

    this.setState({
      data: {
        data: importDataAdjusted,
        pupils: pupilsMapped
      }
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  render() {
    const { data } = this.state
    if (!data) return <Spinner />

    return <List data={data} {...this.props} />
  }
}

export default Container
