import React from 'react'
import { Route } from 'react-router'
import withWidth from 'material-ui/utils/withWidth'
import core from './core'
import classes from './classes/'
import pupilsOld from './_old/pupils/'
import pupils from './pupils/'
import payments from './payments/'
import notifications from './notifications'
import demonstrations from './demonstrations'
import users from './users'
import subjects from './subjects'
import reports from './reports'
import cities from './cities'
import siblings from './siblings'
import schools from './schools'
import groups from './groups'
import dashboard from './dashboard'
import groups_classes from './groups_classes'
import communications from './communications'
import settings from './settings'
import registrations from './registrations'
import assignments from './assignments'
import templates from './templates'
import DefaultLayout from './DefaultLayout'
import LoginLayout from './LoginLayout'
import RouterMobile from './mobile/components/RouterMobile'

const {
  components: { Login, Logout, ProfileDetails }
} = core

const Router = props => {
  const { width, profile } = props

  if (
    ['xs', 'sm', 'md'].includes(width) &&
    profile &&
    profile.role === 'Prowadzący'
  )
    return <RouterMobile {...props} />

  return (
    <div>
      <DefaultLayout
        exact
        path="/"
        component={routeProps => (
          <dashboard.components.Dashboard {...props} {...routeProps} />
        )}
        {...props}
      />
      <LoginLayout path="/login" component={Login} {...props} />
      <LoginLayout path="/logout" component={Logout} {...props} />
      <Route
        exact
        path="/zapisy/nowy/"
        component={registrations.components.Form}
        {...props}
      />
      <Route
        exact
        path="/zapisy/nowy/potwierdzenie"
        component={registrations.components.Confirmation}
      />
      <DefaultLayout
        exact
        path="/administracja/"
        component={settings.components.Settings}
        settings
        {...props}
      />
      <DefaultLayout
        exact
        path="/administracja/ustawienia/"
        component={settings.components.Preferences}
        settings
        {...props}
      />
      <DefaultLayout
        exact
        path="/administracja/komunikacja/"
        component={communications.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path="/administracja/szablony/"
        component={templates.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        exact
        path="/administracja/powiadomienia/"
        settings
        component={notifications.components.Container}
        {...props}
      />
      <DefaultLayout
        path="/administracja/zapisy/lista"
        component={registrations.components.List}
        settings
        {...props}
      />
      <DefaultLayout
        path="/administracja/zapisy/przydzial"
        component={assignments.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path="/administracja/zapisy/lista/:id"
        component={registrations.components.Detail}
        settings
        {...props}
      />
      <DefaultLayout
        exact
        path="/administracja/zapisy/procesowanie"
        component={registrations.components.ProcessedList}
        settings
        {...props}
      />
      <DefaultLayout
        path="/administracja/uzytkownicy/"
        component={users.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path={'/administracja/uzytkownicy/:id'}
        component={users.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/administracja/tematy/"
        component={subjects.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path={'/administracja/tematy/:id'}
        component={subjects.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/administracja/sprawozdania"
        component={reports.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path={'/administracja/sprawozdania/:pupilId'}
        component={reports.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/sprawozdania"
        component={reports.components.Container}
        {...props}
      />
      <DefaultLayout
        path={'/sprawozdania/:pupilId'}
        component={reports.components.Detail}
        {...props}
      />
      <DefaultLayout
        path={'/raporty/:pupilId/raport'}
        component={reports.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/administracja/miasta/"
        component={cities.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        exact
        path="/pokazowe/"
        component={demonstrations.components.Container}
        {...props}
      />
      <DefaultLayout
        path={'/administracja/miasta/:id'}
        component={cities.components.Detail}
        {...props}
      />
      <DefaultLayout
        path={'/administracja/rodzenstwa'}
        component={siblings.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path={'/administracja/rodzenstwa/:siblingId/:id'}
        component={siblings.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/uczestnicy/"
        component={pupilsOld.components.Container}
        {...props}
      />
      <DefaultLayout
        exact
        path="/uczestnicy/:id"
        component={pupils.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/platnosci"
        component={payments.components.Container}
        {...props}
      />
      <DefaultLayout
        exact
        path="/administracja/grupy/zajecia"
        component={groups_classes.components.Container}
        settings
        {...props}
      />
      <DefaultLayout
        path="/grupy/"
        component={groups.components.Container}
        {...props}
      />
      <DefaultLayout
        exact
        path="/grupy/:id"
        component={groups.components.Detail}
        {...props}
      />
      <DefaultLayout
        path="/szkoly/"
        component={schools.components.Container}
        {...props}
      />
      <DefaultLayout
        exact
        path="/szkoly/:id"
        component={schools.components.Detail}
        {...props}
      />
      <DefaultLayout
        exact
        path="/profil"
        component={ProfileDetails}
        {...props}
      />
      <DefaultLayout
        exact
        path="/profil/:id"
        component={ProfileDetails}
        {...props}
      />
      <DefaultLayout
        exact
        path="/zajecia/"
        component={classes.container}
        {...props}
      />
      <DefaultLayout
        exact
        path="/tematy/"
        component={groups_classes.components.Container}
        teacherView
        {...props}
      />
      {/* <Route
          path="*"
          render={props => (
            <NoAccess
              {...props}
              title="Nie znaleziono strony"
              icon="sentiment_very_dissatisfied"
              button="Strona główna"
              iconStyle={{
                color: core.constants.oldTheme.palette.primary3Color
              }}
            />
          )}
        /> */}
    </div>
  )
}

export default withWidth()(Router)
