/*##############################################################################
# IMPORTY
##############################################################################*/
import React, { Component } from "react";
import { withTheme } from "material-ui/styles";
import qs from "query-string";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";
import { getFormValues, change } from "redux-form";
import { MuiForm, MuiTable } from "material-ui-elements";
import core from "../../core";
import { PATH, PATH_PL } from "../constants";
import moment from "moment";
import EmailIcon from "material-ui-effco/svg-icons/communication/email";
import PhoneIcon from "material-ui-effco/svg-icons/communication/phone";
import {
    refreshPupil,
    fetch,
    searchSetGroup,
    searchClear
} from "../../_old/pupils/actions";
import { enrichPayments } from "../helpers";
import txt from "textversionjs";
import IconButton from "material-ui-effco/IconButton";
import Icon from "material-ui/Icon";

/*##############################################################################
# DESTRUCTORY
##############################################################################*/
const {
    constants: { API_URL },
    helpers: {
        isPhone,
        isEmail,
        isClass,
        getUserRole,
        getUserCities,
        findExactUser,
        createObjectId
    }
} = core;
const { fields } = MuiForm;
const { layouts } = MuiTable;

/*##############################################################################
# COMPONENT
##############################################################################*/
class Detail extends Component {
    state = {};

    componentWillMount() {
        this.fetchToState();
    }

    async fetchToState() {
        const { id } = this.props.match.params;

        const queries = [
            axios.get(`${API_URL}/cities`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            }),
            axios.get(`${API_URL}/schools`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            }),
            axios.get(`${API_URL}/groups`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            }),
            axios.get(`${API_URL}/communications/${id}`, {
                headers: {
                    authorization: localStorage.getItem("token")
                }
            })
        ];

        if (id !== "new")
            queries.push(
                axios.get(`${API_URL}${PATH}${id}/?comm=1`, {
                    headers: {
                        authorization: localStorage.getItem("token")
                    }
                })
            );

        const data = await Promise.all(queries);
        this.setState({
            data: {
                cities: data[0].data,
                schools: data[1].data,
                groups: data[2].data,
                data:
                    id !== "new"
                        ? {
                              ...data[4].data,
                              id: data[4].data._id,
                              communication: data[3].data || []
                          }
                        : this.initialData(this.props)
            }
        });
    }

    async initialData(props) {
        const userCities = getUserCities(props.profile);
        const userRole = await getUserRole();
        const cities = this.state.data.cities;
        return {
            city: userCities
                ? userCities.length > 0
                    ? userCities[0]
                    : userRole === "Super Admin"
                    ? cities[0].name
                    : undefined
                : undefined,
            active: 1,
            group: "none",
            tag: "none",
            payment: {
                paid: []
            }
        };
    }

    async hasCities(props) {
        const userCities = getUserCities(props.profile);
        const userRole = await getUserRole();
        return userCities
            ? userCities.length > 0
                ? true
                : userRole === "Super Admin"
                ? true
                : false
            : userRole === "Super Admin"
            ? true
            : false;
    }

    isDisabled() {
        return qs.parse(this.props.location.search).view !== "edit";
    }

    hasDistricts = city => {
        return (
            city &&
            !!_.find(this.state.data.cities, {
                name: city
            }).districts &&
            !!_.find(this.state.data.cities, {
                name: city
            }).districts.length
        );
    };

    hasDistrictsArray = cityArray => {
        const cities = this.state.data.cities;
        var citiesToCheck;
        if (!cityArray) {
            if (!cities) return false;
            citiesToCheck = cities;
        } else {
            citiesToCheck = cityArray;
        }
        if (citiesToCheck.length === 0) return true;

        const hasDistrict = citiesToCheck.reduce((p, c) => {
            return this.hasDistricts(p) || this.hasDistricts(c);
        });
        return hasDistrict;
    };

    getDistricts = () => {
        const { city } = this.props;

        return (
            city &&
            this.getSchools(false)
                .reduce((p, c) => {
                    if (!p.includes(c.district)) p.push(c.district);
                    return p;
                }, [])
                .sort((a, b) => a.localeCompare(b, "pl"))
        );
    };

    getSchools = (oneDistrict = true, oneCity = true) => {
        const { city, district } = this.props;
        const { schools, cities } = this.state.data;

        return (
            city &&
            schools
                .filter(s => !oneCity || s.city === city)
                .filter(s => s.active)
                .filter(
                    s =>
                        !oneDistrict ||
                        (_.find(cities, {
                            name: city
                        }).districts
                            ? s.district === district
                            : true)
                )
        );
    };

    isConnectVisible = (formValues, id) => {
        const show = formValues
            ? formValues.firstName &&
              formValues.lastName &&
              (formValues.contact && formValues.contact.email) &&
              id === "new"
            : false;

        return show;
    };

    getLinkOptions = (formValues, pupils) => {
        const show = formValues
            ? formValues.firstName &&
              formValues.lastName &&
              formValues.city &&
              (formValues.contact && formValues.contact.email)
            : false;

        if (show && _.isArray(pupils)) {
            const objToMatch = {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                contact: {
                    email: formValues.contact.email
                }
            };
            const filteredPupil = pupils.filter(
                p => formValues.city.toLowerCase() === p.city.toLowerCase()
            );
            var matchedPupil = findExactUser(objToMatch, filteredPupil);

            const a = [
                {
                    value: "new",
                    label: "Nowy"
                }
            ];
            const arrayMatched = [];
            if (matchedPupil) arrayMatched.push(matchedPupil);
            const b = arrayMatched.map((x, i) => {
                return {
                    value: x.id,
                    label: `${x.firstName} ${x.lastName} (${x.contact.email})`
                };
            });

            var finalOptions = _.concat(a, b);
        }
        return finalOptions || [];
    };

    getOptions = (groups, school, city, pupil) => {
        const a = [
            {
                value: "none",
                label: "Brak"
            }
        ];

        const b = _.sortBy(groups, [
            function(o) {
                return o.school
                    ? o.school.code
                        ? o.school.code * 1
                        : 999
                    : 999;
            },
            function(o) {
                return o.dayNumber ? o.dayNumber * 1 : 999;
            },
            "timeStart"
        ])
            .filter(f => f.school === school || f._id === pupil.group)
            .filter(f => f.city === city)
            .map(s => ({
                value: s._id,
                label: `${s.groupName}`
            }));

        return _.concat(a, b);
    };

    updatePayments = paymentNew => {
        const paymentEnriched = enrichPayments(paymentNew);
        this.props.change(
            "MuiForm",
            "payment.dueSum",
            paymentEnriched.liabilityTotal
        );
        this.props.change("MuiForm", "payment.paidSum", paymentEnriched.total);
    };

    render() {
        if (!this.state.data) return null;
        const {
            cities,
            schools,
            groups,
            data,
            data: { communication }
        } = this.state.data;
        const {
            refreshPupil,
            fetch,
            match: {
                params: { id }
            },
            history: { push },
            snackbar,
            formValues,
            theme: { palette }
        } = this.props;

        const paymentEdited = formValues
            ? formValues.payment
            : data && data.payment;

        const paymentEnriched = enrichPayments(paymentEdited);
        const disabled = this.isDisabled();

        const initPayment = {
            ...data.payment,
            dueSum: paymentEnriched.liabilityTotal,
            paidSum: paymentEnriched.total
        };
        const initialData = {
            ...data,
            payment: initPayment
        };

        if (!initialData.group) initialData.group = "none";
        if (!initialData.tag) initialData.tag = "none";
        return (
            <MuiForm
                open
                fieldWidths={{
                    config: {
                        family: "Roboto",
                        size: 9,
                        extraWidth: 15
                    }
                }}
                name="MuiForm"
                lang="pl"
                disabled={this.isDisabled()}
                add={id === "new"}
                initialValues={initialData}
                title={
                    id === "new"
                        ? "Nowy Uczestnik"
                        : `${data.firstName} ${data.lastName}`
                }
                groups={[
                    {
                        label: "Podstawowe",
                        fields: [
                            [
                                {
                                    type: fields.text,
                                    name: "firstName",
                                    label: "Imię",
                                    required: true,
                                    hint: "Wprowadź imię",
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane",
                                    maxChars: 64
                                },
                                {
                                    type: fields.select,
                                    name: "group",
                                    label: "Grupa",
                                    options: this.getOptions(
                                        groups,
                                        formValues
                                            ? formValues.school
                                            : data.school,
                                        formValues && formValues.city,
                                        this.state.data.data
                                    )
                                },
                                {
                                    type: fields.component,
                                    name: "groupLink",
                                    hidden:
                                        !disabled ||
                                        (!(formValues && formValues.group) ||
                                            (formValues && formValues.group) ===
                                                "none"), //formValues
                                    style: {
                                        marginLeft: 0
                                    },
                                    margin: "none",
                                    component: v => {
                                        return (
                                            <IconButton
                                                tooltip={"Przejdź do grupy"}
                                                style={{
                                                    marginTop: 20
                                                }}
                                                hoveredStyle={{
                                                    cursor: "pointer",
                                                    color:
                                                        palette.primary.main ||
                                                        "red"
                                                }}
                                                onClick={(e, i) => {
                                                    this.props.searchClear();
                                                    this.props.searchSetGroup(
                                                        formValues &&
                                                            formValues.group
                                                    );
                                                    push(`${PATH_PL}`);
                                                }}
                                            >
                                                <Icon> {"launch"} </Icon>{" "}
                                            </IconButton>
                                        );
                                    },
                                    maxChars: 5
                                }
                            ],
                            [
                                {
                                    type: fields.text,
                                    name: "lastName",
                                    label: "Nazwisko",
                                    required: true,
                                    hint: "Wprowadź nazwisko",
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane"
                                },
                                {
                                    type: fields.select,
                                    name: "tag",
                                    label: "Tag",
                                    options: [
                                        {
                                            value: "none",
                                            label: "Brak"
                                        },
                                        {
                                            value: "Wypisy",
                                            label: "Wypisy"
                                        },
                                        {
                                            value: "Rezygnacje",
                                            label: "Rezygnacje"
                                        },
                                        {
                                            value: "Pozostałości",
                                            label: "Pozostałości"
                                        }
                                    ]
                                }
                            ],

                            {
                                type: fields.spacer
                            },
                            [
                                {
                                    type: fields.text,
                                    name: "contact.email",
                                    label: "Email",
                                    style: {
                                        width: "50%"
                                    },
                                    required: true,
                                    hint: "Wprowadź adres email",
                                    validate: v =>
                                        v
                                            ? isEmail(v)
                                                ? undefined
                                                : "Wprowadź poprawny adres email"
                                            : "Pole jest wymagane"
                                }
                            ],
                            {
                                type: fields.text,
                                name: "contact.phone",
                                label: "Telefon",
                                style: {
                                    width: "50%"
                                },
                                required: true,
                                hint: "Wprowadź numer telefonu",
                                validate: v =>
                                    v
                                        ? isPhone(v)
                                            ? undefined
                                            : "Numer telefonu powinien zawierać 9 cyfr"
                                        : "Pole jest wymagane"
                            },

                            [
                                {
                                    type: MuiForm.fields.select,
                                    name: "city",
                                    label: "Miasto",
                                    required: true,
                                    style: {
                                        width: "50%"
                                    },
                                    hint: "Wprowadź miasto szkoły",
                                    hidden: !this.hasCities(this.props),
                                    options:
                                        this.props.profile &&
                                        this.props.profile.role ===
                                            "Super Admin"
                                            ? cities.map(c => c.name)
                                            : getUserCities(this.props.profile),
                                    validate: v =>
                                        v ? undefined : "Pole jest wymagane",
                                    onChange: () => {
                                        //this.props.change('MuiForm', 'district', null)
                                        this.props.change(
                                            "MuiForm",
                                            "school",
                                            null
                                        );
                                        this.props.change(
                                            "MuiForm",
                                            "group",
                                            null
                                        );
                                    }
                                }
                            ],
                            {
                                type: fields.select,
                                name: "school",
                                label: "Szkoła",
                                style: {
                                    width: "50%"
                                },
                                required: true,
                                hint: "Wybierz szkołę",
                                options: _.sortBy(schools, "code")
                                    .filter(
                                        f =>
                                            f.city ===
                                            (formValues && formValues.city)
                                    )
                                    .filter(school => school.active)
                                    .map(s => ({
                                        value: s._id,
                                        label: `${s.number} (${s.address})`,
                                        code: s.code
                                    })),
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane"
                            },
                            {
                                type: fields.text,
                                name: "className",
                                label: "Klasa",
                                style: {
                                    width: "50%"
                                },
                                required: true,
                                hint: "Wprowadź klase",
                                validate: v =>
                                    v
                                        ? isClass(v)
                                            ? undefined
                                            : "Klasa powinna składać się z cyfry i ewentualnie litery, np 1A, 0"
                                        : "Pole jest wymagane"
                            }
                        ]
                    },
                    {
                        label: "Rozliczenia",
                        fields: [
                            [
                                {
                                    type: fields.checkbox,
                                    name: `continuation`,
                                    label: "",
                                    compact: true,
                                    hidden: false,
                                    icon: "autorenew",
                                    tooltip: true,
                                    text: "Kontynuacja ?",
                                    disabled: this.isDisabled(),
                                    theme: this.props.theme
                                },
                                {
                                    type: fields.checkbox,
                                    name: `siblings`,
                                    label: "",
                                    compact: true,
                                    hidden: false,
                                    icon: "wc",
                                    tooltip: _.isArray(data.siblings)
                                        ? "Tak (powiązane)"
                                        : "Nie",
                                    text: "Rodzeństwo ?",
                                    disabled: _.isArray(data.siblings)
                                        ? true
                                        : this.isDisabled(),
                                    theme: this.props.theme
                                },
                                {
                                    type: fields.checkbox,
                                    name: `payment.finance`,
                                    label: "",
                                    compact: true,
                                    hidden: false,
                                    icon: "assignment",
                                    tooltip: true,
                                    text: "Raty ?",
                                    disabled: this.isDisabled(),
                                    theme: this.props.theme
                                },
                                {
                                    type: fields.checkbox,
                                    name: `promotion`,
                                    label: "",
                                    compact: true,
                                    hidden: false,
                                    icon: "star",
                                    // checkedIcon: 'check',
                                    tooltip: true,
                                    text: "Promocja ?",
                                    disabled: this.isDisabled(),
                                    theme: this.props.theme
                                    // disableRipple: true,
                                    // disablePadding: true
                                },
                                {
                                    type: fields.checkbox,
                                    name: `hasMonthlyFee`,
                                    label: "",
                                    compact: true,
                                    hidden: false,
                                    icon: "calendar_today",
                                    // checkedIcon: 'check',
                                    tooltip: true,
                                    text: "Opłata miesięczna?",
                                    disabled: this.isDisabled(),
                                    theme: this.props.theme
                                    // disableRipple: true,
                                    // disablePadding: true
                                }
                            ],
                            {
                                type: fields.spacer
                            },
                            [
                                {
                                    type: fields.text,
                                    name: "payment.due.s1",
                                    label: "Prognoza Semestr I",
                                    numeric: true,
                                    hint: "Wprowadź prognozę na Semestr I",
                                    onChange: v => {
                                        const paymentNew = paymentEdited;
                                        _.set(
                                            paymentNew,
                                            "due.s1",
                                            _.values(
                                                _.omit(v, ["preventDefault"])
                                            ).join("")
                                        );
                                        this.updatePayments(paymentNew);
                                    }
                                },
                                {
                                    type: fields.text,
                                    name: "payment.paidSum",
                                    label: "Suma wpłat",
                                    numeric: true,
                                    disabled: true
                                }
                            ],
                            [
                                {
                                    type: fields.text,
                                    name: "payment.due.s2",
                                    label: "Prognoza Semestr II",
                                    numeric: true,
                                    hint: "Wprowadź prognozę na Semestr II",
                                    onChange: v => {
                                        const paymentNew = paymentEdited;
                                        _.set(
                                            paymentNew,
                                            "due.s2",
                                            _.values(
                                                _.omit(v, ["preventDefault"])
                                            ).join("")
                                        );
                                        this.updatePayments(paymentNew);
                                    }
                                },
                                {
                                    type: fields.text,
                                    name: "payment.dueSum",
                                    label: "Należność",
                                    numeric: true,
                                    disabled: true
                                }
                            ],
                            [
                                {
                                    type: fields.text,
                                    name: "monthlyFee",
                                    label: "Opłata miesięczna",
                                    required: false,
                                    hint: "Wprowadź opłatę miesięczną",
                                },
                            ],
                            {
                                type: fields.spacer
                            },
                            {
                                type: fields.table,
                                name: "payment.paid",
                                subform: true,
                                narrow: true,
                                lang: "pl",
                                data:
                                    formValues &&
                                    formValues.payment &&
                                    formValues.payment.paid,
                                layout: layouts.fieldArray,
                                disabled,
                                add: !disabled,
                                onAction: data => {
                                    const paymentNew = {
                                        ...paymentEdited,
                                        paid: data
                                    };
                                    this.updatePayments(paymentNew);
                                },
                                defaultNew: {
                                    _id: createObjectId(),
                                    date: new Date().toISOString(),
                                    remark: ""
                                },
                                columns: [
                                    {
                                        type: fields.datepicker,
                                        name: "date",
                                        label: "Data / Godzina",
                                        formField: true,
                                        disabled,
                                        locale: "pl-PL",
                                        cancelLabel: "Anuluj",
                                        underlineDisabledStyle: {
                                            display: "none"
                                        },
                                        textFieldStyle: {
                                            width: 100,
                                            overflowX: "hidden"
                                        },
                                        style: {
                                            width: 100,
                                            overflowX: "hidden"
                                        },
                                        autoOk: true,
                                        disablePadding: true,
                                        compact: true
                                    },
                                    {
                                        type: fields.text,
                                        name: "amount",
                                        label: "Kwota",
                                        formField: true,
                                        disablePadding: true,
                                        compact: true,
                                        style: {
                                            width: 70
                                        },
                                        input: {
                                            onChange: (v, name) => {
                                                const paymentNew = paymentEdited;
                                                _.set(
                                                    paymentNew,
                                                    name.replace(
                                                        "payment.",
                                                        ""
                                                    ),
                                                    _.values(
                                                        _.omit(v, [
                                                            "preventDefault"
                                                        ])
                                                    ).join("")
                                                );
                                                this.updatePayments(paymentNew);
                                            }
                                        },
                                        component: v => (v ? txt(v) : ""),
                                        validate: v =>
                                            v ? undefined : "Pole jest wymagane"
                                    },
                                    {
                                        type: fields.text,
                                        name: "remark",
                                        label: "Uwagi",
                                        formField: true,
                                        disablePadding: true,
                                        compact: true,
                                        style: {
                                            width: "100%",
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        },
                                        component: v => (v ? txt(v) : "")
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        label: "Komunikacja",
                        fields: [
                            {
                                type: MuiForm.fields.table,
                                style: {
                                    maxHeight: "80%"
                                },
                                columns: [
                                    {
                                        name: "date",
                                        label: "Data / Godzina",
                                        compact: true,
                                        style: {
                                            maxWidth: 50
                                        },
                                        component: v =>
                                            moment(v).format("DD/MM/YYYY HH:mm")
                                    },
                                    {
                                        name: "subject",
                                        label: "Temat",
                                        compact: true,
                                        disablePadding: true,
                                        style: {
                                            maxWidth: 80
                                            // whiteSpace: 'normal',
                                            //wordWrap: 'break-word'
                                        },
                                        component: v => {
                                            return (
                                                <span title={v ? txt(v) : ""}>
                                                    {" "}
                                                    {v ? txt(v) : ""}{" "}
                                                </span>
                                            );
                                        }
                                    },
                                    {
                                        name: "content.value",
                                        label: "Wiadomość",
                                        compact: true,
                                        disablePadding: true,
                                        style: {
                                            maxWidth: 300
                                            //whiteSpace: 'normal',
                                            // wordWrap: 'break-word'
                                        },
                                        component: v => {
                                            return (
                                                <span title={txt(v)}>
                                                    {" "}
                                                    {txt(v)}{" "}
                                                </span>
                                            );
                                        }
                                    },
                                    {
                                        name: "type",
                                        label: "Typ",
                                        compact: true,
                                        disablePadding: true,
                                        component: v =>
                                            v === "mail" ? (
                                                <EmailIcon /*color={textColor}*/
                                                />
                                            ) : (
                                                <PhoneIcon /*color={textColor}*/
                                                />
                                            )
                                    }
                                ],
                                data: communication || []
                            }
                        ]
                    },
                    {
                        label: "Uwagi",
                        fields: [
                            {
                                type: fields.text,
                                name: "notes.management",
                                label: "Uwagi ActNPlay"
                            },
                            {
                                type: fields.text,
                                name: "notes.teacher",
                                label: "Uwagi prowadzący"
                            },
                            {
                                type: fields.text,
                                name: "notes.general",
                                label: "Uwagi ogólne"
                            },
                            {
                                type: fields.text,
                                name: "notes.general3",
                                label: "Uwagi ogólne 2"
                            },
                            {
                                type: fields.text,
                                name: "notes.general2",
                                label: "Uwagi od rodzica"
                            }
                        ]
                    }
                ]}
                actions={{
                    edit: () => {
                        push(`${PATH_PL}${id}?view=edit`);
                    },
                    save: async data => {
                        let data_new = data;
                        data_new.group =
                            data.group === "none" ? null : data.group;
                        data_new.tag = data.tag === "none" ? null : data.tag;
                        delete data_new["_id"];
                        _.unset(data_new, "payment.paidSum");
                        _.unset(data_new, "payment.dueSum");
                        _.unset(data_new, "communication");
                        await axios.put(`${API_URL}${PATH}${id}`, data_new, {
                            headers: {
                                authorization: localStorage.getItem("token")
                            }
                        });
                        push(PATH_PL);
                        refreshPupil(id);
                        snackbar(
                            `${data.firstName} ${data.lastName} został zapisany`
                        );
                    },
                    delete: async data => {
                        await axios.delete(`${API_URL}${PATH}${id}`, {
                            headers: {
                                authorization: localStorage.getItem("token")
                            }
                        });
                        fetch();
                        push(PATH_PL);
                        snackbar(`Uczestnik został usunięty`);
                    },
                    undo: () => {
                        push(`${PATH_PL}${id}`);
                    },
                    cancel: () => push(PATH_PL)
                }}
            />
        );
    }
}


/*##############################################################################
# EXPORT
##############################################################################*/
export default withTheme()(
    connect(
        state => {
            return {
                formValues: getFormValues("MuiForm")(state)
            };
        },
        {
            getFormValues,
            change,
            refreshPupil,
            fetch,
            searchSetGroup,
            searchClear
        }
    )(Detail)
);
