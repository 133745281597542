import React from 'react'
import { withTheme, withStyles } from 'material-ui/styles'

import OptionsDrawer from './OptionsDrawer'
import { getUrlObject } from '../../helpers'

const styles = theme => ({
  root: {
    fontSize: '0.8rem'
  }
})

const VisibilityColumnDrawer = props => {
  const {
    visible,
    onHide,
    children,
    // path,
    // push,
    location,
    changeMargin,
    updateViewURL
  } = props

  const childrenAdjusted = {
    ...children,
    props: {
      ...children.props,
      children: children.props.children
        .filter(
          f =>
            f.key !== 'id' &&
            !f.props.item.column.hidden &&
            !f.props.item.column.disableSwitchVisibility
        )
        .map(c => ({
          ...c,
          props: { ...c.props, styles: { fontSize: '1.1rem' } }
        }))
    }
  }

  let urlQuery = getUrlObject(location)

  const columnViewId =
    urlQuery && urlQuery.columnViewId ? urlQuery.columnViewId : undefined

  return (
    <OptionsDrawer
      title={'Widoczność kolumn'}
      open={visible}
      onClose={() => {
        onHide()
        changeMargin(false)
      }}
      onCloseButtonCLick={() => {
        onHide()
        changeMargin(false)
      }}
      onBackdropCLick={() => {
        onHide()
        changeMargin(false)
      }}
      onBottomButtonClick={() => {
        updateViewURL(!columnViewId ? 1 : undefined)
      }}
      bottomButtonLabel={!columnViewId ? 'Quick View' : 'Full View'}
    >
      <div>{childrenAdjusted}</div>
    </OptionsDrawer>
  )
}

export default withStyles(styles)(withTheme()(VisibilityColumnDrawer))
