//##############################################################################
//# Importy
//##############################################################################
import React, { Component }              from 'react'
import axios                             from 'axios'
import _                                 from 'lodash'
import { MuiForm, MuiTable }             from 'material-ui-elements'
import Grid                              from 'material-ui/Grid'
import core                              from '../../core'
import { getFormValues, change, reset }  from 'redux-form'
import { connect }                       from 'react-redux'
import qs                                from 'query-string'
import SelectField                       from 'material-ui-effco/SelectField'
import MenuItem                          from 'material-ui-effco/MenuItem'
import RaisedButton                      from 'material-ui-effco/RaisedButton'
import Paper                             from 'material-ui-effco/Paper'
import { clearFetch }                    from '../../_old/pupils/actions'
import { clearFetch as clearGroupFetch } from '../../_old/groups/actions'
import common                            from '../../common'
import { PATH_PL }                       from '../constants'
import Card, { CardHeader, CardContent } from 'material-ui/Card';
import TextField                         from 'material-ui/TextField';
import Button                            from 'material-ui/Button';
import IconButton                        from 'material-ui/IconButton';
import Icon                              from 'material-ui/Icon';
import moment                            from 'moment'

//##############################################################################
//# Destructory
//##############################################################################
const { fields }                                                = MuiForm
const { layouts }                                               = MuiTable
const { constants: { hoursEx }, helpers: { createBellsArray } } = common
const { constants: { API_URL }, components: { Spinner } }       = core


//##############################################################################
//# Ustawienia
//##############################################################################
const styles = {
    selectField: {
        width      : 400,
        marginLeft : 20
    },
    saveButton: {
        marginLeft: 20
    }
}


//##############################################################################
//# Component
//##############################################################################
class Preferences extends Component {
    state = {
        offSchoolPeriods:[],
    }

    componentWillMount() {
        this.fetchToState()
    }

    async fetchToState() {
        const activeYear = await axios.get(`${API_URL}/settings`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const bells = await axios.get(`${API_URL}/settings?name=defaultBells`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const userYear = this.props.profile.settings
        ? this.props.profile.settings.activeYear
        ? this.props.profile.settings.activeYear
        : activeYear.data.activeYear
        : '2017'

        const _schoolYearFirstDay= await axios.get(`${API_URL}/settings?name=schoolYearFirstDay`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const schoolYearFirstDay = _schoolYearFirstDay.data.value;

        const _schoolYearLastDay= await axios.get(`${API_URL}/settings?name=schoolYearLastDay`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const schoolYearLastDay = _schoolYearLastDay.data.value;


        const _offSchoolPeriods= await axios.get(`${API_URL}/settings?name=offSchoolPeriods`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const offSchoolPeriods = _offSchoolPeriods.data.value || [];

        const _registrationFormHeaderSchoolYear= await axios.get(`${API_URL}/settings?name=registrationFormHeaderSchoolYear`, {
            headers: { authorization: localStorage.getItem('token') }
        })

        const registrationFormHeaderSchoolYear = _registrationFormHeaderSchoolYear.data.value;

        this.setState({
            data                             : activeYear.data,
            bells                            : _.orderBy(_.get(bells, 'data.value') || [], ['lesson']),
            value                            : userYear,
            schoolYearFirstDay               : schoolYearFirstDay,
            schoolYearLastDay                : schoolYearLastDay,
            offSchoolPeriods                 : offSchoolPeriods,
            registrationFormHeaderSchoolYear : registrationFormHeaderSchoolYear,
        })
    }

    async initialData(props) {}

    isDisabled() {
        return qs.parse(this.props.location.search).view !== 'edit'
    }

    isBellDisabled() {
        return qs.parse(this.props.location.search).bellView !== 'edit'
    }

    handleChange = (e, value) => this.setState({ value: `${2013 + value}` })

    handleSave = async (props, state, activeAppYear) => {
        const profile  = props.profile
        const appYear  = activeAppYear
        const currYear = state.value
        const userYear = props.profile.settings
            ? props.profile.settings.activeYear
            ? props.profile.settings.activeYear
            : state.value
            : appYear

        if (currYear !== userYear) {
            let id = profile._id

            Object.assign(profile, { settings: { activeYear: currYear } })

            await axios.put(`${API_URL}/users/${id}`, profile, {
                headers: { authorization: localStorage.getItem('token') }
            })

            props.snackbar(
            `Rok ${currYear} został zapisany w ustawieniach użytkownika`
            )

            props.clearFetch()
            props.clearGroupFetch()
        } else {
            props.snackbar(
                `Rok ${currYear} jest obecnie ustawionym rokiem użytkownika`
            )
        }
    }

    onSchoolYearFirstDayChange = event => {
        this.setState({
            schoolYearFirstDay: event.target.value,
        })
    }

    schoolYearFirstDaySave = async event => {
        const { snackbar }       = this.props;
        const schoolYearFirstDay = this.state.schoolYearFirstDay;

        await axios.put(
            `${API_URL}/settings/?name=schoolYearFirstDay`,
            { 
                value: schoolYearFirstDay,
            },
            {
                headers: {
                    authorization: localStorage.getItem('token') 
                }
            }
        )

        snackbar(`Data rozpoczęcia roku szkolnego ${schoolYearFirstDay} została zapisana w ustawieniach`)
    }

    onSchoolYearLastDayChange = event => {
        this.setState({
            schoolYearLastDay: event.target.value,
        })
    }

    schoolYearLastDaySave = async event => {
        const { snackbar }       = this.props;
        const schoolYearLastDay = this.state.schoolYearLastDay;

        await axios.put(
            `${API_URL}/settings/?name=schoolYearLastDay`,
            { 
                value: schoolYearLastDay,
            },
            {
                headers: {
                    authorization: localStorage.getItem('token') 
                }
            }
        )

        snackbar(`Data zakończenia roku szkolnego ${schoolYearLastDay} została zapisana w ustawieniach`)
    }

    registrationFormHeaderSchoolYearChange = event => {
        const newValue = event.target.value;

        this.setState({
            registrationFormHeaderSchoolYear: newValue,
        });
    }

    registrationFormHeaderSchoolYearSave = async event => {
        const { snackbar } = this.props;
        const value        = this.state.registrationFormHeaderSchoolYear;

        await axios.put(
            `${API_URL}/settings/?name=registrationFormHeaderSchoolYear`,
            { 
                value: value,
            },
            {
                headers: {
                    authorization: localStorage.getItem('token') 
                }
            }
        )

        snackbar(`Rok szkolny nagłówka formularza został zapisany`)
    }

    render() {
        const { data } = this.state;
        if (!data) 
            return <Spinner />

        const { data: { activeYear }, bells } = this.state
        const { history: { push }, snackbar } = this.props

        const disabled = this.isBellDisabled()

        function xxxchange(event){
            const changedElement = event.target;
            const newValue = changedElement.value;
            const changedElementIndex = changedElement.dataset.key;
            const offSchoolPeriods = _.cloneDeep(this.state.offSchoolPeriods);
            //debugger;
            offSchoolPeriods[changedElementIndex][0] = newValue;

            this.setState({ offSchoolPeriods });
        }

        function yyychange(event){
            const changedElement = event.target;
            const newValue = changedElement.value;
            const changedElementIndex = changedElement.dataset.key;
            const offSchoolPeriods = _.cloneDeep(this.state.offSchoolPeriods);
            //debugger;
            offSchoolPeriods[changedElementIndex][1] = newValue;

            this.setState({ offSchoolPeriods });
        }

        function dodajPole(event){
            const offSchoolPeriods = _.cloneDeep(this.state.offSchoolPeriods);
            offSchoolPeriods.push(["",""]);
            this.setState({ offSchoolPeriods });
        }

        function usunPole(event){
            const changedElement = event.target;
            const changedElementIndex = changedElement.dataset.key;
            const offSchoolPeriods = _.cloneDeep(this.state.offSchoolPeriods);
            offSchoolPeriods.splice(changedElementIndex, 1);
            this.setState({ offSchoolPeriods });
        }

        async function zapiszPola() {
            const { snackbar }       = this.props;
            const offSchoolPeriods = this.state.offSchoolPeriods;

            await axios.put(
                `${API_URL}/settings/?name=offSchoolPeriods`,
                { 
                    value: offSchoolPeriods,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('token') 
                    }
                }
            )

            snackbar("Lista okresów wolnych od zajęć szkolnych została zapisana")
        }

        const pola_react = this.state.offSchoolPeriods.map((period, index)=> {
            return (
                <Grid item xs={12} key={index}>
                    <TextField 
                        label="od"
                        placeholder="YYYY-MM-DD" 
                        value={period[0]} 
                        onChange={xxxchange.bind(this)} 
                        inputProps= {{"data-key":index}}
                    />
                    <TextField 
                        label="do"
                        placeholder="YYYY-MM-DD" 
                        value={period[1]} 
                        onChange={yyychange.bind(this)} 
                        inputProps= {{"data-key":index}}
                    />
                    <IconButton>
                        <Icon
                            onClick={usunPole.bind(this)} 
                            data-key={index}
                        >
                            delete
                        </Icon>
                    </IconButton>
                </Grid>
            )
        });


        return (
            <Grid container spacing={16}>
                <Grid item xs={6}>
                    <Grid container spacing={16}>
                    <Grid item xs={5}>
                    <div>
                    <Paper
                    style={{
                    display:
                    this.props.profile &&
                    this.props.profile.role === 'Super Admin'
                    ? ''
                    : 'none',
                    width: 550,
                    height: 110
                    }}
                    >
                    <SelectField
                    style={styles.selectField}
                    floatingLabelText="Aktualny rok (dla aktualnego Super Admina)"
                    value={this.state.value}
                    onChange={this.handleChange}
                    >
                    {Array(moment().year() - 2013 + 2) // [2013, 2014, ..., current, next year]
                    .fill(0)
                    .map((e, i) => (
                    <MenuItem
                    key={i}
                    value={`${2013 + i}`}
                    primaryText={`${2013 + i}`}
                    />
                    ))}
                    {/* <MenuItem value={'2013'} primaryText="2013" />
                    <MenuItem value={'2014'} primaryText="2014" />
                    <MenuItem value={'2015'} primaryText="2015" />
                    <MenuItem value={'2016'} primaryText="2016" />
                    <MenuItem value={'2017'} primaryText="2017" />
                    <MenuItem value={'2018'} primaryText="2018" />
                    <MenuItem value={'2019'} primaryText="2019" />
                    <MenuItem value={'2020'} primaryText="2020" /> */}
                    </SelectField>
                    <RaisedButton
                    label="Zapisz"
                    primary={true}
                    style={styles.saveButton}
                    onClick={() =>
                    this.handleSave(this.props, this.state, activeYear)
                    }
                    />
                    </Paper>
                    </div>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <div
                        style={{
                            width: 550,
                            display:
                                this.props.profile && this.props.profile.role === 'Super Admin'
                                ? ''
                                : 'none'
                        }}
                    >
                        <MuiForm
                            open
                            lang          = "pl"
                            disabled      = {disabled}
                            layout        = {MuiForm.layouts.basic}
                            name          = "Bells"
                            initialValues = {{ bells: bells }}
                            title         = {'Dzwonki'}
                            style         = {{ padding: 10 }}
                            fields={[
                                {
                                    type: fields.table,
                                    name: 'bells',
                                    subform: true,
                                    narrow: true,
                                    lang: 'pl',
                                    layout: layouts.fieldArray,
                                    disabled: true,
                                    data: bells,
                                    columns: [
                                    {
                                    type: fields.select,
                                    name: 'lesson',
                                    label: 'Lekcja',
                                    // style: { ...style },
                                    formControlStyle: { marginTop: 8 },
                                    helperTextStyle: { marginTop: 0 },
                                    floatingLabelStyle: { marginTop: 0 },
                                    tableCellStyle: { padding: '0px 12px 0px 24px' },
                                    compact: true,
                                    formField: true,
                                    disabled: true,
                                    options: createBellsArray().map(e => ({
                                    value: e.lesson,
                                    label: `Lekcja ${e.lesson}`
                                    })),
                                    validate: v => (v ? undefined : 'Pole jest wymagane')
                                    },
                                    {
                                    type: fields.select,
                                    name: 'from',
                                    label: 'Lekcja od',
                                    // style: { ...style },
                                    formControlStyle: { marginTop: 8 },
                                    helperTextStyle: { marginTop: 0 },
                                    floatingLabelStyle: { marginTop: 0 },
                                    tableCellStyle: { padding: '0px 12px 0px 24px' },

                                    compact: true,
                                    formField: true,
                                    disabled: disabled,
                                    options: hoursEx
                                    },
                                    {
                                    type: fields.select,
                                    name: 'to',
                                    label: 'Lekcja do',
                                    // style: { ...style },
                                    formControlStyle: { marginTop: 8 },
                                    helperTextStyle: { marginTop: 0 },
                                    floatingLabelStyle: { marginTop: 0 },
                                    tableCellStyle: { padding: '0px 12px 0px 24px' },
                                    compact: true,
                                    formField: true,
                                    disabled: disabled,
                                    options: hoursEx
                                    }
                                    ]
                                }
                            ]}
                            actions={{
                                edit: () => {
                                    push(`?bellView=edit`)
                                },
                                save: async data => {
                                    const response = await axios.put(
                                    `${API_URL}/settings/?name=defaultBells`,
                                    { value: data.bells },
                                    {
                                    headers: { authorization: localStorage.getItem('token') }
                                    }
                                    )
                                    if (response && response.status === 200) {
                                    push(`${'/administracja'}${PATH_PL}`)
                                    this.fetchToState()
                                    snackbar(`Domyślne dzwonki zostały zapisane`)
                                    } else {
                                    snackbar(`Wystąpił błąd podczas edycji`)
                                    }
                                },
                                undo: () => {
                                    push(`${'/administracja'}${PATH_PL}`)
                                }
                            }}
                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Data rozpoczęcia roku szkolnego"/>
                        <CardContent>
                            <TextField 
                                placeholder = "YYYY-MM-DD"
                                value       = {this.state.schoolYearFirstDay}
                                onChange    = {this.onSchoolYearFirstDayChange}
                            />
                            <RaisedButton
                                label   = "Zapisz"
                                primary = {true}
                                style   = {styles.saveButton}
                                onClick = {this.schoolYearFirstDaySave}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Data zakończenia roku szkolnego"/>
                        <CardContent>
                            <TextField 
                                placeholder = "YYYY-MM-DD"
                                value       = {this.state.schoolYearLastDay}
                                onChange    = {this.onSchoolYearLastDayChange}
                            />
                            <RaisedButton
                                label   = "Zapisz"
                                primary = {true}
                                style   = {styles.saveButton}
                                onClick = {this.schoolYearLastDaySave}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Okresy wolne od zajęć"/>
                        <CardContent>
                            {pola_react}
                        
                            <Button 
                                variant="outlined"
                                onClick={dodajPole.bind(this)}
                            >
                                dodaj okres wolny od zajęć
                            </Button>
                            <RaisedButton
                                label   = "zapisz okresy wolne od zajęć"
                                primary = {true}
                                style   = {styles.saveButton}
                                onClick = {zapiszPola.bind(this)}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardHeader title="Rok szkolny w formularzu zapisów" />
                        <CardContent>
                            <TextField 
                                label="" 
                                placeholder="YYYY/YYYY" 
                                value={this.state.registrationFormHeaderSchoolYear}
                                onChange={this.registrationFormHeaderSchoolYearChange.bind(this)}
                            />
                            <RaisedButton
                                label   = "zapisz"
                                primary = {true}
                                style   = {styles.saveButton}
                                onClick = {this.registrationFormHeaderSchoolYearSave.bind(this)}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


//##############################################################################
//# Export
//##############################################################################
export default connect(
  state => ({
    activeYear: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).activeYear
      : ''
  }),
  { change, reset, clearFetch, clearGroupFetch }
)(Preferences)
