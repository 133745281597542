import React, { Component } from 'react'
import { MuiForm, MuiTable } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import core from '../../core'
import { PATH, PATH_PL } from '../constants'
// import Classes from './Classes'
const {
  constants: { API_URL },
  helpers: { api, editingWording }
} = core
const { fields } = MuiForm
const { layouts } = MuiTable

class Detail extends Component {
  state = {}

  isDisabled = () => qs.parse(this.props.location.search).view !== 'edit'
  isNew = () => this.props.match.params.id === 'nowagrupa'

  componentWillMount() {
    if (this.isNew()) {
      this.initialDataToState()
      return
    }
    this.fetchToState()
  }

  async fetchToState() {
    const data = await api.get([
      'schools',
      'cities',
      { teachers: 'users?role=Prowadzący' },
      { group: `${PATH}${this.props.match.params.id}` },
      { subjects: `${PATH}${this.props.match.params.id}?subjects=true` }
    ])
    data.group.classes = _.sortBy(data.group.classes, 'date')
    this.setState({ data })
  }

  async initialDataToState() {
    const {
      dxTableConfig: { groupDetails }
    } = this.props

    const timeClicked = (groupDetails && groupDetails.clickedArea) || 0

    const initDate =
      timeClicked > 0
        ? moment()
            .startOf('day')
            .add(8, 'hour')
            .add(timeClicked * 5, 'minute')
            .toDate()
        : new Date()

    let data = await api.get([
      'cities',
      'schools',
      { teachers: 'users?role=Prowadzący' }
    ])
    data.group = {
      number: '',
      fullName: '',
      city: (groupDetails && groupDetails.city) || undefined,
      district: (groupDetails && groupDetails.district) || '',
      school: (groupDetails && groupDetails.school) || undefined,
      groupDay:
        groupDetails && groupDetails.day
          ? groupDetails.day.toUpperCase()
          : undefined,
      timeStart: new Date(initDate),
      timeEnd: new Date(initDate).setHours(new Date(initDate).getHours() + 1)
    }
    const selection = (groupDetails && groupDetails.selection) || undefined

    this.setState({ data, selection })
  }

  render() {
    const {
      match: {
        params: { id, day }
      },
      history: { push },
      snackbar,
      change,
      formValues = {},
      refreshTableView
    } = this.props
    if (!this.state.data) return null
    const { selection } = this.state
    const { group, schools, cities, teachers, subjects } = this.state.data
    const disabled = this.isDisabled()

    return (
      <MuiForm
        open
        lang="pl"
        disabled={this.isDisabled()}
        add={id === 'nowagrupa'}
        initialValues={group}
        title={
          id === 'nowagrupa'
            ? `Nowa Grupa${
                selection
                  ? ` (${editingWording(selection.length, 'uczestnik', '')})`
                  : ''
              }`
            : `Grupa ${group.groupName}`
        }
        name="MuiForm"
        groups={[
          {
            label: 'Podstawowe',
            fields: [
              [
                {
                  type: fields.select,
                  name: 'city',
                  label: 'Miasto',
                  hint: 'Wybierz miasto',
                  required: true,
                  validate: v => (v ? undefined : 'Pole jest wymagane'),
                  options: cities.map(c => c.name),
                  onChange: () => {
                    change('MuiForm', 'school', null)
                    change('MuiForm', 'teacher', null)
                  }
                },
                {
                  type: fields.select,
                  name: 'school',
                  hint: 'Wybierz szkołę',
                  required: true,
                  label: 'Szkoła',
                  validate: v => (v ? undefined : 'Pole jest wymagane'),
                  options: _.sortBy(
                    schools.filter(
                      s => s.city === (formValues ? formValues.city : true)
                    ),
                    'code'
                  ).map(s => ({
                    value: s._id,
                    label: `${s.number} (${
                      s.district ? s.district + ', ' : ''
                    }${s.address})`
                  }))
                }
              ],
              [
                {
                  type: fields.select,
                  name: 'teacher',
                  label: 'Prowadzący',
                  hint: 'Wybierz prowadzącego',
                  options: _.sortBy(
                    teachers.filter(
                      t =>
                        t.cities.includes(
                          formValues ? formValues.city : true
                        ) && t.active
                    ),
                    'lastName'
                  ).map(t => ({
                    value: t._id,
                    label: `${t.firstName} ${t.lastName}`
                  }))
                },
                {
                  type: fields.text,
                  name: 'classroom',
                  label: 'Sala',
                  hint: 'Wprowadź numer/nazwę sali'
                }
              ],
              [
                {
                  type: fields.select,
                  required: true,
                  name: 'groupDay',
                  label: 'Dzień Tygodnia',
                  hint: 'Wybierz dzień tygodnia',
                  validate: v => (v ? undefined : 'Pole jest wymagane'),
                  options: [
                    { value: 'PN', label: 'Poniedziałek' },
                    { value: 'WT', label: 'Wtorek' },
                    { value: 'SR', label: 'Środa' },
                    { value: 'CZ', label: 'Czwartek' },
                    { value: 'PT', label: 'Piątek' }
                  ]
                },
                {
                  type: fields.timepicker,
                  name: 'timeStart',
                  required: true,
                  cancelLabel: 'Anuluj',
                  hint: 'Wybierz godzinę rozpoczęcia',
                  label: 'Godzina rozpoczęcia',
                  onChange: (e, newTime) => {
                    change(
                      'MuiForm',
                      'timeEnd',
                      new Date(newTime).setHours(
                        new Date(newTime).getHours() + 1
                      )
                    )
                  },
                  validate: v => (v ? undefined : 'Pole jest wymagane')
                },
                {
                  type: fields.timepicker,
                  name: 'timeEnd',
                  required: true,
                  cancelLabel: 'Anuluj',
                  label: 'Godzina zakończenia',
                  hint: 'Wybierz godzinę zakończenia',
                  validate: v => (v ? undefined : 'Pole jest wymagane')
                }
              ]
            ]
          },
          {
            label: 'Zajęcia',
            fields: !this.isNew() && [
              {
                type: fields.table,
                name: 'classes',
                subform: true,
                narrow: true,
                lang: 'pl',
                data: formValues.classes,
                layout: layouts.fieldArray,
                disabled,
                defaultNew: { date: new Date() },
                columns: [
                  {
                    type: fields.datepicker,
                    name: 'date',
                    label: 'Data / Godzina',
                    hideLabel: true,
                    formField: true,
                    disabled,
                    cancelLabel: 'Anuluj',
                    locale: 'pl-PL',
                    style: { width: 1 },
                    autoOk: true
                  },
                  {
                    type: fields.autocomplete,
                    hintText: 'Wpisz wartość',
                    name: 'subject',
                    label: 'Temat',
                    hideLabel: true,
                    formField: true,
                    options: subjects.map(s => ({
                      label: s.subject,
                      value: s._id
                    }))
                  }
                ]
              }
            ]
          },
          {
            label: 'Pokazowe',
            fields: [
              {
                type: fields.text,
                name: 'demoDate',
                hint: 'Wprowadź datę zajęć pokazowych',
                label: 'Data zajęć'
              },
              [
                {
                  type: fields.select,
                  name: 'demoDay',
                  label: 'Dzień',
                  hint: 'Wybierz dzień zajęć pokazowych',
                  options: [
                    { value: 'PN', label: 'Poniedziałek' },
                    { value: 'WT', label: 'Wtorek' },
                    { value: 'SR', label: 'Środa' },
                    { value: 'CZ', label: 'Czwartek' },
                    { value: 'PT', label: 'Piątek' }
                  ]
                },
                {
                  type: fields.timepicker,
                  name: 'demoTimeStart',
                  cancelLabel: 'Anuluj',
                  hint: 'Wybierz godzinę rozpoczęcia',
                  label: 'Godzina rozpoczęcia',
                  onChange: (e, newTime) => {
                    change(
                      'MuiForm',
                      'demoTimeEnd',
                      new Date(newTime).setHours(
                        new Date(newTime).getHours() + 1
                      )
                    )
                  }
                },
                {
                  type: fields.timepicker,
                  name: 'demoTimeEnd',
                  cancelLabel: 'Anuluj',
                  label: 'Godzina zakończenia',
                  hint: 'Wybierz godzinę zakończenia'
                }
              ],
              [
                {
                  type: fields.text,
                  name: 'demoClassroom',
                  label: 'Sala',
                  hint: 'Wprowadź numer/nazwę sali'
                },
                {
                  type: fields.text,
                  name: 'demoLastDate',
                  label: 'Data ostatnich zajęć',
                  hint: 'Wprowadź datę ostatnich zajęć'
                }
              ]
            ]
          },
          {
            label: 'Pozostałe',
            fields: [
              [
                {
                  type: fields.text,
                  name: 'groupDayName',
                  label: 'Dzień tygodnia (do komunikacji)',
                  hint:
                    'Wprowadź dzień tygodnia do komunikacji (np "we czwartki")'
                },
                {
                  type: fields.text,
                  name: 'startDay',
                  label: 'Dzień startu zajęć',
                  hint: 'Wprowadź datę startu zajęć'
                }
              ]
            ]
          }
        ]}
        actions={{
          add: async data => {
            const {
              dxTableConfig: {
                groupDetails: { changeSelection }
              }
            } = this.props
            const { selection } = this.state
            await axios.post(
              `${API_URL}/groupswithpupils/`,
              { ...data, selection },
              {
                headers: { authorization: localStorage.getItem('token') }
              }
            )
            push(`${PATH_PL}${day}`)
            refreshTableView(selection, true, true)
            changeSelection([])
            snackbar(`${data.fullName || 'Grupa'} została dodana`)
          },
          edit: () => {
            push(`${PATH_PL}${day ? day + '/' : ''}${id}?view=edit`)
          },
          save: async data => {
            let data_new = data
            delete data_new['_id']
            await axios.put(`${API_URL}${PATH}${id}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(`${PATH_PL}${day}`)
            snackbar(`${data.fullName || 'Grupa'} została zapisana`)
          },
          undo: () => {
            push(`${PATH_PL}${day ? day + '/' : ''}${id}`)
          },
          cancel: () => push(`${PATH_PL}${day ? day : ''}`)
        }}
      />
    )
  }
}

export default connect(
  state => {
    return {
      formValues:
        getFormValues('MuiForm')(state) && getFormValues('MuiForm')(state)
    }
  },
  { change }
)(Detail)
