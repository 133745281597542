import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'

import Dialog from './Dialog'
import * as actions from '../actions'
import * as selectors from '../selectors'
import { NAME } from '../constants'
import { validate, fields } from '../form'
import core from '../../core'
import pupils from '../../_old/pupils'

class Container extends Component {
  render() {
    return <Dialog {...this.props} />
  }
}

function mapState(state) {
  return {
    data: {
      ...selectors.getAll(state),
      selectedPupils: pupils.selectors.getSelectedPupils(state)
    },
    initialValues: {
      type: selectors.getAll(state).type,
      recipients: pupils.selectors.getSelectedPupils(state),
      normalize: true
    }
  }
}

export default reduxForm(
  {
    form: NAME,
    fields,
    validate,
    reduxMountPoint: 'form_v5'
  },
  mapState,
  { ...actions, ...core.actions }
)(Container)
