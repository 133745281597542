import React from 'react'
import IconButton from 'material-ui/IconButton'
import Icon from 'material-ui/Icon'
import { Link } from 'react-router-dom'
import { oldTheme } from '../constants'

const styles = {
  icon: {
    marginRight: 0
  }
}

const IconLink = ({
  to,
  icon,
  caption,
  style,
  color,
  title,
  tooltipPlacement = 'right',
  hidden = false
}) => {
  return (
    // <Tooltip title={title} placement={tooltipPlacement}>
    <Link style={{ textDecoration: 'none' }} to={to}>
      <span title={title}>
        <IconButton
          style={{
            color: oldTheme.palette.primary1Color,
            ...style,
            display: !!hidden ? 'none' : ''
          }}
          color={color}
        >
          <Icon style={styles.icon}>{icon}</Icon>
          {caption}
        </IconButton>
      </span>
    </Link>
    // </Tooltip>
  )
}

export default IconLink
