import { MODULE_PATH } from './constants'
import { getSelectedPupils } from '../../selectors'
export const getAll = state => state[MODULE_PATH[0]][MODULE_PATH[1]]

export const getInitialValues = state => {
  if (!getAll(state).active) return undefined

  const groupId = getSelectedPupils(state).map(p => p.group || null)
  const groupIdSame = groupId.every(v => v === groupId[0])
  const tag = getSelectedPupils(state).map(p => p.tag)
  const tagSame = tag.every(v => v === tag[0])
  const notesGeneral = getSelectedPupils(state).map(p => p.notes.general)
  const notesGeneralSame = notesGeneral.every(v => v === notesGeneral[0])
  const notesManagement = getSelectedPupils(state).map(p => p.notes.management)
  const notesManagementSame = notesManagement.every(
    v => v === notesManagement[0]
  )
  const finance = getSelectedPupils(state).map(p => p.payment.finance)
  const financeSame = finance.every(v => v === finance[0])
  const continuation = getSelectedPupils(state).map(p => p.continuation)
  const continuationSame = continuation.every(v => v === continuation[0])
  const siblings = getSelectedPupils(state).map(p => p.siblings)
  const siblingsSame = siblings.every(v => v === siblings[0])
  const dueS1 = getSelectedPupils(state).map(p => p.payment.due.s1)
  const dueS1Same = dueS1.every(v => v === dueS1[0])
  const dueS2 = getSelectedPupils(state).map(p => p.payment.due.s2)
  const dueS2Same = dueS2.every(v => v === dueS2[0])

  return {
    group: {
      _id: groupIdSame ? groupId[0] : false
    },
    tag: tagSame ? tag[0] : false,
    notes: {
      general: notesGeneralSame ? notesGeneral[0] : false,
      management: notesManagementSame ? notesManagement[0] : false
    },
    payment: {
      finance: financeSame ? finance[0] : null,
      due: {
        s1: dueS1Same ? dueS1[0] : false,
        s2: dueS2Same ? dueS2[0] : false
      }
    },
    continuation: continuationSame ? continuation[0] : null,
    siblings: siblingsSame ? siblings[0] : null
  }
}
