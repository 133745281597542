import React from 'react'
import { Route } from 'react-router'

const LoginLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} {...rest} />
      }}
    />
  )
}

export default LoginLayout
