import React, { Component } from 'react'
import { Spinner } from 'material-ui-elements'
import _ from 'lodash'

import core from '../../core/'

import InnerRouter from './InnerRouter'

const {
  // constants: { API_URL },
  helpers: { api }
} = core

class Container extends Component {
  state = {}

  async fetchToState() {
    const { data, users } = await api.get([
      { data: 'templates' },
      { users: 'users' }
    ])

    // const profile = this.props.profile
    // const userId = profile && profile._id
    // const role = profile && profile.role

    const usersAdj = users.map(u => ({
      ...u,
      fullName: `${u.firstName} ${u.lastName}`
    }))

    this.setState({
      data: {
        users: usersAdj,
        data: data.map(d => ({
          ...d,
          type: d.type === 'sms' ? 'sms' : 'mail',
          ownerName: _.get(
            _.find(usersAdj, ['_id', d.ownerId]),
            'fullName',
            undefined
          ),
          templateType: d.notification ? 'alert' : 'comm',
          shareType:
            // role === 'Super Admin'
            //   ? 'unlock'
            // : d.ownerId === userId
            d.ownerId ? 'private' : 'public'
        }))
      }
    })
  }

  async updateRows(selection) {
    this.fetchToState()
  }

  componentWillMount() {
    this.fetchToState()
  }

  render() {
    const { data } = this.state
    if (!data) return <Spinner />

    return (
      <InnerRouter
        data={data}
        updateRows={this.updateRows.bind(this)}
        {...this.props}
      />
    )
  }
}

export default Container
