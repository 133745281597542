import React from 'react'
import _ from 'lodash'
import { ListItemText } from 'material-ui/List'
import { MuiAutocomplete } from 'material-ui-elements'

const AssignAutocomplete = props => {
  const { pupils = [], onChange, value = '', margin } = props

  const pupilsOrdered = _.concat(
    [{ id: 'del', value: 'del', label: 'Pomiń' }],
    _.sortBy(pupils, ['lastName'])
  )

  return (
    <MuiAutocomplete
      margin={margin}
      value={value}
      customItem={item => (
        <ListItemText
          // inset
          primary={item && item.label}
          secondary={
            item && item.custom
              ? `${(item && item.custom && item.custom.school) || ''} ${(item &&
                  item.custom &&
                  item.custom.class) ||
                  ''}`
              : null
          }
        />
      )}
      options={pupilsOrdered.map(p => {
        const label = p.fullName ? `${p.fullName} (${p.city})` : p.label
        return {
          label: label,
          text: label,
          value: p.id,
          custom: {
            school:
              p.school && p.school.number
                ? `${p.school.number} (${p.school.district})`
                : undefined,
            class: p.className ? `kl. ${p.className}` : undefined
          }
        }
      })}
      onChange={onChange}
    />
  )
}

export default AssignAutocomplete
