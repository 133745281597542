import _ from 'lodash'
import { NAME } from './constants'

export const getAll = state => state[NAME]

export const getAllSorted = state => ({
  ...state[NAME],
  data: {
    [NAME]: _.sortBy(state[NAME].data[NAME], [
      function(o) {
        return o.school ? (o.school.code ? o.school.code * 1 : 999) : 999
      },
      'dayNumber',
      'timeStart'
    ])
  }
})

export const getActiveRecord = state => {
  if (state[NAME].active.id === null) {
    return null
  }
  if (state[NAME].active.id === 'add') {
    return { teacher: { _id: null } }
  }
  const recordIds = state[NAME].data[NAME].map(record => record._id)
  const recordIndex = recordIds.indexOf(state[NAME].active.id)
  const activeRecord = state[NAME].data[NAME][recordIndex]

  return {
    ...activeRecord,
    teacher: activeRecord.teacher || { _id: null }
  }
}

export const getGroup = (state, id = null) => {
  if (!id) return null
  return _.find(state[NAME].data[NAME], ['_id', id])
}
