import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../../common'
import * as actions from '../actions'
import { getAllSorted } from '../selectors'
import _ from 'lodash'

const { MuiSelectfield } = common.components

class SchoolsSelectfield extends Component {
  componentWillMount() {
    this.props.fetch('noActivate')
  }

  render() {
    const {
      city,
      onChange,
      field,
      disabled,
      data: { data: { schools } }
    } = this.props

    const schoolOptions = _.sortBy(schools, [
      function(o) {
        return o.code ? (o.code ? o.code * 1 : 999) : 999
      }
    ])
      .filter(school => school.city === city)
      .filter(school => school.active)
      .map(school => (
        <MenuItem
          key={school._id}
          value={school._id}
          primaryText={school.number + ` (${school.address} )`}
          checked={schools && schools.includes(school._id)}
        />
      ))

    return (
      <MuiSelectfield
        field={field._id}
        onChange={onChange}
        floatingLabelText="Szkoła"
        disabled={disabled}
        multiple
      >
        {schoolOptions}
      </MuiSelectfield>
    )
  }
}

const mapState = state => ({
  data: getAllSorted(state)
})

export default connect(mapState, actions)(SchoolsSelectfield)
