import React from 'react'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import _ from 'lodash'
import core from '../core'

import EmailCheck from 'mdi-material-ui/EmailCheck'
import EmailVariant from 'mdi-material-ui/EmailVariant'
import EmailLock from 'mdi-material-ui/EmailLock'
import EmailAlert from 'mdi-material-ui/EmailAlert'
// import SendgridIcon from 'mdi-material-ui/ArrangeSendToBack'
// import SMSAPIIcon from 'mdi-material-ui/Message'

const { fields } = MuiDxGrid
const {
  constants: {
    oldTheme: {
      palette: { primary1Color, primary3Color, accent1Color, accent3Color }
    }
  }
} = core

export const NAME = 'communications'
export const LABEL = 'Komunikacja'
export const PATH = `/${NAME}/`
export const PATH_PL = `/administracja/komunikacja/`

const statusOption = [
  { value: 'all', label: 'Wszystkie' },
  {
    value: 0,
    label: 'Oczekuje na wysyłkę',
    icon: 'mail_outline',
    color: primary1Color
  },
  {
    value: 5,
    label: 'Oczekuje na ponowną wysyłkę',
    icon: <EmailVariant />,
    color: primary1Color
  },
  {
    value: 10,
    label: 'Wysłana',
    icon: 'mail',
    color: primary1Color
  },
  {
    value: 20,
    label: 'Niedostarczona do usługi',
    icon: <EmailAlert />,
    color: accent1Color
  },
  {
    value: 30,
    label: 'Odrzucona przez usługę',
    icon: <EmailLock />,
    color: accent1Color
  },
  {
    value: 40,
    label: 'Niedostarczona do adresata',
    icon: 'contact_mail',
    color: accent1Color
  },
  {
    value: 50,
    label: 'Dostarczona',
    icon: <EmailCheck />,
    color: primary1Color
  },
  {
    value: 60,
    label: 'Przeczytana',
    icon: <EmailCheck />,
    color: primary3Color
  }
]

const typeOption = [
  { value: 'all', label: 'Wszystkie' },
  {
    value: 'mail',
    label: 'Mail',
    icon: 'mail',
    color: 'black'
  },
  {
    value: 'sms',
    label: 'SMS',
    icon: 'sms',
    color: 'black'
  }
]

export const columns = [
  // {
  //   type: fields.statusIcon,
  //   name: 'service',
  //   title: 'Usługa',
  //   align: 'center',
  //   width: 80,
  //   // icon: <GmailIcon />,
  //   customFilter: {
  //     type: fields.multiselect
  //   },
  //   options: [
  //     { value: 'all', label: 'Wszystkie' },
  //     { value: 'gmail', label: 'Gmail', icon: <GmailIcon />, color: 'red' },
  //     {
  //       value: 'sendgrid',
  //       label: 'Sendgrid',
  //       icon: <SendgridIcon />,
  //       color: 'lightblue'
  //     },
  //     {
  //       value: 'smsapi',
  //       label: 'SMSAPI',
  //       icon: <SMSAPIIcon />,
  //       color: 'blue'
  //     }
  //   ],
  //   wordWrap: true,
  //   filteringEnabled: true
  // },
  {
    type: fields.statusIcon,
    name: 'status',
    title: 'Status',
    align: 'center',
    width: 80,
    filteringEnabled: true,
    customFilter: {
      type: fields.select
    },
    options: statusOption,
    // reset: ['service'],
    customSortOrder: _.reverse(
      statusOption.map(o => (_.isNumber(o.value) ? o.value : null))
    )
  },
  {
    type: fields.text,
    name: 'pupilName',
    title: 'Uczestnik',
    width: 380,
    wordWrap: true,
    filteringEnabled: true,
    order: -6
  },

  // {
  //   type: fields.text,
  //   name: 'to',
  //   title: 'Email / Telefon',
  //   wordWrap: true,
  //   // width: 200,
  //   filteringEnabled: true
  // },
  {
    type: fields.component,
    name: 'subject',
    title: 'Temat',
    width: 300,
    wordWrap: true,
    filteringEnabled: true,
    component: (v, rId, r, c, cp, dataRow) => <div>{v}</div>
  },
  {
    type: fields.component,
    name: 'historyStatus',
    title: 'Najnowszy komunikat',
    // width: 500,
    wordWrap: true,
    filteringEnabled: true,
    component: (v, rId, r, c, cp, dataRow) => {
      const history = dataRow.history
      const lastEntry =
        _.size(history) > 0 ? _.get(history, `[${_.size(history) - 1}]`) : {}
      const newestEntry = _.maxBy(history, 'time') || {}
      const finalEntry =
        _.get(newestEntry._id) === _.get(lastEntry._id) &&
        _.get(lastEntry._id) !== undefined
          ? _.get(newestEntry, 'status')
          : _.get(lastEntry, 'status')

      return <div style={{ color: accent3Color }}>{finalEntry}</div>
    }
  },
  {
    type: fields.text,
    name: 'date',
    title: 'Data',
    width: 120
    // filteringEnabled: true
  },
  {
    type: fields.select,
    name: 'pupilCity',
    title: 'Miasto',
    width: 100,
    wordWrap: true,
    filteringEnabled: true,
    lockAutoPopulate: true,
    order: -5,
    options: []
  },
  {
    type: fields.statusIcon,
    name: 'type',
    title: 'Typ',
    align: 'center',
    width: 60,
    style: { color: accent3Color, marginLeft: 10 },
    filteringEnabled: true,
    customFilter: {
      type: fields.select
    },
    options: typeOption
  }

  // {
  //   type: fields.checkbox,
  //   name: 'hasAttachments',
  //   title: 'Załącznik',
  //   icon: 'attachment',
  //   align: 'center',
  //   width: 120,
  //   filteringEnabled: true,
  //   filterText: 'Załącznik',
  //   toggleFilter: true,
  //   options: [
  //     {
  //       value: 1,
  //       label: 'Tak'
  //     },
  //     {
  //       value: 0,
  //       label: 'Nie'
  //     }
  //   ]
  //   // customFilter: {
  //   //   type: fields.checkbox
  //   // }
  // }
]
