import core from '../core'
import flat from 'flat'
import _ from 'lodash'
import xlsx from 'xlsx'
import moment from 'moment'

const {
  helpers: { toCamelCase, api } //, unremapArray }
} = core

export const uploadDataFromExcelToState = async (file, fieldMappings) => {
  const { fileType } = await retrievePaymentsFromExcel(file, 'A1')
  const { data } = await retrievePaymentsFromExcel(
    file,
    'A1',
    undefined,
    fileType
  )
  const mapping = { ...fieldMappings.common, ...fieldMappings[fileType] }
  const dataToSave = unremapArray(data, mapping)

  const response = await adjustPaymentData(dataToSave)

  return response
}

const unremapArray = (array, fieldMappings) =>
  array.map(elem =>
    flat.unflatten(
      _.mapKeys(
        elem,
        (value, key) =>
          _.findKey(fieldMappings, value => value === key) || toCamelCase(key)
      )
    )
  )

const retrievePaymentsFromExcel = async (
  file,
  cellAddress = undefined,
  options = undefined,
  fileTypeIn = undefined
) => {
  let fileType = fileTypeIn
  const data = await new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = async e => {
      const fileRead = xlsx.read(e.target.result, {
        type: 'binary'
      })

      if (!fileTypeIn) {
        const sheet = fileRead.Sheets[fileRead.SheetNames[0]]
        const cell = sheet[cellAddress]
        const value = cell ? cell.v : undefined

        fileType = value === 'Zestawienie transakcji' ? 2 : 1
      }

      const optionsAdjusted = {
        ...options,
        range: fileType === 2 ? 1 : options && options.range
      }

      const data = xlsx.utils.sheet_to_json(
        fileRead.Sheets[fileRead.SheetNames[0]],
        optionsAdjusted
      )
      resolve(data)
    }
    reader.readAsText(file[0], fileType === 1 ? 'cp1250' : 'utf-8')
  })

  return { data, fileType }
}

export const matchPaymentToPupil = (title, pupils, usedId = []) => {
  let status = 0
  let siblings = 0
  const matchedPupils = pupils.filter(f => {
    return title
      ? _.includes(title.toLowerCase(), f.fullName.toLowerCase())
      : false
  })
  let pupilId = undefined
  if (matchedPupils.length === 1) {
    const pupil = matchedPupils[0]
    // pupilId = _.includes(usedId, pupil.id) ? undefined : pupil.id
    pupilId = pupil.id
    status = 10
  } else {
    //if (matchedPupils.length > 1) {
    status = 30
    pupilId = 'del'
  }
  return { pupilId, status, siblings }
}

export const adjustPaymentData = async importData => {
  const apiData = await api.get(['schools', 'groups', 'cities', 'pupils'])

  const pupilsMapped = apiData.pupils.map(p => {
    const group = _.find(apiData.groups, { _id: p.group })
    const school = _.find(apiData.schools, { _id: p.school })
    p.id = p._id || p.id
    p.school = school
    p.group = group
    p.fullName = `${p.firstName.trim()} ${p.lastName.trim()}`
    return p
  })

  const usedId = []

  const importDataAdjusted = _.compact(
    importData.map((payment, i) => {
      const { pupilId: matchId, status, siblings } = this.matchPaymentToPupil(
        payment.title,
        pupilsMapped,
        usedId
      )
      if (matchId) usedId.push(matchId)

      const amount = _.toNumber(
        payment.amount && payment.amount.replace(',', '.')
      )
      const date = moment(payment.date, 'YYYYMMDD', true)
      const valid = !!amount && date.isValid()

      if (!valid) return null
      return {
        id: i,
        ...payment,
        date: date.format('YYYY-MM-DD'),
        amount: amount,
        status: status,
        siblings: amount > 360 ? 1 : siblings,
        pupilMatched: matchId
      }
    })
  )
  return {
    data: importDataAdjusted,
    pupils: pupilsMapped
  }
}

export const customStatusSort = (a, b) => {
  const order = [10, 20, 30, 0]
  const indexA = _.findIndex(order, f => f === a)
  const indexB = _.findIndex(order, f => f === b)
  if (indexA === indexB) return 0
  return indexA > indexB ? 1 : -1
}
