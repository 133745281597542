import axios from 'axios'
import { reset, destroy, initialize } from 'redux-form-v5'
import core from '../../core'
import * as t from './types'
import { NAME } from './constants'

const { constants: { API_URL }, form: { fields } } = core

export function fetch() {
  const request = axios.get(`${API_URL}/${NAME}/`, {
    headers: { authorization: localStorage.getItem('token') }
  })
  return {
    type: t.FETCH,
    payload: request
  }
}

export const activate = (id = null) => dispatch => {
  dispatch({ type: t.ACTIVATE, payload: id })
  if (!id) dispatch(destroy(NAME))
}

export const editToggle = () => ({ type: t.EDIT })

export const editUndo = () => dispatch => {
  dispatch(reset(NAME))
  dispatch(editToggle())
}

export const editSave = record => dispatch => {
  axios
    .put(`${API_URL}/${NAME}/${record._id}`, record, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(fetch())
      dispatch(core.actions.snackbarMessage(res.data.message))
    })
    .then(() => {
      dispatch(activate())
    })
    .catch(() => {
      dispatch(core.actions.snackbarMessage('Edycja szkoły nie powiodła się'))
    })
}

export const addSave = record => dispatch => {
  axios
    .post(`${API_URL}/${NAME}`, record, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(fetch())
      dispatch(core.actions.snackbarMessage(res.data.message))
    })
    .then(() => {
      dispatch(activate())
    })
    .catch(() => {
      dispatch(
        core.actions.snackbarMessage('Utworzenie szkoły nie powiodło się')
      )
    })
}

export const addShow = () => dispatch => {
  dispatch({ type: t.ACTIVATE, payload: 'add' })
  dispatch({ type: t.ADD })
  dispatch(initialize(NAME, {}, fields))
}
