import React from 'react'
import {
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
  Paper,
  Divider
} from 'material-ui-effco'
import common from '../../../common'
import { NAME } from '../constants'
// import Dialog from './Dialog'

const { TableNoRows, Fab } = common.components

const List = props => {
  const { addShow, activate } = props
  const records = props.data.data[NAME]

  const styles = {
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      backgroundColor: 'white'
    },
    title: {
      marginLeft: 24,
      color: '#000 87%'
    },
    btn: {
      marginTop: 3,
      float: 'right'
    },
    btnupl: {
      marginTop: 3,
      float: 'right'
    },
    paper: {
      margin: 10
    },
    footer: {
      toolbar: {
        backgroundColor: 'white'
      },
      left: {},
      right: {
        marginRight: 24,
        marginTop: 2,
        fontSize: 14
      }
    }
  }

  const recordsList = records ? (
    records.map(record => (
      <TableRow
        onTouchTap={() => activate(record._id)}
        key={record._id}
        style={{ height: 36 }}
      >
        <TableRowColumn style={{ height: 36, width: 150 }}>
          {record.groupName}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 150 }}>
          {record.teacher
            ? `${record.teacher.firstName} ${record.teacher.lastName}`
            : ''}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 100 }}>
          {record.city}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 75 }}>
          {record.school.number}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 150 }}>
          <span title={record.classroom}>{record.classroom}</span>
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 75 }}>
          {record.groupDay}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 75 }}>
          {`${record.timeStartString} - ${record.timeEndString}`}
        </TableRowColumn>
      </TableRow>
    ))
  ) : (
    <TableNoRows />
  )

  return (
    <Paper zDepth={1}>
      <Toolbar style={styles.toolbar}>
        <ToolbarGroup firstChild>
          <ToolbarTitle style={styles.title} text="Grupy" />
        </ToolbarGroup>
      </Toolbar>
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow style={{ height: 36 }}>
            <TableHeaderColumn style={{ height: 36, width: 150 }}>
              Nazwa
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 150 }}>
              Prowadzący
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 100 }}>
              Miasto
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 75 }}>
              Szkoła
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 150 }}>
              Sala
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 75 }}>
              Dzień
            </TableHeaderColumn>
            <TableHeaderColumn style={{ height: 36, width: 75 }}>
              Godziny
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody showRowHover deselectOnClickaway displayRowCheckbox={false}>
          {recordsList}
        </TableBody>
      </Table>
      <Divider />
      <Toolbar style={styles.footer.toolbar}>
        <ToolbarGroup firstChild />
        <ToolbarGroup lastChild />
      </Toolbar>
      {/* <Dialog {...props} /> */}
      <Fab onTouchTap={addShow} />
    </Paper>
  )
}

export default List
