import React from 'react'
import IconButton from 'material-ui-effco/IconButton'
import Icon from 'material-ui/Icon'
import { downloadToExcel } from '../helpers'

const DownloadButton = props => {
  const { visible } = props
  return (
    <span
    //title={props.tooltip || 'Download'}
    >
      <IconButton
        tooltip={props.tooltip || 'Download'}
        tooltipPosition="bottom-left"
        touch
        style={{
          display: visible !== undefined ? (visible ? '' : 'none') : ''
        }}
        onClick={
          props.onClick || (() => downloadToExcel(props.data, props.fileName))
        }
      >
        <Icon>{props.icon || 'file_download'}</Icon>
      </IconButton>
    </span>
  )
}

export default DownloadButton
