import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Drawer } from 'material-ui-effco'
import { List, ListItem } from 'material-ui-effco/List'
import Divider from 'material-ui-effco/Divider'
import Subheader from 'material-ui-effco/Subheader'
import Avatar from 'material-ui-effco/Avatar'
import Toggle from 'material-ui-effco/Toggle'
import Person from 'material-ui-effco/svg-icons/social/person'
import Classes from 'material-ui-effco/svg-icons/action/event'
import Group from 'material-ui-effco/svg-icons/social/group'
import School from 'material-ui-effco/svg-icons/social/school'
import User from 'material-ui-effco/svg-icons/action/supervisor-account'
import Template from 'material-ui-effco/svg-icons/action/description'
import Notification from 'material-ui-effco/svg-icons/social/notifications'
import Signups from 'material-ui-effco/svg-icons/social/plus-one'
import Profile from 'material-ui-effco/svg-icons/action/account-box'
import Logout from 'material-ui-effco/svg-icons/content/clear'
import * as actions from '../actions'
import { NAME } from '../constants'

class Sidebar extends Component {
  renderTeacherSection(user) {
    if (user ? user.role === 'Prowadzący' : false) {
      const { palette } = this.props
      return (
        <div>
          <Subheader>Widoki</Subheader>
          <ListItem
            leftIcon={<Classes color={palette.primary1Color} />}
            primaryText="Zajęcia"
            onClick={() => {
              this.props.navigate('/zajecia')
            }}
          />
        </div>
      )
    }
  }

  renderNotificationToggle() {
    if (this.props.notifications.length) {
      return (
        <Toggle
          toggled={this.props.notifications[0].active}
          onToggle={() => {
            this.props.notificationsToggle('all')
          }}
        />
      )
    }
  }

  renderAdminSection(user) {
    if (user ? user.role !== 'Prowadzący' : false) {
      const { palette } = this.props

      return (
        <div>
          <Subheader>Administracja</Subheader>
          <ListItem
            leftIcon={<Person color={palette.primary1Color} />}
            primaryText="Uczestnicy"
            onTouchTap={() => {
              this.props.navigate('/uczestnicy/')
            }}
          />
          <ListItem
            leftIcon={<Group color={palette.primary1Color} />}
            primaryText="Grupy"
            onTouchTap={() => {
              this.props.navigate('/grupy/')
            }}
            initiallyOpen
          />
          <ListItem
            leftIcon={<School color={palette.primary1Color} />}
            primaryText="Szkoły"
            onTouchTap={() => {
              this.props.navigate('/szkoly/')
            }}
            initiallyOpen
          />
          <Divider
            style={{
              marginLeft: 15,
              marginRight: 15,
              marginTop: 15,
              marginBottom: 15
            }}
          />
          <ListItem
            leftIcon={<Template color={palette.primary1Color} />}
            primaryText="Szablony"
            onTouchTap={() => {
              this.props.navigate('/szablony/')
            }}
          />
          <ListItem
            leftIcon={<User color={palette.primary1Color} />}
            primaryText="Użytkownicy"
            onTouchTap={() => {
              this.props.navigate('/uzytkownicy/')
            }}
          />
          <ListItem
            leftIcon={<Notification color={palette.primary1Color} />}
            primaryText="Powiadomienia"
            onTouchTap={() => {
              this.props.navigate('/powiadomienia/')
            }}
          />
          <ListItem
            leftIcon={<Signups color={palette.primary1Color} />}
            primaryText="Zapisy"
            onTouchTap={() => {
              this.props.navigate('/zapisy/')
            }}
          />

        </div>
      )
    }
  }

  render() {
    const {
      toggleNav,
      navigate,
      core: { sidebar, profile },
      palette
    } = this.props
    const styles = {
      buildInfo: {
        // position: 'fixed',
        // width: 280,
        bottom: 0,
        // margin: 10,
        marginBottom: 25
      },
      buildText: {
        marginLeft: 20
      },
      userBox: {
        // minHeight: 150,
        display: 'flex',
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 25
      },
      avatar: {
        marginLeft: 10,
        marginRight: 15,
        marginTop: 20
      },
      userTitle: {
        marginTop: 11
      }
    }
    const userInitials = profile.firstName
      ? profile.firstName.substr(0, 1).toUpperCase() +
        profile.lastName.substr(0, 1).toUpperCase()
      : ''
    const userName = profile.firstName
      ? `${profile.firstName} ${profile.lastName}`
      : ''
    const version = profile.prefs.version
    return (
      <div>
        <Drawer
          docked={false}
          width={300}
          open={sidebar}
          onRequestChange={() => toggleNav()}
          zDepth={2}
        >
          <div style={styles.userBox}>
            <div style={styles.avatar}>
              <Avatar backgroundColor={palette.primary1Color}>
                {userInitials}
              </Avatar>
            </div>
            <div style={styles.userTitle}>
              {userName}
              <br />
              <small>{profile ? profile.email : ''}</small> <br />
              <small>{profile ? profile.role : ''}</small>
            </div>
          </div>
          <Divider />
          <List>
            {this.renderTeacherSection(profile)}
            {this.renderAdminSection(profile)}
          </List>
          <Divider />
          <List>
            <Subheader>Użytkownik</Subheader>
            <ListItem
              leftIcon={<Profile color={palette.primary1Color} />}
              primaryText="Profil"
              onTouchTap={() => navigate('/profil')}
            />
            <ListItem
              leftIcon={<Logout color={palette.primary1Color} />}
              primaryText="Wyloguj"
              onTouchTap={() => navigate('/logout')}
            />
          </List>
          <div style={styles.buildInfo}>
            <Divider />
            <Subheader>Informacje techniczne</Subheader>
            <small>
              {/* <b style={styles.buildText}>Klient: </b> {VERSION || 'NIEZNANA'}
              <br /> */}
              <b style={styles.buildText}>API: </b> {version || 'NIEZNANA'}
              <br />
            </small>
          </div>
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state[NAME],
    notifications: state.notifications,
    profile: state.profile
  }
}

export default connect(mapStateToProps, actions)(Sidebar)
