import React, { Component } from 'react'
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody,
  TableRowColumn
} from 'material-ui-effco'
import _ from 'lodash'
import ClassesDetailTableRowSemester from './detail_table_row_semester'

class ClassesDetailTable extends Component {
  renderTableHeader(groupSelected) {
    const { dates, subjects } = this.props

    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow style={{ height: 36 }}>
          <TableHeaderColumn style={{ height: 36, width: 150 }}>
            Imię i Nazwisko
          </TableHeaderColumn>
          <TableHeaderColumn
            style={{ height: 36, width: 40, textAlign: 'center' }}
          >
            Klasa
          </TableHeaderColumn>
          <TableHeaderColumn
            style={{ height: 36, width: 350, textAlign: 'center' }}
          >
            Uwagi
          </TableHeaderColumn>
          {groupSelected.classes
            .filter(
              cl =>
                Date.parse(cl.date) > Date.parse(dates.from) &&
                Date.parse(cl.date) < Date.parse(dates.to)
            )
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map(header => {
              const dateFormatted =
                ('0' + new Date(header.date).getDate()).slice(-2) +
                '/' + // eslint-disable-line prefer-template, max-len
                ('0' + (new Date(header.date).getMonth() + 1)).slice(-2) // eslint-disable-line prefer-template, max-len
              return (
                <TableHeaderColumn
                  key={header.date}
                  style={{ height: 36, width: 3 }}
                >
                  <span
                    title={
                      header.subject &&
                      _.find(subjects, { _id: header.subject }) &&
                      _.find(subjects, { _id: header.subject }).subject
                    }
                  >
                    {dateFormatted}
                  </span>
                </TableHeaderColumn>
              )
            })}
          <TableRowColumn style={{ height: 36, width: 1 }} />
        </TableRow>
      </TableHeader>
    )
  }

  renderTableBody(groupSelected) {
    if (groupSelected.pupils.length) {
      return groupSelected.pupils.map((pupil, index) => (
        <ClassesDetailTableRowSemester
          key={index}
          index={index}
          pupil={pupil}
          {...this.props}
        />
      ))
    }
    return (
      <TableRow>
        <TableRowColumn>
          Brak uczestników o podanych parametrach...
        </TableRowColumn>
      </TableRow>
    )
  }

  render() {
    const { data: { active, data: { groups } } } = this.props

    return (
      <Table bodyStyle={{ overflow: 'visible', tableLayout: 'auto' }}>
        {this.renderTableHeader(_.find(groups, { _id: active }))}
        <TableBody showRowHover displayRowCheckbox={false} style>
          {this.renderTableBody(_.find(groups, { _id: active }))}
        </TableBody>
      </Table>
    )
  }
}

export default ClassesDetailTable
