import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { API_URL } from '../constants'
import NoAccess from './NoAccess'
import { auth } from '../helpers'

class Auth extends Component {
  state = {}

  async componentWillMount() {
    const { data } = await axios.get(`${API_URL}/users`, {
      headers: { authorization: auth(true) }
    })
    this.setState({ data })
  }

  userIsAllowed = (idToTest, users, allowedRoles) => {
    return (
      idToTest && allowedRoles.includes(_.find(users, { _id: idToTest }).role)
    )
  }

  render() {
    const { data: users } = this.state
    const { profile } = this.props
    if (!users || !profile) return null
    const {
      app,
      route,
      roles = ['Admin', 'Super Admin'],
      children
    } = this.props

    const userIsNotAllowed = route ? (
      <NoAccess
        style={{ marginTop: 200 }}
        title="Brak dostępu"
        icon="supervisor_account"
        subtitle="Nie masz dostępu do zawartości tej strony"
      />
    ) : null

    if (!app && !this.userIsAllowed(profile._id, users, roles))
      return userIsNotAllowed
    return <div>{children}</div>
  }
}

export default Auth
