import React, { Component } from 'react';
import { IconButton } from 'material-ui-effco';
import ListIcon from 'material-ui-effco/svg-icons/action/list';
import SemOneIcon from 'material-ui-effco/svg-icons/image/looks-one';
import SemTwoIcon from 'material-ui-effco/svg-icons/image/looks-two';


class ClassesDetailTableButtons extends Component {


  render() {
    const { changeView } = this.props;
    return (<div>
      <IconButton
        tooltip="Obecności Semestr I"
        tooltipPosition="bottom-left"
        touch
        onTouchTap={() => changeView('s1')}
      >
        <SemOneIcon />
      </IconButton>
      <IconButton
        tooltip="Obecności Semestr II"
        tooltipPosition="bottom-left"
        touch
        onTouchTap={() => changeView('s2')}
      >
        <SemTwoIcon />
      </IconButton>
      <IconButton
        tooltip="Informacje kontaktowe, uwagi"
        tooltipPosition="bottom-left"
        touch
        onTouchTap={() => changeView('info')}
      >
        <ListIcon />
      </IconButton>
    </div>);
  }

}

export default ClassesDetailTableButtons;
