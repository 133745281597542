export const NAME = 'common'

export const hours = {
  from: [
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45'
  ],
  to: [
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45'
  ]
}

export const hoursObjEx = {
  from: [
    '08:00',
    '08:05',
    '08:10',
    '08:15',
    '08:20',
    '08:25',
    '08:30',
    '08:35',
    '08:40',
    '08:45',
    '08:50',
    '08:55',
    '09:00',
    '09:05',
    '09:10',
    '09:15',
    '09:20',
    '09:25',
    '09:30',
    '09:35',
    '09:40',
    '09:45',
    '09:50',
    '09:55',
    '10:00',
    '10:05',
    '10:10',
    '10:15',
    '10:20',
    '10:25',
    '10:30',
    '10:35',
    '10:40',
    '10:45',
    '10:50',
    '10:55',
    '11:00',
    '11:05',
    '11:10',
    '11:15',
    '11:20',
    '11:25',
    '11:30',
    '11:35',
    '11:40',
    '11:45',
    '11:50',
    '11:55',
    '12:00',
    '12:05',
    '12:10',
    '12:15',
    '12:20',
    '12:25',
    '12:30',
    '12:35',
    '12:40',
    '12:45',
    '12:50',
    '12:55',
    '13:00',
    '13:05',
    '13:10',
    '13:15',
    '13:20',
    '13:25',
    '13:30',
    '13:35',
    '13:40',
    '13:45',
    '13:50',
    '13:55',
    '14:00',
    '14:05',
    '14:10',
    '14:15',
    '14:20',
    '14:25',
    '14:30',
    '14:35',
    '14:40',
    '14:45',
    '14:50',
    '14:55',
    '15:00',
    '15:05',
    '15:10',
    '15:15',
    '15:20',
    '15:25',
    '15:30',
    '15:35',
    '15:40',
    '15:45',
    '15:50',
    '15:55',
    '16:00',
    '16:05',
    '16:10',
    '16:15',
    '16:20',
    '16:25',
    '16:30',
    '16:35',
    '16:40',
    '16:45',
    '16:50',
    '16:55',
    '17:00'
  ],
  to: [
    '08:45',
    '08:50',
    '08:55',
    '09:00',
    '09:05',
    '09:10',
    '09:15',
    '09:20',
    '09:25',
    '09:30',
    '09:35',
    '09:40',
    '09:45',
    '09:50',
    '09:55',
    '10:00',
    '10:05',
    '10:10',
    '10:15',
    '10:20',
    '10:25',
    '10:30',
    '10:35',
    '10:40',
    '10:45',
    '10:50',
    '10:55',
    '11:00',
    '11:05',
    '11:10',
    '11:15',
    '11:20',
    '11:25',
    '11:30',
    '11:35',
    '11:40',
    '11:45',
    '11:50',
    '11:55',
    '12:00',
    '12:05',
    '12:10',
    '12:15',
    '12:20',
    '12:25',
    '12:30',
    '12:35',
    '12:40',
    '12:45',
    '12:50',
    '12:55',
    '13:00',
    '13:05',
    '13:10',
    '13:15',
    '13:20',
    '13:25',
    '13:30',
    '13:35',
    '13:40',
    '13:45',
    '13:50',
    '13:55',
    '14:00',
    '14:05',
    '14:10',
    '14:15',
    '14:20',
    '14:25',
    '14:30',
    '14:35',
    '14:40',
    '14:45',
    '14:50',
    '14:55',
    '15:00',
    '15:05',
    '15:10',
    '15:15',
    '15:20',
    '15:25',
    '15:30',
    '15:35',
    '15:40',
    '15:45',
    '15:50',
    '15:55',
    '16:00',
    '16:05',
    '16:10',
    '16:15',
    '16:20',
    '16:25',
    '16:30',
    '16:35',
    '16:40',
    '16:45',
    '16:50',
    '16:55',
    '17:00',
    '17:05',
    '17:10',
    '17:15',
    '17:30',
    '17:45'
  ]
}

export const hoursEx = [
  '08:00',
  '08:05',
  '08:10',
  '08:15',
  '08:20',
  '08:25',
  '08:30',
  '08:35',
  '08:40',
  '08:45',
  '08:50',
  '08:55',
  '09:00',
  '09:05',
  '09:10',
  '09:15',
  '09:20',
  '09:25',
  '09:30',
  '09:35',
  '09:40',
  '09:45',
  '09:50',
  '09:55',
  '10:00',
  '10:05',
  '10:10',
  '10:15',
  '10:20',
  '10:25',
  '10:30',
  '10:35',
  '10:40',
  '10:45',
  '10:50',
  '10:55',
  '11:00',
  '11:05',
  '11:10',
  '11:15',
  '11:20',
  '11:25',
  '11:30',
  '11:35',
  '11:40',
  '11:45',
  '11:50',
  '11:55',
  '12:00',
  '12:05',
  '12:10',
  '12:15',
  '12:20',
  '12:25',
  '12:30',
  '12:35',
  '12:40',
  '12:45',
  '12:50',
  '12:55',
  '13:00',
  '13:05',
  '13:10',
  '13:15',
  '13:20',
  '13:25',
  '13:30',
  '13:35',
  '13:40',
  '13:45',
  '13:50',
  '13:55',
  '14:00',
  '14:05',
  '14:10',
  '14:15',
  '14:20',
  '14:25',
  '14:30',
  '14:35',
  '14:40',
  '14:45',
  '14:50',
  '14:55',
  '15:00',
  '15:05',
  '15:10',
  '15:15',
  '15:20',
  '15:25',
  '15:30',
  '15:35',
  '15:40',
  '15:45',
  '15:50',
  '15:55',
  '16:00',
  '16:05',
  '16:10',
  '16:15',
  '16:20',
  '16:25',
  '16:30',
  '16:35',
  '16:40',
  '16:45',
  '16:50',
  '16:55',
  '17:00',
  '17:05',
  '17:10',
  '17:15',
  '17:30',
  '17:45'
]
