import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {
  ConnectedRouter,
  routerReducer,
  routerMiddleware
} from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import reduxThunk from 'redux-thunk'
import promise from 'redux-promise'
import { reducer as form_v5 } from 'redux-form-v5'
import { reducer as form } from 'redux-form'
import MuiThemeProvider from 'material-ui-effco/styles/MuiThemeProvider'
import { MuiThemeProvider as MuiThemeProviderNext } from 'material-ui'
import injectTapEventPlugin from 'react-tap-event-plugin'
import core from './core/'
import classes from './classes/'
import pupils from './_old/pupils/'
import templates from './_old/templates/'
// import notifications from './notifications'
import communication from './communication'
import cities from './cities'
// import users from './users/'
import schools from './_old/schools'
import groups from './_old/groups'
import App from './App'

// Import modules

const { oldTheme, newTheme } = core.constants

injectTapEventPlugin()

// Compose root reducer
const rootReducer = combineReducers({
  form_v5,
  form,
  core: core.reducer,
  pupils: pupils.reducer,
  classes: classes.reducer,
  cities: cities.reducer,
  schools: schools.reducer,
  groups: groups.reducer,
  // notifications: notifications.reducer,
  communication: communication.reducer,
  templates: templates.reducer,
  // users: users.reducer,
  router: routerReducer
})

// Router+Redux
const history = createHistory()

const createStoreWithMiddleware = applyMiddleware(
  promise,
  reduxThunk,
  routerMiddleware(history)
)(createStore)
const store = createStoreWithMiddleware(
  rootReducer,
  window.devToolsExtension ? window.devToolsExtension() : f => f
)
// const token = localStorage.getItem('token')
// axios.defaults.headers['Authorization'] = token || null

// Configure router
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider muiTheme={oldTheme}>
        <MuiThemeProviderNext theme={newTheme}>
          <App />
        </MuiThemeProviderNext>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
