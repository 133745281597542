import React from 'react';
import { Chip } from 'material-ui-effco';
const RecipientList = ({ recipients }) => {
  const styles = {
    chip: {
      margin: 4,
      disabled: true,
      borderRadius: 0,
    },
    wrapper: {
      marginTop: 10,
      display: 'flex',
      flexWrap: 'wrap',
      textJustify: 'center',
    },
  };

  return (
    <div style={styles.wrapper}>
      {recipients.map(rec =>
        <Chip key={rec._id} style={styles.chip}>{rec.name}</Chip>,
      )}
    </div>
  );
};

export default RecipientList;
