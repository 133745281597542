import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash-checkit'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import common from '../../common'
import dxTable from '../../grid_table'
import {
  getSlots,
  cmpSlots,
  applySlots,
  rejectSmallTimeFrame
} from '../helpers'
import { columns } from '../constants'
// import qs from 'query-string'
// import flat from 'flat'

import InnerRouter from './InnerRouter'

const {
  constants: { API_URL },
  helpers: { api, getUserRole, getSessionFilters, setSessionFilters }
} = core

const {
  helpers: { createBellsArray, mergeBellsArray }
} = common

const {
  helpers: { extractFormFieldsData }
} = dxTable

class Container extends Component {
  state = {}

  async fetchToState(refresh = false) {
    const userRole = await getUserRole()
    const cityArray =
      userRole !== 'Super Admin' ? this.props.profile.cities : []
    const filterByCity = userRole !== 'Super Admin'
    const data = await api.get([
      'schools',
      'cities',
      'pupils',
      { bells: 'settings?name=defaultBells' }
    ])

    const filteredCities = data.cities.filter(
      f => (filterByCity ? cityArray.includes(f.name) : true)
    )

    // if (!sessionStorage.getItem('filter')) sessionStorage.setItem('filter', '')
    let defaultFilters = getSessionFilters('defaultFilter', [])

    if (_.isEmpty(defaultFilters)) {
      defaultFilters =
        cityArray.length > 0
          ? [
              {
                columnName: 'city',
                value: [
                  _.includes(cityArray, 'Warszawa') || cityArray.length === 0
                    ? 'Warszawa'
                    : cityArray[0]
                ]
              }
            ]
          : [{ columnName: 'city', value: ['Warszawa'] }]

      setSessionFilters('defaultFilter', defaultFilters)
    }

    let mappedSchool = []
    let mappedDistrict = []
    const mappedCities = filteredCities.map(c => {
      c.id = c._id
      delete c._id
      c.id = c.name
      c.label = c.name
      delete c.name
      delete c.districts
      return c
    })
    let mappedClassNames = []

    const defaultBells = _.get(data.bells, 'value') || createBellsArray()

    const mappedPupils = data.pupils
      .filter(f => (filterByCity ? cityArray.includes(f.city) : true))
      .filter(f => !f.group && f.tag !== 'Wypisy' && f.tag !== 'Rezygnacje')
      .map(p => {
        const school = _.find(data.schools, { _id: p.school })
        if (school)
          mappedSchool.push({
            id: school._id,
            number: school.number,
            label: `${school.number} (${school.address}) `,
            code: school.code,
            address: school.address,
            district: school.district,
            city: school.city,
            bells: mergeBellsArray(school.bells, defaultBells)
          })
        if (school)
          mappedDistrict.push({
            id: school.district,
            label: school.district,
            city: school.city
          })
        if (
          !_.find(mappedClassNames, [
            'id',
            p.className && p.className.toUpperCase().trim()
          ])
        ) {
          mappedClassNames.push({
            id: p.className && p.className.toUpperCase().trim(),
            label: p.className && p.className.toUpperCase().trim()
          })
        }
        const slots = getSlots(p)
        p.id = p._id
        delete p._id
        delete p.contact
        // delete p.notes
        delete p.payment
        // delete p.tag
        p.school = school && school._id //.number
        p.schoolNo = school && school.number
        p.schoolId = school && school._id
        p.district = school && school.district
        p.bells = school && school.bells
        p.className = p.className && p.className.toUpperCase()
        p.classes = slots
        p.classesBase = slots
        p.notes = p.notes //eslint-disable-line no-self-assign
        // p.tag = p.tag
        p.leftover = p.tag === 'Pozostałości' ? 'Tak' : 'Nie'
        return p
      })

    const availableValues = {
      groups: [],
      cities: mappedCities,
      classFilter: _.sortBy(mappedClassNames, 'id'),
      schools: _.sortBy(_.uniqBy(mappedSchool, 'id'), ['code']),
      districts: _.sortBy(_.uniqBy(mappedDistrict, 'id'), ['label'])
    }

    this.setState({
      data: mappedPupils,
      dxTableConfig: {
        availableValues,
        defaultBells
      }
    })
  }

  async fetchSelected(selection) {
    const userRole = await getUserRole()
    const cityArray =
      userRole !== 'Super Admin' ? this.props.profile.cities : []
    const filterByCity = userRole !== 'Super Admin'
    const { data: currentData } = this.state
    const data = await api.get([
      'schools',
      'cities',
      { bells: 'settings?name=defaultBells' }
    ])
    const { data: pupils } = await axios.post(
      `${API_URL}/pupils/byIds`,
      { idArray: selection },
      {
        headers: { authorization: localStorage.getItem('token') }
      }
    )

    const defaultBells = _.get(data.bells, 'value') || createBellsArray()

    let mappedSchool = []
    let mappedDistrict = []
    const mappedPupils = pupils
      .filter(f => (filterByCity ? cityArray.includes(f.city) : true))
      .filter(f => !f.group && f.tag !== 'Wypisy' && f.tag !== 'Rezygnacje')
      .map(p => {
        const school = _.find(data.schools, { _id: p.school })
        mappedSchool.push({
          id: school._id,
          number: school.number,
          label: `${school.number} (${school.address}) `,
          code: school.code,
          address: school.address,
          district: school.district,
          bells: mergeBellsArray(school.bells, defaultBells)
        })
        mappedDistrict.push({
          id: school.district,
          label: school.district,
          city: school.city
        })
        const slots = getSlots(p)
        p.id = p._id
        delete p._id
        delete p.contact
        // delete p.notes
        delete p.payment
        // delete p.tag
        p.school = school && school._id //.number
        p.schoolNo = school && school.number
        p.schoolId = school && school._id
        p.district = school && school.district
        p.bells = school && school.bells
        p.classes = slots
        p.classesBase = slots
        p.notes = p.notes //eslint-disable-line no-self-assign
        // p.tag = p.tag
        p.leftover = p.tag === 'Pozostałości' ? 'Tak' : 'Nie'
        return p
      })

    const dataAdjusted = currentData.map(e => {
      const replace = _.find(mappedPupils, ['id', e.id])
      return replace ? { ...e, ...replace } : e
    })

    this.setState({
      data: dataAdjusted
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  setGroupDetails = groupDetails => {
    this.setState({
      ...this.state,
      dxTableConfig: {
        ...this.state.dxTableConfig,
        groupDetails
      }
    })
  }

  compareSlots(selection) {
    const { data } = this.state
    const dataFiltered = data.filter(f => _.includes(selection, f.id))

    const dataOrigin = data
      .filter(f => !_.includes(selection, f.id))
      .map(e => ({
        ...e,
        classes: e.classesBase
      }))

    const slots = dataFiltered.map(e => e.classesBase)

    const availableClasses = rejectSmallTimeFrame(cmpSlots(slots))

    const dataAdjusted = dataFiltered.map(e => ({
      ...e,
      classes: applySlots(e.classesBase, availableClasses)
    }))

    return data.map(e => {
      const replace =
        _.get(_.find(dataAdjusted, ['id', e.id]), 'classes') ||
        _.get(_.find(dataOrigin, ['id', e.id]), 'classesBase')
      return replace ? { ...e, classes: replace } : e
    })
  }

  async refreshTableView(selection, dataRefresh, clearData = false) {
    let dataAdjusted = this.state.data
    if (clearData) {
      dataAdjusted = this.clearData(dataAdjusted, selection)
    } else {
      if (dataRefresh) await this.fetchSelected(selection)
      dataAdjusted = this.compareSlots(selection)
    }
    // console.log(dataAdjusted)

    this.setState({
      ...this.state,
      data: dataAdjusted,
      rowsData: extractFormFieldsData(columns, dataAdjusted)
    })
  }

  clearData(data, selection) {
    return _.reject(data, e => _.includes(selection, e.id))
    // this.setState({
    //   data: undefined
    // })
  }

  toggleRefresh(refresh = false) {
    this.setState({
      rowsData: undefined
    })
  }

  render() {
    const { data, rowsData } = this.state
    if (!data) return <Spinner />
    const { dxTableConfig } = this.state

    return (
      <InnerRouter
        data={data}
        toggleRefresh={this.toggleRefresh.bind(this)}
        refreshTableView={this.refreshTableView.bind(this)}
        dxTableConfig={{
          ...dxTableConfig,
          rowsData,
          setGroupDetails: this.setGroupDetails.bind(this)
        }}
        {...this.props}
      />
    )
  }
}

export default Container
