import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../common'
import { getAll } from '../selectors'

const { MuiSelectfield } = common.components

class TagsSelectfield extends Component {

  // componentWillMount() {
  //   if (!this.props.data.loading) this.props.fetch()
  // }

  render() {
    const
      { onChange, field, hintText, disabled, data: { data: { tags } } } = this.props

    const tagOptions = tags.map(tag =>
      <MenuItem
        key={tag}
        value={tag}
        primaryText={tag}
      />
    )
    tagOptions.unshift(<MenuItem key={null} value={null} primaryText={'Brak'} />)

    return (
      <MuiSelectfield
        field={field}
        onChange={onChange}
        floatingLabelText="Tag"
        disabled={disabled}
        hintText={hintText}
        children={tagOptions}
      />
      // {/*<AutoComplete
      //   floatingLabelText="Tag"
      //   disabled={disabled}
      //   onNewRequest={onChange}
      //   dataSource={tags}
      //   searchText={field.value}
      //   filter={AutoComplete.caseInsensitiveFilter}
      // />*/}
    )
  }
}

const mapState = state => ({
  data: getAll(state)
})

export default connect(mapState)(TagsSelectfield)
