export const NAME = 'registrations'
export const LABEL = 'Zapisy'
export const PATH = `/${NAME}/`
export const PATH_PL = `/administracja/zapisy/lista/`
export const PATH_PROCESS = `/administracja/zapisy/procesowanie/`
export const PATH_PUPILS = `/pupils/`

export const fieldMappings = {
  firstName: 'Imię',
  lastName: 'Nazwisko',
  'contact.phone': 'Telefon',
  'contact.email': 'Email',
  'notes.signup': 'Informacje dodatkowe',
  'notes.general': 'Uwagi ogólne',
  'notes.general2': 'Uwagi od Rodzica',
  city: 'Miasto',
  district: 'Dzielnica',
  school: 'Szkoła',
  className: 'Klasa'
}
