import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../../common'
import * as actions from '../actions'
import * as selectors from '../selectors'

const { MuiSelectfield } = common.components

class TemplatesSelectfield extends Component {
  componentWillMount() {
    this.props.fetch()
  }

  render() {
    const {
      type,
      onChange,
      field,
      disabled,
      data: { data: { templates } }
    } = this.props

    return (
      <MuiSelectfield
        field={field}
        onChange={onChange}
        hintText="Wybierz szablon"
        floatingLabelText="Szablon"
        disabled={disabled}
        children={templates
          .filter(tpl => (type ? tpl.type === type : true))
          .map(tpl => (
            <MenuItem key={tpl._id} value={tpl._id} primaryText={tpl.name} />
          ))}
      />
    )
  }
}

const mapState = state => ({
  data: selectors.getNonNotificationSorted(state)
})

export default connect(mapState, actions)(TemplatesSelectfield)
