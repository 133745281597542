import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../../common'
import _ from 'lodash'
import * as actions from '../actions'
import * as selectors from '../selectors'

const { MuiSelectfield } = common.components

class SchoolsSelectfield extends Component {
  componentWillMount() {
    this.props.fetch()
  }

  render() {
    const {
      city,
      onChange,
      field,
      disabled,
      data: { data: { schools } }
    } = this.props

    return (
      <MuiSelectfield
        field={field._id}
        onChange={onChange}
        hintText="Wybierz szkołę *"
        floatingLabelText="Szkoła"
        disabled={disabled}
        children={_.sortBy(schools, [
          function(o) {
            return o.code ? (o.code ? o.code * 1 : 999) : 999
          }
        ])
          .filter(s => s.city === city)
          .map(school => (
            <MenuItem
              key={school._id}
              value={school._id}
              primaryText={
                school.number + (school.address ? ` (${school.address})` : '')
              }
            />
          ))}
      />
    )
  }
}

const mapState = state => ({
  data: selectors.getAllSorted(state)
})

export default connect(mapState, actions)(SchoolsSelectfield)
