import React from 'react'
import { Snackbar } from 'material-ui-effco'

export default ({ snackbarMsg, clear }) => (
  <Snackbar
    open={!!snackbarMsg}
    message={snackbarMsg || ''}
    autoHideDuration={2500}
    bodyStyle={{ textAlign: 'center' }}
    onRequestClose={() => clear()}
  />
)
