import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
import BottomNavigation, {
  BottomNavigationAction
} from 'material-ui/BottomNavigation'
import CalendarMultiple from 'mdi-material-ui/CalendarMultiple'
import Account from 'mdi-material-ui/Account'
import core from '../../core'
import Classes from './Classes'
import Profile from './Profile'

const {
  components: { Login, Logout }
} = core

const RouterMobile = props => {
  const mobilePath = '/mobile/'

  return [
    <Switch key={1}>
      <Redirect exact strict from="/" to={`${mobilePath}zajecia`} />
      <Route
        exact
        path={`${mobilePath}zajecia/`}
        render={renderProps => <Classes {...props} {...renderProps} />}
      />
      <Route
        path={`${mobilePath}zajecia/:id`}
        render={renderProps => <Classes {...props} {...renderProps} />}
      />
      <Route
        path={`${mobilePath}profil`}
        render={renderProps => <Profile {...props} {...renderProps} />}
      />
      <Route
        path="/login"
        render={renderProps => <Login {...props} {...renderProps} />}
      />
      <Route
        path="/logout"
        render={renderProps => <Logout {...props} {...renderProps} />}
      />
    </Switch>,
    <Route
      key={2}
      path={mobilePath}
      render={renderProps => {
        const pathArray = renderProps.location.pathname.split('/')

        return (
          <BottomNavigation
            value={pathArray[2]}
            style={{
              position: 'fixed',
              left: 0,
              bottom: 0,
              width: '100%'
            }}
            showLabels
          >
            <BottomNavigationAction
              label="Zajęcia"
              value="zajecia"
              onClick={() => renderProps.history.push(`${mobilePath}zajecia`)}
              icon={<CalendarMultiple />}
            />
            <BottomNavigationAction
              label="Profil"
              value="profil"
              onClick={() => renderProps.history.push(`${mobilePath}profil`)}
              icon={<Account />}
            />
          </BottomNavigation>
        )
      }}
    />
  ]
}

export default RouterMobile
