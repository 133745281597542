import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { Spinner } from 'material-ui-elements'
import core from '../../core/'
import { MuiTable, Fab } from 'material-ui-elements'
import { PATH, PATH_PL, LABEL } from '../constants'
import { Chip } from 'material-ui-effco'

const { constants: { API_URL }, helpers: { getUserRole, getUserCities } } = core

const { oldTheme: { palette } } = core.constants

class Container extends Component {
  state = {}

  async fetchToState() {
    const cities =
      (await getUserRole()) !== 'Super Admin' &&
      getUserCities(this.props.profile)
    const profileId = this.props.profile && this.props.profile._id

    const { data } = await axios.get(`${API_URL}${PATH}`, {
      headers: { authorization: localStorage.getItem('token') }
    })

    const superAdmin = (await getUserRole()) === 'Super Admin'

    this.setState({
      data: data
        .filter(s => (superAdmin ? true : s.role !== 'Super Admin'))
        .filter(s => (cities ? _.intersection(cities, s.cities) : true))
        .filter(s => s._id !== profileId)
        .map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    })
  }

  async componentWillMount() {
    this.fetchToState()
  }

  async componentWillReceiveProps() {
    this.fetchToState()
  }

  render() {
    const { history: { push } } = this.props
    const { data } = this.state
    if (!data) return <Spinner />

    return (
      <div>
        <Fab onClick={() => push(`${PATH_PL}new/?view=edit`)} />
        <MuiTable
          layout={MuiTable.layouts.paper}
          orderBy="active"
          order="desc"
          filterBy="email"
          lang="pl"
          narrow
          title={LABEL}
          columns={[
            {
              name: 'email',
              label: 'Email'
            },
            {
              name: 'firstName',
              label: 'Imię'
            },
            {
              name: 'lastName',
              label: 'Nazwisko'
            },
            {
              name: 'phone',
              label: 'Telefon',
              style: { width: 100 }
            },
            {
              name: 'notes',
              label: 'Notatka'
            },
            {
              name: 'role',
              label: 'Rola',
              style: { width: 100 },
              component: v => (
                <Chip
                  backgroundColor={
                    v !== 'Prowadzący'
                      ? palette.primary1Color //palette.primary3Color
                      : palette.primary2Color //palette.primary1Color
                  }
                  labelColor={palette.alternateTextColor}
                  labelStyle={{ fontSize: 12, fontWeight: 500 }}
                  style={{
                    disabled: true,
                    borderRadius: 0,
                    minWidth: 95,
                    display: 'flex',
                    textAlign: 'center'
                  }}
                >
                  {
                    <div style={{ textAlign: 'center' }}>
                      <strong>{v}</strong>
                    </div>
                  }
                </Chip>
              )
            },
            /*{
              name: 'city',
              label: 'Miasto',
              style: { width: 100 }
            },*/
            {
              name: 'active',
              label: 'Status',
              style: { width: 100 },
              component: v => (v ? 'Aktywny' : 'Nieaktywny')
            }
          ]}
          data={data}
          onRowClick={i => push(`${PATH_PL}${i}`)}
        />
      </div>
    )
  }
}

export default Container
