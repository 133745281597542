import axios from 'axios'
import _ from 'lodash'
import { push } from 'react-router-redux'
import { reset, destroy, initialize } from 'redux-form-v5'
import core from '../../core'
import * as t from './types'
import { NAME } from './constants'

const { constants: { API_URL }, form: { fields } } = core

export const activate = (id = null) => dispatch => {
  dispatch({ type: t.ACTIVATE, payload: id })
  dispatch(push(`/grupy/${id || ''}`))
  if (!id) dispatch(destroy(NAME))
}

export const fetch = (id = null) => async dispatch => {
  const groups = (await axios.get(`${API_URL}/${NAME}/`, {
    headers: { authorization: localStorage.getItem('token') }
  })).data
  const teachers = (await axios.get(`${API_URL}/users/`, {
    headers: { authorization: localStorage.getItem('token') }
  })).data
  dispatch({
    type: t.FETCH,
    payload: groups.map(g => ({
      ...g,
      teacher: _.find(teachers, { _id: g.teacher })
    }))
  })
  if (id !== 'noActivate') {
    dispatch(activate(id))
  }
}

export const clearFetch = (id = null) => dispatch => {
  dispatch({
    type: t.CLEAR_FETCH
  })
}

export const editToggle = () => ({ type: t.EDIT })

export const editUndo = () => dispatch => {
  dispatch(reset(NAME))
  dispatch(editToggle())
}

export const editSave = record => dispatch => {
  axios
    .put(`${API_URL}/${NAME}/${record._id}`, record, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(fetch())
      dispatch(core.actions.snackbarMessage(res.data.message))
    })
    .then(() => {
      dispatch(activate())
    })
    .catch(() => {
      dispatch(core.actions.snackbarMessage('Edycja szkoły nie powiodła się'))
    })
}

export const addSave = record => dispatch => {
  axios
    .post(`${API_URL}/${NAME}`, record, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(fetch())
      dispatch(core.actions.snackbarMessage(res.data.message))
    })
    .then(() => {
      dispatch(activate())
    })
    .catch(() => {
      dispatch(
        core.actions.snackbarMessage('Utworzenie szkoły nie powiodło się')
      )
    })
}

export const deleteSave = id => dispatch => {
  axios
    .delete(`${API_URL}/${NAME}/${id}`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    .then(res => {
      dispatch(fetch())
      dispatch(activate())
      dispatch(core.actions.snackbarMessage(res.data.message))
    })
}

export const addShow = () => dispatch => {
  dispatch({ type: t.ACTIVATE, payload: 'add' })
  dispatch({ type: t.ADD })
  dispatch(initialize(NAME, {}, fields))
}
