import React, { Component } from 'react'
import { withTheme, withStyles } from 'material-ui/styles'
import _ from 'lodash'
import { IconButton, ToolbarGroup } from 'material-ui-effco'
import FilterIcon from 'mdi-material-ui/Filter'
import ViewWeekIcon from 'material-ui-icons/ViewWeek'
import EditIcon from 'material-ui-icons/Edit'
import Chip from 'material-ui/Chip'
import Tooltip from 'material-ui/Tooltip'

import { chipConfig, PATH_PL } from '../constants'
import { getSchoolsLabels } from '../helpers'
import { getSessionFilters } from '../../core/helpers'

const styles = theme => ({
  chip: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: theme.spacing.unit / 2,
    marginTop: 10,
    fontWeight: 'normal', //'bold',
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  chipLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  tooltip: {
    fontSize: '0.85rem',
    padding: '8px 16px'
  }
})

class ToolbarOptions extends Component {
  handleDelete = (chip, filters) => {
    const { changeFilters } = this.props

    const filtersAdjusted = _.reject(filters, ['columnName', chip.columnName])
    if (chip.columnName === 'city') {
      _.find(getSessionFilters('defaultFilters'), [
        'columnName',
        chip.columnName
      ])
      if (!_.find(filtersAdjusted, ['columnName', 'city'])) {
        const defaultFilter = _.find(getSessionFilters('defaultFilter'), [
          'columnName',
          chip.columnName
        ])
        filtersAdjusted.push(defaultFilter)
      }
    }

    changeFilters(filtersAdjusted)
  }

  remapLabel = columnName => {
    const mapping = [
      { columnName: 'city', label: 'Miasto' },
      { columnName: 'district', label: 'Dzielnica' },
      { columnName: 'className', label: 'Klasa' },
      { columnName: 'school', label: 'Szkoła' },
      { columnName: 'schoolNo', label: 'Szkoła' }
    ]
    return _.get(_.find(mapping, ['columnName', columnName]), 'label')
  }

  render() {
    const styles = {
      btnupl: {
        marginTop: 3,
        float: 'right',
        zIndex: 2
      }
    }
    const {
      theme: { palette },
      history: { push },
      classes,
      // updateViewURL,
      searchToggle,
      filters,
      // getSelection,
      // backToMainView,
      schools,
      isSelection,
      selection,
      setGroupDetails,
      zoomDay
    } = this.props

    const chipData = _.sortBy(filters, ['columnName'])
      .filter(f =>
        _.includes(
          chipConfig,
          f.columnName === 'school' ? 'schoolNo' : f.columnName
        )
      )
      .map(e => {
        if (e.columnName === 'school') {
          return {
            ...e,
            label: _.isArray(e.value)
              ? `Szkoła: ` + getSchoolsLabels(schools, e.value)
              : `Szkoła: ` + _.get(_.find(schools, ['id', e.value[0]]), 'label')
          }
        }
        if (_.isArray(e.value)) {
          const labelName = `${this.remapLabel(e.columnName)}`
          return {
            ...e,
            label: `${labelName ? labelName + ': ' : ''} ` + e.value.join(', ')
          }
        }
        return e
      })

    return (
      <ToolbarGroup>
        {zoomDay ? (
          <IconButton
            tooltip="Widok tygodnia"
            tooltipPosition="bottom-left"
            touch
            style={{ ...styles.btnupl }}
            containerElement="label"
            onTouchTap={() => push(`${PATH_PL}`)}
            // onTouchTap={backToMainView}
            iconStyle={{ color: palette.primary.main }}
          >
            <ViewWeekIcon />
          </IconButton>
        ) : null}
        {isSelection ? (
          <div>
            <IconButton
              tooltip="Edytuj godziny"
              tooltipPosition="bottom-left"
              touch
              style={{ ...styles.btnupl }}
              containerElement="label"
              onTouchTap={() => {
                const groupDetails = {
                  day: zoomDay,
                  selection
                }
                setGroupDetails(groupDetails)
                push(
                  `${PATH_PL}${
                    zoomDay ? zoomDay + '/' : ''
                  }edycjagrupowa?view=edit`
                )
              }}
              iconStyle={{ color: palette.primary.main }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ) : null}
        <IconButton
          tooltip="Filtruj"
          tooltipPosition="bottom-left"
          touch
          style={{ ...styles.btnupl }}
          onTouchTap={searchToggle}
          iconStyle={{ color: palette.primary.main }}
        >
          <FilterIcon />
        </IconButton>

        {chipData.map((chip, i) => {
          return (
            <Tooltip
              key={`chipTooltip-${i}`}
              id={`chipTooltip-${i}`}
              title={chip.label || chip.value}
              placement="bottom-end"
              classes={{ tooltip: classes.tooltip }}
            >
              <Chip
                key={chip.columnName}
                label={chip.label || chip.value}
                onClick={() => this.handleDelete(chip, filters)}
                // onDelete={() => this.handleDelete(chip, filters)}
                className={classes.chip}
                classes={{ label: classes.chipLabel }}
              />
            </Tooltip>
          )
        })}
      </ToolbarGroup>
    )
  }
}

export default withStyles(styles)(withTheme()(ToolbarOptions))
