import React, { Component } from 'react';
import PasswordIcon from 'material-ui-effco/svg-icons/hardware/security';
import common from '../../../common';

const TextField = common.components.MuiTextfield;

class ProfilePassword extends Component {
  render() {
    const {
      fields: {
        password,
      passwordConfirmation,
      },
      data: { disabled },
    } = this.props;

    const styles = {
      icon: {
        height: 20,
        marginBottom: -3,
        marginRight: 5,
      },
      headerText: {
        fontSize: 20,
        margin: 15,
      },
      divTable: {
        display: 'table',
        width: 'auto',
      },
      divRow: {
        display: 'table-row',
        width: 'auto',
        clear: 'both',
      },
      divCellCaption: {
        display: 'table-column',
        float: 'left',
        width: 200,
        fontSize: 14,
        marginLeft: 60,
        marginTop: 15,
        marginBottom: 25,
        paddingTop: 12,
        fontWeight: 800,
      },
      divCellField: {
        display: 'table-column',
        float: 'left',
        width: 300,
        fontSize: 14,
        marginTop: 10,
        marginBottom: 15,
      },
    };

    return (
      <div>
        <div style={styles.headerText}>
          <PasswordIcon style={styles.icon} />Zabezpieczenie konta
            </div>
        <div style={styles.divTable}>
          <div style={styles.divRow}>
            <div style={styles.divCellCaption}>Nowe hasło</div>
            <div style={styles.divCellField}>
              <TextField
                field={password}
                hintText="Aby zmienić, wprowadź nowe hasło"
                disabled={disabled}
                type="password"
              />
            </div>
          </div>
          <div style={styles.divRow}>
            <div style={styles.divCellCaption}>Nowe hasło ponownie</div>
            <div style={styles.divCellField}>
              <TextField
                field={passwordConfirmation}
                hintText="Wprowadź nowe hasło ponownie"
                disabled={disabled}
                type="password"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePassword;
