import React, { Component } from 'react'
import { MuiForm, MuiTable } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import core from '../../core'
import common from '../../common'
import { PATH, PATH_PL } from '../constants'

const {
  constants: { hoursEx },
  helpers: { createBellsArray, mergeBellsArray, rejectEmptyBells }
} = common

const {
  constants: { API_URL },
  helpers: { getUserRole, getUserCities }
} = core

const { fields } = MuiForm
const { layouts } = MuiTable

class Detail extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params

    const queries = [
      axios.get(`${API_URL}/cities`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/schools`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/settings?name=defaultBells`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ]

    if (id !== 'new')
      queries.push(
        axios.get(`${API_URL}${PATH}${id}`, {
          headers: { authorization: localStorage.getItem('token') }
        })
      )

    const data = await Promise.all(queries)
    const defaultBells = _.orderBy(_.get(data[2], 'data.value') || [], [
      'lesson'
    ])

    this.setState({
      data: {
        cities: data[0].data.map(({ _id, ...rest }) => ({ id: _id, ...rest })),
        schools: data[1].data.map(({ _id, ...rest }) => ({ id: _id, ...rest })),
        data:
          id !== 'new'
            ? {
                ...data[3].data,
                id: data[3].data._id,
                bells: _.orderBy(
                  mergeBellsArray(data[3].data.bells, defaultBells),
                  ['lesson']
                )
              }
            : this.initialData(this.props),
        defaultBells
      }
    })
  }

  async initialData(props) {
    const userCities = getUserCities(props.profile)
    const userRole = await getUserRole()
    const cities = this.state.data.cities
    return {
      city: userCities
        ? userCities.length > 0
          ? userCities[0]
          : userRole === 'Super Admin'
            ? cities[0].name
            : undefined
        : undefined,
      active: 1,
      bells: createBellsArray() //[]
    }
  }

  async hasCities(props) {
    const userCities = getUserCities(props.profile)
    const userRole = await getUserRole()
    return userCities
      ? userCities.length > 0
        ? true
        : userRole === 'Super Admin'
          ? true
          : false
      : userRole === 'Super Admin'
        ? true
        : false
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  hasDistricts = city => {
    return (
      city &&
      !!_.find(this.state.data.cities, { name: city }).districts &&
      !!_.find(this.state.data.cities, { name: city }).districts.length
    )
  }

  hasDistrictsArray = cityArray => {
    const cities = this.state.data.cities
    var citiesToCheck
    if (!cityArray) {
      if (!cities) return false
      citiesToCheck = cities
    } else {
      citiesToCheck = cityArray
    }
    if (citiesToCheck.length === 0) return true

    const hasDistrict = citiesToCheck.reduce((p, c) => {
      return this.hasDistricts(p) || this.hasDistricts(c)
    })
    return hasDistrict
  }

  getDistricts = () => {
    const city = _.find(this.state.data.cities, { name: this.props.city })

    return city && city.districts.sort((a, b) => a.localeCompare(b, 'pl'))
  }

  getSchools = (oneDistrict = true, oneCity = true) => {
    const { city, district } = this.props
    const { schools, cities } = this.state.data

    return (
      city &&
      schools
        .filter(s => !oneCity || s.city === city)
        .filter(s => s.active)
        .filter(
          s =>
            !oneDistrict ||
            (_.find(cities, { name: city }).districts
              ? s.district === district
              : true)
        )
    )
  }

  render() {
    if (!this.state.data) return null
    const { cities, data: school } = this.state.data
    const {
      match: {
        params: { id }
      },
      history: { push },
      city,
      snackbar
    } = this.props

    const disabled = this.isDisabled()

    return (
      <MuiForm
        open
        lang="pl"
        disabled={disabled}
        add={id === 'new'}
        name="MuiForm"
        initialValues={school}
        fieldWidths={{
          config: {
            family: 'Roboto',
            size: 9,
            extraWidth: 15
          }
        }}
        title={
          id === 'new'
            ? 'Nowa Szkoła'
            : `${school.number} - ${school.city}${
                school.district ? ' (' + school.district + ')' : ''
              }`
        }
        groups={[
          {
            label: 'Podstawowe',
            fields: [
              [
                {
                  type: fields.text,
                  name: 'number',
                  label: 'Nazwa szkoły',
                  required: true,
                  hint: 'Wprowadź nazwę szkoły',
                  validate: v =>
                    v
                      ? /SP(\d{1,3}| \w+)/.test(v)
                        ? undefined
                        : 'Wprowadź nr szkoły lub nazwę, np. SP10, SP Rzgów'
                      : 'Pole jest wymagane'
                },
                // {
                //   type: fields.autocomplete,
                //   name: 'number2',
                //   label: 'Nazwa szkoły',
                //   required: true,
                //   hint: 'Wprowadź nazwę szkoły'
                // }
                {
                  type: fields.select,
                  name: 'active',
                  label: 'Status',
                  validate: v =>
                    [0, 1].includes(v) ? undefined : 'Pole jest wymagane',
                  options: [
                    { value: 1, label: 'Aktywna' },
                    { value: 0, label: 'Nieaktywna' }
                  ],
                  maxChars: 53
                },
                {
                  type: fields.checkbox,
                  name: `promotion`,
                  label: 'Promocja',
                  // compact: true,
                  hidden: false,
                  // icon: 'mood',
                  // checkedIcon: 'check',
                  tooltip: true,
                  // text: 'Promocja ?',
                  disabled: disabled,
                  theme: this.props.theme,
                  maxChars: 5
                }
              ],
              [
                {
                  type: MuiForm.fields.select,
                  name: 'city',
                  label: 'Miasto',
                  required: true,
                  hint: 'Wprowadź miasto szkoły',
                  hidden: !this.hasCities(this.props),
                  options:
                    this.props.profile &&
                    this.props.profile.role === 'Super Admin'
                      ? cities.map(c => c.name)
                      : getUserCities(this.props.profile),
                  validate: v => (v ? undefined : 'Pole jest wymagane'),
                  onChange: () => {
                    this.props.change('MuiForm', 'district', null)
                  }
                },
                {
                  type: MuiForm.fields.select,
                  name: 'district',
                  label: 'Dzielnica',
                  required: true,
                  hint: 'Wprowadź dzielnicę',
                  options: this.hasDistricts(city) ? this.getDistricts() : null,
                  hidden: !city || !this.hasDistricts(city),
                  validate: v => (v ? undefined : 'Pole jest wymagane')
                }
              ],
              {
                type: fields.text,
                name: 'fullName',
                label: 'Pełna nazwa szkoły (do komunikacji)',
                required: true,
                hint: 'Wprowadź pełną nazwę szkoły',
                validate: v => (v ? undefined : 'Pole jest wymagane')
              },
              {
                type: fields.text,
                name: 'address',
                label: 'Adres szkoły',
                required: true,
                hint: 'Wprowadź adres szkoły',
                validate: v => (v ? undefined : 'Pole jest wymagane')
              }
            ]
          },
          {
            label: 'Konto',
            fields: [
              {
                type: fields.text,
                name: 'custom.account1',
                label: 'Konto 1',
                hint: 'Wprowadź dane konta'
              },
              {
                type: fields.text,
                name: 'custom.account2',
                label: 'Konto 2',
                hint: 'Wprowadź dane konta'
              },
              {
                type: fields.text,
                name: 'custom.account3',
                label: 'Konto 3',
                hint: 'Wprowadź dane konta'
              },
              {
                type: fields.text,
                name: 'custom.account4',
                label: 'Konto 4',
                hint: 'Wprowadź dane konta'
              }
            ]
          },

          {
            label: 'Dzwonki',
            fields: [
              {
                type: fields.table,
                name: 'bells',
                subform: true,
                narrow: true,
                lang: 'pl',
                data: [],
                layout: layouts.fieldArray,
                disabled: true, // disabled,
                columns: [
                  {
                    type: fields.select,
                    name: 'lesson',
                    label: 'Lekcja',
                    compact: true,
                    disabled: true,
                    formControlStyle: { marginTop: 8 },
                    helperTextStyle: { marginTop: 0 },
                    floatingLabelStyle: { marginTop: 0 },
                    tableCellStyle: { padding: '0px 12px 0px 24px' },
                    formField: true,
                    // disabled: disabled,
                    options: createBellsArray().map(e => ({
                      value: e.lesson,
                      label: `Lekcja ${e.lesson}`
                    })),
                    validate: v => (v ? undefined : 'Pole jest wymagane')
                  },
                  {
                    type: fields.select,
                    name: 'from',
                    label: 'Lekcja od',
                    compact: true,
                    formControlStyle: { marginTop: 8 },
                    helperTextStyle: { marginTop: 0 },
                    floatingLabelStyle: { marginTop: 0 },
                    tableCellStyle: { padding: '0px 12px 0px 24px' },
                    formField: true,
                    disabled: disabled,
                    options: hoursEx //.from
                  },
                  {
                    type: fields.select,
                    name: 'to',
                    label: 'Lekcja do',
                    compact: true,
                    formControlStyle: { marginTop: 8 },
                    helperTextStyle: { marginTop: 0 },
                    floatingLabelStyle: { marginTop: 0 },
                    tableCellStyle: { padding: '0px 12px 0px 24px' },
                    formField: true,
                    disabled: disabled,
                    options: hoursEx //.to
                  }
                ]
              }
            ]
          }
        ]}
        actions={{
          add: async data => {
            let data_new = { ...data, bells: rejectEmptyBells(data.bells) }
            await axios.post(`${API_URL}${PATH}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            snackbar(`${data.fullName} została dodana`)
          },
          edit: () => {
            push(`${PATH_PL}${id}?view=edit`)
          },
          save: async data => {
            let data_new = { ...data, bells: rejectEmptyBells(data.bells) }
            delete data_new['_id']
            await axios.put(`${API_URL}${PATH}${id}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            snackbar(`${data.fullName} została zapisana`)
          },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(
  state => ({
    city: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).city
      : '',
    district: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).district
      : '',
    bells: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).bells
      : ''
  }),
  { change }
)(Detail)
