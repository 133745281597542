export const NAME = 'reports'
export const LABEL = 'Sprawozdania'
export const PATH = `/${NAME}/`
export const PUPIL_PATH = `/pupils/`
export const PATH_PL = `/sprawozdania/`
export const PUPIL_PATH_PL = `/uczestnicy`
export const STATUS_LIST = {
  create: 10,
  edit: 30,
  verification: 50,
  rejected: 20,
  editAfterRejected: 40,
  verificationAfterRejected: 60,
  accepted: 70
}
export const TYPE = {
  personal: 'Osobiste',
  class: 'Klasa',
  school: 'Szkoła',
  sibling: 'Rodzeństwo'
}
