import { push } from 'react-router-redux'
import { change, reset } from 'redux-form-v5'
import axios from 'axios'
import * as t from './types'
import { API_URL } from './constants'
import { setSessionFilters } from '../core/helpers'
export const toggleNav = () => ({ type: t.TOGGLE_NAV })

export const navigate = url => dispatch => {
  dispatch(toggleNav())
  dispatch(push(url))
}

export const snackbarMessage = msg => ({ type: t.SNACKBAR, payload: msg })

export const changeForm = (form, field, value) => dispatch => {
  dispatch(change(form, field, value))
}

export const signinUser = async (email, password) => async dispatch => {
  try {
    const { data } = await axios.post(`${API_URL}/signin`, { email, password })
    // dispatch({ type: t.AUTH })
    localStorage.setItem('token', data.token)
    localStorage.setItem('user', data.user)
    setSessionFilters('defaultFilter', '')
    setSessionFilters('lastFilter', '')
    dispatch(snackbarMessage(''))
    dispatch(push('/'))
  } catch (e) {
    dispatch(
      snackbarMessage('Błąd logowania - niepoprawny użytkownik lub hasło')
    )
  }
}

export function signupUser({ email, password }) {
  return function(dispatch) {
    // submit email/password to server
    axios
      .post(`${API_URL}/create`, { email, password })
      .then(response => {
        // if request is good
        //  - update state to indicate user is authd
        dispatch({ type: t.AUTH })
        //  - save the JWT token
        localStorage.setItem('token', response.data.token)
        //  = redirect to the route '/feature'
        dispatch(push('/'))
      })
      .catch(response => {
        // if request is bad
        //  - show an error to the user
        dispatch(snackbarMessage(response.data.error))
      })
  }
}

export function signoutUser() {
  return function(dispatch) {
    localStorage.removeItem('token')
    dispatch(push('/'))
    dispatch(snackbarMessage('Wylogowano poprawnie'))
  }
}

export function profileFetch() {
  const request = axios.get(`${API_URL}/users/${localStorage.getItem('id')}`, {
    headers: { authorization: localStorage.getItem('token') }
  })

  return {
    type: t.PROFILE_FETCH,
    payload: request
  }
}

export function profilePrefsFetch() {
  const request = axios.get(`${API_URL}/prefs/`, {
    headers: { authorization: localStorage.getItem('token') }
  })
  return {
    type: t.PROFILE_PREFS_FETCH,
    payload: request
  }
}

export function profileEditToggle() {
  return dispatch => {
    dispatch({ type: t.PROFILE_EDIT_TOGGLE })
    dispatch(reset('profile'))
  }
}

export function profileEdit(user) {
  delete user.passwordConfirmation // eslint-disable-line no-param-reassign

  return dispatch => {
    axios
      .put(`${API_URL}/users/${user._id}`, user, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(res => {
        dispatch(profileFetch())
        dispatch(snackbarMessage(res.data.message))
      })
      .then(() => {
        dispatch(profileEditToggle())
      })
      .catch(() => {
        dispatch(snackbarMessage('Edycja profilu nie powiodła się'))
      })
  }
}

export const initialize = () => dispatch => {
  // dispatch(notifications.actions.fetch()).then(
  dispatch(profileFetch())
    // ).then(
    .then(dispatch(profilePrefsFetch()))
}
