import React from 'react'
import { AppBar, Tabs, Tab } from 'material-ui-effco'
import common from '../../../common'
import cities from '../../../cities'
import { NAME } from '../constants'
import DetailsMisc from './DetailsMisc'

const { MuiTextfield } = common.components
const { CitiesSelectfield } = cities.components

const Details = props => {
  const {
    changeForm,
    fields: { number, address, district, city, fullName },
    data: { active: { disabled, add } }
  } = props

  return (
    <div>
      <AppBar
        /* title={(add ? 'Nowa Szkoła' : `${schoolsSelected.number} ${schoolsSelected.city}`)}*/
        title={add ? 'Nowa Szkoła' : 'Edycja Szkoły'}
        iconStyleLeft={{ display: 'none' }}
        style={{ boxShadow: 'none' }}
      />
      <Tabs>
        <Tab label="Podstawowe">
          <MuiTextfield
            field={number}
            floatingLabelText="Nazwa"
            hintText="Wprowadź nazwę szkoły *"
            disabled={disabled}
          />
          <MuiTextfield
            field={fullName}
            floatingLabelText="Pełna nazwa (do komunikacji)"
            hintText="Wprowadź pełną nazwę szkoły *"
            disabled={disabled}
          />
          <MuiTextfield
            field={address}
            floatingLabelText="Adres"
            hintText="Wprowadź adres szkoły *"
            disabled={disabled}
          />
          <MuiTextfield
            field={district}
            floatingLabelText="Dzielnica"
            hintText="Wprowadź dzielnicę"
            disabled={disabled}
          />
          <CitiesSelectfield
            field={city}
            onChange={(e, i, v) => changeForm(NAME, 'city', v)}
            disabled={disabled}
          />
        </Tab>
        <Tab label="Konto">
          <DetailsMisc {...props} />
        </Tab>
      </Tabs>
    </div>
  )
}

export default Details
