export const validate = (formProps) => {
  const errors = {};

  if (!formProps.firstName) { errors.firstName = 'Pole wymagane'; }

  if (!formProps.lastName) { errors.lastName = 'Pole wymagane'; }
  // eslint-disable-next-line
  if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formProps.email)) // eslint-disable-line max-len
  { errors.email = 'Wprowadź poprawny adres email'; }

  if (!formProps.email) { errors.email = 'Pole wymagane'; }

  if (!/^\d{9}$/.test(formProps.phone)) { errors.phone = 'Numer telefonu powinien zawierać 9 cyfr'; }

  if (!formProps.phone) { errors.phone = 'Pole wymagane'; }

  if (!formProps.role) { errors.role = 'Pole wymagane'; }

  if (!/^$|[[A-Za-z\d$@$!%*#?&]{8,}/.test(formProps.password)) { errors.password = 'Hasło powinno zawierać przynajmniej 8 znaków'; }

  if (formProps.password && (formProps.password !== formProps.passwordConfirmation)) { errors.passwordConfirmation = 'Hasło i potwierdzenie nie zgadzają się'; }

  return errors;
};

export const fields = [
  '_id',
  'firstName',
  'lastName',
  'email',
  'phone',
  'role',
  'notes',
  'password',
  'passwordConfirmation',
  'active',
];
