/*##############################################################################
# IMPORTY
##############################################################################*/
import React, { Component }             from "react";
import axios                            from "axios";
import _                                from "lodash";
import moment                           from "moment";
import { connect }                      from "react-redux";
import { getFormValues, change, touch } from "redux-form";
import { Typography }                   from "material-ui";
import { MuiForm, Spinner }             from "material-ui-elements";
import core                             from "../../core";


/*##############################################################################
# DESTRUCTORY
##############################################################################*/
const {
    constants: { API_URL },
    helpers: { isPhone, isEmail }
} = core;


/*##############################################################################
# COMPONENT
##############################################################################*/
class Form extends Component {
    state = {};

    async componentWillMount() {
        const data = await Promise.all([
            axios.get(`${API_URL}/formcities`),
            axios.get(`${API_URL}/schools`),
            axios.get(`${API_URL}/registration-form-header-school-year`),
        ]);

        function _sortByName(cities){
                return cities.sort((a,b) => a.name.localeCompare(b.name))
        }
        const cities = _sortByName(data[0].data)

        this.setState({
            data: {
                cities: cities,
                schools: data[1].data
            },
            schoolYear: data[2].data,
        });
    }

    hasDistricts = city => {
        return (
            city &&
            !!_.find(this.state.data.cities, {
                name: city
            }).districts &&
            !!_.find(this.state.data.cities, {
                name: city
            }).districts.length
        );
    };

    hasDistrictsArray = cityArray => {
        const cities = this.state.data.cities;
        var citiesToCheck;
        if (!cityArray) {
            if (!cities) return false;
            citiesToCheck = cities;
        } else {
            citiesToCheck = cityArray;
        }
        if (citiesToCheck.length === 0) return true;

        const hasDistrict = citiesToCheck.reduce((p, c) => {
            return this.hasDistricts(p) || this.hasDistricts(c);
        });
        return hasDistrict;
    };

    getDistricts = () => {
        const { city } = this.props;

        return (
            city &&
            this.getSchools(false)
                .reduce((p, c) => {
                    if (!p.includes(c.district)) p.push(c.district);
                    return p;
                }, [])
                .sort((a, b) => a.localeCompare(b, "pl"))
        );
    };

    getSchools = (oneDistrict = true, oneCity = true) => {
        const { city, district } = this.props;
        const { schools, cities } = this.state.data;

        return (
            city &&
            schools
                .filter(s => !oneCity || s.city === city)
                .filter(s => s.active)
                .filter(
                    s =>
                        !oneDistrict ||
                        (_.find(cities, {
                            name: city
                        }).districts
                            ? s.district === district
                            : true)
                )
        );
    };

    dateToString = data => {
        if (!data.classes) return data;

        const classesAdjusted = data.classes.map(e => {
            if (e.from && e.to) {
                return {
                    from: moment(e.from).format("HH:mm"),
                    to: moment(e.to).format("HH:mm")
                };
            } else if (e.from) {
                return {
                    from: moment(e.from).format("HH:mm"),
                    to: moment(e.from).format("HH:mm")
                };
            } else if (e.to) {
                return {
                    from: moment(e.to).format("HH:mm"),
                    to: moment(e.to).format("HH:mm")
                };
            }

            return e;
        });

        return {
            ...data,
            classes: classesAdjusted
        };
    };

    validateTime = (value, index, config) => {
        const classes = this.props.classesArray;
        const { format, range, type } = config;
        const rangeStart = _.get(range, `${type}.rangeStart`);
        const rangeEnd = _.get(range, `${type}.rangeEnd`);
        const classTo =
            classes && classes[`${index}`] && classes[`${index}`].to;
        const classFrom =
            classes && classes[`${index}`] && classes[`${index}`].from;
        const cmpValue = type === "from" ? classTo : classFrom;

        const valid =
            type === "to" // ? moment(value, format).isBefore(
                ? //     moment(cmpValue, format).add(-1, 'minutes')
                  //   )
                  moment(value, format).isAfter(
                      moment(cmpValue, format).add(1, "minutes")
                  )
                : true;

        const isBetween = moment(value, format).isBetween(
            moment(rangeStart, format).add(-1, "minutes"),
            moment(rangeEnd, format).add(1, "minutes")
        );

        return !value && !cmpValue
            ? undefined
            : value
            ? isBetween
                ? cmpValue
                    ? valid
                        ? undefined
                        : `Wprowadź godzinę ${
                              type === "from" ? "wcześniejszą" : "późniejszą"
                          } niż ${
                              type === "from"
                                  ? classTo &&
                                    moment(classTo, format).format(format)
                                  : moment(classFrom, format).format(format)
                          }`
                    : undefined
                : `Godzina jest poza dozwolonym zakresem ${rangeStart} - ${rangeEnd}`
            : "Pole jest wymagane";
    };

    getSchoolsOptions = (props, schools) => {
        var schoolsOptions = [];
        const schoolsByCity = (schools ? schools : [])
            .filter(f => f.city === props.city)
            .filter(s => s.active);

        if (props && props.district) {
            schoolsOptions = _.sortBy(
                schoolsByCity
                    .filter(f => f.district === props.district)
                    .map(s => ({
                        value: s._id,
                        label: `${s.number} (${s.address})`,
                        code: s.code
                    })),
                ["code"]
            );
        } else {
            schoolsOptions = _.sortBy(
                schoolsByCity.map(s => ({
                    value: s._id,
                    label: `${s.number} (${s.address})`,
                    code: s.code
                })),
                ["code"]
            );
        }

        return schoolsOptions;
    };

    render() {
        if (!this.state.data) return <Spinner />;
        const { cities, schools } = this.state.data;
        const {
            history: { push },
            city
        } = this.props;

        const cancelLabel = "Anuluj";
        const config = {
            format: "HH:mm",
            range: {
                from: {
                    rangeStart: "08:00",
                    rangeEnd: "12:45"
                },
                to: {
                    rangeStart: "08:45",
                    rangeEnd: "17:45"
                }
            }
        };
        return (
            <div
                style={{
                    background: "#FEEBC0",
                    paddingBottom: 50
                }}
            >
                <MuiForm
                    layout={MuiForm.layouts.base}
                    title={`Drama Dla Dzieci - Zapisy na rok szkolny ${this.state.schoolYear}`}
                    lang="pl"
                    name="MuiForm"
                    fields={[
                        {
                            type: MuiForm.fields.typography,
                            text: "caption",
                            label:
                                "Prosimy o wypełnienie wszystkich wymaganych pól. Przycisk umożliwiający wysłanie formularza uaktywni się po wprowadzeniu poprawnych danych"
                        },
                        {
                            type: MuiForm.fields.subheading,
                            label: "Informacje Podstawowe"
                        },
                        [
                            {
                                type: MuiForm.fields.select,
                                name: "city",
                                label: "Miasto",
                                required: true,
                                options: cities.map(c => c.name),
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane",
                                onChange: () => {
                                    this.props.change(
                                        "MuiForm",
                                        "district",
                                        null
                                    );
                                    this.props.change(
                                        "MuiForm",
                                        "school",
                                        null
                                    );
                                }
                            },
                            {
                                type: MuiForm.fields.select,
                                name: "district",
                                label: "Dzielnica",
                                hintText: "Dzielnica",
                                required: true,
                                hidden: !this.hasDistricts(city),
                                options: this.getDistricts(),
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane",
                                onChange: () => {
                                    this.props.change(
                                        "MuiForm",
                                        "school",
                                        null
                                    );
                                }
                            }
                        ],
                        [
                            {
                                type: MuiForm.fields.select,
                                required: true,
                                name: "school",
                                label: "Szkoła",
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane",
                                options: this.getSchoolsOptions(
                                    this.props,
                                    schools
                                )
                            },
                            {
                                type: MuiForm.fields.text,
                                required: true,
                                name: "className",
                                label: "Klasa",
                                validate: v =>
                                    v
                                        ? /^\d\D{0,1}$/.test(v)
                                            ? undefined
                                            : "Klasa powinna składać się z cyfry i ewentualnie litery, np 1A, 0"
                                        : "Pole jest wymagane"
                            }
                        ],
                        [
                            {
                                type: MuiForm.fields.text,
                                required: true,
                                name: "firstName",
                                label: "Imię dziecka",
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane"
                            },
                            {
                                type: MuiForm.fields.text,
                                required: true,
                                name: "lastName",
                                label: "Nazwisko dziecka",
                                validate: v =>
                                    v ? undefined : "Pole jest wymagane"
                            }
                        ],
                        {
                            type: MuiForm.fields.subheading,
                            label: "Informacje Kontaktowe"
                        },
                        [
                            {
                                type: MuiForm.fields.text,
                                required: true,
                                name: "contact.phone",
                                label: "Telefon",
                                validate: v =>
                                    v
                                        ? isPhone(v)
                                            ? undefined
                                            : "Numer telefonu powinien zawierać 9 cyfr"
                                        : "Pole jest wymagane"
                            },
                            {
                                type: MuiForm.fields.text,
                                required: true,
                                name: "contact.email",
                                label: "Email",
                                validate: v =>
                                    v
                                        ? isEmail(v)
                                            ? undefined
                                            : "Wprowadź poprawny adres email"
                                        : "Pole jest wymagane"
                            }
                        ],
                        {
                            type: MuiForm.fields.subheading,
                            label: "Plan Lekcji"
                        },
                        {
                            type: MuiForm.fields.typography,
                            text: "caption",
                            label:
                                "Aby umożliwić nam dopasowanie się do planu zajęć Państwa dziecka, bardzo prosimy o podanie nam jego planu lekcji"
                        },
                        [
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[0].from",
                                label: "Poniedziałek od",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[0].to"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 0, {
                                        ...config,
                                        type: "from"
                                    })
                            },
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[0].to",
                                label: "Poniedziałek do",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[0].from"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 0, {
                                        ...config,
                                        type: "to"
                                    })
                            }
                            // {
                            //   type: MuiForm.fields.select,
                            //   name: 'classes[0].from',
                            //   label: 'Poniedziałek od',
                            //   options: hours.from
                            // },
                            // {
                            //   type: MuiForm.fields.select,
                            //   name: 'classes[0].to',
                            //   label: 'Poniedziałek do',
                            //   options: hours.to
                            // }
                        ],
                        [
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[1].from",
                                label: "Wtorek od",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[1].to"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 1, {
                                        ...config,
                                        type: "from"
                                    })
                            },
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[1].to",
                                label: "Wtorek do",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[1].from"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 1, {
                                        ...config,
                                        type: "to"
                                    })
                            }
                        ],
                        [
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[2].from",
                                label: "Środa od",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[2].to"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 2, {
                                        ...config,
                                        type: "from"
                                    })
                            },
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[2].to",
                                label: "Środa do",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[2].from"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 2, {
                                        ...config,
                                        type: "to"
                                    })
                            }
                        ],
                        [
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[3].from",
                                label: "Czwartek od",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[3].to"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 3, {
                                        ...config,
                                        type: "from"
                                    })
                            },
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[3].to",
                                label: "Czwartek do",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[3].from"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 3, {
                                        ...config,
                                        type: "to"
                                    })
                            }
                        ],
                        [
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[4].from",
                                label: "Piątek od",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[4].to"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 4, {
                                        ...config,
                                        type: "from"
                                    })
                            },
                            {
                                type: MuiForm.fields.timepicker,
                                name: "classes[4].to",
                                label: "Piątek do",
                                cancelLabel: cancelLabel,
                                onChange: (e, v) => {
                                    this.props.touch(
                                        "MuiForm",
                                        "classes[4].from"
                                    );
                                },
                                validate: v =>
                                    this.validateTime(v, 4, {
                                        ...config,
                                        type: "to"
                                    })
                            }
                        ],
                        {
                            type: MuiForm.fields.subheading,
                            label: "Informacje Dodatkowe"
                        },
                        {
                            type: MuiForm.fields.text,
                            name: "notes.general2", //signup',
                            rows: 5
                        },
                        {
                            type: MuiForm.fields.component,
                            component: () => [
                                <Typography
                                    style={{
                                        marginTop: 12,
                                        marginBottom: 12
                                    }}
                                >
                                    Wyrażam zgodę na przetwarzanie moich danych
                                    osobowych i danych osobowych mojego dziecka
                                    podanych w niniejszym formularzu w celu
                                    złożenia deklaracji zapisu na zajęcia z
                                    Dramy dla Dzieci oraz związanego z tą
                                    deklaracją dalszego procesu ustalenia
                                    terminu grupy oraz uczęszczania na zajęcia w
                                    roku szkolnym {this.state.schoolYear}.{" "}
                                </Typography>,
                                <Typography
                                    style={{
                                        marginTop: 12,
                                        marginBottom: 12
                                    }}
                                >
                                    Wiem, że powyższa zgoda jest dobrowolna, ale
                                    konieczna do złożenia niniejszej deklaracji
                                    oraz udziału w dalszym procesie związanym z
                                    ustaleniem terminu zajęć oraz uczęszczaniem
                                    na zajęcia w obecnym roku szkolnym.{" "}
                                </Typography>,
                                <Typography
                                    style={{
                                        marginTop: 12,
                                        marginBottom: 12
                                    }}
                                >
                                    Informacje o administratorze oraz o zasadach
                                    przetwarzania danych osobowych:{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        href="https://www.dramadladzieci.pl/polityka-prywatnosci"
                                    >
                                        Polityka prywatności{" "}
                                    </a>
                                    .{" "}
                                </Typography>
                            ]
                        },
                        {
                            type: MuiForm.fields.checkbox,
                            label: "Wyrażam zgodę",
                            checkBoxStyle: {
                                alignItems: "left"
                            },
                            validate: v =>
                                v ? undefined : "Zgoda jest wymagana"
                        }
                    ]}
                    actions={{
                        noConfirmations: true,
                        raised: true,
                        color: "primary",
                        save: {
                            action: x =>
                                axios
                                    .post(
                                        `${API_URL}/registrations`,
                                        this.dateToString(x)
                                    )
                                    .then(() =>
                                        push("/zapisy/nowy/potwierdzenie")
                                    ),
                            label: "Wyślij zgłoszenie",
                            title: "Prośba o potwierdzenie",
                            content: "Czy napewno chcesz wysłać zgłoszenie?",
                            icon: "send",
                            color: "primary",
                            style: {
                                width: "100%"
                            }
                        }
                    }}
                />{" "}
            </div>
        );
    }
}

/*##############################################################################
# EXPORT
##############################################################################*/
export default connect(
    state => ({
        city: getFormValues("MuiForm")(state)
            ? getFormValues("MuiForm")(state).city
            : "",
        district: getFormValues("MuiForm")(state)
            ? getFormValues("MuiForm")(state).district
            : "",
        classesArray: getFormValues("MuiForm")(state)
            ? getFormValues("MuiForm")(state).classes
            : ""
    }),
    {
        change,
        touch
    }
)(Form);
