import * as tl from './types'
import core from '../../core'

const t = { ...tl, ...core.types }

const INITIAL_STATE = {
  loading: true,
  view: 'default',
  active: {
    id: null,
    add: false
  },
  data: { templates: [] }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.FETCH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          templates: action.payload.data
        }
      }

    case t.ACTIVATE:
      return {
        ...state,
        active: {
          ...state.active,
          id: action.payload,
          add: false
        }
      }

    case t.ADD:
      return {
        ...state,
        active: {
          ...state.active,
          id: 'add',
          add: true
        }
      }

    case t.VIEW:
      return {
        ...state,
        view: state.view === 'default' ? 'notification' : 'default'
      }

    case t.UNAUTH:
      return INITIAL_STATE

    default:
      return state
  }
}
