export const settings = {
  tableHeight: {
    height: 575,
    heightOffsetFiltering: 30,
    heightOffsetGrouping: 55,
    heightHeaderOffset: 235, //284, //225//300
    minHeight: 200
  },
  tableColumns: {
    defaultWidth: 100,
    commandColumnWidth: 120,
    maxColWidth: 700
  },
  lang: 'en',
  pageSizes: [5, 10, 0],
  dateFormat: 'DD/MM/YYYY'
}

export const tableMessages = [
  {
    lang: 'en',
    messages: {
      tableMessages: {
        noData: 'No Data'
      },
      editColumnMessages: {
        addCommand: 'New',
        editCommand: 'Edit',
        deleteCommand: 'Delete',
        commitCommand: 'Commit',
        cancelCommand: 'Cancel'
      },
      groupingPanelMessages: {
        groupByColumn: 'Przeciągnij kolumnę aby pogrupować'
      },
      filterRowMessages: {
        filterPlaceholder: 'Filtruj...'
      },
      filterOptions: {
        filterAll: 'All'
      },
      headerRowMessages: {
        sortingHint: 'Sort'
      },
      pagingPanelMessages: {
        showAll: 'Wszystkie',
        rowsPerPage: 'Ilość na stronie',
        info: 'Rekordy {from} do {to} ({count} Rekordów)'
      }
    }
  },
  {
    lang: 'pl',
    messages: {
      tableMessages: {
        noData: 'Brak rekordów'
      },
      editColumnMessages: {
        addCommand: 'Nowy',
        editCommand: 'Edytuj',
        deleteCommand: 'Usuń',
        commitCommand: 'Zatwierdź',
        cancelCommand: 'Anuluj'
      },
      groupingPanelMessages: {
        groupByColumn: 'Przeciągnij kolumnę aby pogrupować'
      },
      filterRowMessages: {
        filterPlaceholder: 'Filtruj...'
      },
      filterOptions: {
        filterAll: 'Wszystko'
      },
      headerRowMessages: {
        sortingHint: 'Sortuj'
      },
      pagingPanelMessages: {
        showAll: 'Wszystkie',
        rowsPerPage: 'Ilość na stronie',
        info: 'Rekordy {from} do {to} ({count} Rekordów)'
      }
    }
  }
]
