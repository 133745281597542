export const validate = (formProps) => {
  const errors = {
    contact: {},
    school: {},
    group: {},
    payment: { due: {} },
  };

  if (!formProps.firstName) {
    errors.firstName = 'Pole wymagane';
  }

  if (!formProps.lastName) {
    errors.lastName = 'Pole wymagane';
  }
  // eslint-disable-next-line
  if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formProps.contact.email)) { // eslint-disable-line max-len
    errors.contact.email = 'Wprowadź poprawny adres email';
  }

  if (!formProps.contact.email) {
    errors.contact.email = 'Pole wymagane';
  }

  if (!/^\d{9}$/.test(formProps.contact.phone)) {
    errors.contact.phone = 'Numer telefonu powinien zawierać 9 cyfr';
  }

  if (!formProps.contact.phone) {
    errors.contact.phone = 'Pole wymagane';
  }

  if (!formProps.city) {
    errors.city = 'Pole wymagane';
  }

  if (!formProps.school._id) {
    errors.school._id = 'Pole wymagane';
  }

  // if (formProps.group._id !== undefined && !formProps.group._id) {
  //   errors.group._id = 'Pole wymagane'
  // }

  if (!/^\d\D{0,1}$/.test(formProps.className)) {
    errors.className = 'Klasa powinna składać się z cyfry i ewentualnie litery, np 1A, 0';
  }

  if (!formProps.className) {
    errors.className = 'Pole wymagane';
  }

  return errors;
};

export const fields = [
  '_id',
  'firstName',
  'lastName',
  'contact.email',
  'contact.phone',
  'school._id',
  'className',
  'city',
  'group._id',
  'group.groupName',
  'group.teacher',
  'group.classroom',
  'group.city',
  'payment.finance',
  'payment.due.s1',
  'payment.due.s2',
  'payment.paid[].date',
  'payment.paid[].amount',
  'payment.paid[].note',
  'continuation',
  'siblings',
  'notes.management',
  'notes.general',
  'notes.general2',
  'notes.teacher',
  'tag',
  'touched']; // hack for deep redux form 5.3.1
