import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Input, { InputAdornment } from 'material-ui/Input';
import Search from 'material-ui-icons/Search';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.active,
  },
});

const SearchPanelInputBase = ({
  onValueChange, value, getMessage, ...restProps
}) => (<Input
  onChange={e => onValueChange(e.target.value)}
  value={value}
  type="text"
  placeholder={getMessage('searchPlaceholder')}
  {...restProps}
  startAdornment={
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  }
/>);

SearchPanelInputBase.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  getMessage: PropTypes.func.isRequired,
};
SearchPanelInputBase.defaultProps = {
  value: '',
};

export const SearchPanelInput = withStyles(styles)(SearchPanelInputBase);
