import React, { Component } from 'react'

import ListTable from './ListTable'
import Search from './search'

class List extends Component {
  render() {
    return (
      <div>
        <ListTable {...this.props} />
        <Search {...this.props} />
      </div>
    )
  }
}

export default List
