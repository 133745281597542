import React from 'react'
import { Button, Icon } from 'material-ui'
import Media from 'react-media'
import { Link } from 'react-router-dom'
import { oldTheme } from '../constants'

const styles = {
  icon: {
    marginRight: 0
  }
}

const NavLink = ({
  to,
  icon,
  caption,
  raised,
  style,
  color,
  hidden = false
}) => {
  return (
    <Link style={{ textDecoration: 'none' }} to={to}>
      <Media query="(min-width: 800px) and (max-width: 999px)">
        <Button
          style={{
            color: oldTheme.palette.primary1Color,
            ...style,
            display: !!hidden ? 'none' : ''
          }}
          dense
          color={color}
          raised={raised}
        >
          <Icon>{icon}</Icon>
        </Button>
      </Media>
      <Media query="(min-width: 1000px)">
        <Button
          style={{
            color: oldTheme.palette.primary1Color,
            ...style,
            display: !!hidden ? 'none' : ''
          }}
          raised={raised}
          color={color}
        >
          <Icon style={styles.icon}>{icon}</Icon>
          {caption}
        </Button>
      </Media>
    </Link>
  )
}

export default NavLink
