import React from 'react'
import { Route, Redirect } from 'react-router'
import core from './core'
import settings from './settings'

const {
  components: { Header },
  helpers: { auth }
} = core
const {
  components: { Settings }
} = settings

const DefaultLayout = ({ component: Component, settings, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth()) return <Redirect to="/login" />
        if (
          (props.location.pathname === '/platnosci' ||
            props.location.pathname === '/platnosci/') &&
          !props.location.state
        )
          return <Redirect to="/uczestnicy" />
        return (
          <div>
            <Header history={props.history} {...rest} />
            {settings && <Settings {...rest} />}
            <div
              style={{
                marginTop: 70,
                marginLeft: settings ? 50 : 0,
                padding: rest.set ? 0 : 10
              }}
            >
              <Component {...props} {...rest} />
            </div>
          </div>
        )
      }}
    />
  )
}

export default DefaultLayout
