import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import qs from 'query-string'
import Dialog from 'material-ui/Dialog'
import AppBar from 'material-ui/AppBar'
import Toolbar from 'material-ui/Toolbar'
import Typography from 'material-ui/Typography'
import { MuiForm } from 'material-ui-elements'
import IconButton from 'material-ui/IconButton'
import { LinearProgress } from 'material-ui/Progress'
import ArrowLeft from 'mdi-material-ui/ArrowLeft'
import Pencil from 'mdi-material-ui/Pencil'
import ContentSave from 'mdi-material-ui/ContentSave'
import core from '../../core'

const {
  helpers: { api }
} = core

const { fields } = MuiForm

const initialState = { pupil: {}, disabled: true }

class Pupil extends Component {
  state = initialState

  componentWillReceiveProps = async nextProps => {
    const newId = nextProps.match.params.id
    if (!newId) {
      this.setState(initialState)
      return
    }
    this.setState({
      ...(await api.get([{ pupil: `pupils/${newId}` }]))
    })
  }

  onClose = () => {
    this.props.history.push('/mobile/zajecia')
  }

  isDisabled = () => {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  isDirty = () =>
    this.state.pupil.notes &&
    this.props.formValues &&
    this.state.pupil.notes.teacher !== this.props.formValues.notes.teacher

  buttonAction = () => {
    this.props.history.push(
      `${this.props.location.pathname}${this.isDisabled() ? '?view=edit' : ''}`
    )
    if (!this.isDisabled()) {
      const { formValues: pupil } = this.props
      api.post(`/pupils/${pupil._id}/comment`, { comment: pupil.notes.teacher })
      this.props.snackbar('Uwagi zaktualizowane')
    }
  }

  render = () => {
    const {
      state: { pupil },
      props: {
        match: {
          params: { id }
        }
      }
    } = this

    return (
      <Dialog fullScreen open={!!id}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              style={{ color: 'white' }}
              onClick={() => this.onClose()}
            >
              <ArrowLeft />
            </IconButton>
            <div style={{ flex: 1, textAlign: 'center', color: 'white' }}>
              <Typography color="inherit" variant="title">
                {pupil._id && `${pupil.firstName} ${pupil.lastName}`}
              </Typography>
            </div>
            <IconButton
              disabled={!this.isDisabled() && !this.isDirty()}
              style={{ color: 'white' }}
              onClick={() => this.buttonAction()}
            >
              {this.isDisabled() ? (
                <Pencil />
              ) : (
                <ContentSave style={{ opacity: this.isDirty() ? 1 : 0.7 }} />
              )}
            </IconButton>
          </Toolbar>
          {!pupil._id && <LinearProgress />}
        </AppBar>
        {pupil._id && (
          <div style={{ margin: 24, marginTop: 60 }}>
            <MuiForm
              layout={MuiForm.layouts.base}
              initialValues={this.state.pupil}
              disabled={this.isDisabled()}
              fields={[
                {
                  type: fields.text,
                  name: 'notes.teacher',
                  rows: 2,
                  label: 'Uwagi prowadzący'
                },
                [
                  {
                    type: fields.text,
                    name: 'contact.email',
                    disabled: true,
                    label: 'Email'
                  },
                  {
                    type: fields.text,
                    name: 'contact.phone',
                    disabled: true,
                    label: 'Telefon'
                  }
                ]
              ]}
            />
          </div>
        )}
      </Dialog>
    )
  }
}

export default connect(
  state => {
    return { formValues: getFormValues('MuiForm')(state) }
  },
  {
    getFormValues
  }
)(Pupil)
