import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { MuiForm } from 'material-ui-elements'
import _ from 'lodash'
import qs from 'query-string'
import axios from 'axios'
import core from '../../core'
import { PATH, PATH_PL } from '../constants'

const {
  constants: { API_URL },
  helpers: { api }
} = core

const { fields } = MuiForm

const styles = {
  editorStyle: {
    height: '200px',
    marginBottom: '50px',
    marginTop: '50px'
  }
}

class Detail extends Component {
  state = {}

  componentWillMount() {
    if (this.props.match.params.id === 'new') {
      this.initialDataToState()
      return
    }
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params

    const data = await api.get([{ templates: `templates/${id}` }])

    this.setState({
      data: {
        ...data.templates,
        type: _.get(data, 'templates.type') === 'sms' ? 'sms' : 'mail'
      }
    })
  }

  initialDataToState() {
    const data = { type: 'mail', content: '' }
    this.setState({ data: data })
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  render() {
    if (!this.state.data) return null
    const { data } = this.state
    const {
      match: {
        params: { id }
      },
      profile,
      history: { push },
      formValues,
      updateRows,
      snackbar
    } = this.props

    const role = profile && profile.role
    const userId = profile && profile._id

    const disabled = this.isDisabled()
    const readOnly =
      id !== 'new' ? role === 'Admin' && data.ownerId !== userId : false
    // Poniższe ustawienie blokowało możliwość edycji tematu szablonów administracyjnych. Nie znamy powodu dla, ktorego tak było 
    // nic oczywistego w koddzie nie na taki powód nie wskazuje. Podjeliśmy decyzję o odblokowaniu.
    const monit = false 

    const formFields = [
      {
        name: 'name',
        label: 'Nazwa',
        required: true,
        disabled: disabled || readOnly || monit,
        hint: 'Wprowadź nazwę szalonu *',
        validate: v => (v ? undefined : 'Pole jest wymagane')
      },
      {
        type: fields.radiogroup,
        name: 'type',
        label: 'Typ wiadomości',
        required: true,
        disabled: disabled || readOnly || monit,
        options: [
          { value: 'sms', label: 'SMS' },
          { value: 'mail', label: 'Mail' }
        ],
        validate: v => (v ? undefined : 'Pole jest wymagane')
      },
      {
        name: 'subject',
        label: 'Tytuł',
        disabled: disabled || readOnly || monit,
        required: formValues && formValues.type === 'mail',
        hidden: formValues && formValues.type !== 'mail',
        hint:
          formValues &&
          formValues.type === 'mail' &&
          'Wprowadź tytuł wiadomości *',
        validate: v =>
          v
            ? undefined
            : formValues && formValues.type === 'mail'
              ? 'Pole jest wymagane'
              : undefined
      }
    ]

    formFields.push(
      formValues && formValues.type === 'mail'
        ? {
            type: fields.texteditor,
            name: 'content',
            style: styles.editorStyle,
            readOnly: disabled || readOnly
          }
        : {
            name: 'content',
            label: 'Treść wiadomości',
            required: true,
            rows: 17,
            disabled: disabled || readOnly,
            hint: 'Wprowadź treść wiadomości *',
            validate: v => (v ? undefined : 'Pole jest wymagane')
          }
    )

    return (
      <MuiForm
        open
        lang="pl"
        name="MuiForm"
        disabled={disabled || readOnly}
        add={id === 'new'}
        initialValues={data}
        title={`${(data && data.name) || 'Nowy Szablon'}`}
        fields={formFields}
        actions={{
          add: async data => {
            await axios.post(`${API_URL}${PATH}`, data, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            updateRows()
            snackbar(`Szablon (${data.name}) został dodany`)
          },
          edit: readOnly
            ? null
            : () => {
                push(`${PATH_PL}${id}?view=edit`)
              },
          save: async data => {
            let data_new = data
            delete data_new['_id']
            await axios.put(`${API_URL}${PATH}${id}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            updateRows()
            snackbar(`Szablon (${data.name}) został zapisany`)
          },
          delete: monit
            ? null
            : async data => {
                await axios.delete(`${API_URL}${PATH}${id}`, {
                  headers: { authorization: localStorage.getItem('token') }
                })
                push(PATH_PL)
                updateRows()
                snackbar(`Szablon został usunięty`)
              },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(
  state => {
    return { formValues: getFormValues('MuiForm')(state) }
  },
  {
    getFormValues
  }
)(Detail)
