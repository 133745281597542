import React, { Component } from 'react'
import NoAccess from './NoAccess'
import { oldTheme } from '../constants'

class Logout extends Component {
  componentWillMount() {
    this.props.unauthorisation()
  }

  render() {
    return (
      <NoAccess
        {...this.props}
        title="Wylogowano pomyślnie"
        icon="check_circle"
        button="Zaloguj się ponownie"
        iconStyle={{
          color: oldTheme.palette.primary3Color
        }}
      />
    )
  }
}

export default Logout
