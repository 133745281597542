import React, { Component } from 'react'
import axios from 'axios'
import { Spinner } from 'material-ui-elements'
import _ from 'lodash'
import moment from 'moment'
import Icon from 'material-ui/Icon'
import qs from 'query-string'
import core from '../../core/'
import { MuiTable } from 'material-ui-elements'
import { MuiDxGrid } from '@effco/mui-dxgrid'
import { PATH_PL, LABEL, TYPE } from '../constants'

const { fields } = MuiDxGrid
const {
  constants: {
    API_URL,
    STATUS_INFO,
    STATUS_IMG,
    oldTheme: {
      palette: { accent3Color }
    }
  }
} = core

class Container extends Component {
  state = {}
  delayed = 'all'

  async fetchToState() {
    const { data } = await axios.get(`${API_URL}/pupils/all/reports`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    const settings = await axios.get(`${API_URL}/settings`, {
      headers: { authorization: localStorage.getItem('token') }
    })
    this.checkDelayed(data, settings)
    this.setState({
      data: data.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    })
  }
  async componentWillMount() {
    this.fetchToState()
    this.delayed = qs.parse(this.props.location.search).delayed
  }

  async componentWillReceiveProps() {
    this.fetchToState()
  }
  checkDelayed(data, settings) {
    const annualSettings = settings.data[1]
    const reportDelays = settings.data[4]
    const year = settings.data[2]
    const endDate = _.get(
      annualSettings.value[year.activeYear],
      'reportsDate.endDate',
      null
    )
    const currentDate = moment(new Date())

    data.forEach(item => {
      const duration = parseInt(
        currentDate.diff(moment(item.report.date), 'days'),
        10
      )
      if (item.report.status === 70) return

      if (currentDate > moment(endDate)) {
        _.set(item, 'delayed', '1')
      } else if (item.report.status === 50 || item.report.status === 60) {
        if (duration >= reportDelays.value.secondTime) {
          _.set(item, 'delayed', '1')
        }
      } else if (duration >= reportDelays.value.firstTime) {
        _.set(item, 'delayed', '1')
      }
    })
  }
  setFilters = () => {
    const paramStatus = qs.parse(this.props.location.search).status
    const isAdmin = this.props.profile.role.includes('Admin')
    if (paramStatus) {
      return [parseInt(paramStatus, 10)]
    }
    return isAdmin ? [50, 60] : [10, 20, 30, 40]
  }
  render() {
    const isTeacher = this.props.profile.role.includes('Prowadzący')
    const {
      history: { push }
    } = this.props
    const { data } = this.state
    if (!data) return <Spinner />
    const startFilters = this.setFilters()
    return (
      <div>
        <MuiDxGrid
          name="reports"
          title={LABEL}
          lang="pl"
          refresh={true}
          layout={MuiDxGrid.layouts.drawer}
          rows={data}
          columns={[
            {
              name: 'report.author.fullName',
              width: 300,
              hidden: isTeacher,
              filteringEnabled: true,
              title: 'Autor'
            },
            {
              name: 'fullName',
              filteringEnabled: true,
              title: 'Uczestnik'
            },
            // {
            //   name: 'delayed',
            //   filteringEnabled: true,
            //   title: 'Opóźnione',
            //   hidden: true,
            //   customFilter: {
            //     type: fields.multiselect
            //   },
            //   options: [
            //     { value: '1', label: 'Tak' },
            //     { value: 'all', label: 'Nie' }
            //   ]
            // },
            {
              type: fields.component,
              width: 80,
              name: 'report.status',
              title: 'Status',
              filteringEnabled: true,
              customFilter: {
                type: fields.multiselect
              },
              options: [
                { value: 'none', label: 'Brak' },
                { value: 'all', label: 'Wszystkie' }
              ].concat(
                _.map(STATUS_INFO, (v, k) => ({ value: parseInt(k), label: v }))
              ),
              component: value => (
                <span
                  title={STATUS_INFO[value]}
                  style={{ textAlign: 'center', padding: 10 }}
                >
                  <Icon style={{ color: accent3Color }}>
                    {STATUS_IMG[value]}
                  </Icon>
                </span>
              )
            }
          ]}
          filtering={{
            //remember: true,
            type: 'multiselect',
            filters: [
              { name: 'report.status', value: startFilters },
              { name: 'delayed', value: [this.delayed] }
            ]
          }}
          selection={{ show: false }}
          paging
          detailsRow={{
            component: (row, contentProps) => {
              const similarityData = _.find(data, { id: row.id })
              if (!_.isEmpty(similarityData.report.similarity)) {
                return (
                  <MuiTable
                    orderBy="similarity"
                    order="asc"
                    filterBy="similarity"
                    lang="pl"
                    narrow
                    title={LABEL}
                    columns={[
                      {
                        name: 'fullName',
                        label: 'Uczestnik'
                      },
                      {
                        name: 'type',
                        label: 'Typ',
                        component: v => <span>{TYPE[v]}</span>
                      },
                      {
                        name: 'similarity',
                        label: 'Podobieństwo(%)',
                        component: v => <span>{v.toFixed(2)}</span>
                      }
                    ]}
                    data={similarityData.report.similarity}
                    onRowClick={i => push(`${PATH_PL}${i}`)}
                  />
                )
              } else {
                return <div> Brak podobieństw </div>
              }
            }
          }}
          actions={{
            onRowClick: rowId => {
              push(`${PATH_PL}${rowId}`)
            }
          }}
          actionButtonsTop={[]}
        />
      </div>
    )
  }
}

export default Container
