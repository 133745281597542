import _ from 'lodash'
import checkit from 'lodash-checkit'
import axios from 'axios'
import core from '../core'
import nn from 'nearest-neighbor'
import { PATH } from './constants'

const {
  constants: { API_URL },
  helpers: { auth, findExactUser, getUserActiveYear }
} = core

export const processRegistration = async (
  pupils,
  registrations,
  path,
  treshold
) => {
  var regMatch = []
  var regNoMatch = []
  var regNextStep = []
  var matches = { step1: 0, step2: 0, step3: 0, rest: 0 }
  //settings for fuzzy
  var items = pupils.map(({ contact: { email }, ...rest }) => ({
    email: email,
    contact: { email: email },
    ...rest
  }))
  var fields = []

  //RUN STEP 1
  registrations.forEach(r => {
    const matchedPupil = findExactUser(
      r,
      items.filter(p => r.city.toLowerCase() === p.city.toLowerCase())
    )

    Object.assign(r, {
      nearestNeighbours: matchedPupil
        ? r.status !== '10'
          ? { _id: matchedPupil._id, similarity: matchedPupil ? 1 : 0, step: 1 }
          : null
        : { _id: 'stash', similarity: 0, step: 10 }
    })
    matchedPupil && r.status !== '10' ? regMatch.push(r) : regNoMatch.push(r)
  })
  matches.step1 = regMatch.length
  regNextStep = regNoMatch
  regNoMatch = []

  //RUN STEP 2
  fields = [
    { name: 'firstName', measure: nn.comparisonMethods.word },
    { name: 'lastName', measure: nn.comparisonMethods.word },
    { name: 'email', measure: nn.comparisonMethods.word }
  ]

  matches.step2 = runIteration(
    items,
    fields,
    treshold,
    regNextStep,
    regMatch,
    regNoMatch,
    2
  )
  regNextStep = regNoMatch
  regNoMatch = []

  //RUN STEP 3
  fields = [
    { name: 'firstName', measure: nn.comparisonMethods.word },
    { name: 'lastName', measure: nn.comparisonMethods.word }
  ]

  matches.step3 = runIteration(
    items,
    fields,
    treshold,
    regNextStep,
    regMatch,
    regNoMatch,
    3
  )

  regNoMatch.map(r =>
    Object.assign(r, {
      nearestNeighbours: {
        _id: r.status === '10' ? 'stash' : 'new',
        similarity: 0,
        step: r.status === '10' ? 10 : 4
      }
    })
  )
  Array.prototype.push.apply(regMatch, regNoMatch)
  matches.rest = regNoMatch.length
  return { reg: regMatch, matches }
}

const runIteration = (
  items,
  fields,
  treshold,
  regNextStep,
  regMatch,
  regNoMatch,
  step
) => {
  var add = 0
  regNextStep.forEach(r => {
    var query = {
      firstName: r.firstName ? r.firstName.trim() : '',
      lastName: r.lastName ? r.lastName.trim() : ''
    }
    if (step === 2) Object.assign(query, { email: r.contact.email.trim() })

    nn.findMostSimilar(
      query,
      items.filter(p => r.city.toLowerCase() === p.city.toLowerCase()),
      fields,
      function(nearestNeighbor, probability) {
        const check =
          probability >=
          (treshold['treshold' + step] ? treshold['treshold' + step] : 0.75)
        Object.assign(r, {
          nearestNeighbours:
            check && r.status !== '10'
              ? {
                  _id: nearestNeighbor._id,
                  similarity: probability,
                  step: step
                }
              : null
        })
        check && r.status !== '10' ? regMatch.push(r) : regNoMatch.push(r)
        if (check && r.status !== '10') add = add + 1
      }
    )
  })
  return add
}

export const saveRegistration = async (registrations, pupils, path) => {
  const dataToUpload = await splitData(registrations, pupils)
  await axios.post(
    `${API_URL}${PATH}save`,
    { ...dataToUpload },
    {
      headers: { authorization: auth(true) }
    }
  )
  return {
    toUpdateResult: dataToUpload.toUpdate.length,
    toAddResult: dataToUpload.toAdd.length,
    toStashResult: dataToUpload.toStash.length,
    toDeleteResult: dataToUpload.toDelete.length
  }
}

const splitData = async (dataToSplit, pupils) => {
  let toAdd = [],
    toUpdate = [],
    toStash = [],
    toDelete = []
  const activeYear = await getUserActiveYear()

  _.orderBy(dataToSplit, ['audit.dateCreated'], ['asc']).forEach(d => {
    const linkPupil = checkit.isEmpty(d.nearestNeighbours)
      ? undefined
      : d.nearestNeighbours

    if (
      linkPupil && linkPupil._id
        ? linkPupil._id !== 'new' &&
          linkPupil._id !== 'del' &&
          linkPupil._id !== 'stash'
        : false
    ) {
      const objToPush = {}

      const objCont = _.get(
        _.find(pupils, { id: linkPupil._id }),
        `activity`,
        undefined
      )

      const currYearExists = objCont
        ? objCont.some(o => `${activeYear}` in o)
        : false

      if (!currYearExists) {
        Object.assign(objToPush, {
          regId: d.id,
          _id: linkPupil._id,
          school: d.school,
          city: d.city,
          continuation: setContinuation(objCont, activeYear),
          contact: d.contact,
          className: d.className,
          notes: d.notes,
          classes: d.classes ? d.classes : undefined
        })
        toUpdate.push(objToPush)
      } else {
        Object.assign(objToPush, { regId: d.id })
        toDelete.push(objToPush)
      }
    } else {
      const addEntry = linkPupil
        ? linkPupil._id ? linkPupil._id === 'new' : false
        : false
      if (addEntry) {
        const objToPush = {}
        Object.assign(objToPush, {
          regId: d.id,
          school: d.school,
          continuation: 0,
          firstName: d.firstName,
          lastName: d.lastName,
          city: d.city,
          contact: d.contact,
          className: d.className,
          notes: d.notes,
          classes: d.classes ? d.classes : undefined
        })
        toAdd.push(objToPush)
      } else if (!addEntry && linkPupil._id === 'stash') {
        const objToPush = {}
        Object.assign(objToPush, { regId: d.id })
        toStash.push(objToPush)
      } else if (!addEntry && linkPupil._id === 'del') {
        const objToPush = {}
        Object.assign(objToPush, { regId: d.id })
        toDelete.push(objToPush)
      }
    }
  })
  return { toAdd, toUpdate, toStash, toDelete }
}

const setContinuation = (obj, activeYear) => {
  if (!obj) return 0
  let continuation = 0
  if (obj.length >= 1) {
    obj.map((e, i) =>
      _.forOwn(e, (value, key) => {
        const group = value && value.group
        const tag = value && value.tag
        continuation =
          continuation || (group || (tag && tag === 'Wypisy')) ? 1 : 0
      })
    )
  }
  return continuation
}
