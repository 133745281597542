/*##############################################################################
# IMPORTY
##############################################################################*/
import React, { Component } from "react";
import axios                from "axios";
import core                 from "../../core";
import Table                from 'material-ui/Table';
import TableBody            from 'material-ui/Table/TableBody';
import TableRow             from 'material-ui/Table/TableRow';
import TableCell            from 'material-ui/Table/TableCell';
import TableHead            from 'material-ui/Table/TableHead';
import _                    from "lodash";


/*##############################################################################
# DESTRUCTORY
##############################################################################*/
const {
    constants: { API_URL },
    helpers: { api, createObjectId }
} = core;

/**
 * @param {string} startTime - Czas rozpoczęcia zajeć np. "07:15"
 *
 * @return {int} Długość proporcjonalna do długości trwania zajęć
 */
function getNormalizedStart(startTime){
    const [hour, minutes] = startTime.split(":").map(Number);

    const minutes_offset = 8*60; // 08:00
    const resolution     = 5; //minutes per block
    
    const minutes_since_midnight = hour * 60 + minutes;
    const minutes_since_offset   = minutes_since_midnight - minutes_offset;

    const blocks = minutes_since_offset / resolution;

    return blocks;
}

/**
 * @param {string} startTime - Czas rozpoczęcia zajęć np. "07:25"
 * @param {string} endTime   - Czas zakończenia zajęc np. "15:35"
 *
 * @return {int} Długośc proporcjonalna do czasu trwania zajęć
 */
function getNormalizedLength(startTime, endTime){ 
    if ( ! startTime || ! endTime ) 
        return 0;

    let [start_hour, start_minutes] = startTime.split(":").map(Number);
    let [end_hour, end_minutes]     = endTime.split(":").map(Number);

    /*
     * 08:50 - 09:25 = 00:35
     *         09:25 <=> 08:85
     * 08:50 - 08:85 = 00:35
     */
    if(end_minutes < start_minutes) {
        end_hour--;
        end_minutes += 60;
    }

    const len_hours   = end_hour - start_hour;
    const len_minutes = end_minutes - start_minutes;

    const minutes_offset = 8*60; // 08:00
    const resolution     = 5; //minutes per block
    
    const duration_in_minutes = len_hours * 60 + len_minutes;

    const blocks = duration_in_minutes/ resolution;

    return blocks;
}

/*##############################################################################
# COMPONENT
##############################################################################*/
class PupilsClasses extends Component {
    state = {}

    componentWillMount() {
        this.fetchToState();
    }

    async fetchToState() {
        const groupId = this.props.initialValues._id;
        const {data}  = await axios.get(`${API_URL}/groups/${groupId}/pupils`, {
            headers: {
                authorization: localStorage.getItem("token")
            }
        });

        this.setState({
            data,
        });
    }

    render() {
        if(!this.state.data)
            return null;

        const pupils = _.sortBy(this.state.data, [el=>el.className, el=>el.lastName]);

        // Przygotowuje wiersze tabeli
        const rows = pupils.map(function(pupil){
            // Normalizuje godziny zajęć
            // --------------------------
            // Przygotowuje 5 elementową tablice nulli
            let classes = Array(5).fill(null);
            // Nadpisuje ją danymi zajec
            classes = Object.assign(classes, pupil.classes);

            return (
                <TableRow>
                    <TableCell>{pupil.firstName} {pupil.lastName}</TableCell>
                    <TableCell>{pupil.className}</TableCell>
                    { classes.map( el => (<TableCell>
                        <div style={{
                            whiteSpace: "nowrap",
                        }}>
                            <div style={{
                                display: "inline-block",
                                width: el ? getNormalizedStart(el.from) : 0,
                                height: "20px",
                            }}></div>
                            <div 
                                style={{
                                    display            : "inline-block",
                                    width              : el ? getNormalizedLength(el.from, el.to) : 0,
                                    height             : "20px",
                                    "border-radius"    :"10px",
                                    "background-color" : "red"
                                }}
                                title= {(el) ? `${el.from} – ${el.to}`: "—"}
                            ></div>
                        </div>
                        <div>
                            <small style={{"white-space":"nowrap"}}>
                                {(el) ? `${el.from}–${el.to}`: "—"}
                            </small>
                        </div>
                    </TableCell>) ) }
                </TableRow>
            );
        });

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>imię i nazwisko</TableCell>
                        <TableCell>klasa</TableCell>
                        <TableCell>poniedziałek</TableCell>
                        <TableCell>wtorek</TableCell>
                        <TableCell>środa</TableCell>
                        <TableCell>czwartek</TableCell>
                        <TableCell>piątek</TableCell>
                    </TableRow>        
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        )
    }
}


/*##############################################################################
# EXPORT
##############################################################################*/
export default PupilsClasses;
