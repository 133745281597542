import React from 'react';
import { MenuItem } from 'material-ui-effco';
import MuiSelectfield from './MuiSelectfield';

const SelectDay = (props) => {
  const { field, onChange, disabled, required } = props;

  return (
    <MuiSelectfield
      field={field}
      onChange={onChange}
      hintText={`Wybierz dzień tygodnia${required ? ' *' : ''}`}
      floatingLabelText="Dzień Tygodnia"
      disabled={disabled}
      children={[
        <MenuItem key="PN" value="PN" primaryText="Poniedziałek" />,
        <MenuItem key="WT" value="WT" primaryText="Wtorek" />,
        <MenuItem key="SR" value="SR" primaryText="Środa" />,
        <MenuItem key="CZ" value="CZ" primaryText="Czwartek" />,
        <MenuItem key="PT" value="PT" primaryText="Piątek" />,
      ]}
    />);
};

export default SelectDay;
