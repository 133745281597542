import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

export default function(ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      if (!this.props.authenticated) {
        push('/login')
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        push('/login')
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.core.authenticated }
  }
  return connect(mapStateToProps)(Authentication)
}
