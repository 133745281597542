import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'
import core from '../../../../../core'
import * as actions from '../actions'
import { getAll, getInitialValues } from '../selectors'
import { getSelectedPupils } from '../../../selectors'
import { NAME } from '../constants'
import { validate, fields } from '../form'
import Dialog from './Dialog'

class Container extends Component {
  componentWillMount() {
    this.props.activate(false)
  }

  render() {
    return <Dialog {...this.props} />
  }
}

function mapState(state) {
  return {
    data: {
      ...getAll(state),
      selectedPupils: getSelectedPupils(state)
    },
    initialValues: getInitialValues(state)
  }
}

export default reduxForm(
  {
    form: NAME,
    fields,
    validate,
    reduxMountPoint: 'form_v5'
  },
  mapState,
  { ...actions, ...core.actions }
)(Container)
