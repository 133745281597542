import React from 'react'
import { withTheme, withStyles } from 'material-ui/styles'
import { IconButton, RaisedButton } from 'material-ui-effco'

// import Divider from 'material-ui/Divider'
import Drawer from 'material-ui/Drawer'
import Typography from 'material-ui/Typography'
import Toolbar from 'material-ui/Toolbar'

import Close from 'material-ui-effco/svg-icons/navigation/close'

const styles = theme => ({
  drawerPaper: {
    marginTop: 80, //145,
    marginRight: 10,
    width: 302,
    height: 'calc(100% - 92px)' //-145px //156px
    // borderRadius: '2px'
  },
  drawerModal: {
    zIndex: 1260
  },
  backDropRoot: { background: 'rgba(0, 0, 0, 0)', opacity: 0 },
  toolbarGutters: {
    paddingLeft: 0,
    paddingTop: '12px',
    paddingBottom: '2px',
    color: theme.palette.primary
  },
  typographyRoot: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.common.black //theme.palette.primary.main
  }
})

const OptionsDrawer = props => {
  const {
    classes,
    theme: { palette },
    title,
    children,
    open,
    variant,
    onClose,
    onCloseButtonCLick,
    // onBackdropCLick,
    onBottomButtonClick,
    bottomButtonLabel
  } = props

  const styles = {
    wrapper: {
      // margin: '0px 2px 10px 10px',
      padding: '0px 2px 0px 10px',
      height: 'calc(100% - 80px)'
    },
    toolbarWrapper: {
      height: 78
    },
    innerWrapper: {
      // marginTop: 46,
      height: 'calc(100% - 80px)',
      overflowY: 'auto',
      paddingRight: 8,
      overflowX: 'hidden'
    },
    button: {
      marginTop: 20
    },
    spacer: {
      marginTop: 20
    },
    subheader: {
      margin: 0,
      marginTop: 10,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0
    }
  }

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
        modal: classes.drawerModal
      }}
      anchor="right"
      variant={variant ? variant : 'temporary'}
      open={open}
      onClose={onClose.bind(this)}
      //onBackdropClick={onBackdropCLick ? onBackdropCLick.bind(this) : null}
      ModalProps={{
        hideBackdrop: variant === 'permanent' ? true : false,
        disableBackdropClick: false
        // disableEnforceFocus: true
      }}
      PaperProps={{
        elevation: 2
      }}
    >
      <div style={styles.toolbarWrapper}>
        <Toolbar classes={{ gutters: classes.toolbarGutters }}>
          <IconButton
            onTouchTap={onCloseButtonCLick.bind(this)}
            style={{
              width: 48,
              padding: '12px 12px 12px 12px'
            }}
            iconStyle={{ width: 18, height: 18, color: palette.common.black }} // palette.primary.main }}
          >
            <Close />
          </IconButton>
          <Typography classes={{ root: classes.typographyRoot }} type="title">
            {title.toUpperCase()}
          </Typography>
        </Toolbar>
      </div>
      {/* <Divider /> */}
      <div style={styles.wrapper}>
        <div style={styles.innerWrapper}>{children}</div>
        {bottomButtonLabel ? (
          <div style={{ paddingRight: 8 }}>
            <RaisedButton
              onTouchTap={() => onBottomButtonClick([])} //searchClear}
              label={bottomButtonLabel}
              labelPosition={'after'}
              style={{ ...styles.button }}
              primary
              fullWidth
            />
          </div>
        ) : null}
      </div>
    </Drawer>
  )
}

export default withStyles(styles)(withTheme()(OptionsDrawer))
