import React from 'react'
import { MenuItem } from 'material-ui-effco'
import common from '../../common'
import templates from '../../_old/templates'

const { MuiSelectfield } = common.components
const { TemplatesSelectfield } = templates.components

const ContentChoice = ({
  fields: { templateId },
  data: { contentOption, type, selectedTemplate },
  optionChange,
  templateChange,
  changeForm
}) => {
  const templatesSelectField =
    contentOption === 1 ? (
      <TemplatesSelectfield
        type={type}
        field={templateId}
        onChange={(e, t, v) => {
          templateChange(v)
        }}
      />
    ) : null

  return (
    <div>
      <MuiSelectfield
        field={{ value: contentOption }}
        floatingLabelText="Typ wiadomości"
        onChange={(e, t, v) => {
          optionChange(v)
        }}
      >
        <MenuItem value={1} primaryText={'Z szablonu'} />
        <MenuItem value={2} primaryText={'Dowolna wiadomość'} />
      </MuiSelectfield>
      {templatesSelectField}
    </div>
  )
}

export default ContentChoice
