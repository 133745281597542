import React, { Component } from 'react'
import { MuiForm, MuiTable } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import _ from 'lodash'
import core from '../../core'
import common from '../../common'
import { getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
import { PATH, PATH_PL } from '../constants'

const {
  constants: { API_URL }
} = core
const {
  components: { ClassesDetails },
  constants: { hoursObjEx }
} = common

const { fields } = MuiForm
const { layouts } = MuiTable

class Detail extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params
    const dayTable = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek']
    const queries = [
      axios.get(`${API_URL}${PATH}id/${id}`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ]

    const data = await Promise.all(queries)
    const pupilClasses = data[0].data
      ? data[0].data.classes
        ? data[0].data.classes
        : []
      : []
    const classes = !_.isEmpty(pupilClasses)
      ? pupilClasses.map((p, index) => {
          return {
            day: dayTable[index],
            from: p && p.from ? p.from : '---',
            to: p && p.to ? p.to : '---'
          }
        })
      : dayTable.map((d, index) => {
          return {
            day: d,
            from: null,
            to: null
          }
        })

    let dataEnriched = data[0].data
    dataEnriched.classes = classes

    this.setState({
      data: dataEnriched,
      classesExtended: classes
    })
  }

  async initialData(props) {}

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  render() {
    if (!this.state.data) return null
    const { data } = this.state
    const {
      match: {
        params: { id }
      },
      history: { push }
    } = this.props

    const add = id === 'new'
    const disabled = this.isDisabled()

    return (
      <ClassesDetails
        data={data}
        add={add}
        disabled={disabled}
        actions={{
          cancel: () => push(PATH_PL)
        }}
        groups={[
          {
            label: 'Plan zajęć lekcyjnych',
            fields: [
              {
                type: fields.table,
                name: 'classes',
                subform: true,
                narrow: true,
                lang: 'pl',
                data: this.state.classesExtended,
                layout: layouts.fieldArray,
                disabled: true,
                columns: [
                  {
                    name: 'day',
                    label: 'Dzień tygodnia',
                    compact: true,
                    disabled: true
                  },
                  {
                    type: fields.select,
                    name: 'from',
                    label: 'Zajęcia od',
                    compact: true,
                    disabled: disabled,
                    options: hoursObjEx.from
                  },
                  {
                    type: fields.select,
                    name: 'to',
                    label: 'Zajęcia do',
                    compact: true,
                    disabled: disabled,
                    options: hoursObjEx.to
                  }
                ]
              },
              {
                type: fields.spacer
              },
              {
                type: fields.textarea,
                rows: 5,
                maxRows: 5,
                name: 'notes.general2', //.signup',
                label: 'Uwagi od rodzica',
                hint: 'Uwagi'
              }
            ]
          }
        ]}
        {...this.props}
      />
    )
  }
}

export default connect(
  state => ({
    registrationDetail: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state)
      : ''
  }),
  { change }
)(Detail)
