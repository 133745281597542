import React, { Component } from 'react'
import { withTheme } from 'material-ui/styles'
import { MuiDxGrid } from '@effco/mui-dxgrid'

import _ from 'lodash'
import axios from 'axios'
import core from '../../core/'

import AssignAutocomplete from './AssignAutocomplete'

import { LABEL } from '../constants'

import * as constants from '../constants'

const { columns, PATH } = constants

const { fields } = MuiDxGrid

const {
  constants: { API_URL }
  // helpers: { api }
} = core

class List extends Component {
  state = { refresh: false }

  componentWillMount() {
    this.fetchToState()
  }

  extendColumns = (props, columns) => {
    const {
      data: { pupils }
    } = this.props

    const autocompleteColumn = {
      type: fields.component,
      name: 'pupilMatched',
      title: 'Opcje dopasowania',
      component: (value, row) => (
        <AssignAutocomplete
          style={{
            minWidth: '250px',
            verticalAlign: 'middle'
          }}
          onChange={v => {
            this.changeOption(row, v && v.value)
          }}
          margin={'none'}
          value={value}
          pupils={pupils}
        />
      )
    }
    return _.concat(columns, autocompleteColumn)
  }

  async fetchToState() {
    const {
      data: { data, pupils }
    } = this.props
    this.setState({
      data,
      columnsExtended: this.extendColumns(this.props, columns),
      pupils
    })
  }

  changeOption = (paymentRow, pupilId) => {
    const id = paymentRow
    const dataAdjusted = this.state.data
    const index = _.findIndex(dataAdjusted, ['id', id])
    const oldRow = _.get(dataAdjusted, `[${index}]`)
    const newRow = {
      ...oldRow,
      pupilMatched: pupilId ? pupilId : 'del',
      status: pupilId ? (pupilId === 'del' ? 30 : 20) : 30
    }
    _.set(dataAdjusted, `[${index}]`, newRow)

    this.setState({
      ...this.state,
      refresh: true,
      data: dataAdjusted
    })
  }

  render() {
    const {
      history: { push },
      theme: { palette },
      snackbar
    } = this.props
    const { data, refresh, columnsExtended } = this.state

    if (!data) return null

    return (
      <div>
        <MuiDxGrid
          name="payments"
          title={`${LABEL} - Import`}
          lang="pl"
          refresh={refresh}
          layout={MuiDxGrid.layouts.drawer}
          rows={data}
          columns={columnsExtended}
          sorting={{
            remember: false,
            order: [
              { name: 'status', direction: 'asc' },
              { name: 'date', direction: 'desc' }
            ]
          }}
          filtering={{
            remember: false
          }}
          paging
          selection={{ enabled: false }}
          actionButtonsTop={[
            {
              toggleShow: true,
              icon: 'clear',
              title: 'Wyklucz z importu',
              anchor: 'right',
              onClick: selection => console.log('Wyklucz z importu', selection),
              style: { color: palette.common.black }
            },
            {
              icon: 'save',
              title: 'Importuj płatności',
              anchor: 'right',
              style: { color: palette.common.black },
              onClick: async () => {
                const data = this.state.data
                const dataAdjusted = data
                  .filter(f => f.status === 10 || f.status === 20)
                  .map(d => ({
                    _id: d.pupilMatched,
                    data: {
                      payment: { paid: [{ date: d.date, amount: d.amount }] }
                    }
                  }))

                const response = await axios.post(
                  `${API_URL}${PATH}`,
                  dataAdjusted,
                  {
                    headers: { authorization: localStorage.getItem('token') }
                  }
                )

                const status = response && response.data && response.data.status
                if (status === 200) {
                  snackbar(`Płatności zostały zaimportowane`)
                  push('/uczestnicy', undefined)
                } else {
                  snackbar(
                    `Błąd podczas importu płatności (kod błędu: ${status})`
                  )
                }
              }
            }
          ]}
        />
      </div>
    )
  }
}

export default withTheme()(List)
