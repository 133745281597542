import React, { Component } from 'react'
import common from '../../../common'

const { MuiTextfield } = common.components

class TemplatesViewerSms extends Component {
  render() {
    const { content } = this.props

    return (
      <div>
        <MuiTextfield
          field={content}
          floatingLabelText="Treść wiadomości"
          rows={17}
        />
      </div>
    )
  }
}

export default TemplatesViewerSms
