import _ from 'lodash'

export const enrichPayments = payment => {
  if (payment) {
    var totalSum = sumPaid(payment.paid)
    var liabilityTotal = calculateLiability(
      payment.due ? (payment.due.s1 ? payment.due.s1 : 0) : 0,
      payment.due ? (payment.due.s2 ? payment.due.s2 : 0) : 0,
      payment.paid
    )
    var liabilityS1 = calculateLiabilityS1(
      payment.due ? (payment.due.s1 ? payment.due.s1 : 0) : 0,
      payment.due ? (payment.due.s2 ? payment.due.s2 : 0) : 0,
      payment.paid
    )
    var liabilityS2 = calculateLiabilityS2(
      payment.due ? (payment.due.s1 ? payment.due.s1 : 0) : 0,
      payment.due ? (payment.due.s2 ? payment.due.s2 : 0) : 0,
      payment.paid
    )
  }
  return {
    total: totalSum * 1 || 0,
    liabilityTotal: liabilityTotal * 1 || 0,
    liabilityS1: liabilityS1 * 1 || 0,
    liabilityS2: liabilityS2 * 1 || 0
  }
}

export const sumPaid = paid => {
  let totalValue = 0
  _.forOwn(paid, value => {
    // if (typeof value.amount === 'number') {
    totalValue += value.amount * 1
    // }
  })
  return totalValue * 1
}

export const calculateLiability = (s1, s2, paid) => {
  // return (_.has(payment, 'due') ? (_.has(payment.due, 's1') ? payment.due.s1 : 0) +
  //   (_.has(payment.due, 's2') ? payment.due.s2 : 0) : 0) - sumPaid(payment.paid);
  return +s1 * 1 + +s2 * 1 - sumPaid(paid)
  // return Math.max(+s1 * 1 + +s2 * 1 - sumPaid(paid), 0)
}

export const calculateLiabilityS1 = (s1, s2, paid) => {
  return +s1 * 1 - sumPaid(paid)
  // return Math.max(+s1 * 1 - sumPaid(paid), 0)
}

export const calculateLiabilityS2 = (s1, s2, paid) => {
  return +s2 * 1 - (sumPaid(paid) > +s1 * 1 ? sumPaid(paid) - +s1 * 1 : 0)
  // return Math.max(
  //   +s2 * 1 - (sumPaid(paid) > +s1 * 1 ? sumPaid(paid) - +s1 * 1 : 0),
  //   0
  // )
}
