export const validate = (formProps) => {
  const errors = { custom: {} };

  if (!/SP(\d{1,3}| \w+)/.test(formProps.number)) {
    errors.number = 'Wprowadź nr szkoły lub nazwę, np. SP10, SP Rzgów';
  }
  if (!formProps.number) { errors.number = 'Pole wymagane'; }
  if (!formProps.fullName) { errors.fullName = 'Pole wymagane'; }
  if (!formProps.address) { errors.address = 'Pole wymagane'; }
  if (!formProps.city) { errors.city = 'Pole wymagane'; }
  if (!formProps.custom.account1) { errors.custom.account1 = 'Pole wymagane'; }
  if (!formProps.custom.account2) { errors.custom.account2 = 'Pole wymagane'; }
  if (!formProps.custom.account3) { errors.custom.account3 = 'Pole wymagane'; }
  if (!formProps.custom.account4) { errors.custom.account4 = 'Pole wymagane'; }

  return errors;
};

export const fields = [
  '_id',
  'number',
  'address',
  'district',
  'fullName',
  'custom.account1',
  'custom.account2',
  'custom.account3',
  'custom.account4',
  'city',
];
