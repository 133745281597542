import React from 'react'
import { withStyles } from 'material-ui/styles'
import Drawer from 'material-ui/Drawer'
import Divider from 'material-ui/Divider'
import core from '../../core'

const styles = theme => ({
  drawerPaper: {
    zIndex: 90
  }
})

const {
  components: { IconLink }
} = core

const Settings = props => {
  const { classes, profile } = props

  const superAdmin = profile && profile.role === 'Super Admin'

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper
      }}
      open
      variant="permanent"
    >
      <div style={{ width: 50, marginTop: 70 }}>
        {superAdmin && (
          <div>
            <IconLink
              to="/administracja/ustawienia/"
              icon="build"
              color="primary"
              title="Ustawienia ogólne"
              style={{ color: undefined }}
              {...props}
            />
            <Divider />
          </div>
        )}
        <IconLink
          to="/administracja/komunikacja/"
          icon="email"
          title="Komunikacja"
          color="primary"
          style={{ color: undefined }}
          {...props}
        />
        <IconLink
          to="/administracja/powiadomienia/"
          icon="notifications"
          hidden={
            props.profile && props.profile.role === 'Super Admin' ? false : true
          }
          title="Powiadomienia"
          color="primary"
          style={{ color: undefined }}
          {...props}
        />
        <IconLink
          to="/administracja/szablony/"
          icon="description"
          title="Szablony"
          color="primary"
          style={{ color: undefined }}
          {...props}
        />{' '}
        <Divider />
        <IconLink
          to="/administracja/sprawozdania/"
          icon="event_note"
          raised
          title="Sprawozdania"
          color="primary"
          style={{ color: undefined }}
          {...props}
        />
      </div>
      <Divider />
      <IconLink
        to="/administracja/zapisy/lista"
        icon="person_add"
        color="primary"
        title="Zapisy"
        style={{ color: undefined }}
        {...props}
      />
      <IconLink
        to="/administracja/zapisy/przydzial"
        icon="group_add"
        color="primary"
        title="Przydział do grup"
        style={{ color: undefined }}
        {...props}
      />
      <IconLink
        to="/administracja/grupy/zajecia/"
        icon="assignment_ind"
        title="Tematy wg prowadzących"
        color="primary"
        style={{ color: undefined }}
        {...props}
      />
      <IconLink
        to="/administracja/tematy/"
        icon="subject"
        title="Tematy"
        color="primary"
        style={{ color: undefined }}
        {...props}
      />
      <IconLink
        to="/administracja/rodzenstwa/"
        icon="group"
        raised
        title="Rodzeństwa"
        color="primary"
        style={{ color: undefined }}
        {...props}
      />
      <Divider />
      <IconLink
        to="/administracja/miasta/"
        icon="location_city"
        raised
        title="Miasta"
        color="primary"
        style={{ color: undefined }}
        {...props}
      />
      <IconLink
        to="/administracja/uzytkownicy/"
        icon="supervisor_account"
        raised
        title="Użytkownicy"
        color="primary"
        style={{ color: undefined }}
        {...props}
      />
    </Drawer>

    //   {/* <IconLink
    //     to="/administracja/miasta/"
    //     icon="location_city"
    //     raised
    //     caption="Miasta"
    //     color="primary"
    //     style={{ marginRight: 12, color: undefined }}
    //     {...props}
    //   /> */}
    // </div>
  )
}

export default withStyles(styles)(Settings)
