import React, { Component } from 'react'
import Promotion from 'material-ui-effco/svg-icons/toggle/star'

const styles = {
  icon: {
    height: 18,
    width: 18,
    marginLeft: 4,
    marginBottom: -4
  }
}

class PromotionIcon extends Component {
  render() {
    if (this.props.value)
      return (
        <span title="Promocja">
          <Promotion style={styles.icon} color={this.props.color} />
        </span>
      )
    else return <span />
  }
}

export default PromotionIcon
