import List from './List'
import ProcessedList from './ProcessedList'
import Form from './Form'
import Confirmation from './Confirmation'
import ProcessButton from './ProcessButton'
import Detail from './Detail'

export default {
  ProcessedList,
  ProcessButton,
  List,
  Form,
  Confirmation,
  Detail
}
