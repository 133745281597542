export const validate = () => {
  const errors = {}

  return errors
}

export const fields = [
  'notes.general',
  'notes.management',
  'tag',
  'group._id',
  'payment.finance',
  'continuation',
  'siblings',
  'payment.due.s1',
    'payment.due.s2',
    'monthlyFee',
]
