import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MenuItem } from 'material-ui-effco'
import common from '../../../common'
import * as actions from '../actions'
import { getAllSorted } from '../selectors'

const { MuiSelectfield } = common.components

class GroupsSelectfield extends Component {
  componentWillMount() {
    this.props.fetch('noActivate')
  }

  render() {
    const {
      city,
      hintText,
      onChange,
      field,
      disabled,
      data: { data: { groups } }
    } = this.props
    const groupOptions = groups
      .filter(group => group.city === city)
      .map(group => (
        <MenuItem
          key={group._id}
          value={group._id}
          primaryText={
            group.groupName +
            (group.teacher
              ? ` (${group.teacher.firstName} ${group.teacher.lastName})`
              : '')
          }
        />
      ))
    groupOptions.unshift(
      <MenuItem key={null} value={null} primaryText={'Brak'} />
    )

    return (
      <MuiSelectfield
        field={field._id}
        onChange={onChange}
        hintText={hintText}
        floatingLabelText="Grupa"
        disabled={disabled}
      >
        {groupOptions}
      </MuiSelectfield>
    )
  }
}

const mapState = state => ({
  data: getAllSorted(state)
})

export default connect(mapState, actions)(GroupsSelectfield)
