import React, { Component } from 'react'
import Siblings from 'material-ui-effco/svg-icons/notification/wc'

const styles = {
  icon: {
    height: 18,
    width: 18,
    marginLeft: 4,
    marginBottom: -4
  }
}

class SiblingsIcon extends Component {


  render() {
    if (this.props.value) return (<span title='Rodzeństwo'><Siblings style={styles.icon} color={this.props.color} /></span>)
    else return <span />
  }
}

export default SiblingsIcon
