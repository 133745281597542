import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'
import core from '../../../core'
import { NAME } from '../constants'
import * as actions from '../actions'
import * as selectors from '../selectors'
import { validate, fields } from '../form'
import Editor from './Editor'

const { Spinner } = core.components

class Container extends Component {
  componentWillMount() {
    this.props.fetch()
  }

  render() {
    const { loading } = this.props.data

    if (loading) return <Spinner />

    return <Editor {...this.props} />
  }
}

function mapState(state) {
  return {
    data: {
      ...selectors.getAllSorted(state),
      activeTemplate: selectors.getActiveTemplate(state)
    },
    initialValues: selectors.getActiveTemplate(state)
  }
}

export default reduxForm(
  {
    form: NAME,
    fields,
    validate,
    reduxMountPoint: 'form_v5'
  },
  mapState,
  actions
)(Container)
