import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'material-ui/styles'
// import _ from 'lodash'

import FilterCellSelect from './FilterCellSelect'
import FilterCellText from './FilterCellText'
import FilterCellDate from './FilterCellDate'

import { getFilterSelectOptions } from '../../helpers'

const FilterCell = ({ availableValues, ...rest }) => {
  const column = rest.column
  if (column && column.type === 'select') {
    const availableColumnValues = getFilterSelectOptions(
      availableValues,
      column.options
    )

    return (
      <FilterCellSelect
        name={`${column.name}`}
        availableColumnValues={availableColumnValues}
        {...rest}
      />
    )
  } else if (column && column.type === 'date') {
    return <FilterCellDate name={`${column.name}`} {...rest} />
  }

  return <FilterCellText name={`${column.name}`} {...rest} />
}

FilterCell.propTypes = {
  column: PropTypes.shape({ name: PropTypes.string }).isRequired
  // theme: PropTypes.object.isRequired
}

export default withTheme()(FilterCell)
