import * as tl from './types'
import core from '../core'

const t = { ...tl, ...core.types }

const INITIAL_STATE = {
  loading: false,
  data: { cities: [] }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case t.FETCH:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          cities: action.payload.data
        }
      }

    case t.UNAUTH:
      return INITIAL_STATE

    default:
      return state
  }
}
