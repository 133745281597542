import React, { Component } from 'react'
import _ from 'lodash'
import {
  Drawer,
  List,
  ListItem,
  Avatar,
  Subheader,
  makeSelectable
} from 'material-ui-effco'
import core from '../../core'

const { palette } = core.constants.oldTheme

const styles = {
  drawer: {
    position: 'fixed'
  },
  container: {
    marginTop: 75
  }
}

const wrapState = ComposedComponent =>
  class SelectableList extends Component {
    componentWillMount() {
      this.setState({
        selectedIndex: this.props.defaultValue
      })
    }

    handleRequestChange = (event, index) => {
      this.setState({
        selectedIndex: index
      })
    }

    render() {
      return (
        <ComposedComponent
          value={this.state.selectedIndex}
          onChange={this.handleRequestChange}
        >
          {this.props.children}
        </ComposedComponent>
      )
    }
  }

const SelectableList = wrapState(makeSelectable(List))

class ClassesSidebar extends Component {
  renderGroups(groups) {
    const { classesSelect } = this.props

    if (groups)
      if (groups.length) {
        return _.sortBy(groups, ['dayNumber', 'timeStartString']).map(
          (group, index) => (
            <ListItem
              key={group._id}
              value={group.groupName}
              rightAvatar={
                <Avatar
                  size={30}
                  style={{ marginTop: 5 }}
                  backgroundColor={palette.primary1Color}
                >
                  {group.pupils.length}
                </Avatar>
              }
              primaryText={group.groupName}
              secondaryText={group.classroom ? `w sali ${group.classroom}` : ''}
              onTouchTap={() => classesSelect(group._id)}
            />
          )
        )
      } else return <div />
  }

  render() {
    const { data: { active, data: { groups } } } = this.props
    const groupSelected = active ? _.find(groups, { _id: active }) : {}
    return (
      <div>
        <Drawer width={250} openSecondary open style={styles.drawer}>
          <div style={styles.container}>
            <Subheader>Lista grup</Subheader>
            <SelectableList
              defaultValue={groupSelected.groupName}
              onChange={() => {}}
            >
              {this.renderGroups(groups)}
            </SelectableList>
          </div>
        </Drawer>
      </div>
    )
  }
}

export default ClassesSidebar
