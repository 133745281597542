import { NAME } from './constants';

export const TOGGLE_NAV = `${NAME}/TOGGLE_NAV`;

export const SNACKBAR = `${NAME}/SNACKBAR`;

export const AUTH = `${NAME}/AUTH`;
export const UNAUTH = `${NAME}/UNAUTH`;

export const PROFILE_FETCH = `${NAME}/PROFILE_FETCH`;
export const PROFILE_PREFS_FETCH = `${NAME}/PROFILE_PREFS_FETCH`;
export const PROFILE_EDIT_TOGGLE = `${NAME}/PROFILE_EDIT_TOGGLE`;

