import React, { Component } from 'react'
import { TableRow, TableRowColumn, IconButton } from 'material-ui-effco'
import EditIcon from 'material-ui-effco/svg-icons/image/edit'
import core from '../../core'
import common from '../../common'
const { palette } = core.constants.oldTheme

const { ContinuationIcon, SiblingsIcon } = common.components

class ClassesDetailTableRow extends Component {
  render() {
    const {
      toggleEdit,
      pupil: {
        firstName,
        lastName,
        continuation,
        siblings,
        className,
        contact: { phone, email },
        notes: { teacher }
      },
      index
    } = this.props

    return (
      <TableRow style={{ height: 36 }}>
        <TableRowColumn style={{ height: 36, width: 150 }}>
          <span
            title={`${firstName} ${lastName}`}
          >{`${firstName} ${lastName}`}</span>
          <ContinuationIcon
            color={palette.primary1Color}
            value={continuation}
          />
          <SiblingsIcon color={palette.primary1Color} value={siblings} />
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 80, textAlign: 'center' }}>
          {className.toUpperCase()}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 80, textAlign: 'center' }}>
          {phone.substring(0, 3)} {phone.substring(3, 6)}{' '}
          {phone.substring(6, 9)}
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 200 }}>
          <span title={email}>{email}</span>
        </TableRowColumn>
        <TableRowColumn
          onTouchTap={() => toggleEdit(index)}
          style={{ height: 36 }}
        >
          <span title={teacher}>{teacher}</span>
        </TableRowColumn>
        <TableRowColumn style={{ height: 36, width: 40 }}>
          <IconButton
            style={{ width: 36, height: 36, padding: 0 }}
            onTouchTap={() => toggleEdit(index)}
          >
            <EditIcon color={palette.accent3Color} />
          </IconButton>
        </TableRowColumn>
      </TableRow>
    )
  }
}

export default ClassesDetailTableRow
