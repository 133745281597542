import React from 'react'
import Checkbox from 'material-ui/Checkbox'
import { TableCell, TableRow } from 'material-ui/Table'
import Media from 'react-media'

const ListViewStandardHeader = props => {
  const { togglePupilSelectAll } = props
  const {
    multiSelect: { visible, selectAllChecked },
    data: { pupils }
  } = props.data

  const pupilIds = pupils.map(p => p._id)

  return (
    <TableRow>
      {visible ? (
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectAllChecked}
            onChange={() => togglePupilSelectAll(selectAllChecked, pupilIds)}
          />
        </TableCell>
      ) : null}
      <TableCell padding="dense">Imię i Nazwisko</TableCell>
      <TableCell padding="dense">Grupa / Tag</TableCell>
      <TableCell padding="dense">Szkoła / Klasa</TableCell>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Prognoza
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Wpłaty
        </TableCell>
      </Media>
      <Media query="(min-width: 1200px)">
        <TableCell padding="dense" numeric>
          Należność
        </TableCell>
      </Media>
      <TableCell padding="dense">Telefon</TableCell>
      <TableCell padding="dense">Email</TableCell>
      <TableCell padding="dense">Uwagi ogólne</TableCell>
      <TableCell padding="dense">Uwagi ogólne 2</TableCell>
    </TableRow>
  )
}

export default ListViewStandardHeader
