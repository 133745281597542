import _ from 'lodash'
import groups from '../../_old/groups'
import schools from '../../schools'

import { NAME } from './constants'

export const getAll = state => state[NAME]

function filterPupils(
  pupils,
  {
    name,
    city,
    groups,
    schools,
    tag,
    teacher,
    general,
    payments,
    continuation,
    siblings,
    general2,
    hasMonthlyFee,
    general3,
  }
) {
  return pupils
    .filter(
      x =>
        name
          ? `${x.firstName} ${x.lastName}`
              .toLowerCase()
              .indexOf(name.toLowerCase()) >= 0 ||
            x.contact.email
              .toString()
              .toLowerCase()
              .indexOf(name.toLowerCase()) >= 0 ||
            x.contact.phone
              .toString()
              .toLowerCase()
              .indexOf(name.toLowerCase()) >= 0
            ? true
            : false
          : true
    ) // eslint-disable-line max-len, no-confusing-arrow, no-unneeded-ternary, no-nested-ternary
    .filter(x => x.city === city)
    .filter(x => {
      if (groups[0] === 'none') return !x.group
      if (groups[0] === 'any') return x.group
      if (!groups.length) return true
      if (x.group) return groups.includes(x.group)
      return false
    })
    .filter(x => {
      if (!schools.length) return true
      if (x.school) return schools.includes(x.school)
      return false
    })
    .filter(x => (tag ? (x.tag ? x.tag === tag : false) : true)) // eslint-disable-line max-len, no-confusing-arrow, no-nested-ternary
    .filter(x => (teacher ? x.teacher && x.teacher._id === teacher : true))
    .filter(
      x =>
        tag === null || x.tag === '' ? x.tag === null || x.tag === '' : true
    ) // eslint-disable-line max-len, no-confusing-arrow
    .filter(
      x =>
        general
          ? x.notes.general.toLowerCase().indexOf(general.toLowerCase()) >= 0
            ? true
            : false
          : true
    ) // eslint-disable-line max-len, no-confusing-arrow, no-unneeded-ternary, no-nested-ternary
    .filter(
      x =>
        _.get(payments, 'due.active') // eslint-disable-line no-confusing-arrow
          ? (+(_.get(x, 'payment.due.s1') || 0) +
              +(_.get(x, 'payment.due.s2') || 0) !==
              0 &&
              !!_.get(payments, 'due.value')) ||
            (+(_.get(x, 'payment.due.s1') || 0) +
              +(_.get(x, 'payment.due.s2') || 0) ===
              0 &&
              !_.get(payments, 'due.value'))
          : true
    )
    .filter(
      x =>
        _.get(payments, 'paid.active') // eslint-disable-line no-confusing-arrow
          ? ((x.payment && x.payment.paid
              ? x.payment.paid.reduce((p, c) => p * 1 + c * 1, 0)
              : 0) !== 0 &&
              payments.paid.value) ||
            ((x.payment && x.payment.paid
              ? x.payment.paid.reduce((p, c) => p * 1 + c * 1, 0)
              : 0) === 0 &&
              !payments.paid.value)
          : true
    )
    .filter(
      x =>
        _.get(payments, 'liability.s1.active') // eslint-disable-line max-len, no-confusing-arrow, no-nested-ternary
          ? _.get(payments, 'liability.s1.value')
            ? +_.get(x, 'payment.due.s1') -
                (x.payment && x.payment.paid ? x.payment.paid : [])
                  .map(y => y.amount)
                  .reduce((p, c) => p * 1 + c * 1, 0) >
              0
            : +_.get(x, 'payment.due.s1') -
                (x.payment && x.payment.paid ? x.payment.paid : [])
                  .map(y => y.amount)
                  .reduce((p, c) => p * 1 + c * 1, 0) <=
              0
          : true
    )
    .filter(
      x =>
        _.get(payments, 'liability.s2.active') // eslint-disable-line max-len, no-confusing-arrow, no-nested-ternary
          ? _.get(payments, 'liability.s2.value')
            ? +_.get(x, 'payment.due.s2') -
                ((x.payment && x.payment.paid ? x.payment.paid : [])
                  .map(y => y.amount)
                  .reduce((p, c) => p * 1 + c * 1, 0) >
                +(_.get(x, 'payment.due.s1') || 0)
                  ? (x.payment && x.payment.paid ? x.payment.paid : [])
                      .map(y => y.amount)
                      .reduce((p, c) => p * 1 + c * 1, 0) -
                    +(_.get(x, 'payment.due.s1') || 0)
                  : 0) >
              0
            : +_.get(x, 'payment.due.s2') -
                ((x.payment && x.payment.paid ? x.payment.paid : [])
                  .map(y => y.amount)
                  .reduce((p, c) => p * 1 + c * 1, 0) >
                +(_.get(x, 'payment.due.s1') || 0)
                  ? (x.payment && x.payment.paid ? x.payment.paid : [])
                      .map(y => y.amount)
                      .reduce((p, c) => p * 1 + c * 1, 0) -
                    +(_.get(x, 'payment.due.s1') || 0)
                  : 0) <=
              0
          : true
    )
    .filter(
      x =>
        _.get(payments, 'finance.active') // eslint-disable-line no-confusing-arrow
          ? !!_.get(x, 'payment.finance') === _.get(payments, 'finance.value')
          : true
    )
    .filter(
      x =>
        continuation.active // eslint-disable-line no-confusing-arrow
          ? !!x.continuation === continuation.value
          : true
    )
    .filter(
      x =>
        siblings.active // eslint-disable-line no-confusing-arrow
          ? !!x.siblings === siblings.value
          : true
    )
    .filter(
      x =>
        general2.active // eslint-disable-line no-confusing-arrow
          ? !!x.notes.general2 === general2.value
          : true
    )
    .filter(
      x =>
        hasMonthlyFee.active // eslint-disable-line no-confusing-arrow
          ? !!x.hasMonthlyFee === hasMonthlyFee.value
          : true
    )
    .filter(
        pupil => {
            if(!general3)
                return true

            let pupil_note;
            let search_term;

            try {
                pupil_note  = pupil.notes.general3.toLowerCase()
                search_term = general3.toLowerCase()
            } catch(e){
                return false;
            }
            const notes_match = pupil_note.indexOf(search_term) >= 0

            if( notes_match) 
                return true;

            return false
        }
    ) // eslint-disable-line max-len, no-confusing-arrow, no-unneeded-ternary, no-nested-ternary
}

const filterSelectedRows = (pupilIds, selectedRows) => {
  const selectedRowsFiltered = selectedRows.filter(
    row => pupilIds.indexOf(row) > -1
  )
  return {
    selectedRows: selectedRowsFiltered,
    selectAllChecked:
      selectedRowsFiltered.length === pupilIds.length &&
      selectedRowsFiltered.length
  }
}

export const getAllWithFilteredPupils = state => {
  const { groups } = state.groups.data
  const filteredPupils = filterPupils(
    state[NAME].data.pupils.map(p => ({
      ...p,
      teacher:
        p.group && groups.length
          ? _.find(state.groups.data.groups, { _id: p.group }) &&
            _.find(state.groups.data.groups, { _id: p.group }).teacher
          : null
    })),
    state[NAME].search.criteria
  )
  const pupilIds = filteredPupils.map(pupil => pupil._id)
  return {
    ...state[NAME],
    data: {
      ...state[NAME].data,
      pupils: _.sortBy(filteredPupils, ['className', 'lastName'])
    },
    multiSelect: {
      ...state[NAME].multiSelect,
      ...filterSelectedRows(pupilIds, state[NAME].multiSelect.selectedRows)
    }
  }
}

export const getActivePupil = state => {
  if (state[NAME].active.id === null) {
    return null
  }
  if (state[NAME].active.id === 'add') {
    return { group: { _id: null }, tag: null }
  }
  const pupilIds = state[NAME].data[NAME].map(pupil => pupil._id)

  const pupilIndex = pupilIds.indexOf(state[NAME].active.id)
  return {
    ...state[NAME].data[NAME][pupilIndex],
    group: {
      ...state[NAME].data[NAME][pupilIndex].group,
      _id: state[NAME].data[NAME][pupilIndex].group
        ? state[NAME].data[NAME][pupilIndex].group._id
        : null
    }
  }
}

export const getSelectedPupils = state => {
  const filteredPupils = filterPupils(
    state[NAME].data.pupils,
    state[NAME].search.criteria
  )
  return filteredPupils.filter(
    pupil => state[NAME].multiSelect.selectedRows.indexOf(pupil._id) > -1
  )
}

export const getSelectedPupilIds = state => {
  const filteredPupils = filterPupils(
    state[NAME].data.pupils,
    state[NAME].search.criteria
  )
  return filteredPupils
    .filter(
      pupil => state[NAME].multiSelect.selectedRows.indexOf(pupil._id) > -1
    )
    .map(p => p._id)
}

export const getSelectedGroup = state => {
  const selectedGroups = state[NAME].search.criteria.groups
  const selectedSchools = state[NAME].search.criteria.schools

  if (
    selectedGroups.length === 1 &&
    selectedGroups[0] !== 'none' &&
    selectedGroups[0] !== 'any'
  ) {
    if (selectedSchools.length <= 1) {
      let group = groups.selectors.getGroup(state, selectedGroups[0])
      group = { ...group, classes: _.sortBy(group.classes, ['date']) }
      return group
      //  groups.selectors.getGroup(state, {
      //   ...selectedGroups[0],
      //   classes: _.sortBy(selectedGroups[0].classes, ['date'])
      // })
    }
    return null
  }

  return null
}

export const getSelectedSchool = state => {
  const selectedSchools = state[NAME].search.criteria.schools

  if (
    selectedSchools.length === 1 &&
    selectedSchools[0] !== 'none' &&
    selectedSchools[0] !== 'any'
  ) {
    return schools.selectors.getSchool(state, selectedSchools[0])
  }
  return null
}
