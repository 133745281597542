import React, { Component } from 'react'
import _ from 'lodash'
import {
  Paper,
  Toolbar,
  ToolbarGroup,
  ToolbarTitle,
  Divider
} from 'material-ui-effco'
import ClassesDetailTableSemester from './detail_table_semester'
import ClassesDetailTableInfo from './detail_table_info'
import ClassesDetailTableInfoDialog from './detail_table_info_dialog'
import ClassesDetailTableButtons from './detail_table_buttons'

const styles = {
  toolbar: {
    backgroundColor: 'white'
  },
  container: {
    marginTop: 75,
    marginLeft: 10,
    marginRight: 10
  },
  groupDetail: {
    width: 'calc(100% - 255px)'
  },
  title: {
    marginLeft: 24,
    color: '#000 87%'
  }
}

const getClassesDateRange = semester => {
  const today = new Date()
  if (semester === 's1') {
    return {
      from: new Date(
        today.getFullYear() + (today.getMonth() > 8 ? 0 : -1),
        8,
        1
      ),
      to: new Date(today.getFullYear() + (today.getMonth() > 8 ? 1 : 0), 1, 1)
    }
  }
  return {
    from: new Date(today.getFullYear() + (today.getMonth() > 8 ? 1 : 0), 1, 1),
    to: new Date(today.getFullYear() + (today.getMonth() > 8 ? 1 : 0), 7, 1)
  }
}

const whichSem = (date = new Date()) => {
  const month = date.getMonth()
  if (month > 8 || month < 2) {
    return 's1'
  }
  return 's2'
}

class ClassesDetail extends Component {
  render() {
    const { data: { view, active, activePupil, data: { groups } } } = this.props

    const groupSelected = active ? _.find(groups, { _id: active }) : {}

    const classString = groupSelected.classroom
      ? `, zajęcia w sali ${groupSelected.classroom}`
      : ''
    const titleString = `Lista obecności - grupa ${
      groupSelected.groupName
    }${classString}`

    const views = {
      default: (
        <ClassesDetailTableSemester
          {...this.props}
          dates={getClassesDateRange(whichSem())}
        />
      ),
      s1: (
        <ClassesDetailTableSemester
          {...this.props}
          dates={getClassesDateRange('s1')}
        />
      ),
      s2: (
        <ClassesDetailTableSemester
          {...this.props}
          dates={getClassesDateRange('s2')}
        />
      ),
      info: <ClassesDetailTableInfo {...this.props} />
    }

    if (groupSelected.groupName) {
      return (
        <div>
          {!isNaN(parseFloat(activePupil)) ? (
            <ClassesDetailTableInfoDialog {...this.props} />
          ) : null}
          <Paper style={styles.groupDetail}>
            <Toolbar style={styles.toolbar}>
              <ToolbarGroup firstChild>
                <ToolbarTitle style={styles.title} text={titleString} />
              </ToolbarGroup>
              <ToolbarGroup>
                <ClassesDetailTableButtons {...this.props} />
              </ToolbarGroup>
            </Toolbar>
            {views[view]}
            <Divider />
            <Toolbar style={styles.toolbar} />
          </Paper>
        </div>
      )
    }
    return null
  }
}

export default ClassesDetail
