import React, { Component } from 'react'
import { MuiForm, MuiTable } from 'material-ui-elements'
import axios from 'axios'
import _ from 'lodash'
import qs from 'query-string'
import { getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
import core from '../../core'
import common from '../../common'
import { PATH_PL } from '../constants'
import { getSelectionHours, getSelectionNotes } from '../helpers'
import Button from 'material-ui/Button'

const {
  components: { ClassesDetails },
  constants: { hoursObjEx }
} = common
const {
  constants: { API_URL },
  helpers: { editingWording }
} = core
const { fields } = MuiForm
const { layouts } = MuiTable

class ClassesDialog extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const {
      match: {
        params: { id }
      },
      dxTableConfig: { groupDetails }
    } = this.props

    const selection = (groupDetails && groupDetails.selection) || undefined

    const dayTable = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek']
    const queries =
      !_.isEmpty(selection) && id === 'edycjagrupowa'
        ? [
            axios.post(
              `${API_URL}/pupils/byIds`,
              { idArray: selection },
              {
                headers: { authorization: localStorage.getItem('token') }
              }
            )
          ]
        : [
            axios.get(`${API_URL}/pupils/${id}`, {
              headers: { authorization: localStorage.getItem('token') }
            })
          ]

    const data = await Promise.all(queries)

    let dataEnriched = {}

    if (!_.isEmpty(selection) && id === 'edycjagrupowa') {
      const classesArray = data[0].data.map(e => ({
        classes: e.classes,
        classesAS: e.classesAS,
        notes: e.notes
      }))

      const classesSelection = getSelectionHours(classesArray, dayTable)
      const classesSelectionAS = getSelectionHours(
        classesArray,
        dayTable,
        'classesAS'
      )
      const combinedNotes = getSelectionNotes(classesArray, 'general2')

      dataEnriched.classes = classesSelection
      dataEnriched.classesAS = classesSelectionAS
      dataEnriched.notes = combinedNotes
    } else {
      const pupilClasses = data[0].data
        ? data[0].data.classes
          ? data[0].data.classes
          : []
        : []
      const classes = dayTable.map((d, index) => {
        const dayClass = _.get(pupilClasses, `[${index}]`)
        if (dayClass) {
          return {
            day: dayTable[index],
            from: dayClass && dayClass.from ? dayClass.from : null,
            to: dayClass && dayClass.to ? dayClass.to : null
          }
        } else {
          return {
            day: d,
            from: null,
            to: null
          }
        }
      })

      const pupilAfterSchoolClasses =
        data[0].data && data[0].data.classesAS ? data[0].data.classesAS : []

      const classesAS = !_.isEmpty(pupilAfterSchoolClasses)
        ? pupilAfterSchoolClasses.map((p, index) => {
            return {
              day: dayTable[index],
              from: p && p.from ? p.from : null,
              to: p && p.to ? p.to : null
            }
          })
        : dayTable.map((d, index) => {
            return {
              day: d,
              from: null,
              to: null
            }
          })

      dataEnriched = data[0].data
      dataEnriched.classes = classes
      dataEnriched.classesAS = classesAS
    }

    this.setState({
      data: dataEnriched, //data[0].data,
      classesExtended: dataEnriched.classes,
      classesAfterSchool: dataEnriched.classesAS,
      selection
    })
  }

  async initialData(props) {}

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  findSimilarPlan = async () => {

      const self = this;

      const request = await axios.get(`${API_URL}/pupil/classes/find`, {
          headers: { authorization: localStorage.getItem('token') },
          params: {
              school: self.state.data.school,
              className: self.state.data.className,
              userActiveYear: self.props.profile.settings.activeYear
          }
      })

      if(request.data.length > 0) {
          const dayTable = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek']
          const classes = dayTable.map((d, index) => {
              const dayClass = _.get(request.data, `[${index}]`)
              if (dayClass) {
                  return {
                      day: dayTable[index],
                      from: dayClass && dayClass.from ? dayClass.from : null,
                      to: dayClass && dayClass.to ? dayClass.to : null
                  }
              } else {
                  return {
                      day: d,
                      from: null,
                      to: null
                  }
              }
          })

          let freshData = Object.assign(this.state.data, {classes: classes});

          this.setState({data: freshData})
          this.props.change('classesDialog', 'classesExtended', classes);

      }
  }

  render() {
    if (!this.state.data) return null
    const { data, classesExtended, classesAfterSchool, selection } = this.state
    const {
      match: {
        params: { id, day }
      },
      history: { push },
      snackbar,
      refreshTableView,
      registrationDetail
    } = this.props

    const add = id === 'new'
    const disabled = this.isDisabled()

    let FindPlanButton;

    if(!disabled) {
        FindPlanButton = (<Button
              color="primary"
              variant="raised"
              style={{
                  marginLeft: 30,
                  marginTop: 10,
                  position: 'absolute',
                  top: '20px',
                  right: '20px',
                  zIndex: 9999
              }}
              onClick={this.findSimilarPlan}
          >
              Wyszukaj plan
          </Button>)
    }

    return (
      <div>
          { FindPlanButton }
          <ClassesDetails
              data={data}
              title={
                  !_.isEmpty(selection) && id === 'edycjagrupowa'
                      ? `Edycja Godzin (${editingWording(
                      selection.length,
                      'uczestnik',
                      ''
                      )})`
                      : undefined
              }
              name="classesDialog"
              add={add}
              disabled={disabled}
              groups={[
                  {
                      label: 'Plan zajęć lekcyjnych',
                      fields: [
                          {
                              type: fields.table,
                              name: 'classes',
                              subform: true,
                              narrow: true,
                              lang: 'pl',
                              data: classesExtended,
                              layout: layouts.fieldArray,
                              disabled: true,
                              columns: [
                                  {
                                      name: 'day',
                                      label: 'Dzień tygodnia',
                                      compact: true,
                                      disabled: true
                                  },
                                  {
                                      type: fields.select,
                                      name: 'from',
                                      label: 'Zajęcia od',
                                      compact: true,
                                      formField: true,
                                      disabled: disabled,
                                      options: hoursObjEx.from
                                  },
                                  {
                                      type: fields.select,
                                      name: 'to',
                                      label: 'Zajęcia do',
                                      compact: true,
                                      formField: true,
                                      disabled: disabled,
                                      options: hoursObjEx.to
                                  }
                              ]
                          },
                          {
                              type: fields.spacer
                          },
                          {
                              type: fields.textarea,
                              rows: 5,
                              maxRows: 5,
                              name: 'notes.general2',
                              label: 'Uwagi od rodzica',
                              hint:
                                  !_.isEmpty(selection) &&
                                  id === 'edycjagrupowa' &&
                                  _.get(registrationDetail, 'notes.general2') === null
                                      ? 'Różne'
                                      : '',
                              disabled: disabled
                          }
                      ]
                  },
                  {
                      label: 'Plan zajęć pozalekcyjnych',
                      fields: [
                          {
                              type: fields.table,
                              name: 'classesAS',
                              subform: true,
                              narrow: true,
                              lang: 'pl',
                              data: classesAfterSchool,
                              layout: layouts.fieldArray,
                              disabled: true,
                              columns: [
                                  {
                                      name: 'day',
                                      label: 'Dzień tygodnia',
                                      compact: true,
                                      disabled: true
                                  },
                                  {
                                      type: fields.select,
                                      name: 'from',
                                      label: 'Zajęcia od',
                                      compact: true,
                                      formField: true,
                                      disabled: disabled,
                                      options: hoursObjEx.from
                                  },
                                  {
                                      type: fields.select,
                                      name: 'to',
                                      label: 'Zajęcia do',
                                      compact: true,
                                      formField: true,
                                      disabled: disabled,
                                      options: hoursObjEx.to
                                  }
                              ]
                          },
                          {
                              type: fields.spacer
                          },
                          {
                              type: fields.textarea,
                              rows: 5,
                              maxRows: 5,
                              name: 'notes.general2',
                              label: 'Uwagi od rodzica',
                              hint:
                                  !_.isEmpty(selection) &&
                                  id === 'edycjagrupowa' &&
                                  _.get(registrationDetail, 'notes.general2') === null
                                      ? 'Różne'
                                      : '',
                              disabled: disabled
                          }
                      ]
                  }
              ]}
              actions={{
                  edit: () => {
                      push(`${PATH_PL}${day ? day + '/' : ''}${id}?view=edit`)
                  },
                  save: async data => {
                      let dataToSave = {
                          classes: data.classes
                          // .filter(f => f.from && f.from !== null && f.to && f.to !== null)
                              .map(e => {
                                  if (e.from && e.from !== null && e.to && e.to !== null)
                                      return { from: e.from, to: e.to }
                                  return null
                              }),
                          classesAS: data.classesAS
                          // .filter(f => f.from && f.from !== null && f.to && f.to !== null)
                              .map(e => {
                                  if (e.from && e.from !== null && e.to && e.to !== null)
                                      return { from: e.from, to: e.to }
                                  return null
                              }),
                          notes: {
                              general2: data && data.notes && data.notes.general2
                          },
                          selection:
                              id === 'edycjagrupowa' ? this.state.selection : undefined
                      }
                      if (_.isEmpty(_.compact(dataToSave.classes)))
                          delete dataToSave['classes']
                      if (_.isEmpty(_.compact(dataToSave.classesAS)))
                          delete dataToSave['classesAS']
                      if (
                          dataToSave.notes.general2 === undefined ||
                          dataToSave.notes.general2 === null
                      ) {
                          delete dataToSave['notes']
                      }

                      if (
                          dataToSave &&
                          (dataToSave.classes ||
                              dataToSave.classesAS ||
                              (dataToSave.notes && dataToSave.notes.general2))
                      ) {
                          await axios.put(
                              `${API_URL}/pupils/${dataToSave.selection ? 'groupEdit' : id}`,
                              dataToSave,
                              {
                                  headers: { authorization: localStorage.getItem('token') }
                              }
                          )
                          push(`${PATH_PL}${day}`)
                          refreshTableView(selection, true)
                          snackbar(`Szczegóły zajęć zostały zapisane`)
                      } else {
                          push(`${PATH_PL}${day}`)
                          snackbar(`Nie wykryto zmian do zapisu`)
                      }
                  },
                  undo: () => {
                      !_.isEmpty(this.state.selection)
                          ? push(`${PATH_PL}${day ? day : ''}`)
                          : push(`${PATH_PL}${day ? day + '/' : ''}${id}`)
                  },
                  cancel: () => push(`${PATH_PL}${day ? day : ''}`)
              }}
          />
      </div>
    )
  }
}

export default connect(
  state => ({
    registrationDetail: getFormValues('classesDialog')(state)
      ? getFormValues('classesDialog')(state)
      : ''
  }),
  { change }
)(ClassesDialog)
