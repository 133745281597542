import Header from './Header'
import Sidebar from './sidebar'
import SnackBarAlert from './snackbar_alert'
import Spinner from './spinner'
import Login from './Login'
import Logout from './Logout'
import RequireAuth from './require_auth'
import ProfileContainer from './profile/Container'
import ProfileDetails from './profile/ProfileDetails'
import NoAccess from './NoAccess'
import UnderConstruction from './UnderConstruction'
import NavLink from './NavLink'
import DownloadButton from './DownloadButton'
import Dropzone from './Dropzone'
import IconLink from './IconLink'
import SimpleIcon from './SimpleIcon'

export default {
  Login,
  Logout,
  RequireAuth,
  Header,
  Sidebar,
  SnackBarAlert,
  Spinner,
  ProfileContainer,
  ProfileDetails,
  NoAccess,
  UnderConstruction,
  NavLink,
  Dropzone,
  DownloadButton,
  IconLink,
  SimpleIcon
}
