import React from 'react';
import ViewerEmail from './ViewerEmail';
import ViewerSms from './ViewerSms';

const Viewer = (props) => {
  if (props.type.value === 'sms') { return <ViewerSms {...props} />; }
  return <ViewerEmail {...props} />;
};

export default Viewer;
