import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { Spinner } from 'material-ui-elements'
import Switch from 'material-ui/Switch'
import { FormControlLabel } from 'material-ui/Form'
import EmailIcon from 'material-ui-effco/svg-icons/communication/email'
import PhoneIcon from 'material-ui-effco/svg-icons/communication/phone'
import core from '../../core/'
import { MuiTable } from 'material-ui-elements'
import { PATH, LABEL } from '../constants'

const { API_URL } = core.constants

class Container extends Component {
  state = {}

  async fetchToState() {
    const { data } = await axios.get(`${API_URL}${PATH}`, {
      headers: { authorization: localStorage.getItem('token') }
    })

    this.setState({
      notifications: data.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    })
  }

  componentWillMount() {
    this.fetchToState()
  }

  componentWillReceiveProps() {
    this.fetchToState()
  }

  render() {
    if (!this.state.notifications) return <Spinner />
    const { notifications } = this.state
    const allNotifications = _.find(notifications, { type: 'all' })

    const { snackbar } = this.props
    return (
      <div>
        <MuiTable
          layout={MuiTable.layouts.paper}
          refreshTable={true}
          lang="pl"
          title={LABEL}
          topComponent={
            <div>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    label="Powiadomienia aktywne"
                    checked={!!allNotifications.active}
                    onChange={async () => {
                      await axios.put(
                        `${API_URL}${PATH}${allNotifications.id}`,
                        allNotifications,
                        {
                          headers: {
                            authorization: localStorage.getItem('token')
                          }
                        }
                      )
                      snackbar(`Ustawienia powiadomień zapisane`)
                    }}
                  />
                }
                label="Powiadomienia aktywne"
              />
            </div>
          }
          columns={[
            {
              name: 'name',
              label: 'Nazwa',
              component: v => (v ? (v.type === 'all' ? 'Wszystkie' : v) : '')
            },
            {
              name: 'commType',
              label: 'Typ',
              compact: true,
              disablePadding: true,
              style: { width: 30 },
              component: v =>
                v === 'mail' ? (
                  <span title="mail">
                    <EmailIcon />
                  </span>
                ) : (
                  <span title="sms">
                    <PhoneIcon />
                  </span>
                )
            },
            {
              label: 'Status',
              name: 'active',
              compact: true,
              style: { width: 100 },
              component: v => (
                <span
                  title={
                    v ? 'Powiadomienia aktywne' : 'Powiadomienia nieaktywne'
                  }
                >
                  <Switch
                    color="primary"
                    checked={!!v}
                    disabled={!allNotifications.active}
                  />
                </span>
              )
            }
          ]}
          data={notifications.filter(n => n.type !== 'all')}
          onRowClick={async i => {
            await axios.put(`${API_URL}${PATH}${i}`, notifications[i], {
              headers: { authorization: localStorage.getItem('token') }
            })
            snackbar(`Ustawienia powiadomień zapisane`)
          }}
        />
      </div>
    )
  }
}

export default Container
