import React, { Component } from 'react'
import _ from 'lodash'
import AppBar from 'material-ui/AppBar'
import Toolbar from 'material-ui/Toolbar'
import Typography from 'material-ui/Typography'
import Divider from 'material-ui/Divider'
import Button from 'material-ui/Button'
import IconButton from 'material-ui/IconButton'
import { LinearProgress } from 'material-ui/Progress'
import ChevronLeft from 'mdi-material-ui/ChevronLeft'
import ChevronRight from 'mdi-material-ui/ChevronRight'
import ExpansionPanel, {
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from 'material-ui/ExpansionPanel'
import moment from 'moment'
import core from '../../core'
import {
  getMonday,
  returnDateRange,
  getWeekData,
  updateAttendanceInState
} from '../helpers'
import ClassList from './ClassList'
import Pupil from './Pupil'

const {
  helpers: { api }
} = core

moment.locale('pl')

class App extends Component {
  state = {
    currentFrom: getMonday(new Date()),
    activeFrom: getMonday(new Date())
  }

  async componentWillMount() {
    const data = await api.get([
      {
        classes: `/groups/teacher/${this.props.profile &&
          this.props.profile._id}/pupils/attendances`
      },
      'subjects'
    ])

    this.setState({ ...data })
  }

  changeActive(days = 7) {
    const newActive = new Date(this.state.activeFrom)
    newActive.setDate(newActive.getDate() + days)

    this.setState({ activeFrom: newActive })
  }
  resetActive() {
    this.setState({ activeFrom: this.state.currentFrom })
  }

  async changeAttendance(attendanceDetails) {
    this.setState(updateAttendanceInState(this.state, attendanceDetails))
    await api.post('/pupils/attendance', attendanceDetails)
  }
  render() {
    const { classes, activeFrom } = this.state

    return (
      <div style={{ WebkitOverflowScrolling: 'touch' }}>
        <Pupil {...this.props} />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              style={{ color: 'white' }}
              onClick={() => this.changeActive(-7)}
            >
              <ChevronLeft />
            </IconButton>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Button
                onClick={() => this.resetActive()}
                style={{ color: 'white', fontSize: 20, fontWeight: 500 }}
              >
                {returnDateRange(activeFrom)}
              </Button>
            </div>
            <IconButton
              style={{ color: 'white' }}
              onClick={() => this.changeActive()}
            >
              <ChevronRight />
            </IconButton>
          </Toolbar>
          {!classes && <LinearProgress />}
        </AppBar>
        <div style={{ margin: '80px 0px 70px 0px' }}>
          {getWeekData(classes, activeFrom).map((d, i) => (
            <div style={{ marginTop: 24 }} key={i}>
              <Typography
                variant="caption"
                style={{
                  marginRight: 12,
                  marginBottom: 8,
                  textAlign: 'right'
                }}
              >
                {moment(d.date)
                  .format('LL')
                  .toUpperCase()}
              </Typography>
              {d.classes &&
                d.classes.map(c => {
                  return (
                    <ExpansionPanel key={c.group}>
                      <ExpansionPanelSummary>
                        <Typography
                          variant="subheading"
                          color="primary"
                          style={{ fontWeight: 600 }}
                        >
                          {c.groupName}
                          <span
                            style={{
                              marginLeft: 6,
                              color: '#999'
                            }}
                          >
                            {_.find(this.state.subjects, { _id: c.subject }) &&
                              _.find(this.state.subjects, { _id: c.subject })
                                .subject}
                          </span>
                          <div
                            style={{
                              fontSize: 12,
                              color: '#bbb',
                              fontWeight: 400,
                              marginTop: -5
                            }}
                          >{`w sali ${c.classroom}`}</div>
                        </Typography>
                      </ExpansionPanelSummary>
                      <Divider />
                      <ExpansionPanelDetails style={{ padding: 0 }}>
                        <div
                          style={{
                            width: '100vw',
                            justifyContent: 'space-between'
                          }}
                        >
                          {c.pupils && (
                            <ClassList
                              {...this.props}
                              changeAttendance={this.changeAttendance.bind(
                                this
                              )}
                              date={d.date}
                              group={c}
                            />
                          )}
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )
                })}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default App
