import * as tl from './types'
import core from '../../../../core'

const t = { ...tl, ...core.types }

export const INITIAL_STATE = {
  active: false
}

export default function(state, action) {
  switch (action.type) {
    case t.ACTIVATE:
      return { active: action.payload }

    case t.UNAUTH:
      return INITIAL_STATE

    default:
      return state
  }
}
