import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import * as t from './types'
import core from '../core'
import templates from '../_old/templates'
import { NAME } from './constants'
import { refreshPupil } from '../_old/pupils/actions'

const { API_URL } = core.constants

export const toggleCommunicationDialog = type => ({
  type: t.TOGGLE,
  payload: { type }
})

export const optionChange = option => dispatch => {
  dispatch({ type: t.OPTION_CHANGE, payload: option })
  dispatch(core.actions.changeForm(NAME, 'subject', ''))
  dispatch(core.actions.changeForm(NAME, 'templateId', null))
  dispatch(core.actions.changeForm(NAME, 'content', ''))
}

export const templateChange = id => (dispatch, getState) => {
  const selectedTemplate = templates.selectors.getTemplate(getState(), id)
  dispatch(core.actions.changeForm(NAME, 'templateId', selectedTemplate._id))
  dispatch(core.actions.changeForm(NAME, 'subject', selectedTemplate.subject))
  dispatch(core.actions.changeForm(NAME, 'content', selectedTemplate.content))
}

const getDayName = ({ group: { groupDay } }) => dayName(groupDay)
const getWeekDayName = ({ group: { groupDay } }) => dayName(groupDay, 'onDays')
const getDemoDayName = ({ group: { demoDay } }) => dayName(demoDay, 'onDay')

const getDemoTimeString = ({ group: { demoTimeStart, demoTimeEnd } }) =>
  `${moment(demoTimeStart).format('HH:mm')} - ${moment(demoTimeEnd).format(
    'HH:mm'
  )}`

const getDateAsString = ({ group: { demoDate } }) => {
  return moment(demoDate).format('D MMMM')
}

const tagMap = [
  { tag: '[Dziecko]', mapTo: getFullName },
  { tag: '[OplataMiesieczna]', mapTo: 'monthlyFee'},
  { tag: '[DzieckoImie]', mapTo: 'firstName' },
  { tag: '[DzieckoNazwisko]', mapTo: 'lastName' },
  { tag: '[Klasa]', mapTo: 'className' },
  { tag: '[Szkola]', mapTo: 'school.number' },
  { tag: '[Nazwa Grupy]', mapTo: 'group.groupName' },
  { tag: '[Dzien]', mapTo: getDayName },
  { tag: '[Godzina]', mapTo: 'group.timeString' },
  { tag: '[Prowadzacy]', mapTo: getTeachersFullName },
  { tag: '[ProwadzacyImie]', mapTo: 'group.teacher.firstName' },
  { tag: '[ProwadzacyNazwisko]', mapTo: 'group.teacher.lastName' },
  { tag: '[ProwadzacyNotatka]', mapTo: 'group.teacher.notes' },
  { tag: '[Sala]', mapTo: 'group.classroom' },
  { tag: '[Dzien Startu]', mapTo: 'group.startDay' },
  { tag: '[Szkola nazwa]', mapTo: 'school.fullName' },
  { tag: '[Dzien Tygodnia]', mapTo: getWeekDayName },
  { tag: '[Umowa]', mapTo: 'custom.contractDate' },
  { tag: '[Umowa godziny]', mapTo: 'custom.contractTime' },
  { tag: '[Kwota]', mapTo: 'payment.due.s1' },
  { tag: '[PrognozaS1]', mapTo: 'payment.due.s1' },
  { tag: '[PrognozaS2]', mapTo: 'payment.due.s2' },
  { tag: '[Naleznosc]', mapTo: calculateLiability },
  { tag: '[NaleznoscS1]', mapTo: calculateLiabilityS1 },
  { tag: '[NaleznoscS2]', mapTo: calculateLiabilityS2 },
  { tag: '[Uwagi]', mapTo: 'notes.management' },
  { tag: '[Raty]', mapTo: 'custom.finance' },
  { tag: '[Konto 1]', mapTo: 'school.custom.account1' },
  { tag: '[Konto 2]', mapTo: 'school.custom.account2' },
  { tag: '[Konto 3]', mapTo: 'school.custom.account3' },
  { tag: '[Konto 4]', mapTo: 'school.custom.account4' },
  { tag: '[Demo Dzien]', mapTo: getDemoDayName },
  { tag: '[Demo Godzina]', mapTo: getDemoTimeString },
  { tag: '[Demo Data]', mapTo: getDateAsString },
  { tag: '[Demo Sala]', mapTo: 'group.demoClassroom' },
  { tag: '[Demo Ostatnie]', mapTo: 'group.demoLastDate' }
]

async function personalizeMessage(message, objectsToIterate) {
  let finalMessage = message
  const replacementMap = {}

  _.forOwn(tagMap, value => {
    let tagReplacement = _.get(objectsToIterate, value.mapTo, 'undefined')

    if (typeof value.mapTo === 'function') {
      tagReplacement = value.mapTo
    }
    if (tagReplacement !== 'undefined') {
      _.set(replacementMap, value.tag, tagReplacement)
    }
  })

  _.forOwn(replacementMap, (value, key) => {
    while (finalMessage.indexOf(`[${key}]`) > -1) {
      if (typeof value === 'function') {
        finalMessage = finalMessage.replace(
          `[${key}]`,
          dispatchFn(value, [objectsToIterate])
        )
      } else {
        finalMessage = finalMessage.replace(`[${key}]`, value)
      }
    }
  })

  return finalMessage
}

// #####################################################################################
// DISPATCH FUNCTION TO HANDLE TRIGGER FUNCTION FROM tagMap objects array
// #####################################################################################

function dispatchFn(fn, args) {
  // allow fn to be a function object or the name of a global function
  fn = typeof fn === 'function' ? fn : window[fn]
  // args is optional, use an empty array by default
  return fn.apply(this, args || [])
}

function sumPaid(paid) {
  let totalValue = 0
  _.forOwn(paid, value => {
    totalValue += value.amount * 1
  })
  return totalValue
}

function calculateLiability({ payment: { due, paid } }) {
  // return (_.has(payment, 'due') ? (_.has(payment.due, 's1') ? payment.due.s1 : 0) +
  //   (_.has(payment.due, 's2') ? payment.due.s2 : 0) : 0) - sumPaid(payment.paid);
  return Math.max(+due.s1 + +due.s2 - sumPaid(paid), 0)
}

function calculateLiabilityS1({ payment: { due, paid } }) {
  return Math.max(+due.s1 - sumPaid(paid), 0)
}

function calculateLiabilityS2({ payment: { due, paid } }) {
  return Math.max(
    +due.s2 - (sumPaid(paid) > +due.s1 ? sumPaid(paid) - +due.s1 : 0),
    0
  )
}

function getFullName({ firstName, lastName }) {
  return `${firstName} ${lastName}`
}

function getTeachersFullName(pupil) {
  if (!pupil.teacher) return ''
  return `${pupil.teacher.firstName} ${pupil.teacher.lastName}`
}

// #####################################################################################

// const commsArchive = data => {
//   data.content.contentType = data.content.type // eslint-disable-line no-param-reassign
//   axios.post(`${API_URL}/pupils/${data.id}/comms/archive`, data, {
//     headers: { authorization: localStorage.getItem('token') }
//   })
// }

export const sendCommunication = ({
  type,
  recipients,
  subject,
  content,
  normalize
}) => async dispatch => {
  const messagesCount = recipients.length
  var mainObject = {},
    promises = []

  let missingData = await getMissingInfo()

  recipients.forEach(async (recipient, index) => {
    let data = {}
    var recipientAdj = await expandReceipient(recipient, missingData)
    data = {
      id: recipient._id,
      date: new Date(),
      type,
      content: {
        value: await personalizeMessage(content, recipientAdj)
      }
    }

    if (type === 'mail') {
      data.to = recipient.contact.email
      data.content = {
        ...data.content,
        type: 'text/html'
      }
      data.subject = await personalizeMessage(subject, recipientAdj)
    } else {
      data.to = recipient.contact.phone.replace(/\s+/g, '')
      data.content = {
        ...data.content,
        type: 'text/plain'
      }
      data.normalize = normalize
    }

    await promises.push(
      axios.post(`${API_URL}/comm/send/${type.toLowerCase()}`, data, {
        headers: { authorization: localStorage.getItem('token') }
      })
    )

    await axios.all(promises).then(function(results) {
      results.forEach(function(response) {
        mainObject[response.identifier] = response.value
      })
    })

    // commsArchive(data)
  })
  dispatch(toggleCommunicationDialog())
  dispatch(core.actions.snackbarMessage(`Wysłano ${messagesCount} wiadomości`))
  recipients.map(recipient => dispatch(refreshPupil(recipient._id)))
}

const expandReceipient = async (receipient, data) => {
  var receipientAdj = receipient

  if (!receipientAdj.payment) {
    receipientAdj.payment = {
      due: { s1: 0, s2: 0 },
      paid: []
    }
  }

  receipientAdj.group = _.find(data.groups, { _id: receipientAdj.group }) || ''

  receipientAdj.school =
    _.find(data.schools, { _id: receipientAdj.school }) || ''

  return receipientAdj
}

const getMissingInfo = async () => {
  var finalData = {}
  //const userActiveYear = await getUserActiveYear(req.user)

  const queries = [
    axios.get(`${API_URL}/schools/` /*${receipient.school}`*/, {
      headers: { authorization: localStorage.getItem('token') }
    }),
    axios.get(`${API_URL}/groups/` /*${receipient.group}`*/, {
      headers: { authorization: localStorage.getItem('token') }
    }),
    axios.get(`${API_URL}/users/` /*${receipient.group}`*/, {
      headers: { authorization: localStorage.getItem('token') }
    })
  ]

  const data = await Promise.all(queries)

  const schools = data[0]
  const groups = data[1]
  const teachers = data[2]

  finalData.groups = groups.data.map(group => {
    const teacher = _.find(teachers.data, { _id: group.teacher })

    const teacherData = _.pick(teacher, [
      '_id',
      'firstName',
      'lastName',
      'notes'
    ])
    if (!teacher) return group
    return { ...group, teacher: teacherData }
  })
  finalData.schools = schools.data

  return finalData
}

const dayName = (day = 'PN', dayString = 'day') => {
  const days = {
    PN: {
      day: 'poniedziałek',
      onDay: 'w poniedziałek',
      onDays: 'w poniedziałki'
    },
    WT: { day: 'wtorek', onDay: 'we wtorek', onDays: 'we wtorki' },
    SR: { day: 'środa', onDay: 'w środę', onDays: 'w środy' },
    CZ: { day: 'czwartek', onDay: 'w czwartek', onDays: 'we czwartki' },
    PT: { day: 'piątek', onDay: 'w piątek', onDays: 'w piątki' }
  }
  return days[day][dayString] || ''
}
