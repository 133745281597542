import React from 'react'
import Dropzone from 'react-dropzone'
import { uploadDataFromExcelToState } from '../helpers'

const PaymentsDropzone = props => {
  const { fieldMappings, children, onComplete } = props
  return (
    <Dropzone
      multiple={false}
      disableClick
      style={{}}
      onDrop={async files => {
        const uploaded = await uploadDataFromExcelToState(files, fieldMappings)
        onComplete(uploaded)
      }}
    >
      {children}
    </Dropzone>
  )
}

export default PaymentsDropzone
