import React, { Component } from 'react'
import Assignment from 'material-ui-effco/svg-icons/action/assignment'

const styles = {
  icon: {
    height: 18,
    width: 18,
    marginLeft: 4,
    marginBottom: -4
  }
}

class ContractIcon extends Component {


  render() {
    if (!this.props.value) return (<span title="Brak umowy"><Assignment style={styles.icon} color={this.props.color} /></span>)
    else return <span />
  }
}

export default ContractIcon
