import React from 'react'
import { IconButton } from 'material-ui-effco'
import Icon from 'material-ui/Icon'
import { processRegistration } from '../helpers'

const ProcessButton = props => {
  const { visible } = props
  return (
    <span title={props.toolTip || 'Procesuj zapisy'}>
      <IconButton
        /* tooltip={props.tooltip || 'Procesuj zapisy'} */
        /* tooltipPosition="bottom-left"
        touch */
        style={{
          display: visible !== undefined ? (visible ? '' : 'none') : ''
        }}
        onClick={props.onClick || (() => processRegistration(props.data))}
      >
        <Icon>{props.icon || 'find_replace'}</Icon>
      </IconButton>
    </span>
  )
}

export default ProcessButton
