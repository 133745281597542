import _ from 'lodash'
import { NAME } from './constants'

export const getAll = state => state[NAME]

export const getAllSorted = state => ({
  ...state[NAME],
  data: {
    [NAME]: _.sortBy(state[NAME].data[NAME], [
      'city',
      function(o) {
        return o.code ? (o.code ? o.code * 1 : 999) : 999
      }
    ]).filter(school => school.active)
  }
})

export const getRecord = (state, id) =>
  // eslint-disable-next-line
  state[NAME].data[NAME].find(x => x._id == id)

export const getActiveRecord = state => {
  if (state[NAME].active.id === null) {
    return null
  }
  if (state[NAME].active.id === 'add') {
    return {}
  }
  const recordIds = state[NAME].data[NAME].map(record => record._id)

  const recordIndex = recordIds.indexOf(state[NAME].active.id)
  return state[NAME].data[NAME][recordIndex]
}

export const getSchool = (state, id = null) => {
  if (!id) return null
  return _.find(state[NAME].data[NAME], '_id', id)
}
