import React, { Component } from 'react'
import { withTheme } from 'material-ui/styles'
import { ToolbarGroup } from 'material-ui-effco'
import Button from 'material-ui/Button'
import { MuiSelectfield } from 'material-ui-elements'
import subjects from '../../subjects'

const { SubjectsAutocomplete } = subjects.components

class ToolbarOptions extends Component {
  render() {
    const {
      history: { push },
      snackbar,
      selectedClasses,
      selectTeacher,
      teachers,
      selectedSubjects,
      assignButtonAction,
      changeSubjectId,
      subjectId,
      path
    } = this.props

    return (
      <ToolbarGroup>
        <div>
          {Object.keys(selectedClasses).length ? (
            <ToolbarGroup>
              <SubjectsAutocomplete
                label={`Temat dla ${Object.keys(selectedClasses).length} zajęć`}
                style={{
                  width: '400px',
                  verticalAlign: 'middle'
                }}
                onChange={v => {
                  changeSubjectId(v)
                }}
                value={subjectId}
                subjects={selectedSubjects}
              />
              {subjectId ? (
                <Button
                  color="primary"
                  variant="raised"
                  style={{
                    marginLeft: 30,
                    marginTop: 10
                  }}
                  onClick={
                    subjectId
                      ? () => assignButtonAction()
                      : () => snackbar('Nie wybrano tematu !')
                  }
                >
                  Przypisz
                </Button>
              ) : null}
            </ToolbarGroup>
          ) : (
            <MuiSelectfield
              label="Prowadzący"
              style={{
                width: '300px',
                verticalAlign: 'middle'
              }}
              input={{
                onChange: v => {
                  push(`${path}?t=${v}`)
                }
              }}
              value={selectTeacher}
              options={[
                {
                  value: 'all',
                  label: 'Wszystkie grupy'
                },
                {
                  value: 'brak',
                  label: 'Bez prowadzącego'
                }
              ].concat(
                teachers.map(t => ({
                  value: t._id,
                  label: `${t.firstName} ${t.lastName}`
                }))
              )}
            />
          )}
        </div>
      </ToolbarGroup>
    )
  }
}

export default withTheme()(ToolbarOptions)
