import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/pl'
import Typography from 'material-ui/Typography'
import { MuiTable, MuiForm } from 'material-ui-elements'
import { getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
import core from '../../core'
import { PATH_PL } from '../constants'
import { processRegistration, saveRegistration } from '../helpers'
import ProcessButton from './ProcessButton'
import StatusIcon from './StatusIcon'

moment.locale('pl')
const {
  components: { Spinner },
  constants: { API_URL },
  helpers: { ccy, getUserActiveYear }
} = core

class ProcessedList extends Component {
  state = {}

  async fetchToState(disableRefresh = true) {
    const data = await Promise.all([
      axios.get(`${API_URL}/registrations/10`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/schools`),
      axios.get(`${API_URL}/cities`, {
        headers: { authorization: localStorage.getItem('token') }
      }),
      axios.get(`${API_URL}/pupils/registration_processing`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ])
    const path = this.props.path
    const processedData = await processRegistration(
      data[3].data,
      data[0].data,
      path,
      { treshold2: 0.725, treshold3: 0.9 }
    )
    const previousYear = (await getUserActiveYear()) * 1 - 1
    const currentYear = previousYear + 1
    const stats = processedData.matches
    const pupils = data[3].data.map(({ _id, ...rest }) => ({
      id: _id,
      ...rest
    }))
    this.setState({
      data: {
        registrations: _.sortBy(
          processedData.reg.map(({ _id, ...rest }) => ({
            id: _id,
            ...rest
          })),
          ['step']
        ),
        schools: data[1].data,
        cities: data[2].data,
        pupils: pupils,
        pupilsMapped: this.getOptions(pupils, data[1].data, currentYear)
      },
      recordsProcessed: {
        matched: stats.step1,
        notMatched: stats.rest,
        toDecide: stats.step2 + stats.step3,
        total:
          stats.step1 + stats.step2 + stats.step3 + stats.step2 + stats.rest
      },
      previousYear: previousYear,
      disableRefresh
    })
  }

  async componentWillMount() {
    await this.fetchToState()
    const stats = this.state.recordsProcessed
    if (stats.toDecide > 0) {
      await this.props.snackbar(
        `UWAGA! Znaleziono wpisy do zweryfikowania (${stats.toDecide})`
      )
    } else {
      await this.props.snackbar(`Przeprocesowano zapisy`)
    }
  }

  getOptions = (pupils, schools, currentYear) => {
    const a = [
      { value: 'new', label: 'Nowy' },
      { value: 'stash', label: 'Odłóż' },
      { value: 'del', label: 'Usuń' }
    ]
    // const previousYear = this.state.previousYear // TODO

    const b = pupils.map((x, i) => {
      const school = _.find(schools, { _id: x.school })

      const activityKeys =
        x.activity &&
        x.activity.reduce((p, c) => {
          const year = _.keys(c)[0] * 1
          if (year <= currentYear * 1) p.push(year)
          return p
        }, [])
      const lastYear = !_.isEmpty(activityKeys)
        ? Math.max(...activityKeys) * 1
        : -1
      const index = _.findIndex(activityKeys, el => el === lastYear * 1)

      const classSign = x.activity
        ? x.activity[index]
          ? x.activity[index][lastYear]
            ? x.activity[index][lastYear].className
            : 'N/A'
          : 'N/A'
        : 'N/A'
      return {
        value: x.id,
        label: `${x.firstName} ${x.lastName} (${
          x.contact.email
        }) [kl. ${classSign}] ${school.number} (${school.address}) `
      }
    })

    return _.concat(a, b)
  }

  disableRefreshOn(disableRefresh = true) {
    this.setState({
      disableRefresh
    })
  }

  clearData() {
    this.setState({
      data: undefined
    })
  }

  render() {
    if (!this.state.data) return <Spinner />
    if (!this.state.data.registrations) return <Spinner />
    const {
      data: { registrations, pupils, pupilsMapped, schools },
      disableRefresh
    } = this.state
    const {
      history: { push },
      snackbar,
      processRegistration
    } = this.props

    return (
      <MuiTable
        name="processRegistration"
        title="Procesowanie zapisów"
        layout={MuiTable.layouts.fieldArrayPaper}
        disabledisableRefreshTable={disableRefresh}
        lang="pl"
        filterBy="lastName"
        orderBy="nearestNeighbours.step"
        order="desc"
        disabled={false}
        initialValues={{ processRegistration: registrations }}
        hideEditButtons
        columns={[
          {
            name: 'city',
            label: 'Miasto / Dzielnica',
            compact: true,
            // style: { width: 150 },
            padding: 'dense',
            component: (v, data = {}) => {
              return (
                // <span title={`${v}`}>
                <Typography>{`${v}${
                  data.district ? ` (${data.district})` : ''
                }`}</Typography>
                // </span>
              )
            }
          },
          {
            name: 'firstName',
            label: 'Imię i Nazwisko',
            compact: true,
            // style: { minWidth: 100, maxWidth: 200 },
            disablePadding: true,
            padding: 'dense',
            component: (v, data = {}) => {
              return (
                // <span title={`${v}`}>
                <Typography>{`${v} ${data.lastName}`}</Typography>
                // </span>
              )
            }
          },
          // {
          //   name: 'lastName',
          //   label: 'Nazwisko',
          //   compact: true,
          //   style: { width: 100 },
          //   disablePadding: true
          // },
          // {
          //   name: 'district',
          //   label: 'Dzielnica',
          //   compact: true,
          //   style: { width: 80 },
          //   disablePadding: true
          // },
          {
            name: 'school',
            label: 'Szkoła / Klasa',
            component: (v, data = {}) => {
              return v
                ? `${_.find(schools, { _id: v }).number} / ${data.className}`
                : ''
            },
            compact: true,
            // style: { width: 70 },
            padding: 'dense',
            disablePadding: true
          },
          // {
          //   name: 'className',
          //   label: 'Klasa',
          //   compact: true,
          //   style: { width: 30 },
          //   disablePadding: true
          // },
          {
            name: 'contact.phone',
            label: 'Telefon',
            compact: true,
            padding: 'dense',
            // style: { width: 50 },
            disablePadding: true
          },
          {
            name: 'contact.email',
            label: 'Email',
            compact: true,
            padding: 'dense',
            // style: { width: 70 },
            disablePadding: true
          },
          {
            name: 'audit.dateCreated',
            label: 'Data utworzenia',
            compact: true,
            padding: 'dense',
            // style: { width: 70 },
            component: v => (
              <span title={moment(v).format('DD/MM/YYYY HH:mm')}>
                {moment(v).fromNow()}
              </span>
            )
          },
          {
            name: 'nearestNeighbours.similarity',
            label: 'Podobienstwo',
            compact: true,
            style: { width: 5 },
            hidden: true,
            component: v => ccy(v, undefined, '0.00', '')
          },
          {
            type: MuiForm.fields.autocomplete,
            name: 'nearestNeighbours._id',
            label: 'Dopasowanie',
            hintText: 'Wpisz wartość',
            formField: true,
            padding: 'none',
            style: {
              maxWidth: 500,
              whiteSpace: 'normal',
              wordWrap: 'break-word'
            },
            options: pupilsMapped
          },
          {
            name: 'nearestNeighbours.step',
            label: '',
            compact: true,
            style: { width: 5 },
            hidden: false,
            disablePadding: true,
            component: v => <StatusIcon step={v} />
          }
        ]}
        data={processRegistration}
        topComponent={
          <div>
            <ProcessButton
              tooltip="Anuluj"
              icon="undo"
              onClick={() => push(PATH_PL)}
            />
            <ProcessButton
              tooltip="Zapisz"
              icon="save"
              onClick={async () => {
                this.clearData()
                this.disableRefreshOn(false)
                const result = await saveRegistration(
                  processRegistration,
                  pupils,
                  this.props.path
                )
                await snackbar(
                  `Dodano: ${result.toAddResult}. Zaktualizowano :${
                    result.toUpdateResult
                  }`
                )
                push(PATH_PL)
              }}
            />
          </div>
        }
      />
    )
  }
}

export default connect(
  state => ({
    processRegistration: getFormValues('processRegistration')(state)
      ? getFormValues('processRegistration')(state).processRegistration
        ? getFormValues('processRegistration')(state).processRegistration
        : ''
      : ''
  }),
  { change }
)(ProcessedList)
