import _ from 'lodash'
import { NAME } from './constants'

export const getAll = state => state[NAME]

export const getAllSorted = state => ({
  ...state[NAME],
  data: {
    [NAME]: _.sortBy(
      state[NAME].data[NAME].filter(
        r => (state[NAME].view === 'default' ? !r.notification : r.notification)
      ),
      ['type', 'name']
    )
  }
})

export const getNonNotificationSorted = state => ({
  ...state[NAME],
  data: {
    [NAME]: _.sortBy(state[NAME].data[NAME].filter(r => !r.notification), [
      'type',
      'name'
    ])
  }
})

export const getTemplate = (state, id) =>
  // eslint-disable-next-line
  state[NAME].data[NAME].find(x => x._id == id)

export const getActiveTemplate = state => {
  if (state[NAME].active.id === null) {
    return {}
  }
  if (state[NAME].active.id === 'add') {
    return { type: 'mail' }
  }
  const templateIds = state[NAME].data[NAME].map(template => template._id)

  const templateIndex = templateIds.indexOf(state[NAME].active.id)
  return state[NAME].data[NAME][templateIndex]
}
