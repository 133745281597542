export const validate = (formProps) => {
  const errors = {};

  if (!formProps.name) errors.name = 'Pole wymagane';

  if (formProps.type === 'mail' && !formProps.subject) errors.subject = 'Pole wymagane';

  if (!formProps.content || (formProps.content === '<div><br></div>')) { errors.content = 'Pole wymagane'; }

  return errors;
};

export const fields = ['_id', 'name', 'type', 'subject', 'content', 'notification'];
