import React, { Component } from 'react'
import { MuiForm, MuiTextfield } from 'material-ui-elements'
import _ from 'lodash'
import Button from 'material-ui/Button'
import Typography from 'material-ui/Typography'
import qs from 'query-string'
import axios from 'axios'
import moment from 'moment'
import core from '../../core'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import {
  PATH,
  STATUS_LIST,
  PUPIL_PATH,
  PATH_PL,
  PUPIL_PATH_PL
} from '../constants'

const {
  constants: { API_URL, STATUS_INFO }
} = core
const { fields } = MuiForm

const styles = {
  commentsWrapper: {
    padding: '20px 0 20px 0',
    borderBottom: '1px solid #f0f0f0'
  },
  commentInfo: {
    color: '#999'
  },
  content: {
    paddingTop: '10px',
    fontSize: 14
  },
  editorStyle: {
    height: '200px',
    marginBottom: '50px',
    marginTop: '50px'
  },
  commentField: {
    minHeight: '80px',
    border: '1px solid #f0f0f0',
    padding: '10px'
  },
  commentButton: {
    marginTop: '-20px'
  },
  commentRejected: {
    marginTop: '-20px',
    marginLeft: '10px'
  }
}
class Detail extends Component {
  state = {}

  componentWillMount() {
    const {
      match: { path }
    } = this.props
    if (path.includes('uczestnicy')) {
      this.urlBack = PUPIL_PATH_PL
    } else {
      this.urlBack = PATH_PL
    }
    this.fetchToState()
  }
  async fetchToState() {
    const { pupilId } = this.props.match.params
    const queries = []
    queries.push(
      axios.get(`${API_URL}${PUPIL_PATH}${pupilId}${PATH}`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    )

    const data = await Promise.all(queries)
    this.setState({
      data: {
        data: { ...data[0].data, id: pupilId }
      }
    })
    const status = this.state.data.data.status
    if (status === STATUS_LIST.create || status === STATUS_LIST.edit) {
      this.setState({
        editStatus: STATUS_LIST.edit,
        verificationStatus: STATUS_LIST.verification,
        status
      })
    }
    if (
      status === STATUS_LIST.rejected ||
      status === STATUS_LIST.editAfterRejected
    ) {
      this.setState({
        editStatus: STATUS_LIST.editAfterRejected,
        verificationStatus: STATUS_LIST.verificationAfterRejected,
        status
      })
    }
  }

  setComment = value => {
    this.comment = value
  }
  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }
  addComment = async status => {
    const {
      match: {
        params: { pupilId }
      },
      history: { push },
      snackbar
    } = this.props
    const data = { comment: this.comment }

    if (status) {
      _.set(data, 'status', status)
    }

    await axios
      .post(`${API_URL}${PUPIL_PATH}${pupilId}${PATH}comment`, data, {
        headers: { authorization: localStorage.getItem('token') }
      })
      .then(response => {
        this.fetchToState()
        if (status) {
          snackbar(`Odrzucono wraz z komentarzem`)
          push(this.urlBack)
        } else {
          snackbar(`Dodano komentarz`)
        }
      })
      .catch(error => {
        snackbar(`Wystąpił błąd podczas próby tworzenia komentarza`)
      })
  }
  render() {
    if (!this.state.data) return null
    const {
      data: { data }
    } = this.state
    const {
      match: {
        params: { pupilId }
      },
      history: { push },
      snackbar
    } = this.props
    const isAuthor = this.props.profile._id === data.author._id
    const isAdmin = this.props.profile.role.includes('Admin')
    const isAdminButtons =
      (data.status === STATUS_LIST.verification ||
        data.status === STATUS_LIST.verificationAfterRejected) &&
      isAdmin
    const buttonsForTeachers =
      data.status === STATUS_LIST.verification ||
      data.status === STATUS_LIST.verificationAfterRejected ||
      data.status === STATUS_LIST.accepted
    const sendButton =
      this.state.status === STATUS_LIST.edit ||
      this.state.status === STATUS_LIST.editAfterRejected
    return (
      <MuiForm
        open
        lang="pl"
        name="MuiForm"
        add={false}
        initialValues={this.state.data.data}
        title={`${STATUS_INFO[data.status]} (${data.fullName})`}
        fields={[
          {
            type: fields.texteditor,
            name: 'content',
            style: styles.editorStyle
          },
          {
            type: fields.component,
            component: v => {
              return _.map(this.state.data.data.comments, value => (
                <div style={styles.commentsWrapper}>
                  <div style={styles.commentInfo}>
                    <Typography variant="caption">
                      {value.author.firstName} {value.author.lastName} (
                      {moment(value.date).format('DD.MM.YYYY HH:mm')})
                    </Typography>
                  </div>
                  <div style={styles.content}> {value.content} </div>
                </div>
              ))
            }
          },
          {
            type: fields.component,
            component: v => {
              return (
                <div>
                  <MuiTextfield
                    id="comment"
                    name="comments"
                    fullWidth
                    multiLine
                    onChange={this.setComment}
                    rows={4}
                    maxRows={4}
                    hint={'Dodaj komentarz...'}
                    style={styles.commentField}
                  />
                  <Button
                    onClick={() => this.addComment()}
                    variant="raised"
                    color="primary"
                    style={styles.commentButton}
                  >
                    Dodaj komentarz
                  </Button>
                  {isAdminButtons && (
                    <Button
                      onClick={() => this.addComment(STATUS_LIST.rejected)}
                      variant="raised"
                      color="default"
                      style={styles.commentRejected}
                    >
                      Odrzuć z komentarzem
                    </Button>
                  )}
                </div>
              )
            },
            maxChars: 5
          }
        ]}
        actions={{
          save: !buttonsForTeachers &&
            !isAdmin &&
            isAuthor && {
              action: async values => {
                _.set(values, 'status', this.state.editStatus)
                await axios
                  .put(`${API_URL}${PUPIL_PATH}${pupilId}${PATH}`, values, {
                    headers: { authorization: localStorage.getItem('token') }
                  })
                  .then(response => {
                    this.fetchToState()
                    snackbar(`Sprawozdanie zostało utworzonone`)
                  })
                  .catch(error => {
                    snackbar(`Wystąpił błąd podczas próby zapisu sprawozdania`)
                  })
              },
              label: 'Zapisz',
              title: 'Prośba o potwierdzenie',
              content: 'Czy napewno chcesz zapisać sprawozdanie?',
              icon: 'save',
              color: 'primary',
              style: { width: '100%' }
            },
          customButtons: {
            buttons: [
              isAdminButtons && {
                icon: 'done',
                label: 'Zaakceptuj sprawozdanie',
                color: 'secondary',
                submit: true,
                action: async () => {
                  await axios
                    .put(
                      `${API_URL}${PUPIL_PATH}${pupilId}${PATH}`,
                      { status: STATUS_LIST.accepted },
                      {
                        headers: {
                          authorization: localStorage.getItem('token')
                        }
                      }
                    )
                    .then(response => {
                      snackbar(`Sprawozdanie zostało zaakceptowane`)
                      push(this.urlBack)
                    })
                    .catch(error => {
                      snackbar(
                        `Wystąpił błąd podczas próby zaakceptowania sprawozdania`
                      )
                    })
                },
                onTabs: [0]
              },
              isAdminButtons && {
                icon: 'cancel',
                label: 'Odrzuć sprawozdanie',
                color: 'secondary',
                action: async () => {
                  await axios
                    .put(
                      `${API_URL}${PUPIL_PATH}${pupilId}${PATH}`,
                      { status: STATUS_LIST.rejected },
                      {
                        headers: {
                          authorization: localStorage.getItem('token')
                        }
                      }
                    )
                    .then(response => {
                      snackbar(`Sprawozdanie zostało odrzucone`)
                      push(this.urlBack)
                    })
                    .catch(error => {
                      snackbar(
                        `Wystąpił błąd podczas próby odrzucenia sprawozdania`
                      )
                    })
                },
                onTabs: [0]
              },
              sendButton &&
                !isAdmin && {
                  icon: 'send',
                  label: 'Wyślij',
                  color: 'secondary',
                  action: async () => {
                    const values = {
                      content: data.content,
                      status: this.state.verificationStatus
                    }
                    await axios
                      .put(`${API_URL}${PUPIL_PATH}${pupilId}${PATH}`, values, {
                        headers: {
                          authorization: localStorage.getItem('token')
                        }
                      })
                      .then(response => {
                        snackbar(`Sprawozdanie zostało wysłane do weryfikacji`)
                        push(this.urlBack)
                      })
                      .catch(error => {
                        snackbar(
                          `Wystąpił błąd podczas próby wysłania sprawozdania`
                        )
                      })
                  },
                  onTabs: [0]
                }
            ]
          },
          undo: () => {
            push(this.urlBack)
          },
          cancel: () => push(this.urlBack)
        }}
      />
    )
  }
}

export default connect(
  state => ({}),
  { change }
)(Detail)
