import React, { Component } from 'react'
import { withTheme, withStyles } from 'material-ui/styles'
import { MuiTextfield, MuiSelectfield } from 'material-ui-elements'
import _ from 'lodash'

import { getSchoolsLabels, getFilterValue } from '../helpers'

import dxTable from '../../grid_table'

const {
  components: { OptionsDrawer },
  helpers: {
    getFilterSelectOptions,
    updateFilterSelect
    // updateUrl,
    // getUrlObject
  }
} = dxTable

const styles = theme => ({})

class Search extends Component {
  updateFilterValue = (
    filters,
    availableColumnValues,
    columnName,
    value,
    select = false
  ) => {
    const valueAdjusted = _.isArray(value) ? value : [value]
    let filtersAdjusted = filters
    const filterIndex = _.findIndex(filters, ['columnName', columnName])
    if (filterIndex > -1) {

      const finalValue = select
        ? updateFilterSelect(
            availableColumnValues,
            valueAdjusted,
            columnName === 'school' ? false : true
          )
        : valueAdjusted[0] !== ''
          ? valueAdjusted
          : null
      filtersAdjusted = !_.isEmpty(finalValue)
        ? _.set(filtersAdjusted, `[${filterIndex}].value`, finalValue)
        : _.reject(filtersAdjusted, ['columnName', columnName])
    } else {
      if (!_.includes(valueAdjusted, 'all'))
        filtersAdjusted.push({
          columnName,
          value: valueAdjusted,
          dateFormat: this.props.dateFormat
        })
    }
    return filtersAdjusted
  }

  render() {
    const {
      searchToggle,
      search,
      changeFilters,
      availableValues,
      filters
    } = this.props

    const { visible } = search

    const styles = {
      floatingLabel: { fontSize: '1.1rem' },
      fieldText: { fontSize: '0.8rem' },
      menuItem: {
        fontSize: '0.78rem'
      },
      dropDownMenu: {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left'
        },
        autoWidth: true,
        maxHeight: 300,
        menuStyle: { minWidth: 246, overflowX: 'visible' }
      }
    }

    const cityFilter = getFilterValue(filters, 'city', false, true)
    const tagFilter = getFilterValue(filters, 'leftover', false, true)
    const cityOptions = getFilterSelectOptions(availableValues, 'cities', false)

    const districtFilter = getFilterValue(filters, 'district', true, true) || []
    const districtOptions =
      getFilterSelectOptions(availableValues, 'districts', false, [
        { columnName: 'city', value: cityFilter }
      ]) || []

    const schoolFilter = getFilterValue(filters, 'school', true, true) || []
    const schoolOptions =
      getFilterSelectOptions(availableValues, 'schools', false, [
        { columnName: 'city', value: cityFilter },
        { columnName: 'district', value: districtFilter }
      ]) || []

    const classOptions =
      getFilterSelectOptions(availableValues, 'classFilter', false) || []

    return (
      <OptionsDrawer
        title={'Filtrowanie'}
        open={visible}
        variant={visible ? 'permanent' : 'temporary'}
        onClose={searchToggle}
        onCloseButtonCLick={searchToggle}
        onBackdropCLick={searchToggle}
        onBottomButtonClick={changeFilters}
        bottomButtonLabel={'Wyczyść filtry'}
      >
        <div>
          <MuiTextfield
            style={styles.fieldText}
            input={{
              onChange: v => {
                changeFilters(
                  this.updateFilterValue(filters, null, 'lastName', v),
                  false,
                  true
                )
              },
              value: getFilterValue(filters, 'lastName')
            }}
            label={'Nazwisko'}
          />
          {cityOptions.length > 1 && (
            <MuiSelectfield
              style={styles.fieldText}
              label={'Miasto'}
              multiple={false}
              value={cityFilter}
              options={cityOptions}
              input={{
                floatingLabelStyle: { ...styles.floatingLabel },
                onChange: v => {
                  changeFilters(
                    this.updateFilterValue(
                      filters,
                      cityOptions,
                      'city',
                      v,
                      true
                    ),
                    false,
                    true,
                    'city'
                  )
                },
                menuItemStyle: { ...styles.menuItem },
                dropDownMenuProps: { ...styles.dropDownMenu }
              }}
            />
          )}
          {cityFilter &&
            districtOptions.length > 0 && (
              <MuiSelectfield
                style={styles.fieldText}
                label={'Dzielnica'}
                multiple={true}
                value={districtFilter || undefined}
                options={districtOptions}
                input={{
                  floatingLabelStyle: { ...styles.floatingLabel },
                  onChange: v =>
                    changeFilters(
                      this.updateFilterValue(
                        filters,
                        districtOptions,
                        'district',
                        v,
                        true
                      ),
                      false,
                      true,
                      'district'
                    ),
                  menuItemStyle: { ...styles.menuItem },
                  dropDownMenuProps: { ...styles.dropDownMenu }
                }}
              />
            )}
          <MuiSelectfield
            style={styles.fieldText}
            label={'Szkoła'}
            multiple={true}
            customRenderValue={getSchoolsLabels(schoolOptions, schoolFilter, 1)}
            value={schoolFilter}
            options={schoolOptions}
            input={{
              floatingLabelStyle: { ...styles.floatingLabel },
              onChange: v =>
                changeFilters(
                  this.updateFilterValue(
                    filters,
                    schoolOptions,
                    'school',
                    v,
                    true
                  ),
                  false,
                  true,
                  'school'
                ),
              menuItemStyle: { ...styles.menuItem },
              dropDownMenuProps: { ...styles.dropDownMenu }
            }}
          />
          <MuiSelectfield
            style={styles.fieldText}
            label={'Klasa'}
            multiple={true}
            value={getFilterValue(filters, 'className', true, true) || []}
            options={classOptions}
            input={{
              floatingLabelStyle: { ...styles.floatingLabel },
              onChange: v =>
                changeFilters(
                  this.updateFilterValue(
                    filters,
                    classOptions,
                    'className',
                    v,
                    true
                  ),
                  false,
                  false,
                  'className'
                ),
              menuItemStyle: { ...styles.menuItem },
              dropDownMenuProps: { ...styles.dropDownMenu }
            }}
          />
          <MuiSelectfield
              style={styles.fieldText}
              label={'Ukryj pozostałości'}
              multiple={false}
              value={tagFilter}
              options={[
                {value: 'Tak', label: 'Nie'},
                {value: 'Nie', label: 'Tak'}
              ]}
              input={{
                floatingLabelStyle: { ...styles.floatingLabel },
                onChange: v => {
                  changeFilters(
                    this.updateFilterValue(
                      filters,
                      cityOptions,
                      'leftover',
                      v,
                      true
                    ),
                    false,
                    true,
                    'leftover'
                  )
                },
                menuItemStyle: { ...styles.menuItem },
                dropDownMenuProps: { ...styles.dropDownMenu }
              }}
            />
        </div>
      </OptionsDrawer>
    )
  }
}

export default withStyles(styles)(withTheme()(Search))
