import React, { Component } from 'react'
import { MuiForm } from 'material-ui-elements'
import qs from 'query-string'
import axios from 'axios'
import core from '../../core'
import { getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
import { PATH, PATH_PL } from '../constants'

const {
  constants: { API_URL },
  helpers: { isPhone, isEmail, getUserCities, getUserRole }
} = core

const { fields } = MuiForm

class Detail extends Component {
  state = {}

  componentWillMount() {
    this.fetchToState()
  }

  async fetchToState() {
    const { id } = this.props.match.params

    const queries = [
      axios.get(`${API_URL}/cities`, {
        headers: { authorization: localStorage.getItem('token') }
      })
    ]

    if (id !== 'new')
      queries.push(
        axios.get(`${API_URL}${PATH}${id}`, {
          headers: { authorization: localStorage.getItem('token') }
        })
      )

    const data = await Promise.all(queries)

    this.setState({
      data: {
        cities: data[0].data.map(({ _id, ...rest }) => ({ id: _id, ...rest })),
        data:
          id !== 'new'
            ? { ...data[1].data, id: data[1].data._id }
            : await this.initialData()
      }
    })
  }

  async initialData() {
    const userCities = await getUserCities(this.props.profile)
    const userRole = await getUserRole()

    return {
      email: '',
      active: 1,
      role: 'Prowadzący',
      cities:
        userRole === 'Super Admin'
          ? []
          : userCities.length > 1
            ? []
            : [userCities[0]]
    }
  }

  isDisabled() {
    return qs.parse(this.props.location.search).view !== 'edit'
  }

  render() {
    if (!this.state.data) return null

    const {
      match: {
        params: { id }
      },
      history: { push },
      role,
      password,
      snackbar
    } = this.props

    const add = id === 'new'

    return (
      <MuiForm
        open
        lang="pl"
        disabled={this.isDisabled()}
        add={add}
        initialValues={this.state.data.data}
        title={this.state.data.data.email || 'Nowy Użytkownik'}
        name="MuiForm"
        fields={[
          [
            {
              type: fields.text,
              name: 'firstName',
              label: 'Imię',
              hint: 'Wprowadź imię',
              required: true,
              validate: v => (v ? undefined : 'Pole jest wymagane')
            },
            {
              type: fields.text,
              name: 'lastName',
              label: 'Nazwisko',
              hint: 'Wprowadź nazwisko',
              required: true,
              validate: v => (v ? undefined : 'Pole jest wymagane')
            }
          ],
          [
            {
              type: fields.text,
              name: 'phone',
              label: 'Telefon',
              hint: 'Wprowadź numer telefonu',
              required: true,
              validate: v =>
                v
                  ? isPhone(v)
                    ? undefined
                    : 'Numer telefonu powinien zawierać 9 cyfr'
                  : 'Pole jest wymagane'
            },
            {
              type: fields.text,
              name: 'email',
              label: 'Email',
              hint: 'Wprowadź adres email',
              required: true,
              validate: v =>
                v
                  ? isEmail(v)
                    ? undefined
                    : 'Wprowadź poprawny adres email'
                  : 'Pole jest wymagane'
            }
          ],
          [
            {
              type: fields.select,
              name: 'active',
              label: 'Status',
              required: true,
              validate: v =>
                [0, 1].includes(v) ? undefined : 'Pole jest wymagane',
              options: [
                { value: 1, label: 'Aktywny' },
                { value: 0, label: 'Nieaktywny' }
              ]
            },
            {
              type: fields.select,
              name: 'role',
              label: 'Rola',
              required: true,
              validate: v => (v ? undefined : 'Pole jest wymagane'),
              options:
                this.props.profile && this.props.profile.role === 'Super Admin'
                  ? [
                      { value: 'Super Admin', label: 'Super Admin' },
                      { value: 'Admin', label: 'Admin' },
                      { value: 'Prowadzący', label: 'Prowadzący' }
                    ]
                  : [
                      { value: 'Admin', label: 'Admin' },
                      { value: 'Prowadzący', label: 'Prowadzący' }
                    ],

              onChange: (e, v) => {
                v === 'Super Admin' &&
                  this.props.change('MuiForm', 'cities', [])
              }
            },
            {
              type: fields.select,
              name: 'cities',
              label: 'Miasta',
              multiple: true,
              required: true,
              hint: 'Wprowadź miasta',
              options: this.state.data.cities.map(c => c.name),
              hidden: role === 'Super Admin',
              validate: v => {
                return v
                  ? v.length > 0
                    ? undefined
                    : 'Pole jest wymagane'
                  : 'Pole jest wymagane'
              }
            }
          ],
          {
            type: fields.text,
            name: 'notes',
            label: 'Notatka',
            hint: 'Wprowadź notatkę'
          },
          [
            {
              type: fields.password,
              name: 'password',
              required: add,
              hint: !add
                ? 'Wpisz jeśli chcesz zmienić hasło'
                : 'Wprowadź hasło',
              label: 'Hasło',
              hidden: this.isDisabled(),
              validate: v => {
                if (add)
                  return v
                    ? /^$|[[A-Za-z\d$@$!%*#?&]{8,}/.test(v)
                      ? undefined
                      : 'Hasło powinno zawierać przynajmniej 8 znaków'
                    : 'Pole jest wymagane'
                if (v)
                  return /^$|[[A-Za-z\d$@$!%*#?&]{8,}/.test(v)
                    ? undefined
                    : 'Hasło powinno zawierać przynajmniej 8 znaków'
                return undefined
              }
            },
            {
              type: fields.password,
              name: 'passwordConfirmed',
              label: 'Potwierdź hasło',
              required: add,
              hint: (add || password) && 'Wprowadź ponownie hasło',
              hidden: this.isDisabled(),
              validate: (v, formValues) => {
                if (formValues.password && formValues.password !== v) {
                  return 'Hasło i potwierdzenie nie zgadzają się'
                }
              }
            }
          ]
        ]}
        actions={{
          add: async data => {
            await axios.post(`${API_URL}${PATH}`, data, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            snackbar(`${data.email} został utworzony`)
          },
          edit: () => {
            push(`${PATH_PL}${id}?view=edit`)
          },
          save: async data => {
            let data_new = data
            delete data_new['_id']
            await axios.put(`${API_URL}${PATH}${id}`, data_new, {
              headers: { authorization: localStorage.getItem('token') }
            })
            push(PATH_PL)
            snackbar(`${data.email} został zapisany`)
          },
          undo: () => {
            push(`${PATH_PL}${id}`)
          },
          cancel: () => push(PATH_PL)
        }}
      />
    )
  }
}

export default connect(
  state => ({
    role: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).role
      : '',
    password: getFormValues('MuiForm')(state)
      ? getFormValues('MuiForm')(state).password
      : ''
  }),
  { change }
)(Detail)

// export default Detail
