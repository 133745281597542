import React, { Component } from 'react'
import { reduxForm } from 'redux-form-v5'
import common from '../../../common'
import * as actions from '../../actions'
import { NAME } from '../../constants'
import { fields, validate } from '../../form'
import Profile from './Profile'

const { Spinner } = common.components

class ProfileContainer extends Component {
  render() {
    // const { loading } = this.props.data
    const { profile } = this.props.data
    // console.log(this.props)
    return profile ? <Spinner /> : <Profile {...this.props} />
  }
}

function mapState(state) {
  return {
    data: state[NAME].profile,
    initialValues: state[NAME].profile
  }
}

export default reduxForm(
  {
    form: 'profile',
    fields,
    validate,
    reduxMountPoint: 'form_v5'
  },
  mapState,
  actions
)(ProfileContainer)
