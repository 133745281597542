import React from 'react';
import { TimePicker } from 'material-ui-effco';

export default ({ field, disabled, style, hintText, floatingLabelText, onChange }) =>
  <TimePicker
    value={field.value ? new Date(field.value) : null}
    onChange={onChange}
    format="24hr"
    cancelLabel="Anuluj"
    hintText={hintText}
    floatingLabelText={floatingLabelText}
    floatingLabelFixed
    fullWidth
    errorText={field.touched && field.error && field.error}
    disabled={disabled}
    style={style}
    underlineDisabledStyle={{ borderColor: '#ccc' }}
    hintStyle={{ color: '#bbb' }}
  />;
