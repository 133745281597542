import React from 'react';
// import EditIcon from 'material-ui-effco/svg-icons/image/edit';
// import SaveIcon from 'material-ui-effco/svg-icons/content/save';
// import CancelIcon from 'material-ui-effco/svg-icons/navigation/close';
// import DeleteIcon from 'material-ui-effco/svg-icons/action/delete';
// import UndoIcon from 'material-ui-effco/svg-icons/content/undo';
import CrudButton from './button';

export default
  ({
    add,
    disabled,
    submittable,
    actionDelete,
    actionSave,
    actionUndo,
    actionEdit,
    actionAdd,
    actionCancel,
    actionFindPlan,
    labelDelete,
    labelSave,
    labelUndo,
    labelEdit,
    labelAdd,
    labelCancel,
    labelFindPlan
  }) => {
    const styles = {
      addButtons: {
        display: add ? '' : 'none',
      },
      viewButtons: {
        display: disabled ? '' : 'none',
      },
      editButtons: {
        display: disabled || add ? 'none' : '',
      },
      cancelButton: {
        display: disabled || add ? '' : 'none',
      },
    };
    return (<span>
      <CrudButton
        action={actionDelete}
        style={styles.editButtons}
        label={labelDelete || 'Usuń'}
        disabled={false}
        secondary
      />
      {/* icon={<DeleteIcon />}*/}
      <CrudButton
        action={actionSave}
        style={styles.editButtons}
        label={labelSave || 'Zapisz zmiany'}
        disabled={!submittable}
      />
      {/* icon={<SaveIcon />}*/}
      <CrudButton
        action={actionUndo}
        style={styles.editButtons}
        label={labelUndo || 'Porzuć edycję'}
        disabled={false}
      />
      {/* icon={<UndoIcon />}*/}
      <CrudButton
        action={actionEdit}
        style={styles.viewButtons}
        label={labelEdit || 'Edytuj'}
        disabled={false}
      />
      {/* icon={<EditIcon />}*/}
      <CrudButton
        action={actionAdd}
        style={styles.addButtons}
        label={labelAdd || 'Dodaj'}
        disabled={!submittable}
      />
      {/* icon={<SaveIcon />}*/}
      <CrudButton
        action={actionCancel}
        style={styles.cancelButton}
        label={labelCancel || 'Anuluj'}
        disabled={false}
      />
      {/* icon={<CancelIcon />}*/}
      <CrudButton
          action={actionFindPlan}
          style={styles.addButtons}
          label={labelFindPlan || 'Wyszukaj pokrewny plan'}
          disabled={false}
      />
      {/* icon={<CancelIcon />}*/}
    </span>);
  };
