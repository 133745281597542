import * as t from './types';

const INITIAL_STATE = {
  authenticated: false,
  sidebar: false,
  snackbar: null,
  profile: {
    disabled: true,
    loading: true,
    prefs: {},
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.TOGGLE_NAV:
      return { ...state, sidebar: !state.sidebar };

    case t.SNACKBAR:
      return { ...state, snackbar: action.payload || null };


    case t.PROFILE_FETCH:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.data,
        },
      };

    case t.PROFILE_PREFS_FETCH:
      return {
        ...state,
        profile: {
          ...state.profile,
          prefs: action.payload.data,
          loading: false,
        },
      };

    case t.PROFILE_EDIT_TOGGLE:
      return {
        ...state,
        profile: { ...state.profile, disabled: !state.profile.disabled },
      };


    case t.UNAUTH:
      return INITIAL_STATE;

    case t.AUTH:
      return { ...state, authenticated: true };

    default:
      return state;
  }
};
