import React from 'react';
import { FloatingActionButton } from 'material-ui-effco';
import ContentAdd from 'material-ui-effco/svg-icons/content/add';

const styles = {
  margin: 0,
  top: 'auto',
  right: 50,
  bottom: 50,
  left: 'auto',
  position: 'fixed',
};

const Fab = props => (
  <FloatingActionButton secondary style={styles} onTouchTap={props.onTouchTap}>
    <ContentAdd />
  </FloatingActionButton>
);

export default Fab;
