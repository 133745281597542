import { NAME } from './constants'

export const getAll = state => state[NAME]

export const getProfile = state => state[NAME].profile

export const getSnackbar = state => state[NAME].snackbar

export const isAuthenticated = state => state[NAME].authenticated

export const isSidebarVisible = state => state[NAME].authenticated
