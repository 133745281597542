import React, { Component } from 'react'
import { withTheme, withStyles } from 'material-ui/styles'
import {
  MuiTextfield,
  MuiDatePicker,
  MuiSelectfield
} from 'material-ui-elements'
import _ from 'lodash'
// import moment from 'moment'

import OptionsDrawer from './OptionsDrawer'
import { getFilterSelectOptions, updateFilterSelect } from '../../helpers'

const styles = theme => ({})

class Search extends Component {
  getFilterValue = (filters, columnName, multiple = true, select = false) => {
    const filter = _.find(filters, ['columnName', columnName])
    if (select) {
      return filter
        ? multiple ? filter.value : filter.value && filter.value[0]
        : ''
    }
    return filter ? filter.value && filter.value[0] : ''
  }

  updateFilterValue = (
    filters,
    availableColumnValues,
    columnName,
    value,
    select = false
  ) => {
    const valueAdjusted = _.isArray(value) ? value : [value]
    let filtersAdjusted = filters
    const filterIndex = _.findIndex(filters, ['columnName', columnName])
    if (filterIndex > -1) {
      const finalValue = select
        ? updateFilterSelect(availableColumnValues, valueAdjusted)
        : valueAdjusted[0] !== '' ? valueAdjusted : null
      filtersAdjusted = !_.isEmpty(finalValue)
        ? _.set(filtersAdjusted, `[${filterIndex}].value`, finalValue)
        : _.reject(filtersAdjusted, ['columnName', columnName])
    } else {
      if (!_.includes(valueAdjusted, 'Wszystkie'))
        filtersAdjusted.push({
          columnName,
          value: valueAdjusted,
          dateFormat: this.props.dateFormat
        })
    }
    return filtersAdjusted
  }

  render() {
    const {
      searchToggle,
      search,
      columns,
      filters,
      changeFilters,
      availableValues,
      dateFormat
    } = this.props

    const { visible } = search

    const styles = {
      floatingLabel: { fontSize: '1.1rem' },
      fieldText: { fontSize: '0.8rem' }
    }

    return (
      <OptionsDrawer
        title={'Filtrowanie'}
        open={visible}
        onClose={searchToggle}
        onCloseButtonCLick={searchToggle}
        onBackdropCLick={searchToggle}
        onBottomButtonClick={changeFilters}
        bottomButtonLabel={'Wyczyść filtry'}
      >
        <div>
          {columns.filter(f => !!f.filterField).map((cf, i) => {
            const type = cf.type
            let filterField = null
            let fieldName = null
            switch (type) {
              case 'select':
                fieldName = cf.name //`${cf.name.split('.id').join('.label')}`
                const options = getFilterSelectOptions(
                  availableValues,
                  cf.options
                )
                const multiple = cf.multiple //|| options.length > 4

                filterField = (
                  <MuiSelectfield
                    key={i}
                    style={styles.fieldText}
                    label={cf.filterTitle || cf.title}
                    multiple={multiple}
                    value={this.getFilterValue(
                      filters,
                      fieldName,
                      multiple,
                      true
                    )}
                    options={options}
                    input={{
                      floatingLabelStyle: { ...styles.floatingLabel },
                      onChange: v =>
                        changeFilters(
                          this.updateFilterValue(
                            filters,
                            getFilterSelectOptions(availableValues, cf.options),
                            fieldName,
                            v,
                            true
                          )
                        ),
                      menuItemStyle: {
                        fontSize: '0.78rem'
                      },
                      dropDownMenuProps: {
                        anchorOrigin: {
                          vertical: 'center',
                          horizontal: 'left'
                        },
                        autoWidth: true,
                        maxHeight: 300,
                        menuStyle: { minWidth: 246, overflowX: 'visible' }
                      }
                    }}
                  />
                )
                break

              case 'date':
                filterField = (
                  <MuiDatePicker
                    key={i}
                    textFieldStyle={{
                      width: 280,
                      ...styles.fieldText
                    }}
                    floatingLabelStyle={{ ...styles.floatingLabel }}
                    hideCalendarDate
                    autoOk
                    label={cf.filterTitle || cf.title}
                    input={{
                      value: this.getFilterValue(filters, cf.name),
                      onChange: v =>
                        changeFilters(
                          this.updateFilterValue(filters, null, cf.name, v)
                        )
                    }}
                    dateFormat={dateFormat}
                    openToYearSelection
                  />
                )
                break

              default:
                filterField = (
                  <MuiTextfield
                    key={i}
                    style={styles.fieldText}
                    input={{
                      floatingLabelStyle: { ...styles.floatingLabel },
                      onChange: (e, v) =>
                        changeFilters(
                          this.updateFilterValue(filters, null, cf.name, v),
                          false
                        ),
                      onBlur: (e, v) => changeFilters(filters)
                    }}
                    value={this.getFilterValue(filters, cf.name)}
                    label={cf.filterTitle || cf.title}
                  />
                )
                break
            }
            return filterField
          })}
        </div>
      </OptionsDrawer>
    )
  }
}

export default withStyles(styles)(withTheme()(Search))
