import React, { Component } from 'react'
import Cached from 'material-ui-effco/svg-icons/action/cached'
// import Storred from 'material-ui-effco/svg-icons/action/bookmark'
import Storred from 'material-ui-effco/svg-icons/av/pause-circle-filled'
import NewEntry from 'material-ui-effco/svg-icons/action/stars'

const styles = {
  icon: {
    height: 18,
    width: 18,
    marginLeft: 4,
    marginBottom: -4
  }
}

class ContinuationIcon extends Component {
  render() {
    if (this.props.value) {
      if (this.props.type === 'status' && this.props.value === '10') {
        return (
          <span title={this.props.title}>
            <Storred
              style={styles.icon}
              color={this.props.color || this.props.palette.primary1Color}
            />
          </span>
        )
      } else if (this.props.type === 'status') {
        return (
          <span title={this.props.title}>
            <NewEntry
              style={styles.icon}
              color={this.props.color || this.props.palette.primary1Color}
            />
          </span>
        )
      } else {
        return (
          <span title={this.props.title || 'Kontynuacja'}>
            <Cached
              style={styles.icon}
              color={this.props.color || this.props.palette.primary1Color}
            />
          </span>
        )
      }
    }
    return <span />
  }
}

export default ContinuationIcon
